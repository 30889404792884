export enum PyramidDesk {
    HEADLINE = 1,
    EUROPE_STORIES = 2,
    GREEN = 3,
    BUSINESS = 4,
    CULTURE = 5,
    TRAVEL = 6,
    HEALTH = 7,
    NEXT = 8,
    EUROVIEWS = 9,
    EU_POLICY = 10,
    SOCIAL_MEDIA = 11,
    NO_COMMENT = 12,
    EU_MAGAZINES = 13,
    SPONSORS_MAGAZINES = 14,
}

export enum PyramidDeskLabel {
    HEADLINE = 'Headline',
    EUROPE_STORIES = 'Europe Stories',
    GREEN = 'Green',
    BUSINESS = 'Business',
    CULTURE = 'Culture',
    TRAVEL = 'Travel',
    HEALTH = 'Health',
    NEXT = 'Next',
    EUROVIEWS = 'Euroviews',
    EU_POLICY = 'Eu policy',
    SOCIAL_MEDIA = 'Social Media',
    NO_COMMENT = 'No comment',
    EU_MAGAZINES = 'Eu Magazines',
    SPONSORS_MAGAZINES = 'Sponsors Magazines',
}
