import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { User } from 'entities/user'
import { takeUntil } from 'rxjs/operators'
import { environment } from '../environments/environment'
import { AuthService } from '../services/auth/auth'
import { BaseAbstractComponent } from './shared/base.component'

@Component({
    selector: 'cms-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseAbstractComponent implements OnInit {
    isProd = environment.production

    public currentUser: User

    constructor(private authService: AuthService, private router: Router) {
        super()
    }

    ngOnInit(): void {
        this.authService.me.subscribe(user => {
            this.currentUser = user
        })
    }

    isAuth(): boolean {
        return this.authService.isAuthentified
    }

    getUserId(): number | null {
        return this.authService.currentUser ? this.authService.currentUser.id : null
    }

    logout(ev) {
        ev.preventDefault()
        this.authService
            .logout()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.router.navigate(['login'])
            })
    }
}
