import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { ProgramCategoryModel } from 'models/program-category.model'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter } from 'rxjs/operators'
import { cacheAndNetworkQuery } from 'tools/apollo'
import { PaginationInterface, ResultPaginationInterface } from '../../interfaces/mercury'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { programCategoriesQuery } from './program-categories.query'

@Injectable()
export class ProgramCategoryService extends BaseService {
    /**
     *
     * @type BehaviorSubject<ProgramCategoryModel[]>
     */
    protected _programCategories: BehaviorSubject<ProgramCategoryModel[]> = new BehaviorSubject([])

    /**
     * @type Observable<ProgramCategoryModel[]>
     */
    public programCategories: Observable<ProgramCategoryModel[]> = this._programCategories
        .asObservable()
        .pipe(filter(value => !!value))

    /**
     *
     * @type BehaviorSubject<any>
     */
    protected _pagination: BehaviorSubject<ResultPaginationInterface | undefined> = new BehaviorSubject(undefined)

    /**
     * @type Observable<any>
     */
    public pagination: Observable<any> = this._pagination.asObservable().pipe(filter(value => !!value))

    /**
     *
     * @param apollo
     */
    constructor(apollo: Apollo, errorsMessagesService: ErrorsMessagesService) {
        super(apollo, errorsMessagesService)
    }

    /**
     *
     * @param pagination
     * @returns {Observable<any>}
     */
    getAllProgramCategories(pagination?: PaginationInterface) {
        cacheAndNetworkQuery(
            this.apollo,
            {
                query: programCategoriesQuery,
                variables: {
                    pagination,
                },
            },
            ['data'],
        ).subscribe(
            (data: { programCategories }) => {
                this._programCategories.next(data.programCategories.results)
                this._pagination.next(data.programCategories.pagination)
            },
            error => {
                // returned an empty programCategories' array
                this._programCategories.next([])
                // add an error
                this.errorMessagesServices.addErrorsMessage(error)
            },
        )
    }
}
