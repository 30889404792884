import { Component, OnDestroy, OnInit } from '@angular/core'

@Component({
    selector: 'cms-today-date',
    templateUrl: './today-date.component.html',
    styleUrls: ['./today-date.component.scss'],
})
export class TodayDateComponent implements OnInit, OnDestroy {
    timer: any = 0
    now: Date = new Date()

    constructor() {}

    ngOnInit() {
        this.timer = this.run()
    }

    ngOnDestroy() {
        clearInterval(this.timer)
    }

    run() {
        return setInterval(() => {
            this.now = new Date()
        }, 1000)
    }
}
