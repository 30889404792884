<section class="c-custom-filter qa-composite-filter composite">
    <div class="c-custom-filter__arrow"></div>
    <div class="c-custom-filter__composite">
        <ng-content></ng-content>
    </div>
    <div class="c-custom-filter__footer">
        <button type="button" class="btn btn-outline" (click)="resetValue()">Clear</button>
        <button type="button" class="btn btn-primary" (click)="filter()">Apply</button>
    </div>
</section>
