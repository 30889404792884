<h1 class="dashboard-section-title">
    <strong>All articles</strong>
</h1>

<cms-contributor-filters
    [datagridFilters]="activeDatagridFilters"
    [disableAddfilterButton]="isLoading"
    (selectedFilters)="onContributorFiltersSelected($event)"></cms-contributor-filters>

<!-- Toolbar Filters -->
<div class="u-no-gutter-right toolbar-filters">
    <h3 class="c-dashboard__subtitle">Filters :</h3>

    <!-- Filters list -->
    <div class="u-flex">
        <div class="clr-checkbox-wrapper toolbar-filters__items" *ngFor="let opt of toolbarFiltersList">
            <input
                class="clr-checkbox"
                type="checkbox"
                #toolbarStoriesCheckboxes
                id="opt-{{ opt.label }}-story"
                [name]="opt.label"
                [checked]="opt.selected"
                (change)="onToolbarFilterSelected(opt)" />
            <label
                class="toolbar-filters__label"
                id="opt-label-{{ opt.label }}-story"
                for="opt-{{ opt.label }}-story"
                >{{ opt.label }}</label
            >
        </div>
    </div>
</div>

<!-- Datagrid -->
<clr-datagrid (clrDgRefresh)="onDatagridRefresh($event)" [clrDgLoading]="isLoading" class="c-dashboard-pyramid">
    <!-- Placeholder -->
    <clr-dg-placeholder>We couldn't find any articles to display!</clr-dg-placeholder>

    <!-- COLUMNS -->

    <!-- Channel & Event name -->
    <clr-dg-column class="u-w250p"
        >Channel & Story name
        <clr-dg-filter [clrDgFilter]="channelsFilter" [(clrDgFilterOpen)]="channelsFilter.open">
            <cms-datagrid-composite-filter #channelsFilter [filterName]="FilterName.CHANNELS_AND_EVENTS_COMPOSITE">
                <cms-datagrid-filterable-filter
                    [title]="'Channel'"
                    [filterTitle]="'Channel'"
                    [type]="FilterTypes.CHECKBOX"
                    [list]="channelsList"
                    [filterName]="FilterName.CHANNELS"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.CHANNELS]"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.CHANNELS, $event)"
                    #injectedFilters></cms-datagrid-filterable-filter>

                <cms-datagrid-filterable-filter
                    [title]="'Search a story by name or ID'"
                    [filterTitle]="'Story'"
                    [displayList]="false"
                    [filterName]="FilterName.EVENTS"
                    [searchPlaceholder]="'Type a story name or ID'"
                    [useList]="false"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.EVENTS]"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.EVENTS, $event)"
                    #injectedFilters></cms-datagrid-filterable-filter>
            </cms-datagrid-composite-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <!-- Headline & CMS Story ID -->
    <clr-dg-column
        >Headline & CMS Article ID
        <clr-dg-filter [clrDgFilter]="headlineFilter" [(clrDgFilterOpen)]="headlineFilter.open">
            <cms-datagrid-filterable-filter
                #headlineFilter
                [title]="'Search by headline & CMS article ID'"
                [displayList]="false"
                [filterTitle]="'Headline'"
                [filterName]="FilterName.HEADLINES"
                [searchPlaceholder]="'Type a headline & CMS article ID'"
                [useList]="false"
                [contributorFilter]="contributorDatagridFilters[FilterName.HEADLINES]"
                (datagridSelectedFilter)="
                    onDatagridFilterSelected(FilterName.HEADLINES, $event)
                "></cms-datagrid-filterable-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <!-- Desk -->
    <clr-dg-column class="u-w160p">
        <ng-container *clrDgHideableColumn="{ hidden: false }">Desk</ng-container>
        <clr-dg-filter [clrDgFilter]="desksFilter" [(clrDgFilterOpen)]="desksFilter.open">
            <cms-datagrid-filterable-filter
                [title]="'Filter by desk'"
                [filterTitle]="'Desk'"
                [type]="FilterTypes.CHECKBOX"
                [list]="desksList"
                [filterName]="FilterName.DESKS"
                [contributorFilter]="contributorDatagridFilters[FilterName.DESKS]"
                (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.DESKS, $event)"
                #desksFilter></cms-datagrid-filterable-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <!-- Assigned to -->
    <clr-dg-column class="u-w160p">
        <ng-container *clrDgHideableColumn="{ hidden: false }">Assigned to</ng-container>
        <clr-dg-filter [clrDgFilter]="contributorsFilter" [(clrDgFilterOpen)]="contributorsFilter.open">
            <cms-datagrid-composite-filter #contributorsFilter [filterName]="FilterName.CONTRIBUTORS_COMPOSITE">
                <cms-datagrid-filterable-filter
                    [list]="currentContributorList"
                    [type]="FilterTypes.CHECKBOX"
                    [filterName]="FilterName.CURRENT_CONTRIBUTOR"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.CURRENT_CONTRIBUTOR]"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.CURRENT_CONTRIBUTOR, $event)"
                    #injectedFilters></cms-datagrid-filterable-filter>

                <cms-datagrid-filterable-filter
                    [title]="'Search by name'"
                    [filterTitle]="'Authors'"
                    [type]="FilterTypes.CHECKBOX"
                    [displayList]="false"
                    [filterName]="FilterName.CONTRIBUTORS"
                    [searchPlaceholder]="'Type a name'"
                    [showSelectedItemsAboveTheList]="true"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.CONTRIBUTORS]"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.CONTRIBUTORS, $event)"
                    #injectedFilters></cms-datagrid-filterable-filter>
            </cms-datagrid-composite-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <!-- Program & Vertical -->
    <clr-dg-column class="u-w160p">
        <ng-container *clrDgHideableColumn="{ hidden: false }">Program & Vertical</ng-container>
        <clr-dg-filter [clrDgFilter]="programAndVerticalFilter" [(clrDgFilterOpen)]="programAndVerticalFilter.open">
            <cms-datagrid-composite-filter
                #programAndVerticalFilter
                [filterName]="FilterName.PROGRAMS_AND_VERTICALS_COMPOSITE">
                <cms-datagrid-filterable-filter
                    [title]="'Search by vertical'"
                    [filterTitle]="'Verticals'"
                    [type]="FilterTypes.SELECT"
                    [list]="verticalsList"
                    [filterName]="FilterName.VERTICALS"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.VERTICALS]"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.VERTICALS, $event)"
                    #injectedFilters
                    #groupFilter></cms-datagrid-filterable-filter>

                <cms-datagrid-filterable-filter
                    [title]="'Search by program'"
                    [filterTitle]="'Programs'"
                    [type]="FilterTypes.GROUPED"
                    [list]="programsList"
                    [displayList]="false"
                    [filterName]="FilterName.PROGRAMS"
                    [searchPlaceholder]="'Program name...'"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.PROGRAMS]"
                    [groupFilter]="groupFilter.value[0]?.label || ''"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.PROGRAMS, $event)"
                    #injectedFilters
                    #filteredGroupedList></cms-datagrid-filterable-filter>
            </cms-datagrid-composite-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <!-- Lang. -->
    <clr-dg-column class="u-w80p">
        <ng-container *clrDgHideableColumn="{ hidden: false }">Lang.</ng-container>
        <clr-dg-filter [clrDgFilter]="languagesFilter" [(clrDgFilterOpen)]="languagesFilter.open">
            <cms-datagrid-composite-filter #languagesFilter [filterName]="FilterName.LANGUAGE_AND_WITH_VIDEO_COMPOSITE">
                <cms-datagrid-filterable-filter
                    [list]="storiesWithVideoList"
                    [filterTitle]="'Articles with video'"
                    [type]="FilterTypes.CHECKBOX"
                    [filterName]="FilterName.STORIES_WITH_VIDEO"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.STORIES_WITH_VIDEO]"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.STORIES_WITH_VIDEO, $event)"
                    #injectedFilters></cms-datagrid-filterable-filter>

                <hr class="lang-filter-hr" />

                <section class="all-languages-filter">
                    <ul class="filterable-list list-unstyled compact">
                        <li class="filterable-list-item">
                            <clr-checkbox-wrapper>
                                <input
                                    type="checkbox"
                                    clrCheckbox
                                    class="listed-checkbox"
                                    [checked]="checkedAllLanguages"
                                    id="lst-langs-check-all"
                                    (change)="toggleAllLanguages()" />
                                <label
                                    for="lst-langs-check-all"
                                    [class.itemActive]="checkedAllLanguages"
                                    class="lst-langs-check-all"
                                    >All languages</label
                                >
                            </clr-checkbox-wrapper>
                        </li>
                    </ul>
                </section>

                <hr class="lang-filter-hr" />

                <cms-datagrid-filterable-filter
                    [list]="languagesList"
                    [filterTitle]="'Languages'"
                    [type]="FilterTypes.CHECKBOX"
                    [filterName]="FilterName.LANGUAGES"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.LANGUAGES]"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.LANGUAGES, $event)"
                    #injectedFilters
                    #languagesListFilter></cms-datagrid-filterable-filter>
            </cms-datagrid-composite-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <!-- Story Type -->
    <clr-dg-column class="u-w150p">
        <ng-container *clrDgHideableColumn="{ hidden: true }">Article Type</ng-container>
        <clr-dg-filter [clrDgFilter]="storyTypesFilter" [(clrDgFilterOpen)]="storyTypesFilter.open">
            <cms-datagrid-filterable-filter
                [title]="'Filter by article type'"
                [filterTitle]="'Article type'"
                [type]="FilterTypes.CHECKBOX"
                [list]="storyTypesList"
                [filterName]="FilterName.STORY_TYPES"
                [contributorFilter]="contributorDatagridFilters[FilterName.STORY_TYPES]"
                (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.STORY_TYPES, $event)"
                #storyTypesFilter></cms-datagrid-filterable-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <!-- Pub. Date -->
    <clr-dg-column class="u-w135p">
        <ng-container *clrDgHideableColumn="{ hidden: true }">Pub. Date</ng-container>
        <clr-dg-filter [clrDgFilter]="publicationDate" [(clrDgFilterOpen)]="publicationDate.open">
            <cms-datagrid-date-range-filter
                [filterName]="FilterName.PUBLICATION_DATE"
                [contributorFilter]="contributorDatagridFilters[FilterName.PUBLICATION_DATE]"
                #publicationDate></cms-datagrid-date-range-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <!-- Last Update -->
    <clr-dg-column class="u-w135p">
        <ng-container *clrDgHideableColumn="{ hidden: true }">Last Update</ng-container>
        <clr-dg-filter [clrDgFilter]="UpdatedAtDate" [(clrDgFilterOpen)]="UpdatedAtDate.open">
            <cms-datagrid-date-range-filter
                [filterName]="FilterName.LAST_UPDATE_DATE"
                [contributorFilter]="contributorDatagridFilters[FilterName.LAST_UPDATE_DATE]"
                #UpdatedAtDate></cms-datagrid-date-range-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <!-- Pub. Status -->
    <clr-dg-column class="u-w120p">
        <ng-container *clrDgHideableColumn="{ hidden: false }">Pub. Status</ng-container>
        <clr-dg-filter [clrDgFilter]="pubStatusFilter" [(clrDgFilterOpen)]="pubStatusFilter.open">
            <cms-datagrid-filterable-filter
                [list]="statusesList"
                [filterTitle]="'Pub. Status'"
                [type]="FilterTypes.CHECKBOX"
                [filterName]="FilterName.PUBLICATION_STATUSES"
                [contributorFilter]="contributorDatagridFilters[FilterName.PUBLICATION_STATUSES]"
                (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.PUBLICATION_STATUSES, $event)"
                #pubStatusFilter></cms-datagrid-filterable-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <!-- ROWS -->
    <clr-dg-row
        *ngFor="let story of stories"
        [ngClass]="{ 'embargo-date': story.event.embargoDate, 'status-breakingNews': story.isBreakingNews }"
        [clrDgExpanded]="showRowDetails">
        <!-- Event name -->
        <clr-dg-cell class="u-cell-flex u-w250p">
            <img
                class="c-img"
                src="assets/images/search-box/{{ story.event.publicationOwner?.slug }}-96x96.png"
                width="20"
                height="20" />

            <a [routerLink]="['/story', story.id]" [title]="story.event.daletTitleName" class="eventName__link">
                {{ story.event.daletTitleName }}
            </a>

            <clr-tooltip *ngIf="story.event.productionNote" class="eventName__tooltip">
                <cds-icon
                    clrTooltipTrigger
                    role="img"
                    shape="info-circle"
                    size="20"
                    aria-label="Production note tooltip"></cds-icon>
                <clr-tooltip-content clrPosition="right" clrSize="md" *clrIfOpen>
                    {{ story.event.productionNote }}
                </clr-tooltip-content>
            </clr-tooltip>
        </clr-dg-cell>

        <!-- Story headline -->
        <clr-dg-cell>
            <a
                [routerLink]="['/story', story.id]"
                [title]="story.articleHeadline ? story.articleHeadline : '*** NO HEADLINE FOR THIS ARTICLE ***'"
                [innerHTML]="story.articleHeadline ? story.articleHeadline : '*** NO HEADLINE FOR THIS ARTICLE ***'"
                class="c-link">
            </a>
        </clr-dg-cell>

        <!-- Desk -->
        <clr-dg-cell>
            <span title="{{ story.event.pyramidDesk?.title }}">{{ story.event.pyramidDesk?.title }}</span>
        </clr-dg-cell>

        <!-- Assigned to -->
        <clr-dg-cell class="u-w160p">
            {{ story.assignee | contributorGetName }}
        </clr-dg-cell>

        <!-- Program & Vertical -->
        <clr-dg-cell class="u-w160p">
            <span>{{ story.event.program?.translations | translationFilter : 'title' | titlecase }}</span>
        </clr-dg-cell>

        <!-- Language -->
        <clr-dg-cell class="narrow-column u-w80p">
            <span>{{ story.language.isoCode }}</span>
            <cms-connected-contributors
                [display]="'dashboard'"
                [type]="'story'"
                [connectedContributorData]="connectedContributorData[story.id]">
            </cms-connected-contributors>
        </clr-dg-cell>

        <!-- Story type -->
        <clr-dg-cell class="storyType u-w150p">
            <span title="{{ getStoryType(story) }}">{{ getStoryType(story) }}</span>
        </clr-dg-cell>

        <!-- Publication date -->
        <clr-dg-cell class="date u-w135p">
            <span *ngIf="story.publishedAt">
                {{ story.publishedAt | formatDate }}
            </span>
        </clr-dg-cell>

        <!-- Last publication date -->
        <clr-dg-cell class="date u-w135p">
            <span *ngIf="story.lastPublishedAt">
                {{ story.lastPublishedAt | formatDate }}
            </span>
        </clr-dg-cell>

        <!-- Publication status -->
        <clr-dg-cell class="u-w135p">
            <cms-publication-status [story]="story"></cms-publication-status>
        </clr-dg-cell>

        <!-- Row details -->
        <cms-dashboard-stories-detail *clrIfExpanded [story]="story" ngProjectAs="clr-dg-row-detail">
        </cms-dashboard-stories-detail>
    </clr-dg-row>

    <!-- Footer -->
    <clr-dg-footer>
        <clr-dg-pagination #pagination [clrDgTotalItems]="total" [clrDgPageSize]="itemsPerPage" [clrDgPage]="page">
            {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} articles
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>

<cms-item-legend></cms-item-legend>
