import { DOCUMENT } from '@angular/common'
import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2, ViewContainerRef } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { UserRole } from 'enums/user-role'
import { FeatureFlag } from 'interfaces/feature-flag'
import { BehaviorSubject, Observable, Subject, of } from 'rxjs'
import { debounceTime, pluck, switchMap, take, tap } from 'rxjs/operators'
import { AuthService } from 'services/auth/auth'
import { networkQuery } from 'tools/apollo'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { featureFlag, featureFlags, updateFeatureFlag } from './feature-flag.query'

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagService extends BaseService implements OnDestroy {
    public refresh = new Subject()
    private featuresInBetaTest: string[] = []
    private routerLinkObserver: MutationObserver
    private routerLinkObserverSubject: BehaviorSubject<boolean> = new BehaviorSubject(true)
    private renderer: Renderer2

    constructor(
        apollo: Apollo,
        protected errorsMessagesService: ErrorsMessagesService,
        private authService: AuthService,
        rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private document: Document,
    ) {
        super(apollo, errorsMessagesService)
        this.renderer = rendererFactory.createRenderer(null, null)
    }

    public ngOnDestroy() {
        if (this.routerLinkObserverSubject) {
            this.routerLinkObserverSubject.unsubscribe()
        }
        if (this.routerLinkObserver) {
            this.routerLinkObserver.disconnect()
        }
    }

    public async init(): Promise<void> {
        await this.getFeatureFlagValue<string[]>('features-in-beta-test')
            .toPromise()
            .then(value => {
                this.featuresInBetaTest = value || []
            })

        this.setupRouterLinkCleaner()
    }

    public initFeature(viewContainerRef: ViewContainerRef, name: string): void {
        const viewRef = viewContainerRef.detach(0)

        const allowFeature = this.isAllowedFeature(name)

        if (allowFeature) {
            viewContainerRef.insert(viewRef!)
        } else {
            viewRef!.destroy()
        }
    }

    public isAllowedFeature(name: string): boolean {
        let allowFeature = true

        if (this.featuresInBetaTest.includes(name)) {
            const userRoles = this.authService.currentUser?.getRoles()

            allowFeature = userRoles?.some(role => [UserRole.DEVELOPER, UserRole.BETA_TESTER]?.includes(role)) || false
        }

        return allowFeature
    }

    public getFeatureFlags() {
        return networkQuery<FeatureFlag[]>(
            this.apollo,
            {
                query: featureFlags,
            },
            ['data', 'featureFlags'],
        )
    }

    public getFeatureFlagValue<T>(slug: string): Observable<T | undefined> {
        return networkQuery<FeatureFlag>(
            this.apollo,
            {
                query: featureFlag,
                variables: {
                    slug,
                },
            },
            ['data', 'featureFlag'],
        ).pipe(
            switchMap((featureFlag: FeatureFlag) => {
                let parsedValue: T | undefined

                try {
                    parsedValue = JSON.parse(featureFlag.value)
                } catch {}

                return of(parsedValue)
            }),
        )
    }

    public updateFeatureFlag(slug: string, value: string): Observable<FeatureFlag> {
        return this.apollo
            .mutate({
                mutation: updateFeatureFlag,
                variables: {
                    slug,
                    value,
                },
            })
            .pipe(take(1), pluck('data', 'updateFeatureFlag'))
    }

    private startRouterLinkObserver() {
        this.routerLinkObserver.observe(this.document.body, {
            attributes: true,
            subtree: true,
            attributeFilter: ['href'],
        })
    }

    private setupRouterLinkCleaner() {
        this.routerLinkObserver = new MutationObserver(() => {
            this.routerLinkObserverSubject.next(true)
        })

        this.routerLinkObserverSubject
            .pipe(
                tap(() => {
                    // The observer sometimes triggers itself, resulting in an infinite loop
                    // To prevent this, we disconnect it and reconnect it after a short delay
                    this.routerLinkObserver.disconnect()
                    this.cleanRouterLinks()
                }),
                debounceTime(50),
                tap(() => {
                    this.startRouterLinkObserver()
                }),
            )
            .subscribe()

        this.startRouterLinkObserver()
    }

    private cleanRouterLinks() {
        // Find all elements with routerLink attributes (href after compilation)
        const links = this.document.querySelectorAll('[href]')

        links.forEach((link: HTMLElement) => {
            const route = link.getAttribute('href')

            if (!this.isAllowedFeature(route!)) {
                // Remove the link for non-beta testers
                this.renderer.removeChild(link.parentElement, link)
            }
        })
    }
}
