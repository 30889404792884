/**
 * Display a progress bar
 *
 * @Input {boolean} isDisplayed
 * @Input {boolean} isLoading
 * @Ouput {none} none
 *
 * Ex : <cms-progress-bar [loadingFlagInit]="true"></cms-progress-bar>
 *
 */
import { Component, Input } from '@angular/core'

@Component({
    selector: 'cms-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
    @Input() isDisplayed: boolean
    @Input() isLoading: boolean = true
    @Input() message: string = ''
}
