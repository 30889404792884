import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'trimmedTitleCase' })
export class TrimmedTitleCasePipe implements PipeTransform {
    transform(input: any): any {
        if (typeof input !== 'string') {
            return input
        }

        return input
            .replace(/\s+/g, ' ')
            .trim()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ')
    }
}
