export const paginationFragment = `
fragment paginationInfo on Pagination {
    total
    count
    previous {
        limit
        offset
    }
    next {
        limit
        offset
    }
}
`
