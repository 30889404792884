import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { AfricanewsLanguages, LanguageIsoCode } from 'enums/language'
import { ResultPaginationInterface } from 'interfaces/mercury'
import { LanguageModel } from 'models/language.model'
import { map, pluck, take } from 'rxjs/operators'
import { fastClone } from 'tools/fast-clone'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { languagesQuery } from './languages.query'

type FullLanguageModel = Required<LanguageModel>

interface LanguagesResult {
    pagination: ResultPaginationInterface
    results: FullLanguageModel[]
}

@Injectable({
    providedIn: 'root',
})
export class LanguagesService extends BaseService {
    private _languages: FullLanguageModel[]
    private _languageById: Record<number, FullLanguageModel> = {}
    private _languageByIsoCode: Partial<Record<LanguageIsoCode, FullLanguageModel>> = {}
    private _languageByCollateLocaleCms: Partial<Record<LanguageIsoCode, FullLanguageModel>> = {}
    private _languageIsoCodeList: LanguageIsoCode[] = []

    private _coreLanguages: FullLanguageModel[]
    private _coreLanguageById: Record<number, FullLanguageModel> = {}
    private _coreLanguageByIsoCode: Partial<Record<LanguageIsoCode, FullLanguageModel>> = {}
    private _coreLanguageIsoCodeList: LanguageIsoCode[] = []

    private _nonCoreLanguages: FullLanguageModel[]

    private _africanewsLanguages: FullLanguageModel[]

    constructor(apollo: Apollo, errorsMessagesService: ErrorsMessagesService) {
        super(apollo, errorsMessagesService)
    }

    public get languages(): FullLanguageModel[] {
        return fastClone(this._languages)
    }
    public get languageById(): Record<number, FullLanguageModel> {
        return fastClone(this._languageById)
    }
    public get languageByIsoCode(): Partial<Record<LanguageIsoCode, FullLanguageModel>> {
        return fastClone(this._languageByIsoCode)
    }
    public get languageByCollateLocaleCms(): Partial<Record<LanguageIsoCode, FullLanguageModel>> {
        return fastClone(this._languageByCollateLocaleCms)
    }
    public get languageIsoCodeList(): LanguageIsoCode[] {
        return fastClone(this._languageIsoCodeList)
    }

    public get defaultLanguage(): FullLanguageModel {
        // Languages are ordered by priority.
        // We set the default language to the one with the highest priority (english).
        return fastClone(this.languages[0])
    }

    public get coreLanguages(): FullLanguageModel[] {
        return fastClone(this._coreLanguages)
    }
    public get coreLanguageById(): Record<number, FullLanguageModel> {
        return fastClone(this._coreLanguageById)
    }
    public get coreLanguageByIsoCode(): Partial<Record<LanguageIsoCode, FullLanguageModel>> {
        return fastClone(this._coreLanguageByIsoCode)
    }
    public get coreLanguageIsoCodeList(): LanguageIsoCode[] {
        return fastClone(this._coreLanguageIsoCodeList)
    }

    public get nonCoreLanguages(): FullLanguageModel[] {
        return fastClone(this._nonCoreLanguages)
    }

    public get africanewsLanguages(): FullLanguageModel[] {
        return fastClone(this._africanewsLanguages)
    }

    public isCoreLanguage(language: LanguageModel): boolean {
        return !!this.coreLanguageById[language.id]
    }
    public isCoreLanguageIsoCode(languageIsoCode: LanguageIsoCode): boolean {
        return !!this.coreLanguageByIsoCode[languageIsoCode]
    }

    public async init(): Promise<void> {
        await this.getLanguages().then(
            languages => {
                this.setLanguages(languages)
                this.setLanguageRecords()
                this.setLanguageLists()
            },
            error => {
                this.errorMessagesServices.addErrorsMessage(error)
            },
        )
    }

    private async getLanguages(): Promise<Required<LanguageModel>[]> {
        return this.apollo
            .query({
                query: languagesQuery,
                fetchPolicy: 'cache-first',
            })
            .pipe(
                take(1),
                pluck('data'),
                map((data: { languages: LanguagesResult }) =>
                    data.languages.results.map(language => ({
                        ...language,
                        fullname: language.fullname![0].toUpperCase() + language.fullname!.slice(1),
                    })),
                ),
            )
            .toPromise()
    }

    private setLanguages(languages: FullLanguageModel[]): void {
        this._languages = languages
        this._coreLanguages = languages.filter(language => language.isCoreLanguage)
        this._nonCoreLanguages = languages.filter(language => !language.isCoreLanguage)
        this._africanewsLanguages = languages.filter(language => AfricanewsLanguages.includes(language.id))
    }

    private setLanguageRecords(): void {
        for (const language of this.languages) {
            this._languageById[language.id] = language
            this._languageByIsoCode[language.isoCode] = language
            this._languageByCollateLocaleCms[language.collateLocaleCMS] = language
        }

        for (const coreLanguage of this.coreLanguages) {
            this._coreLanguageById[coreLanguage.id] = coreLanguage
            this._coreLanguageByIsoCode[coreLanguage.isoCode] = coreLanguage
        }
    }

    private setLanguageLists(): void {
        this._languageIsoCodeList = this.languages.map(language => language.isoCode)
        this._coreLanguageIsoCodeList = this.coreLanguages.map(coreLanguage => coreLanguage.isoCode)
    }
}
