<div class="clr-select-wrapper">
    <select
        [id]="selectId"
        class="clr-select"
        (ngModelChange)="themeChange($event)"
        [ngModel]="selectedTheme"
        [disabled]="disabled"
        [ngModelOptions]="{ standalone: true }"
        *ngIf="!showLoaderState">
        <option [ngValue]="-1">{{ firstOptionString }}&#8230;</option>
        <ng-container *ngFor="let themesList of themesListByVertical | keyvalue; trackBy: trackByVertical">
            <optgroup label="{{ themesList.key }}">
                <option *ngFor="let theme of themesList.value" [ngValue]="theme.id">
                    {{ theme.title }}
                </option>
            </optgroup>
        </ng-container>
    </select>
</div>
