import { ToolbarFilter, BaseDashboardSearchFilters, DashboardSearchFilters } from 'enums/filters'
import { PublicationStatus } from 'enums/publication-status'
import { HelpersDates } from 'services/helpers/helpersDates'
import { BaseService } from '../base'

export abstract class DashboardAbstract extends BaseService {
    getDefaultQueryVariables<T extends DashboardSearchFilters>(filters: T) {
        const queryVariables = {
            search: {},
        }

        const selectedRules = this.getStatus(filters.options, this.rulesOptions)

        if (filters.daletVersionId) {
            queryVariables['search']['daletVersionId'] = filters.daletVersionId
        }

        if (filters.programId) {
            queryVariables['search']['programId'] = filters.programId
        }

        if (filters.verticalId) {
            queryVariables['search']['verticalId'] = filters.verticalId
        }

        if (filters.typeId) {
            queryVariables['search']['typeId'] = [filters.typeId]
        }

        if (filters.publishedAt) {
            queryVariables['search']['dateRange'] = {
                from: HelpersDates.formatDate(filters.publishedAt[0]),
                to: HelpersDates.formatDate(filters.publishedAt[1]),
            }
        }

        if (filters.lastPublishedAt) {
            queryVariables['search']['lastPublicationDateRange'] = {
                from: HelpersDates.formatDate(filters.lastPublishedAt[0]),
                to: HelpersDates.formatDate(filters.lastPublishedAt[1]),
            }
        }

        if (filters.publicationOwnerId) {
            queryVariables['search']['publicationOwnerId'] = filters.publicationOwnerId
        }

        if (filters.publicationStatus?.length) {
            queryVariables['search']['publicationStatus'] = filters.publicationStatus
        }

        if (filters.withVideoOnly) {
            queryVariables['search']['withVideoOnly'] = filters.withVideoOnly
        }

        if (selectedRules.indexOf(ToolbarFilter.IS_MAGAZINE) !== -1) {
            queryVariables['search']['isMagazine'] = true
        }

        if (selectedRules.indexOf(ToolbarFilter.NEWS) !== -1) {
            queryVariables['search']['isMagazine'] = false
        }

        if (selectedRules.indexOf(ToolbarFilter.EMBARGO) !== -1) {
            queryVariables['search']['hasEmbargo'] = true
        }

        if (selectedRules.indexOf(ToolbarFilter.BREAKING_NEWS) !== -1) {
            queryVariables['search']['isBreakingNews'] = true
        }

        return queryVariables
    }

    protected rulesOptions = [
        PublicationStatus.OFFLINE,
        PublicationStatus.ONLINE,
        PublicationStatus.SCHEDULED,
        PublicationStatus.TO_REPUBLISH,
        PublicationStatus.READY_TO_PUBLISH,
        PublicationStatus.EDIT,
        PublicationStatus.NEW,
        ToolbarFilter.BREAKING_NEWS,
        ToolbarFilter.IS_MAGAZINE,
        ToolbarFilter.NEWS,
        ToolbarFilter.EMBARGO,
    ]

    protected getStoryFilters<T extends BaseDashboardSearchFilters>(filters: T) {
        const variables = {}

        // add search pattern if needed
        if (filters.search) {
            variables['term'] = filters.search
        }

        const rules = {}

        const selectedRules = this.getStatus(filters.options, this.rulesOptions)

        if (selectedRules.indexOf(PublicationStatus.SCHEDULED) !== -1) {
            rules['scheduled'] = true
        }

        if (selectedRules.indexOf(PublicationStatus.TO_REPUBLISH) !== -1) {
            rules['toRepublish'] = true
        }

        if (selectedRules.indexOf(PublicationStatus.READY_TO_PUBLISH) !== -1) {
            rules['readyToPublish'] = true
        }

        if (selectedRules.indexOf(PublicationStatus.NEW) !== -1) {
            rules['new'] = true
        }

        if (selectedRules.indexOf(PublicationStatus.EDIT) !== -1) {
            rules['edit'] = true
        }

        if (selectedRules.indexOf(PublicationStatus.ONLINE) !== -1) {
            rules['online'] = true
        }

        if (selectedRules.indexOf(PublicationStatus.OFFLINE) !== -1) {
            rules['offline'] = true
        }

        if (Object.keys(rules).length > 0) {
            variables['rules'] = rules
        }

        if (selectedRules.indexOf(ToolbarFilter.IS_MAGAZINE) !== -1) {
            variables['isMagazine'] = true
        }

        if (selectedRules.indexOf(ToolbarFilter.NEWS) !== -1) {
            variables['isMagazine'] = false
        }

        return variables
    }

    getStatus(options: { id: number; value: boolean }[], statusList: number[]): number[] {
        const opt: number[] = []
        options.forEach(status => {
            if (status.value === true && statusList.indexOf(status.id) !== -1) {
                opt.push(status.id)
            }
        })

        return opt
    }
}
