/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core'
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router'
import { Observable } from 'rxjs'
import { FeatureFlagService } from 'services/feature-flag/feature-flag.service'
import { AuthService } from '../services/auth/auth'

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        private authService: AuthService,
        private featureFlagService: FeatureFlagService,
    ) {}

    /**
     *
     * @param route
     * @param state
     * @returns {Promise<boolean>}
     */
    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkUser(route, state.url)
    }

    /**
     *
     * @param route
     * @param state
     * @returns {Promise<boolean>}
     */
    public canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkUser(route, state.url)
    }

    private async checkUser(route: ActivatedRouteSnapshot, url: string): Promise<boolean | UrlTree> {
        const isAuth = await this.authService.isAuth()

        if (!isAuth) {
            // not logged in so redirect to login page with the return url
            return this.router.createUrlTree(['/login'], {
                queryParams: { returnUrl: url },
            })
        }

        if (
            !this.authService.userHasAccess({
                right: route.data?.right,
                role: route.data?.role,
            }) ||
            !this.featureFlagService.isAllowedFeature(url)
        ) {
            // access not allowed so redirect to unauthorized page
            return this.router.createUrlTree(['/unauthorized'])
        }

        return true
    }
}
