import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { GalleryModel, GalleryModelForPreview } from 'models/gallery.model'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter, pluck } from 'rxjs/operators'
import { cacheAndNetworkQuery, networkQuery } from 'tools/apollo'
import { QueryOptionsInterface, ResultPaginationInterface } from '../../interfaces/mercury'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import {
    galleriesQuery,
    galleryByIdQuery,
    galleryByKeyForPreviewQuery,
    galleryByKeyQuery,
    upsertGalleryQuery,
} from './galleries.queries'

@Injectable()
export class GalleryService extends BaseService {
    protected _galleries: BehaviorSubject<any[]> = new BehaviorSubject([])

    /**
     * @type Observable<any[]>
     */
    public galleries: Observable<any[]> = this._galleries.asObservable().pipe(filter(value => !!value))

    /**
     *
     * @type BehaviorSubject<any>
     */
    protected _pagination: BehaviorSubject<ResultPaginationInterface | undefined> = new BehaviorSubject(undefined)

    /**
     * @type Observable<any>
     */
    public pagination: Observable<any> = this._pagination.asObservable().pipe(filter(value => !!value))

    /**
     *
     * @param apollo
     */
    constructor(apollo: Apollo, errorsMessagesService: ErrorsMessagesService) {
        super(apollo, errorsMessagesService)
    }

    /**
     *
     * @returns {void}
     */
    listGalleries(
        queryOptions?: QueryOptionsInterface,
        searchOptions: {
            term?: string
        } = {},
    ): void {
        const variables = queryOptions
            ? {
                  options: queryOptions,
              }
            : {}

        if (searchOptions.term) {
            variables['searchInput'] = { term: searchOptions.term }
        }

        cacheAndNetworkQuery(
            this.apollo,
            {
                query: galleriesQuery,
                variables,
            },
            ['data', 'galleries'],
        ).subscribe(
            (data: { results: any[]; pagination: ResultPaginationInterface }) => {
                if (!data.results) {
                    this._galleries.next([])
                } else {
                    this._galleries.next(data.results)
                    this._pagination.next(data.pagination)
                }
            },
            error => {
                this._galleries.next([])
                this.errorMessagesServices.addErrorsMessage(error)
            },
            () => {},
        )
    }

    byId(id: number): Observable<Record<string, any> | GalleryModel> {
        return networkQuery(
            this.apollo,
            {
                query: galleryByIdQuery,
                variables: {
                    id,
                },
            },
            ['data'],
        )
    }

    byKey(key: string): Observable<GalleryModel> {
        return networkQuery(
            this.apollo,
            {
                query: galleryByKeyQuery,
                variables: {
                    key,
                },
            },
            ['data', 'galleryByKey'],
        )
    }

    byKeyForPreview(key: string): Observable<GalleryModelForPreview> {
        return networkQuery(
            this.apollo,
            {
                query: galleryByKeyForPreviewQuery,
                variables: {
                    key,
                },
            },
            ['data', 'galleryByKey'],
        )
    }

    /**
     *
     * @param id message level startAt endAt isActive
     * @returns  {Observable<InAppMessageModel>}
     */
    upsertGalley(gallery: GalleryModel) {
        return this.apollo
            .mutate({
                mutation: upsertGalleryQuery,
                variables: {
                    gallery,
                },
            })
            .pipe(pluck('data'), pluck('upsertGallery'))
    }
}
