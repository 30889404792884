import { Component } from '@angular/core'

@Component({
    selector: 'cms-item-legend',
    templateUrl: './item-legend.component.html',
    styleUrls: ['./item-legend.component.scss'],
})
export class ItemLegendComponent {
    constructor() {}
}
