import { gql } from '@apollo/client/core'

export const TECHNICAL_TAG_FRAGMENT = `
    fragment technicalTagInfo on TechnicalTag {
        id
        name
        slug
        isArchived
        parent {
            id
            slug
            name
            isArchived
        }
    }
`

// 4th child set does not need a 'children' as it's the end of the hierarchy
export const TECHNICAL_TAG_BY_ID_QUERY = gql`
    ${TECHNICAL_TAG_FRAGMENT}

    query technicalTags($id: Int, $isArchived: Boolean) {
        technicalTags(id: $id, isArchived: $isArchived) {
            results {
                ...technicalTagInfo
                children {
                    ...technicalTagInfo
                    children {
                        ...technicalTagInfo
                        children {
                            ...technicalTagInfo
                        }
                    }
                }
            }
        }
    }
`

export const UPSERT_TECHNICAL_TAG_MUTATION = gql`
    ${TECHNICAL_TAG_FRAGMENT}

    mutation upsertTechnicalTag($technicalTag: TechnicalTagInput!) {
        upsertTechnicalTag(technicalTag: $technicalTag) {
            ...technicalTagInfo
        }
    }
`

export const TECHNICAL_TAG_BY_NAME_QUERY = gql`
    ${TECHNICAL_TAG_FRAGMENT}

    query technicalTagsByName($term: String!, $excludedId: [Int]) {
        technicalTagsByName(search: { term: $term, excludedId: $excludedId }) {
            results {
                ...technicalTagInfo
                parent {
                    ...technicalTagInfo
                    parent {
                        ...technicalTagInfo
                        parent {
                            ...technicalTagInfo
                        }
                    }
                }
            }
        }
    }
`

export const REMOVE_TECHNICAL_TAG_FROM_STORY = gql`
    mutation removeTechnicalTagFromStory($technicalTagId: Int, $storyId: Int) {
        removeTechnicalTagFromStory(technicalTagId: $technicalTagId, storyId: $storyId)
    }
`
