export const imageMetaFragment = `
fragment imageMetaInfo on ImageMeta {
  imageId
  altText
  caption
  langId
  copyright {
      link
      text
  }
  source {
      link
      text
  }
  callToAction {
      text
      link
  }
}
`
