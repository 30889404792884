/**
 * Encode a JSON object in base64
 */
export function encodeJSON(objectToEncode: any, options?: { removeFalsyValues: boolean }): string {
    return btoa(
        JSON.stringify(objectToEncode, options?.removeFalsyValues ? (_, value) => value || undefined : undefined),
    )
}

/**
 * Decode a JSON object from a base64 string
 */
export function decodeJSON<T>(encodedObject: string): T {
    return JSON.parse(atob(encodedObject) || '{}')
}
