import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { MutationResult } from 'interfaces/query-result'
import { EventCreateOrUpdate, EventModel } from 'models/event.model'
import { Observable, of } from 'rxjs'
import { catchError, finalize, pluck, switchMap, take } from 'rxjs/operators'
import { networkQuery } from 'tools/apollo'
import { createEvent, getEvent, updateEvent } from './event.query'

@Injectable()
export class EventService {
    saving = false

    constructor(protected apollo: Apollo) {}

    getEvent(id: number) {
        return networkQuery<EventModel>(
            this.apollo,
            {
                query: getEvent,
                variables: {
                    id,
                },
            },
            ['data', 'event'],
        )
    }

    createOrUpdate(data: EventCreateOrUpdate): Observable<MutationResult> {
        this.saving = true

        const mutation = data.id ? updateEvent : createEvent

        // Only pass if in a create event scenario
        if (typeof data.id === 'undefined') {
            data['isCmsOrigin'] = true
        }

        return this.apollo
            .mutate({
                mutation,
                variables: data,
            })
            .pipe(
                take(1),
                pluck('data'),
                switchMap((res: any) => {
                    return of({ res })
                }),
                catchError(err => {
                    return of({ res: false, msg: err })
                }),
                finalize(() => {
                    this.saving = false
                }),
            )
    }
}
