import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { Observable } from 'rxjs'
import { pluck } from 'rxjs/operators'
import { cacheAndNetworkQuery, networkQuery } from 'tools/apollo'
import { TechnicalTagModel } from '../../models/technical-tag.model'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import {
    TECHNICAL_TAG_BY_ID_QUERY,
    TECHNICAL_TAG_BY_NAME_QUERY,
    UPSERT_TECHNICAL_TAG_MUTATION,
    REMOVE_TECHNICAL_TAG_FROM_STORY,
} from './technical-tags.query'

@Injectable()
export class TechnicalTagsService extends BaseService {
    constructor(apollo: Apollo, protected errorMessagesServices: ErrorsMessagesService) {
        super(apollo, errorMessagesServices)
    }

    /**
     * Grabs the tag hierarchy for a known tag id. If no tag id is passed, the entire tag hierarchy is recovered.
     * @param id An optional Technical Tag id to recover the data for
     * @param isArchived An optional Boolean value enabling the recovery of archived technical tags
     * @returns {Observable<TechnicalTagModel[]>}
     */
    getTechnicalTagById(id?: number, isArchived?: boolean): Observable<TechnicalTagModel[]> {
        return networkQuery(
            this.apollo,
            {
                query: TECHNICAL_TAG_BY_ID_QUERY,
                variables: {
                    id,
                    isArchived,
                },
            },
            ['data', 'technicalTags', 'results'],
        )
    }

    getTechnicalTagByName(term: string, excludedId?: number[]): Observable<TechnicalTagModel[]> {
        return cacheAndNetworkQuery(
            this.apollo,
            {
                query: TECHNICAL_TAG_BY_NAME_QUERY,
                variables: {
                    term,
                    excludedId,
                },
            },
            ['data', 'technicalTagsByName', 'results'],
        )
    }

    /**
     * Creates or updates a technical tag
     * @param {TechnicalTagModel} technicalTagData
     * @returns {Observable<TechnicalTagModel>}
     */
    upsertTechnicalTag(technicalTag: TechnicalTagModel): Observable<TechnicalTagModel> {
        return this.apollo
            .mutate({
                mutation: UPSERT_TECHNICAL_TAG_MUTATION,
                variables: { technicalTag },
            })
            .pipe(pluck('data', 'upsertTechnicalTag'))
    }

    removeTechnicalTagFromStory(technicalTagId: number, storyId: number): Observable<TechnicalTagModel> {
        return this.apollo
            .mutate({
                mutation: REMOVE_TECHNICAL_TAG_FROM_STORY,
                variables: { technicalTagId, storyId },
            })
            .pipe(pluck('data', 'upsertTechnicalTag'))
    }
}
