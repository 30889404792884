import { gql } from '@apollo/client/core'
import { paginationFragment } from '../shared/pagination.fragment'
import { themeFragment, themeWithTranslatedVerticalsAndTranslationsFragment } from './themes.fragment'

export const themesQuery = gql`
    ${paginationFragment}
    ${themeFragment}

    query themes($pagination: PaginationInput) {
        themes(options: { pagination: $pagination, order: { key: "slug", direction: ASC } }) {
            results {
                ...themeInfo
            }
            pagination {
                ...paginationInfo
            }
        }
    }
`
export const themesFilterQuery = gql`
    ${paginationFragment}
    ${themeWithTranslatedVerticalsAndTranslationsFragment}

    query themes($pagination: PaginationInput) {
        themes(options: { pagination: $pagination, order: { key: "slug", direction: ASC } }) {
            results {
                ...themeWithTranslatedVerticalsAndTranslationsInfo
            }
            pagination {
                ...paginationInfo
            }
        }
    }
`
