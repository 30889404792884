import { gql } from '@apollo/client/core'
import { storyMutateFragment } from '../find-by-id.query'

export const storyTitlesAndSettingsInputFragment = `
  fragment storyTitlesAndSettingsInput on Story {
    articleHeadline
    shortTitle
    socialTitle
    summary
    isLiveCoverage
    isBreakingNews
    type {
      id
      slug
    }
    campaigns {
      id
      slug
      title
      startAt
      endAt
      isActive
    }
  }
`

export const upsertStoryTitlesAndSettings = gql`
    ${storyTitlesAndSettingsInputFragment}
    ${storyMutateFragment}

    mutation updateStoryTitlesAndSettings(
        $storyId: Int!
        $eventId: Int!
        $langId: Int!
        $data: StoryTitlesAndSettingsInput!
        $fieldsToReplicate: [StorySettingsReplicatedFields]
    ) {
        updateStoryTitlesAndSettings(
            storyId: $storyId
            eventId: $eventId
            langId: $langId
            data: $data
            fieldsToReplicate: $fieldsToReplicate
        ) {
            ...storyTitlesAndSettingsInput
            ...storyMutateInfos
        }
    }
`

export const getStoryTitlesAndSettings = gql`
    ${storyTitlesAndSettingsInputFragment}
    ${storyMutateFragment}

    query getStoryTitlesAndSettings($storyId: Int!) {
        story(id: $storyId) {
            id
            ...storyTitlesAndSettingsInput
            ...storyMutateInfos
        }
    }
`
