<clr-alerts
    *ngIf="errorMessagesServices.errorsMessages | async"
    class="c-sticky-alert"
    [ngClass]="{ 'hidden-alerts': !authService.isAuthentified }">
    <clr-alert
        *ngFor="let message of errorMessagesServices.errorsMessages | async"
        [clrAlertType]="message.level ? message.level : 'danger'"
        [clrAlertClosable]="true"
        [clrAlertAppLevel]="true"
        (clrAlertClosedChange)="onClose(message)">
        <div class="alert-item">
            <div class="alert-text">
                {{ message.message }}
            </div>
            <div class="alert-actions" *ngIf="message.buttonLabel && message.buttonLink">
                <a
                    *ngIf="isExternalLink(message.buttonLink); else internalLink"
                    class="btn alert-action"
                    [href]="message.buttonLink"
                    target="_blank"
                    >{{ message.buttonLabel }}</a
                >
                <ng-template #internalLink>
                    <a class="btn alert-action" [routerLink]="message.buttonLink">{{ message.buttonLabel }}</a>
                </ng-template>
            </div>
        </div>
    </clr-alert>
</clr-alerts>
