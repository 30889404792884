import { languageFragment } from '../languages/language.fragment'

const locationFragmentString = `
    id
    slug
    translations {
      ...locationTranslationInfo
    }`

export const locationTranslationFragment = `
${languageFragment}

fragment locationTranslationInfo on GeoTranslation {
    title
    slug
    language {
        ...languageInfo
    }
}
`

export const continentFragment = `
${locationTranslationFragment}

fragment continentInfo on Continent {
    ${locationFragmentString}
}
`

export const countryFragment = `
${locationTranslationFragment}

fragment countryInfo on Country {
    ${locationFragmentString}
}
`

export const locationFragment = `
${locationTranslationFragment}

fragment locationInfo on Location {
    ${locationFragmentString}
}
`
