import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { Story } from 'entities/story'
import { PublicationStatus, PublicationStatusLabelsById } from 'enums/publication-status'
import { StoryEvent, StoryModel } from '../../../models/story.model'

@Component({
    selector: 'cms-publication-status',
    templateUrl: './publication-status.component.html',
    styleUrls: ['./publication-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicationStatusComponent {
    protected publicationStatus = PublicationStatus
    private _story: Story

    @Input()
    set story(story: StoryModel) {
        this._story = new Story(story)
    }

    get story(): Story {
        return this._story
    }

    /**
     * Return the status of the given story
     *
     * @param story
     * @returns {string}
     */
    getStatus(story: Story): string {
        if (!story || !story.publicationStatus) {
            return ''
        }

        return PublicationStatusLabelsById[story.publicationStatus.id] || ''
    }

    isPublishedOnChannel(story: Story, channel: string) {
        return story.publicationChannels?.some(pc => pc.labelShort === channel)
    }

    /**
     * Return the status class name to switch status colo
     *
     * @param story
     * @returns {string}
     */
    getStatusClass(story: Story): string {
        const classStatusBase = 'eventStatus eventStatus'

        let CssNamingSlug = ''

        if (story && story.publicationStatus && story.publicationStatus.slug) {
            CssNamingSlug = story.publicationStatus.slug
        }

        return `${classStatusBase}--${(story && story.publicationStatus && CssNamingSlug) || 'todo'}`
    }

    /**
     * Checking if an event is late compared to it's deadline
     *
     * @param evt
     * @returns {boolean}
     */
    isLate(evt: StoryEvent): boolean {
        return evt.deadlineAt ? evt.deadlineAt < new Date() : false
    }
}
