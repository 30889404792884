import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { EuronewsVideoModel } from 'models/euronews-video.model'
import { Observable } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { networkQuery } from 'tools/apollo'
import { YoutubeVideoModel } from '../../models/youtube-video.model'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { youtubeVideoQuery, euronewsVideoFromYoutubeQuery } from './youtube-video.query'

@Injectable()
export class YoutubeVideoService extends BaseService {
    constructor(apollo: Apollo, errorMessagesService: ErrorsMessagesService) {
        super(apollo, errorMessagesService)
    }

    public fetchYoutubeVideo(youtubeId: string): Observable<YoutubeVideoModel> {
        return networkQuery<YoutubeVideoModel>(
            this.apollo,
            {
                query: youtubeVideoQuery,
                variables: {
                    id: youtubeId,
                },
            },
            ['data', 'youtubeVideo'],
        ).pipe(catchError(this.handleError<YoutubeVideoModel>()))
    }

    public fetchEuronewsVideoFromYoutube(youtubeId: string): Observable<EuronewsVideoModel> {
        return networkQuery<EuronewsVideoModel>(
            this.apollo,
            {
                query: euronewsVideoFromYoutubeQuery,
                variables: {
                    id: youtubeId,
                },
            },
            ['data', 'euronewsVideoFromYoutube'],
        ).pipe(catchError(this.handleError<EuronewsVideoModel>()))
    }

    /**
     * Extract the youtube ID from a youtube URL.
     * @param youtubeUrl
     */
    public parseYoutubeUrl(youtubeUrl: string): string {
        const parsedArray = this.matchsYoutubeUrl(youtubeUrl)
        const youtubeId = parsedArray && parsedArray[1] ? parsedArray[1] : ''

        return youtubeId
    }

    private matchsYoutubeUrl(url: string): RegExpMatchArray | null {
        return url.match(
            /(?:http(?:s)?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:(?:watch)?\?(?:.*&)?v(?:i)?=|(?:embed|v|vi|user)\/))([^?&"'<> #]+)/,
        )
    }

    private handleError<T>() {
        return (error: any): Observable<T> => {
            throw error
        }
    }
}
