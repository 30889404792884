import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { BehaviorSubject } from 'rxjs'
import { filter } from 'rxjs/operators'
import { cacheAndNetworkQuery } from 'tools/apollo'
import { VerticalModel } from '../../models/vertical.model'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { englishVerticalsWithThemesQuery } from './verticals.query'

@Injectable()
export class VerticalService extends BaseService {
    private _verticals: BehaviorSubject<VerticalModel[]> = new BehaviorSubject([])

    public verticals = this._verticals.asObservable().pipe(filter(verticalModel => Boolean(verticalModel.length)))

    constructor(apollo: Apollo, errorMessageService: ErrorsMessagesService) {
        super(apollo, errorMessageService)
    }

    public getList() {
        return cacheAndNetworkQuery<VerticalModel[]>(
            this.apollo,
            {
                query: englishVerticalsWithThemesQuery,
            },
            ['data', 'verticals', 'results'],
        ).subscribe(results => {
            this._verticals.next(results)
        })
    }
}
