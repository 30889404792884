import { LanguageIsoCode } from 'enums/language'
import { ContributorModel } from 'models/contributor.model'

// Mock data
export const contributors: ContributorModel[] = [
    {
        id: 12,
        imageIsActive: false,
        isBioActive: false,
        isActive: false,
        twitterScreenName: '@alfaallahguide_en',
        translations: [
            {
                contributorId: 12,
                slug: 'shaban-in-english',
                surname: 'rahman',
                forename: 'shaban',
                country: 'france',
                onlineDescription: 'Beautiful authorDescription',
                biography: 'my crappy bio',
                language: {
                    id: 1,
                    websiteSubdomain: 'www',
                    fullname: 'English',
                    isRTL: false,
                    isActive: true,
                    collateLocaleCMS: LanguageIsoCode.ENGLISH,
                    isoCode: LanguageIsoCode.ENGLISH,
                    priority: 1,
                },
            },
            {
                contributorId: 13,
                slug: 'shaban-in-french',
                surname: 'rahman en fr',
                forename: 'shaban en fr',
                country: 'france',
                onlineDescription: 'Jolie authorDescription',
                biography: 'ma bio degueu',
                language: {
                    id: 2,
                    websiteSubdomain: 'fr',
                    fullname: 'French',
                    isRTL: false,
                    isActive: true,
                    collateLocaleCMS: LanguageIsoCode.FRENCH,
                    isoCode: LanguageIsoCode.FRENCH,
                    priority: 2,
                },
            },
        ],
        writtenLanguage: {
            id: 2,
            fullname: 'French',
            isRTL: false,
            isActive: true,
            collateLocaleCMS: LanguageIsoCode.FRENCH,
            isoCode: LanguageIsoCode.FRENCH,
            priority: 2,
        },
        job: {
            id: 1,
            description: 'Journalist',
            translations: [
                {
                    contributorJobId: 1,
                    title: 'Journalist',
                    language: {
                        id: 1,
                        websiteSubdomain: 'www',
                        fullname: 'English',
                        isRTL: false,
                        isActive: true,
                        collateLocaleCMS: LanguageIsoCode.ENGLISH,
                        isoCode: LanguageIsoCode.ENGLISH,
                        priority: 1,
                    },
                },
                {
                    contributorJobId: 1,
                    title: 'Journaliste',
                    language: {
                        id: 2,
                        websiteSubdomain: 'www',
                        fullname: 'French',
                        isRTL: false,
                        isActive: true,
                        collateLocaleCMS: LanguageIsoCode.FRENCH,
                        isoCode: LanguageIsoCode.FRENCH,
                        priority: 1,
                    },
                },
            ],
        },
        jobsInStory: [
            {
                id: 1,
                description: 'Journalist',
                translations: [
                    {
                        contributorJobId: 1,
                        title: 'Journalist',
                        language: {
                            id: 1,
                            websiteSubdomain: 'www',
                            fullname: 'English',
                            isRTL: false,
                            isActive: true,
                            collateLocaleCMS: LanguageIsoCode.ENGLISH,
                            isoCode: LanguageIsoCode.ENGLISH,
                            priority: 1,
                        },
                    },
                    {
                        contributorJobId: 1,
                        title: 'Journaliste',
                        language: {
                            id: 2,
                            websiteSubdomain: 'www',
                            fullname: 'French',
                            isRTL: false,
                            isActive: true,
                            collateLocaleCMS: LanguageIsoCode.FRENCH,
                            isoCode: LanguageIsoCode.FRENCH,
                            priority: 1,
                        },
                    },
                ],
            },
        ],
        department: {
            id: 1,
            description: 'Editors',
            translations: [
                {
                    contributorDepartmentId: 1,
                    title: 'Editors',
                    language: {
                        id: 1,
                        websiteSubdomain: 'www',
                        fullname: 'English',
                        isRTL: false,
                        isActive: true,
                        collateLocaleCMS: LanguageIsoCode.ENGLISH,
                        isoCode: LanguageIsoCode.ENGLISH,
                        priority: 1,
                    },
                },
                {
                    contributorDepartmentId: 1,
                    title: 'Editeurs',
                    language: {
                        id: 2,
                        websiteSubdomain: 'www',
                        fullname: 'French',
                        isRTL: false,
                        isActive: true,
                        collateLocaleCMS: LanguageIsoCode.FRENCH,
                        isoCode: LanguageIsoCode.FRENCH,
                        priority: 1,
                    },
                },
            ],
        },
    },
    {
        id: 13,
        imageIsActive: false,
        isBioActive: false,
        isActive: true,
        twitterScreenName: '@oliver-shmidt_en',
        translations: [
            {
                contributorId: 13,
                slug: 'oliver-in-english',
                surname: 'shmidt',
                forename: 'oliver',
                country: 'france',
                onlineDescription: 'authorDescription from hell',
                biography: 'my cool bio',
                language: {
                    id: 1,
                    websiteSubdomain: 'www',
                    fullname: 'English',
                    isRTL: false,
                    isActive: true,
                    collateLocaleCMS: LanguageIsoCode.ENGLISH,
                    isoCode: LanguageIsoCode.ENGLISH,
                    priority: 1,
                },
            },
        ],
        writtenLanguage: {
            id: 1,
            websiteSubdomain: 'www',
            fullname: 'English',
            isRTL: false,
            isActive: true,
            collateLocaleCMS: LanguageIsoCode.ENGLISH,
            isoCode: LanguageIsoCode.ENGLISH,
            priority: 1,
        },
        job: {
            id: 2,
            description: 'Editor in chief',
            translations: [
                {
                    contributorJobId: 2,
                    title: 'Editor in chief',
                    language: {
                        id: 1,
                        websiteSubdomain: 'www',
                        fullname: 'English',
                        isRTL: false,
                        isActive: true,
                        collateLocaleCMS: LanguageIsoCode.ENGLISH,
                        isoCode: LanguageIsoCode.ENGLISH,
                        priority: 1,
                    },
                },
                {
                    contributorJobId: 2,
                    title: 'Editeur en chef',
                    language: {
                        id: 2,
                        websiteSubdomain: 'www',
                        fullname: 'French',
                        isRTL: false,
                        isActive: true,
                        collateLocaleCMS: LanguageIsoCode.FRENCH,
                        isoCode: LanguageIsoCode.FRENCH,
                        priority: 1,
                    },
                },
            ],
        },
        jobsInStory: [
            {
                id: 1,
                description: 'Journalist',
                translations: [
                    {
                        contributorJobId: 1,
                        title: 'Journalist',
                        language: {
                            id: 1,
                            websiteSubdomain: 'www',
                            fullname: 'English',
                            isRTL: false,
                            isActive: true,
                            collateLocaleCMS: LanguageIsoCode.ENGLISH,
                            isoCode: LanguageIsoCode.ENGLISH,
                            priority: 1,
                        },
                    },
                    {
                        contributorJobId: 1,
                        title: 'Journaliste',
                        language: {
                            id: 2,
                            websiteSubdomain: 'www',
                            fullname: 'French',
                            isRTL: false,
                            isActive: true,
                            collateLocaleCMS: LanguageIsoCode.FRENCH,
                            isoCode: LanguageIsoCode.FRENCH,
                            priority: 1,
                        },
                    },
                ],
            },
        ],
        department: {
            id: 2,
            description: 'Coordination',
            translations: [
                {
                    contributorDepartmentId: 2,
                    title: 'Coordination',
                    language: {
                        id: 1,
                        websiteSubdomain: 'www',
                        fullname: 'English',
                        isRTL: false,
                        isActive: true,
                        collateLocaleCMS: LanguageIsoCode.ENGLISH,
                        isoCode: LanguageIsoCode.ENGLISH,
                        priority: 1,
                    },
                },
                {
                    contributorDepartmentId: 2,
                    title: 'Coordination',
                    language: {
                        id: 2,
                        websiteSubdomain: 'www',
                        fullname: 'French',
                        isRTL: false,
                        isActive: true,
                        collateLocaleCMS: LanguageIsoCode.FRENCH,
                        isoCode: LanguageIsoCode.FRENCH,
                        priority: 1,
                    },
                },
            ],
        },
    },
]
