import { gql } from '@apollo/client/core'
import { paginationFragment } from '../shared/pagination.fragment'

export const alertTargetTypesQuery = gql`
    ${paginationFragment}

    query alertTargetTypes($pagination: PaginationInput) {
        alertTargetTypes(options: { pagination: $pagination }) {
            results {
                id
                slug
            }
            pagination {
                ...paginationInfo
            }
        }
    }
`
