import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { Subject } from 'rxjs'
import { networkQuery } from 'tools/apollo'
import { Contributor } from '../../entities/contributors'
import { ContributorModel } from '../../models/contributor.model'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { findByIdQuery, findByIdQueryAdmin } from './find-by-id.query'
import { meQuery } from './me.query'
import { upsertContributor } from './upsert.query'

@Injectable()
export class ContributorService extends BaseService {
    protected _contributor: Subject<ContributorModel> = new Subject()
    public contributor = this._contributor.asObservable()

    constructor(apollo: Apollo, errorsMessagesService: ErrorsMessagesService) {
        super(apollo, errorsMessagesService)
    }

    /**
     * Search contributor by his id
     *
     * @param {int} int
     *
     * @returns {Subscription}
     */
    findById(id: number, isAdmin: boolean = false) {
        const query = isAdmin ? findByIdQueryAdmin : findByIdQuery

        return networkQuery(
            this.apollo,
            {
                query,
                variables: { id },
            },
            ['data'],
        ).subscribe(
            (data: { contributor: ContributorModel }) => {
                if (!data || !data.contributor) {
                    return
                }

                this._contributor.next(data.contributor)
            },
            error => {
                // returned an empty contributors' array
                this._contributor.next(undefined)
                // add an error
                this.errorMessagesServices.addErrorsMessage(error)
            },
        )
    }

    /**
     * Return the logged contributor
     *
     * Take care : each time this method is called it will do a request, so if multiple component use this, or if a component is refreshed
     * frequently it might crash the app.
     * With standard Observable there is a share() method for multicast, but apollo-client doesn't provide share. So it might be a better
     * solution to get a BehaviourSubject and only one call to me() during the login process per exampl.
     * Components that needs me info will just have to subscribe on the BehaviourSubject. This could be usefull for managing the ACL,
     * instead of calling me() it would subscribe to the BehaviourSubject
     *
     * @returns {Subscription}
     */
    me() {
        return networkQuery(
            this.apollo,
            {
                query: meQuery,
            },
            ['data', 'me'],
        )
    }

    record(contributor: Contributor) {
        const mutation = this.getMutation(contributor)

        return this.apollo.mutate({
            mutation: upsertContributor,
            variables: {
                contributor: mutation,
            },
        })
    }

    private getMutation(contributor: Contributor) {
        const mutation = {
            slug: contributor.slug,
            isBioActive: contributor.isBioActive,
            isActive: contributor.isActive,
            isBetaTester: contributor.isBetaTester,
            twitterScreenName: contributor.twitterScreenName,
            jobId: contributor.getJobId(),
            departmentId: contributor.getDepartmentId(),
            writtenLangId: contributor.getWrittenLanguageId(),
            translations: contributor.translations.map(translation => {
                const trans = {
                    langId: translation.language ? translation.language.id : 1,
                    slug: contributor.slug,
                    surname: translation.surname,
                    forename: translation.forename,
                    country: translation.country,
                    biography: translation.biography,
                    onlineDescription: translation.onlineDescription,
                }
                if (contributor.id) {
                    trans['contributorId'] = contributor.id
                }

                return trans
            }),
        }

        if (contributor.id) {
            mutation['id'] = contributor.id
        }

        return mutation
    }
}
