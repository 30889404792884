import { Pipe, PipeTransform } from '@angular/core'
import { Story } from 'entities/story'
import { FormatDatePipe } from './format-date.pipe'

/**
 * Pipe to format the publication date of a story.
 * If dates are the same, it will show "Created on [date]".
 * If dates are different, it will show "Created on [date] • Updated on [date]".
 * If dates are different and the time is the same, it will show "Created on [date] • Updated at [time]".
 */
@Pipe({ name: 'publicationDate' })
export class PublicationDatePipe implements PipeTransform {
    constructor(protected formatDatePipe: FormatDatePipe) {}

    transform(story: Story, timezone?: string, locale?: string): string {
        const dateFormat = 'dd/MM/Y HH:mm'
        const publishedAt = this.formatDatePipe.transform(story.publishedAt, timezone, locale, dateFormat)
        const lastPublishedAt = this.formatDatePipe.transform(story.lastPublishedAt, timezone, locale, dateFormat)

        const publishedAtDate = new Date(story.publishedAt).toDateString()
        const lastPublishedAtDate = new Date(story.lastPublishedAt).toDateString()

        switch (true) {
            case publishedAt === lastPublishedAt:
                return `Created on ${publishedAt}`
            case publishedAtDate === lastPublishedAtDate:
                return `Created on ${publishedAt} • Updated at ${lastPublishedAt.slice(-5)}`
            default:
                return `Created on ${publishedAt} • Updated on ${lastPublishedAt}`
        }
    }
}
