import { themeWithTranslatedVerticalsFragment } from 'services/themes/themes.fragment'

export const storySearchFragment = `
${themeWithTranslatedVerticalsFragment}

fragment storySearchInfo on Story {
    id
    isIndexed
    masterCms
    event {
        id
        isMagazine
        publicationOwner {
            id
            slug
            canonicalUrl
        }
        program {
            id
            slug
            canonicalUrl
            theme {
                ...themeWithTranslatedVerticalsInfo
            }
        }
        programDeliverable {
            id
            slug
            title
        }
        daletTitleName
    }
    language {
        id
        isRTL
        websiteSubdomain
        fullname
        isoCode
    }
    publicationStatus {
        id
        slug
    }
    type {
        id
    }
    articleHeadline
    urlTitleSlug
    publishedAt
    expiresAt
    createdAt
    creator {
        translations {
            surname
            forename
            language {
                isoCode
            }
        }
        writtenLanguage {
            isoCode
        }
    }
    signature {
        name
    }
    images {
        url
    }
    totalChars
    videos {
        url
        quality {
            id
            slug
        }
        type {
            id
            slug
        }
        format {
            id
            slug
        }
        housenumber
        isSynchronized
        endAt
        youtubeId
        dailymotionId
    }
    bcsSlots {
        langId
        bcsBlock {
            id
            title
        }
    }
}
`
