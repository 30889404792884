import { gql } from '@apollo/client/core'
import { tagFragment } from 'services/tags/tags.fragment'
import { TECHNICAL_TAG_FRAGMENT } from 'services/technical-tags/technical-tags.query'
import { themeFragment } from 'services/themes/themes.fragment'
import { continentFragment, countryFragment, locationFragment } from '../../geo-locations/location.fragment'
import { languageFragment } from '../../languages/language.fragment'
import { storyMutateFragment } from '../find-by-id.query'

export const storyTagsandMetadatasFragment = `
${themeFragment}
${tagFragment}
${languageFragment}
${continentFragment}
${countryFragment}
${locationFragment}
${TECHNICAL_TAG_FRAGMENT}

fragment storyTagsandMetadatasInfos on Story {
    themes {
        ...themeInfo
    }
    tags {
        ...tagInfo
    }
    technicalTags {
        ...technicalTagInfo
        parent {
            ...technicalTagInfo
            parent {
                ...technicalTagInfo
                parent {
                    ...technicalTagInfo
                }
            }
        }
    }
    geo {
        continent {
            ...continentInfo
        }
        country {
            ...countryInfo
        }
        location {
            ...locationInfo
        }
    }
}
`

export const upsertStoryTagsAndMetadata = gql`
    ${storyTagsandMetadatasFragment}
    ${storyMutateFragment}

    mutation updateStoryTagsMetadata(
        $storyId: Int!
        $tags: [Int!]
        $themes: [Int!]
        $location: GeoInput
        $fieldsToReplicate: [StoryTagsAndMetadataReplicatedFields]
        $technicalTags: [Int!]
    ) {
        updateStoryTagsMetadata(
            storyId: $storyId
            tags: $tags
            themes: $themes
            location: $location
            fieldsToReplicate: $fieldsToReplicate
            technicalTags: $technicalTags
        ) {
            ...storyTagsandMetadatasInfos
            ...storyMutateInfos
        }
    }
`
export const getStoryTagsAndMetadatas = gql`
    ${storyTagsandMetadatasFragment}
    ${storyMutateFragment}

    query getStoryTitlesAndSettings($storyId: Int!) {
        story(id: $storyId) {
            id
            ...storyTagsandMetadatasInfos
            ...storyMutateInfos
        }
    }
`
