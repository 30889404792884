import { CommonModule, TitleCasePipe } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { InMemoryCache } from '@apollo/client/core'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { onError } from '@apollo/client/link/error'
import { ClarityModule } from '@clr/angular'
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker'
import { MediumEditorComponent } from '@euronews/ng2-meditor'
import { Apollo, ApolloModule } from 'apollo-angular'
import { LocalStorageWrapper, SynchronousCachePersistor } from 'apollo3-cache-persist'
import { AIGenerateDirective } from 'app/shared/ai-generate/ai-generate.directive'
import { ArticleListingComponent } from 'app/shared/article-listing/article-listing.component'
import { DashboardTagsComponent } from 'app/shared/dashboard-tags/dashboard-tags.component'
import { FeedbackSurveyComponent } from 'app/shared/feedback-survey/feedback-survey.component'
import { IndexationStatusComponent } from 'app/shared/indexation-status/indexation-status.component'
import { PublicationStatusComponent } from 'app/shared/publication-status/publication-status.component'
import { DraggableBadgeComponent } from 'app/story/tags-and-metadata/draggable-badge/draggable-badge.component'
import { GalleryPreviewUtils } from 'app/story/wysiwyg/utils/gallery-preview-utils'
import { ClickOutsideDirective } from 'directives/click-outside.directive'
import { CopyClipboardDirective } from 'directives/copy-to-clipboard.directive'
import { DebounceDirective } from 'directives/debounce'
import { DisableControlDirective } from 'directives/disable.control.directive'
import { IfAuthorizedDirective } from 'directives/if-authorized.directive'
import { TrimDirective } from 'directives/trim'
import { Ng2CompleterModule } from 'ng2-completer'
import { DragulaModule } from 'ng2-dragula'
import { ContributorGetNamePipe } from 'pipes/contributor'
import { DirectionPipe } from 'pipes/direction.pipe'
import { FormatDatePipe } from 'pipes/format-date.pipe'
import { HighlightPipe } from 'pipes/highlight.pipe'
import { ImageRatioUrlPipe } from 'pipes/image-ratio-url'
import { ImageUrlPipe } from 'pipes/image-url.pipe'
import { MarkdownPipe } from 'pipes/markdown.pipe'
import { ObjKeyValueListPipe } from 'pipes/obj-key-value-list.pipe'
import { ObjToKeysPipe } from 'pipes/obj-to-keys'
import { PublicationDatePipe } from 'pipes/publication-date.pipe'
import { ReversePipe } from 'pipes/reverse.pipe'
import { SafeurlPipe } from 'pipes/safeurl.pipe'
import { SlugifyPipe } from 'pipes/slugify.pipe'
import { SnakeCasePipe } from 'pipes/snakeCase.pipe'
import { StatusPipe } from 'pipes/status'
import { StoryImageUrlPipe } from 'pipes/story-image-url.pipe'
import { StoryTextPipe } from 'pipes/story-text'
import { ToStoryPipe } from 'pipes/to-story.pipe'
import { TranslateKeyPipe } from 'pipes/translate-key.pipe'
import { TranslationFilterPipe } from 'pipes/translation-filter.pipe'
import { TrimmedTitleCasePipe } from 'pipes/trimmed-title-case.pipe'
import { AIService } from 'services/ai/ai'
import { AlertTargetTypesService } from 'services/alert-target-types/alert-target-types'
import { AlertTypesService } from 'services/alert-types/alert-types'
import { fragmentMatcher, typePolicies } from 'services/apollo-cache.config'
import { BaseService } from 'services/base'
import { BreakingNewsDefaultImage } from 'services/breaking-news-default-image/image'
import { BreakpointService } from 'services/breakpoint/breakpoint'
import { ContributorsAutocompleteByTermService } from 'services/contributors/autocomplete-by-term'
import { ContributorFiltersService } from 'services/contributors/contributor-filters.service'
import { ContributorsListService } from 'services/contributors/list'
import { ContributorService } from 'services/contributors/one'
import { ErrorsMessagesService } from 'services/errors-messages/errors-messages.service'
import { FilesSelectionService } from 'services/files-selection/files-selection'
import { ContinentsService } from 'services/geo-locations/continents'
import { CountriesService } from 'services/geo-locations/countries'
import { LocationsService } from 'services/geo-locations/locations'
import {
    AllContributorsData,
    AuthorsData,
    ProducersData,
    VideoEditorsData,
} from 'services/ng-completer/contributors.data'
import { AllTagsData, StoryMainTagsData, StoryTagsData } from 'services/ng-completer/tags.data'
import { MessagesNotificationService } from 'services/notifications/messages-notification.service'
import { PartnerFeedOverrideService } from 'services/partner-feed/partner-feed-override.service'
import { ProgramCategoryService } from 'services/program-categories/program-categories'
import { ProgramDeliverableListService } from 'services/program-deliverables/list'
import { DigitalProgramListService } from 'services/programs/digital.list'
import { ProgramListService } from 'services/programs/list'
import { PublicationChannelService } from 'services/publication-channel/publication-channel'
import { PublicationOwnerService } from 'services/publication-owner/publication-owner'
import { PushMessagesService } from 'services/push-messages/push-messages'
import { StoryLogsService } from 'services/story/logs/logs.service'
import { StoryService } from 'services/story/story'
import { StoryMenuService } from 'services/story/story-menu'
import { StoryTextFormatsListService } from 'services/story/text-format'
import { StoryTypesListService } from 'services/story/types'
import { UploadStoryImageService } from 'services/story/upload-story-image'
import { SurveyLinkService } from 'services/survey-link/survey-link.service'
import { TagsAutocompleteByTitleAndLanguageService } from 'services/tags/autocomplete-by-title-and-language'
import { TagsService } from 'services/tags/tags'
import { TechnicalTagsService } from 'services/technical-tags/technical-tags'
import { TechnicalTagsAutocompleter } from 'services/technical-tags/technical-tags.autocompleter'
import { ThemesService } from 'services/themes/themes'
import { WorkflowService } from 'services/workflow/workflow'
import { YoutubeCategoriesService } from 'services/youtube-categories/youtube-categories'
import { YoutubeVideoService } from 'services/youtube-video/youtube-video'
import { HttpUploadLink } from 'tools/upload-link'
import { environment } from '../../environments/environment'
import { FormValidatorComponent } from '../shared/form-validator/form-validator.component'
import { AIGenerateButtonComponent } from './ai-generate/ai-generate-button/ai-generate-button.component'
import { AIGenerateResultsComponent } from './ai-generate/ai-generate-results/ai-generate-results.component'
import { AlertItemComponent } from './alert-item/alert-item.component'
import { AlphabeticalListComponent } from './alphabetical-list/alphabetical-list.component'
import { CharCounterComponent } from './char-counter/char-counter.component'
import { CommonMetaSwitchComponent } from './common-meta-switch/common-meta-switch.component'
import { ConnectedContributorsComponent } from './connected-contributors/connected-contributors.component'
import { ContentComponent } from './content/content.component'
import { ErrorsMessagesComponent } from './errors-messages/errors-messages.component'
import { ContributorFiltersComponent } from './filter/contributor-filters/contributor-filters.component'
import { DatagridCompositeFilterComponent } from './filter/datagrid-composite-filter/datagrid-composite-filter.component'
import { DatagridDateFilterComponent } from './filter/datagrid-date-filter/datagrid-date-filter.component'
import { DatagridDateRangeFilterComponent } from './filter/datagrid-date-range-filter/datagrid-date-range-filter.component'
import { DatagridFilterableComponent } from './filter/datagrid-filterables/datagrid-filterable.component'
import { DatagridCheckboxFilterComponent } from './filter/datagrid-listing-filter/datagrid-checkbox-filter.component'
import { DatagridRadioFilterComponent } from './filter/datagrid-listing-filter/datagrid-radio-filter.component'
import { DatagridSearchFilterComponent } from './filter/datagrid-search-filter/datagrid-search-filter.component'
import { HeaderLinkComponent } from './header-link/header-link.component'
import { IconLinkComponent } from './icon-link/icon-link.component'
import { ImageGridComponent } from './image-grid/image-grid.component'
import { ImagePreviewComponent } from './image-preview/image-preview.component'
import { ImageSearchComponent } from './image-search/image-search.component'
import { ImageSearchInputComponent } from './image-search-input/image-search-input.component'
import { ImageSearchResultsComponent } from './image-search-results/image-search-results.component'
import { InputEditorialTagsComponent } from './input-editorial-tags/input-editorial-tags.component'
import { InputFeedDeliverablesComponent } from './input-feed-deliverables/input-feed-deliverables.component'
import { InputFeedOverridesComponent } from './input-feed-overrides/input-feed-overrides.component'
import { InputImageDtoService } from './input-image/input-image-dto'
import { InputImageMetadataComponent } from './input-image/input-image-metadata/input-image-metadata.component'
import { InputImageComponent } from './input-image/input-image.component'
import { InputLanguageSelectorComponent } from './input-language-selector/input-language-selector.component'
import { InputProgramsSelectListComponent } from './input-programs-select-list/input-programs-select-list.component'
import { InputProgramsSelectorComponent } from './input-programs-selector/input-programs-selector.component'
import { InputStoryTypesComponent } from './input-story-types/input-story-types.component'
import { InputTechnicalTagsComponent } from './input-technical-tags/input-technical-tags.component'
import { InputThemesSelectListComponent } from './input-themes-select-list/input-themes-select-list.component'
import { InputThemesSelectorComponent } from './input-themes-selector/input-themes-selector.component'
import { ItemLegendComponent } from './item-legend/item-legend.component'
import { PhoneNotificationComponent } from './phone-notification/phone-notification.component'
import { PreviewBreakingNewsComponent } from './preview-breaking-news/preview-breaking-news.component'
import { PreviewImageComponent } from './preview-image/preview-image'
import { PreviewVideoComponent } from './preview-video/preview-video'
import { ProgressBarComponent } from './progress-bar/progress-bar.component'
import { RadioGroupComponent } from './radio-group/radio-group.component'
import { RelatedStoriesComponent } from './related-stories/related-stories.component'
import { SearchBoxComponent } from './search-box/search-box.component'
import { AppConfig, APP_CONFIG } from './shared.config'
import { SideMenuComponent } from './side-menu/side-menu.component'
import { SignpostComponent } from './signpost/signpost.component'
import { SpinnerComponent } from './spinner/spinner.component'
import { StoriesStatusComponent } from './stories-status/stories-status.component'
import { StoryVerificationModalComponent } from './story-content-verification-modal/story-content-verification-modal.component'
import { StoryVignetteComponent } from './story-vignette/story-vignette.component'

const imports = [
    ApolloModule,
    ClarityModule,
    CommonModule,
    FormsModule,
    Ng2CompleterModule,
    ReactiveFormsModule,
    RouterModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DragulaModule.forRoot(),
]

@NgModule({
    imports,
    declarations: [
        AlertItemComponent,
        AlphabeticalListComponent,
        ArticleListingComponent,
        CharCounterComponent,
        CommonMetaSwitchComponent,
        ConnectedContributorsComponent,
        ContentComponent,
        ContributorGetNamePipe,
        AIGenerateButtonComponent,
        AIGenerateResultsComponent,
        AIGenerateDirective,
        DebounceDirective,
        DisableControlDirective,
        CopyClipboardDirective,
        TrimDirective,
        ClickOutsideDirective,
        IfAuthorizedDirective,
        FormValidatorComponent,
        HeaderLinkComponent,
        IconLinkComponent,
        ImageUrlPipe,
        ImageRatioUrlPipe,
        ImagePreviewComponent,
        MediumEditorComponent,
        PreviewImageComponent,
        PreviewVideoComponent,
        ProgressBarComponent,
        SearchBoxComponent,
        SideMenuComponent,
        StoryImageUrlPipe,
        StoriesStatusComponent,
        StoryTextPipe,
        StatusPipe,
        ObjToKeysPipe,
        ToStoryPipe,
        TranslateKeyPipe,
        TranslationFilterPipe,
        MarkdownPipe,
        RelatedStoriesComponent,
        SignpostComponent,
        SlugifyPipe,
        SnakeCasePipe,
        DirectionPipe,
        HighlightPipe,
        ReversePipe,
        SafeurlPipe,
        FormatDatePipe,
        PublicationDatePipe,
        TrimmedTitleCasePipe,
        ErrorsMessagesComponent,
        ItemLegendComponent,
        DatagridCheckboxFilterComponent,
        DatagridDateFilterComponent,
        DatagridRadioFilterComponent,
        DatagridSearchFilterComponent,
        DatagridCompositeFilterComponent,
        DraggableBadgeComponent,
        PublicationStatusComponent,
        IndexationStatusComponent,
        ImageSearchComponent,
        ImageSearchResultsComponent,
        ImageSearchInputComponent,
        ImageGridComponent,
        InputLanguageSelectorComponent,
        InputThemesSelectorComponent,
        InputEditorialTagsComponent,
        InputTechnicalTagsComponent,
        InputProgramsSelectorComponent,
        InputStoryTypesComponent,
        RadioGroupComponent,
        InputFeedOverridesComponent,
        StoryVignetteComponent,
        InputProgramsSelectListComponent,
        InputThemesSelectListComponent,
        InputImageComponent,
        InputImageMetadataComponent,
        PreviewBreakingNewsComponent,
        PhoneNotificationComponent,
        DatagridDateRangeFilterComponent,
        ObjKeyValueListPipe,
        InputFeedDeliverablesComponent,
        ContributorFiltersComponent,
        DatagridFilterableComponent,
        SpinnerComponent,
        FeedbackSurveyComponent,
        DashboardTagsComponent,
        StoryVerificationModalComponent,
    ],
    exports: [
        AlertItemComponent,
        AlphabeticalListComponent,
        ArticleListingComponent,
        CharCounterComponent,
        ClarityModule,
        CommonModule,
        CommonMetaSwitchComponent,
        ConnectedContributorsComponent,
        ContentComponent,
        ContributorGetNamePipe,
        AIGenerateButtonComponent,
        AIGenerateResultsComponent,
        AIGenerateDirective,
        DatagridCheckboxFilterComponent,
        DatagridCompositeFilterComponent,
        DatagridDateFilterComponent,
        DatagridRadioFilterComponent,
        DatagridSearchFilterComponent,
        DraggableBadgeComponent,
        ErrorsMessagesComponent,
        FormsModule,
        FormValidatorComponent,
        HeaderLinkComponent,
        IconLinkComponent,
        ImageUrlPipe,
        ImageRatioUrlPipe,
        ImagePreviewComponent,
        MediumEditorComponent,
        Ng2CompleterModule,
        PreviewImageComponent,
        PreviewVideoComponent,
        ProgressBarComponent,
        ReactiveFormsModule,
        RelatedStoriesComponent,
        RouterModule,
        SearchBoxComponent,
        SideMenuComponent,
        StoryImageUrlPipe,
        StoriesStatusComponent,
        StoryTextPipe,
        StatusPipe,
        ObjToKeysPipe,
        ToStoryPipe,
        TranslateKeyPipe,
        TranslationFilterPipe,
        MarkdownPipe,
        SignpostComponent,
        SlugifyPipe,
        SnakeCasePipe,
        DirectionPipe,
        HighlightPipe,
        ReversePipe,
        SafeurlPipe,
        FormatDatePipe,
        PublicationDatePipe,
        TrimmedTitleCasePipe,
        ItemLegendComponent,
        DebounceDirective,
        DisableControlDirective,
        CopyClipboardDirective,
        TrimDirective,
        ClickOutsideDirective,
        IfAuthorizedDirective,
        PublicationStatusComponent,
        IndexationStatusComponent,
        ImageSearchComponent,
        ImageSearchResultsComponent,
        ImageSearchInputComponent,
        ImageGridComponent,
        InputLanguageSelectorComponent,
        InputThemesSelectorComponent,
        InputEditorialTagsComponent,
        InputTechnicalTagsComponent,
        InputProgramsSelectorComponent,
        InputStoryTypesComponent,
        InputFeedDeliverablesComponent,
        RadioGroupComponent,
        InputFeedOverridesComponent,
        StoryVignetteComponent,
        InputProgramsSelectListComponent,
        InputThemesSelectListComponent,
        InputImageComponent,
        PreviewBreakingNewsComponent,
        PhoneNotificationComponent,
        DatagridDateRangeFilterComponent,
        ObjKeyValueListPipe,
        ContributorFiltersComponent,
        DatagridFilterableComponent,
        SpinnerComponent,
        FeedbackSurveyComponent,
        DashboardTagsComponent,
        StoryVerificationModalComponent,
    ],
    providers: [
        BreakpointService,
        ContinentsService,
        ContributorGetNamePipe,
        ContributorsAutocompleteByTermService,
        ContributorService,
        ContributorFiltersService,
        ContributorsListService,
        CountriesService,
        FilesSelectionService,
        ImageUrlPipe,
        LocationsService,
        AllContributorsData,
        ProducersData,
        AuthorsData,
        VideoEditorsData,
        StoryImageUrlPipe,
        StoryMainTagsData,
        AllTagsData,
        StoryMenuService,
        StoryService,
        StoryTagsData,
        StoryTextFormatsListService,
        StoryTextPipe,
        StatusPipe,
        ObjToKeysPipe,
        DirectionPipe,
        ImageRatioUrlPipe,
        SlugifyPipe,
        TitleCasePipe,
        TrimmedTitleCasePipe,
        StoryTypesListService,
        TagsAutocompleteByTitleAndLanguageService,
        TagsService,
        TechnicalTagsService,
        ThemesService,
        ProgramCategoryService,
        ProgramDeliverableListService,
        ProgramListService,
        DigitalProgramListService,
        PublicationChannelService,
        AlertTypesService,
        AlertTargetTypesService,
        UploadStoryImageService,
        WorkflowService,
        YoutubeCategoriesService,
        YoutubeVideoService,
        PushMessagesService,
        ErrorsMessagesService,
        BaseService,
        BreakingNewsDefaultImage,
        { provide: APP_CONFIG, useValue: AppConfig },
        HttpUploadLink,
        MessagesNotificationService,
        GalleryPreviewUtils,
        PartnerFeedOverrideService,
        PublicationOwnerService,
        PartnerFeedOverrideService,
        TechnicalTagsAutocompleter,
        StoryLogsService,
        InputImageDtoService,
        SurveyLinkService,
        AIService,
    ],
})
export class SharedModule {
    constructor(apollo: Apollo, httpUploadLink: HttpUploadLink) {
        const normalLink = new BatchHttpLink({
            uri: environment.mercury.baseUrl,
            credentials: 'include',
        })

        const error = onError(({ graphQLErrors }) => {
            if (graphQLErrors) {
                const found = graphQLErrors.find(e => e.message.match(/MUST_BE_AUTHENTICATED/) !== null)

                if (found) {
                    let redirect = '/login'
                    if (window['location'].pathname) {
                        redirect += `?returnUrl=${window['location'].pathname}`
                    }

                    window['location'].href = redirect
                }
            }
        })
        const defaultLink = error.concat(normalLink)

        const cache = new InMemoryCache({ possibleTypes: fragmentMatcher, typePolicies })

        const persistor = new SynchronousCachePersistor({
            cache,
            storage: new LocalStorageWrapper(window.localStorage),
            maxSize: false,
        })

        persistor.restoreSync()

        apollo.create({
            link: defaultLink,
            cache: cache,
            defaultOptions: {
                query: {
                    fetchPolicy: 'network-only',
                },
                watchQuery: {
                    fetchPolicy: 'cache-and-network',
                },
            },
        })
        apollo.getClient().onClearStore(() => persistor.purge())

        // upload client
        const uploadLink = httpUploadLink.create({
            uri: environment.mercury.baseUrl,
            withCredentials: true,
        })
        apollo.create(
            {
                link: uploadLink,
                cache: cache,
            },
            'upload',
        )
    }
}
