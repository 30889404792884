<clr-modal
    [(clrModalOpen)]="isModalOpen"
    clrModalSize="lg"
    [clrModalClosable]="!eventService.saving"
    (clrModalOpenChange)="onOpenChanged()"
    [clrModalStaticBackdrop]="true"
    data-testid="modal-create-event">
    <h3 class="modal-title">
        <clr-icon shape="sync" size="24" class="c-icon-title"></clr-icon>
        {{ modalTitle | titlecase }}
    </h3>
    <div class="modal-body">
        <!-- SPINNER -->
        <ng-container *ngIf="showLoader.event; else modalContent">
            <cms-progress-bar [isDisplayed]="showLoader.event" [isLoading]="false"></cms-progress-bar>
        </ng-container>

        <!-- MODAL CONTENT -->
        <ng-template #modalContent>
            <form
                clrForm
                clrLayout="horizontal"
                class="u-form-fullwidth"
                *ngIf="!showLoader.event"
                [formGroup]="eventForm"
                (ngSubmit)="onSubmit()"
                id="create-dig-story"
                [class.loadingForm]="showLoader.event"
                #modalForm>
                <!-- EVENT NAME -->
                <div class="clr-form-control clr-row">
                    <span class="clr-col-md-3 clr-control-label">Story Name</span>
                    <div class="clr-col-9">
                        <span class="event-name"> {{ event.daletTitleName }} </span>
                    </div>
                </div>
                <!-- PRODUCER LANGUAGE -->
                <div class="clr-form-control clr-row">
                    <span class="clr-col-md-3 clr-control-label">Languages</span>
                    <div class="langs clr-col-9">
                        <div
                            *ngFor="let language of allLanguagesList"
                            [ngClass]="{
                                'producer-language': language.id === event.producerLanguage?.id,
                                'story-created': currentEventIsoCodes.includes(language.isoCode)
                            }"
                            (click)="currentEventIsoCodes.includes(language.isoCode) && onClickLang(language.isoCode)">
                            <img class="c-topbar__flag" src="assets/images/svg/flags/{{ language.isoCode }}.svg" />
                            <span class="language-name"> {{ language.fullname }} </span>
                            <span
                                *ngIf="language.id === event.producerLanguage?.id"
                                class="badge badge-blue"
                                title="Producer Language"
                                ><strong>P</strong></span
                            >
                        </div>
                    </div>
                </div>
                <!-- BREAKING NEWS -->
                <clr-toggle-container>
                    <label for="isBreakingNews" class="clr-col-md-3">Breaking News</label>
                    <clr-toggle-wrapper>
                        <input
                            type="checkbox"
                            clrToggle
                            class="clr-col-4"
                            id="isBreakingNews"
                            name="isBreakingNews"
                            formControlName="isBreakingNews"
                            value="option1" />
                    </clr-toggle-wrapper>
                </clr-toggle-container>
                <!-- EMBARGO DATE -->
                <div class="clr-row clr-form-control">
                    <label for="hasEmbargoDate" class="clr-control-label clr-col-md-3">Embargo Date</label>
                    <div class="clr-row u-flex u-no-padding-right clr-col-md-9">
                        <clr-toggle-wrapper class="clr-col-1">
                            <input
                                type="checkbox"
                                clrToggle
                                id="hasEmbargoDate"
                                name="hasEmbargoDate"
                                formControlName="hasEmbargoDate"
                                value="option1" />
                        </clr-toggle-wrapper>
                        <div class="c-input__owl-date-time">
                            <input
                                type="text"
                                id="embargoDate"
                                name="embargoDate"
                                formControlName="embargoDate"
                                [selectMode]="'single'"
                                [owlDateTimeTrigger]="embargoDateOwl"
                                [owlDateTime]="embargoDateOwl"
                                [min]="now" />
                            <clr-control-helper *ngIf="embargoDateAlertMessage">
                                <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                                Impossible to set embargo date -
                                <span [ngPlural]="onlineStories">
                                    <ng-template ngPluralCase=">1">Articles</ng-template>
                                    <ng-template ngPluralCase="=1">Article</ng-template>
                                </span>
                                {{ embargoDateAlertMessage }} already published.</clr-control-helper
                            >
                            <owl-date-time #embargoDateOwl></owl-date-time>
                        </div>
                    </div>
                </div>
                <!-- INFOS -->
                <div class="clr-form-control">
                    <label for="instructions" class="clr-col-md-3 clr-control-label">Info</label>
                    <div class="clr-col-md-9 clr-control-container">
                        <div class="clr-textarea-wrapper">
                            <textarea
                                #instructions
                                id="instructions"
                                name="instructions"
                                formControlName="instructions"
                                [maxLength]="instructionsMaxLength"
                                placeholder="e.g. Please hide the video, do not publish to youTube"
                                class="contributor-bio__textarea clr-textarea"></textarea>
                        </div>
                        <span class="clr-subtext">
                            <cms-char-counter
                                class="clr-subtext"
                                [value]="instructions.value"
                                [maxLength]="instructionsMaxLength">
                            </cms-char-counter>
                        </span>
                    </div>
                </div>
            </form>
        </ng-template>
    </div>
    <div class="modal-footer">
        <cms-alert-item
            *ngFor="let error of formErrors"
            [alertClosable]="true"
            [alertType]="'danger'"
            [alertMessage]="error"
            (clrAlertClosedChange)="resetErrors()"></cms-alert-item>

        <cms-alert-item
            *ngFor="let success of formSuccess"
            [alertClosable]="true"
            [alertType]="'success'"
            [alertMessage]="success"
            (clrAlertClosedChange)="resetSuccess()"></cms-alert-item>

        <button
            type="button"
            class="btn btn-outline"
            [attr.disabled]="eventService.saving || null"
            (click)="onOpenChanged()">
            Cancel
        </button>
        <button
            type="submit"
            form="create-dig-story"
            class="btn btn-primary"
            [attr.disabled]="isSaveButtonDisabled || null">
            Save
        </button>
    </div>
</clr-modal>
