import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { StoryLog } from 'models/story.model'
import { Observable } from 'rxjs'
import { BaseService } from 'services/base'
import { ErrorsMessagesService } from 'services/errors-messages/errors-messages.service'
import { networkQuery } from 'tools/apollo'
import { getStoryLogsQuery } from './query'

@Injectable()
export class StoryLogsService extends BaseService {
    constructor(apollo: Apollo, errorsMessagesService: ErrorsMessagesService) {
        super(apollo, errorsMessagesService)
    }

    getStoryLogs(storyId: number): Observable<StoryLog[]> {
        return networkQuery(
            this.apollo,
            {
                query: getStoryLogsQuery,
                variables: { storyId },
            },
            ['data', 'story', 'logs'],
        )
    }
}
