import { encode } from 'html-entities'

export interface SizeForStyle {
    [size: string]: { width: number; height: number }
}
class Str {
    static htmlEntitiesDecode(str: string): string {
        return str
            .replace(/&amp;/g, '&')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&quot;/g, '"')
            .replace(/&#x27;/g, "'")
            .replace(/&#x60;/g, '`')
    }

    static isEncoded(uri: string): boolean {
        uri = uri || ''

        return uri !== decodeURIComponent(uri)
    }

    static fixedEncodeURIComponent(str: string): string {
        return encodeURIComponent(str).replace(/[!'()*]/g, strPart => {
            return '%' + strPart.charCodeAt(0).toString(16)
        })
    }

    static htmlEntitiesEncode(str: string): string {
        return encode(str)
    }
}

class Widget {
    /**
     * The sizes we offer in the CMS and their corresponding max image width on the website
     */
    static sizeMap = new Map([
        ['xsmall', 202],
        ['small', 266],
        ['medium', 404],
        ['large', 534],
        ['xlarge', 606],
        ['fullwidth', 808],
        ['extended', 1280],
        ['fullscreen', 1052],
    ])

    static getSize(node: HTMLElement): string {
        let size = ''
        const match = node.className.match(/widget--size-([^\s$]+)/)

        if (match) {
            size = match[1]
        }

        return size
    }

    static getAlignment(node: HTMLElement): string {
        let alignement = ''
        const match = node.className.match(/widget--align-([^\s$]+)/)

        if (match) {
            alignement = match[1]
        }

        return alignement
    }

    /**
     * Calculates the image aspect ratio. Defaults to 16:9 if all else fails.
     *
     * @param width - The pixel width of the image
     * @param height - The pixel height of the image
     *
     * @returns {number}
     */
    static calculateRatio(width?: number, height?: number): number {
        return width && height ? Math.round((width / height) * 100) / 100 : 1.77
    }

    /**
     * Calculates the correct pixel height for the current image ratio for creating the srcset declaration
     *
     * @param ratio The width / height aspect ratio to use
     * @returns {any} An object of width/heights for the various sizes we offer in the CMS
     */
    static getSizesForStyles(ratio): SizeForStyle {
        const returnObj: any = {}

        for (const [sizeDescriptor, pixelWidth] of Widget.sizeMap) {
            returnObj[sizeDescriptor] = {
                width: pixelWidth,
                height: Math.round(pixelWidth / ratio),
            }
        }

        return returnObj
    }
}

export abstract class Utils {
    public static string = Str
    public static widget = Widget
}
