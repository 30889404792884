import { AfterViewInit, Component, Input } from '@angular/core'
import { BaseAbstractComponent } from 'app/shared/base.component'
import { Story } from 'entities/story'
import { LanguageIsoCode } from 'enums/language'
import { EventModel } from 'models/event.model'
import { takeUntil } from 'rxjs/operators'
import { EventService } from 'services/events/event'

@Component({
    selector: 'cms-dashboard-stories-detail',
    templateUrl: './dashboard-stories-detail.component.html',
    styleUrls: ['./dashboard-stories-detail.component.scss'],
})
export class DashboardStoriesDetailComponent extends BaseAbstractComponent implements AfterViewInit {
    @Input() story: Story

    public loading: boolean = true
    public LanguageIsoCode = LanguageIsoCode

    constructor(protected eventService: EventService) {
        super()
    }

    ngAfterViewInit() {
        if (!this.story.event) {
            return
        }

        this.eventService
            .getEvent(this.story.event.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((event: EventModel) => {
                if (event) {
                    this.story.event.stories = event.stories
                }
                this.loading = false
            })
    }
}
