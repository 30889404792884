import { DatePipe } from '@angular/common'
import { Injectable } from '@angular/core'

@Injectable()
export class HelpersDates {
    /**
     * Return the date formated in format y-MM-dd
     *
     * @param {date} currentDate
     * @returns {date}
     */
    static formatDate(currentDate: Date, format = 'y-MM-dd') {
        const datePipe = new DatePipe('en-GB')

        return datePipe.transform(currentDate, format)
    }

    /**
     * Return the date formated as iso
     *
     * @param {string} dateStr
     * @returns {string}
     */
    static getDateIso(dateStr: string | null | undefined) {
        return (dateStr && new Date(dateStr).toISOString()) || null
    }

    /**
     * Return if the date is in past
     *
     * @param {Date} date
     * @returns {Date}
     */
    static isDatePassed(date: Date | string | null | undefined) {
        const now = new Date()
        const compare =
            (typeof date === 'string' && new Date(date)) ||
            (date instanceof Date && date) ||
            (!date && new Date()) ||
            new Date()

        return Boolean(now.getTime() > compare.getTime())
    }

    /**
     * Return the date + number of hours
     *
     * @param {Date} currentDate
     *        {number} hours
     * @returns {Date}
     */
    static addHours(currentDate: Date, hours: number) {
        const MILLISECS_PER_HOUR = 60 /* min/hour */ * 60 /* sec/min */ * 1000 /* ms/s */

        return new Date(+currentDate + hours * MILLISECS_PER_HOUR)
    }

    /**
     * Return the date formated as iso for entities
     *
     * @param {string} dateStr
     * @returns {string}
     */
    static dateIso(dateStr) {
        const dt = new Date(dateStr)

        return this.isValidDate(dt) ? dt.toISOString() : ''
    }

    /**
     * Return if a date is a valid date
     *
     * @param {date} date
     * @returns {boolean}
     */
    static isValidDate(date: Date) {
        return date instanceof Date && !isNaN(date.valueOf())
    }
}
