export enum WireImportDefaults {
    PROGRAM_DELIVERABLE = 40,
    EVENT_SOURCE_ID = 2,
    STORY_TYPE_ID = 16,
}

export enum WireUIMessages {
    RECOVERING_NEWSWIRE = 'Recovering newswire…',
    RECOVERY_ERROR = 'A problem occurred while recovering the newswire from the database',
    CREATING_EVENT = 'Creating associated Event…',
    CREATE_EVENT_ERROR = 'An error occurred creating the associated Event',
    RECOVERING_STORY = 'Recovering associated Story…',
    DETERMINING_HEADER_IMAGE = 'Determining best-fit header image…',
    CONVERTING_NEWSWIRE_TEXT = 'Converting newswire text…',
    SAVING_STORY_TEXT = 'Saving converted Story text…',
    SAVING_STORY_PLAINTEXT = 'Saving converted Story plaintext…',
    CONVERTING_TO_PLAINTEXT = 'Creating plaintext version of the Story text…',
    IMPORT_SUCCESS = 'Import Successful',
    SAVING_STORY_HEADLINE = 'Saving Story headline…',
    SAVING_CONTRIBUTOR = 'Saving Story contributor…',
}
