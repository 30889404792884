import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormControl, FormGroup, FormRecord, NonNullableFormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { StoryModalBaseAbstractComponent } from 'app/shared/base.component'
import { LanguageIsoCode } from 'enums/language'
import { defaultProgramDeliverableId } from 'enums/program-deliverable'
import { ProgramsCategory } from 'enums/programs-category'
import { PublicationOwnerEnum } from 'enums/publication-owner'
import { PublicationStatus } from 'enums/publication-status'
import { UserRight } from 'enums/user-role'
import { MutationResult } from 'interfaces/query-result'
import { EventCreateOrUpdate, EventModel } from 'models/event.model'
import { LanguageModel } from 'models/language.model'
import { ProgramDeliverableModel } from 'models/program-deliverable.model'
import { ProgramModel } from 'models/program.model'
import { FormatDatePipe } from 'pipes/format-date.pipe'
import { zip } from 'rxjs'
import { distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators'
import { AuthService } from 'services/auth/auth'
import { StoriesByEdition } from 'services/dashboard/stories-by-edition'
import { EventService } from 'services/events/event'
import { LanguagesService } from 'services/languages/languages'
import { ProgramDeliverableListService } from 'services/program-deliverables/list'
import { DigitalProgramListService } from 'services/programs/digital.list'
import { AlertMessage, FormControlNames, ModalTitlePrefix } from './constants'

const FORBIDDEN_STATUS_FOR_CHANGING_PROGRAM: number[] = [PublicationStatus.NEW, PublicationStatus.OFFLINE]
const EMBARGO_MESSAGE_PREFIX = 'Warning, this event is under embargo. Do not publish until'
const EXCLUDED_LANG_ID_LIST = [8]

interface EventForm {
    name: FormControl<string>
    editorialDesk: FormControl<string>
    programId: FormControl<number | null>
    programDeliverableId: FormControl<number | null>
    isBreakingNews: FormControl<boolean>
    isSingleMedia: FormControl<boolean>
    hasEmbargoDate: FormControl<boolean>
    embargoDate: FormControl<Date | null>
    producerLanguageId: FormControl<number | null>
    languages: FormRecord<FormControl<boolean>>
    isCoreLanguages: FormControl<boolean>
    isTv: FormControl<boolean>
    isMultiplex: FormControl<boolean>
    isDigital: FormControl<boolean>
    instructions: FormControl<string>
}
@Component({
    selector: 'cms-create-event',
    templateUrl: './create-event.component.html',
    styleUrls: ['./create-event.component.scss'],
})
export class CreateEventComponent extends StoryModalBaseAbstractComponent implements OnInit, OnDestroy {
    private selectedProgram: ProgramModel
    private selectedProgramIsoCodes: LanguageIsoCode[] = []
    private currentEventIsoCodes: LanguageIsoCode[] = []

    public showLoader = {
        programs: true,
        programDeliverables: true,
        event: true,
    }
    public eventToUpdate: EventModel
    public eventForm: FormGroup<EventForm>
    public isoCodes = LanguageIsoCode
    public allLanguagesList: LanguageModel[] = []
    public disabledLanguagesList: LanguageModel[] = []
    public noCoreLanguagesList: LanguageModel[] = []
    public coreLanguagesList: LanguageModel[] = []
    public selectableLanguagesList: LanguageModel[] = []
    public programDeliverableList: ProgramDeliverableModel[] = []
    public programList: ProgramModel[] = []
    public alertMessages: typeof AlertMessage = AlertMessage
    public DEFAULT_PRODUCER_LANGUAGE_ID = 1
    public instructionsMaxLength: number = 250
    public isModalOpen: boolean = false
    public displayAfricanewsPrograms: boolean
    public isCategoryProgram: boolean
    public isChangingProgramNotRecommended: boolean = false
    public isChangingProgramForbidden: boolean = false
    public isWorkflowMagazine: boolean = false
    public isSelectableForCreation: boolean
    public isSelectableForUpdate: boolean
    public isSaveButtonDisabled: boolean | null = true
    public eventId: number
    public modalTitle: string
    public coreLanguagesTooltipContent: string
    public languagesEventForm: FormGroup
    public embargoDateAlertMessage: string | null = null

    private deliverables: ProgramDeliverableModel[] = []

    public get now(): Date {
        return new Date()
    }

    constructor(
        protected router: Router,
        protected formBuilder: NonNullableFormBuilder,
        protected activatedRoute: ActivatedRoute,
        protected languagesService: LanguagesService,
        protected eventService: EventService,
        protected authService: AuthService,
        protected digitalProgramListService: DigitalProgramListService,
        protected programDeliverableListService: ProgramDeliverableListService,
        protected storiesEditionService: StoriesByEdition,
        protected formatDatePipe: FormatDatePipe,
    ) {
        super()
    }

    ngOnInit(): void {
        this.isModalOpen = true
        this.setModalTitlePrefix()
        this.setCoreLanguagesTooltipContent()
        this.loadLanguageLists()
        this.initEventForm()
        this.loadProgramDeliverableList()
        this.loadProgramList()
        this.runCreationOrUpdateEvent()
    }

    private loadProgramList(): void {
        this.digitalProgramListService.programs
            .pipe(
                map(this.getProgramsWithTranslation),
                tap(programs => {
                    this.programList = programs
                    this.updateLanguageControlsStatuses()
                }),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(() => (this.showLoader.programs = false))
    }

    private setModalTitlePrefix(): void {
        this.modalTitle = this.showLoader.event
            ? ModalTitlePrefix.LOADING
            : this.eventToUpdate
            ? ModalTitlePrefix.UPDATE_EVENT
            : ModalTitlePrefix.CREATE_EVENT
    }

    private setCoreLanguagesTooltipContent(): void {
        this.coreLanguagesTooltipContent = this.languagesService.coreLanguageIsoCodeList.join(', ').toUpperCase()
    }

    private excludeLanguagesForEvent(): void {
        this.noCoreLanguagesList = this.noCoreLanguagesList.filter(
            language => !EXCLUDED_LANG_ID_LIST.includes(language.id),
        )
        this.allLanguagesList = this.allLanguagesList.filter(language => !EXCLUDED_LANG_ID_LIST.includes(language.id))
    }

    private loadLanguageLists(): void {
        this.allLanguagesList = this.languagesService.languages
        this.noCoreLanguagesList = this.languagesService.nonCoreLanguages
        this.coreLanguagesList = this.languagesService.coreLanguages
        this.selectableLanguagesList = this.languagesService.nonCoreLanguages
        this.excludeLanguagesForEvent()
    }

    private initEventForm(): void {
        this.eventForm = this.formBuilder.group<EventForm>({
            name: this.formBuilder.control('', Validators.required),
            editorialDesk: this.formBuilder.control(''),
            programId: this.formBuilder.control(null, Validators.required),
            programDeliverableId: this.formBuilder.control(null, Validators.required),
            isBreakingNews: this.formBuilder.control(false),
            isSingleMedia: this.formBuilder.control(false),
            hasEmbargoDate: this.formBuilder.control(false),
            embargoDate: this.formBuilder.control(null),
            producerLanguageId: this.formBuilder.control(this.DEFAULT_PRODUCER_LANGUAGE_ID),
            languages: this.formBuilder.group({}),
            isCoreLanguages: this.formBuilder.control(false),
            isTv: this.formBuilder.control(false),
            isMultiplex: this.formBuilder.control(false),
            isDigital: this.formBuilder.control(true),
            instructions: this.formBuilder.control(''),
        })

        this.languagesEventForm = this.eventForm.controls.languages
        this.selectableLanguagesList = this.getSelectableLanguages()

        this.selectableLanguagesList.forEach(language => {
            this.languagesEventForm.setControl(
                language.isoCode!,
                this.formBuilder.control({ value: false, disabled: true }),
                {
                    emitEvent: false,
                },
            )
        })
    }

    private loadProgramDeliverableList(): void {
        this.programDeliverableListService.programDeliverables
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(programDeliverables => {
                this.programDeliverableList = programDeliverables
                this.deliverables = programDeliverables
                this.showLoader.programDeliverables = false
            })
        this.programDeliverableListService.get({ isActive: true }, 150, 0)
    }

    private getProgramsWithTranslation(programs: ProgramModel[]): ProgramModel[] {
        return programs.filter(program => program.translations.length)
    }

    private updateLanguageControlsStatuses(): void {
        this.programDeliverableList = this.deliverables
        const selectedProgramId = this.eventForm.controls.programId.value!
        this.selectedProgram = this.getProgramById(selectedProgramId)!
        if (!this.selectedProgram) {
            return
        }
        const selectedProgramDeliverableIds = this.selectedProgram.programDeliverables!.map(
            progDeliverable => progDeliverable.id,
        )
        this.programDeliverableList = this.programDeliverableList.filter(deliverable =>
            selectedProgramDeliverableIds.includes(deliverable.id),
        )

        this.selectedProgramIsoCodes = this.selectedProgram.translations.map(
            translation => translation.language.isoCode,
        )
        this.isWorkflowMagazine = this.isWorkFlowMagazineProgram(this.selectedProgram)
        this.selectableLanguagesList = this.isWorkflowMagazine
            ? this.languagesService.languages
            : this.getSelectableLanguages()

        this.selectableLanguagesList.forEach(language => {
            const languageIsoCodesToCheck: LanguageIsoCode[] = this.isWorkflowMagazine
                ? [language.isoCode!]
                : this.getLanguageIsoCodesToCheck(language.isoCode!)

            const isSelectedProgramHasLanguage = this.selectedProgramHasLanguageIsoCode(languageIsoCodesToCheck)

            const isLanguageSelected =
                this.isEventCreation() && isSelectedProgramHasLanguage
                    ? this.languagesEventForm.controls[language.isoCode!].value
                    : this.eventHasLanguageIsoCode(languageIsoCodesToCheck)

            const isLanguageControlDisabled = this.isEventCreation()
                ? !isSelectedProgramHasLanguage || this.eventToUpdate?.isMagazine || this.isEventDisabled()
                : !isSelectedProgramHasLanguage ||
                  this.eventHasLanguageIsoCode(languageIsoCodesToCheck) ||
                  isLanguageSelected

            this.languagesEventForm.setControl(
                language.isoCode!,
                this.formBuilder.control({
                    value: isLanguageSelected,
                    disabled: isLanguageControlDisabled,
                }),
            )
        })

        if (
            this.programDeliverableListContainsDefaultDeliverable(this.programDeliverableList) &&
            !this.eventForm.controls.programDeliverableId.value
        ) {
            this.eventForm.controls.programDeliverableId.patchValue(defaultProgramDeliverableId)
            this.enableFormControl(FormControlNames.PROGRAM_DELIVERABLE_ID)
        }
    }

    private runCreationOrUpdateEvent(): void {
        zip(this.authService.me, this.activatedRoute.paramMap)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([_, params]) => {
                this.eventId = +params.get('id')!
                this.displayAfricanewsPrograms = !this.isEventCreation()
                this.isCategoryProgram = this.isEventCreation()

                if (this.isEventCreation()) {
                    this.isSelectableForCreation = true
                    this.isSelectableForUpdate = true
                    this.getProgramList()
                    this.disableFormControl(FormControlNames.PROGRAM_DELIVERABLE_ID)
                    this.disableFormControl(FormControlNames.EMBARGO_DATE)
                    this.showLoader.event = false
                    this.setModalTitlePrefix()
                } else {
                    this.updateEvent(this.eventId)
                }
                this.onEventNameControlChange()
                this.onLanguageControlsChange()
                this.onProducerLanguageControlChange()
                this.onProgramControlChange()
                this.onProgramDeliverableControlChange()
                this.onHasEmbargoDateControlChange()
                this.onEmbargoDateControlChange()
            })
    }

    private getEventFormSelectedIsoCodes(): string[] {
        const languagesControlValue = this.languagesEventForm.getRawValue()

        return Object.keys(languagesControlValue).reduce((selectedLanguage: string[], isoCode: string) => {
            if (languagesControlValue[isoCode]) {
                if (isoCode === this.isoCodes.CORE_LANGUAGES) {
                    selectedLanguage.push(...this.coreLanguagesList.map(language => language.isoCode))
                } else {
                    selectedLanguage.push(isoCode)
                }
            }

            return selectedLanguage
        }, [])
    }

    private onProgramControlChange(): void {
        this.eventForm.controls.programId.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.isSaveButtonDisabled = this.saveButtonDisabled()
        })
    }

    private onProgramDeliverableControlChange(): void {
        this.eventForm.controls.programDeliverableId.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.isSaveButtonDisabled = this.saveButtonDisabled()
        })
    }

    private onEventNameControlChange(): void {
        this.eventForm.controls.name.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.isSaveButtonDisabled = this.saveButtonDisabled()
        })
    }

    private onLanguageControlsChange(): void {
        this.languagesEventForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            const coreLanguagesControl = this.languagesEventForm.controls.core_languages
            this.eventForm.controls.isCoreLanguages.setValue(coreLanguagesControl.value)
            coreLanguagesControl.disabled
                ? this.eventForm.controls.isCoreLanguages.disable()
                : this.eventForm.controls.isCoreLanguages.enable()

            this.setAlertDisplayStatuses()
            this.isSaveButtonDisabled = this.saveButtonDisabled()
        })
    }

    private onProducerLanguageControlChange(): void {
        this.eventForm.controls.producerLanguageId.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(_ => {
            this.languagesEventForm.updateValueAndValidity()
        })
    }

    private onHasEmbargoDateControlChange(): void {
        this.eventForm.controls.hasEmbargoDate.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged())
            .subscribe(value => {
                if (value && !this.embargoDateAlertMessage) {
                    this.enableFormControl(FormControlNames.EMBARGO_DATE)
                } else {
                    this.disableFormControl(FormControlNames.EMBARGO_DATE)
                    this.eventForm.controls.embargoDate.setValue(null)
                }
            })
    }

    /**
     * Add or update the embargo message in the info box.
     */
    private onEmbargoDateControlChange(): void {
        this.eventForm.controls.embargoDate.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged())
            .subscribe(value => {
                const embargoMessageRegex = new RegExp(`(?<=.*)\\s*${EMBARGO_MESSAGE_PREFIX} [\\d/ :]*.(?=\\s*.*)`, 'g')
                const instructionsControl = this.eventForm.controls.instructions
                const cleanedInstructions = instructionsControl.value.replace(embargoMessageRegex, '').trim()
                const separator = cleanedInstructions ? '\n' : ''
                const embargoMessage = value ? `${EMBARGO_MESSAGE_PREFIX} ${this.formatDatePipe.transform(value)}.` : ''
                const newInstructions = cleanedInstructions + separator + embargoMessage
                instructionsControl.setValue(newInstructions, { emitEvent: false })
            })
    }

    private isSelectedProgramOwnedBy(publicationOwnerId: number): boolean {
        return this.selectedProgram?.publicationOwner?.id === publicationOwnerId
    }

    private saveButtonDisabled(): boolean | null {
        const isSelectedProgramOwnedByAfricanews = this.isSelectedProgramOwnedBy(PublicationOwnerEnum.Africanews)

        return (
            this.eventForm?.invalid ||
            this.languagesEventForm.invalid ||
            this.eventForm?.controls.programId.invalid ||
            this.eventForm?.controls.programDeliverableId.invalid ||
            this.eventService.saving ||
            this.showLoader.event ||
            this.isEventDisabled() ||
            this.isChangeEventProgramForbidden() ||
            (!this.isProducerLanguageInSelectedLanguages() && !isSelectedProgramOwnedByAfricanews)
        )
    }

    private isProducerLanguageInSelectedLanguages(): boolean {
        const currentProducerLanguageId = this.eventForm.controls.producerLanguageId.value

        if (!currentProducerLanguageId) {
            return false
        }

        const currentProducerLanguage = this.languagesService.languages.find(
            language => language.id === +currentProducerLanguageId,
        )

        const isProducerLanguageIsCoreLanguage = this.languagesService.isCoreLanguageIsoCode(
            currentProducerLanguage!.isoCode,
        )

        return isProducerLanguageIsCoreLanguage
            ? !!this.languagesEventForm.controls[LanguageIsoCode.CORE_LANGUAGES].value
            : !!this.languagesEventForm.controls[currentProducerLanguage!.isoCode].value
    }

    private getSelectableLanguages(): LanguageModel[] {
        const displayedLanguages = [
            {
                id: 0,
                fullname: 'Core Languages',
                isCoreLanguage: true,
                isoCode: this.isoCodes.CORE_LANGUAGES,
            },
        ]

        this.noCoreLanguagesList.forEach(language => {
            displayedLanguages.push({
                id: language.id,
                fullname: language.fullname!,
                isCoreLanguage: language.isCoreLanguage!,
                isoCode: language.isoCode,
            })
        })

        return displayedLanguages
    }

    private eventHasLanguageIsoCode(languageIsoCodeToCheck: LanguageIsoCode[]): boolean {
        return this.isEventCreation()
            ? false
            : languageIsoCodeToCheck.some(isoCode => this.languageExistsInEventStories(isoCode))
    }

    private selectedProgramHasLanguageIsoCode(languageIsoCodeToCheck: LanguageIsoCode[]): boolean {
        return languageIsoCodeToCheck.some(isoCode => this.selectedProgramContainsLanguageIsoCode(isoCode))
    }

    private getLanguageIsoCodesToCheck(languageIsoCode: LanguageIsoCode): LanguageIsoCode[] {
        return languageIsoCode === this.isoCodes.CORE_LANGUAGES
            ? this.languagesService.coreLanguageIsoCodeList
            : [languageIsoCode]
    }

    private disableFormControl(formControlName: FormControlNames): void {
        this.eventForm.controls[formControlName].disable()
    }

    private enableFormControl(formControlName: FormControlNames): void {
        this.eventForm.controls[formControlName].enable()
    }

    private disableEventFormControls(): void {
        if (this.isDaletDisabled()) {
            this.disableFormControl(FormControlNames.NAME)
            this.disableFormControl(FormControlNames.EDITORIAL_DESK)
            this.disableFormControl(FormControlNames.PROGRAM_DELIVERABLE_ID)
            this.disableFormControl(FormControlNames.IS_BREAKING_NEWS)
            this.disableFormControl(FormControlNames.IS_SINGLE_MEDIA)
            this.disableFormControl(FormControlNames.PRODUCER_LANGUAGE_ID)
            this.disableFormControl(FormControlNames.IS_TV)
            this.disableFormControl(FormControlNames.IS_DIGITAL)
        }
        if (this.isEventDisabled()) {
            this.disableFormControl(FormControlNames.PROGRAM_ID)
            this.disableFormControl(FormControlNames.INSTRUCTIONS)
        }
        if (!this.authService.userHasRight(UserRight.UPDATE_EVENT_EMBARGO)) {
            this.disableFormControl(FormControlNames.HAS_EMBARGO_DATE)
            this.disableFormControl(FormControlNames.EMBARGO_DATE)
        }
        if (!this.eventForm.controls.hasEmbargoDate.value) {
            this.disableFormControl(FormControlNames.EMBARGO_DATE)
        }
        this.disableFormControl(FormControlNames.IS_MULTIPLEX)
    }

    private selectedProgramContainsLanguageIsoCode(languageIsoCode: LanguageIsoCode): boolean {
        if (!this.selectedProgram) {
            return false
        }

        return this.selectedProgramIsoCodes.includes(languageIsoCode)
    }

    private getProgramById(programId: number): ProgramModel | null {
        return this.programList.find(program => program.id === programId) || null
    }

    private eventIsEditable(): boolean {
        return !this.eventToUpdate || this.eventToUpdate.isEditable
    }

    private isDaletEvent(): boolean {
        return !!this.eventToUpdate?.daletLink
    }

    private isEventDisabled(): boolean {
        return !this.eventIsEditable()
    }

    private isDaletDisabled(): boolean {
        return this.isEventDisabled() || this.isDaletEvent()
    }

    private isWorkFlowMagazineProgram(program: ProgramModel): boolean {
        return program.category.id === ProgramsCategory.Magazine
    }

    private getProgramList(): void {
        let programSearchOptions = {
            ...{ isDisabledCms: false },
            ...(!this.isEventCreation() && { isSelectableForUpdate: true }),
            ...(this.isEventCreation() && { isSelectableForCreation: true }),
        }
        if (!this.eventToUpdate) {
            programSearchOptions = {
                ...programSearchOptions,
                ...{ publicationOwnerId: PublicationOwnerEnum.Euronews },
                ...{ categoryId: ProgramsCategory.Program },
            }
        }
        this.digitalProgramListService.get(programSearchOptions, 1000, 0)
    }

    private isEventCreation(): boolean {
        return !this.eventId
    }

    private updateEvent(eventId: number): void {
        this.isSelectableForUpdate = true
        this.eventService
            .getEvent(eventId)
            .pipe(
                tap(event => {
                    if (!event) {
                        this.router.navigateByUrl('404')

                        return
                    }
                    this.eventToUpdate = event
                    this.currentEventIsoCodes = event.stories.map(story => story.language.isoCode)

                    this.setEmbargoAlertMessageOnUpdateEvent()
                    this.setDisabledLanguageOnUpdateEvent()
                    this.selectableLanguagesList = this.isWorkflowMagazine
                        ? this.languagesService.languages
                        : this.getSelectableLanguages()

                    this.buildEventFormForUpdate()
                    this.getProgramList()
                    this.disableEventFormControls()
                    this.showLoader.event = false
                    this.setModalTitlePrefix()
                }),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe()
    }

    private buildEventFormForUpdate(): void {
        const languagesControlValues = {}

        const isEventCoreLanguages = this.eventHasCoreLanguages()

        this.selectableLanguagesList.forEach(selectableLanguage => {
            Object.assign(languagesControlValues, {
                [selectableLanguage.isoCode]:
                    selectableLanguage.isoCode === this.isoCodes.CORE_LANGUAGES
                        ? isEventCoreLanguages
                        : this.languageExistsInEventStories(selectableLanguage.isoCode),
            })
        })
        const eventFormPatchObject = {
            name: this.eventToUpdate.daletTitleName,
            editorialDesk: this.eventToUpdate.editorialDesk,
            languages: languagesControlValues,
            programId: this.eventToUpdate.program.id,
            programDeliverableId: this.eventToUpdate.programDeliverable?.id,
            isBreakingNews: this.eventToUpdate.isBreakingNews,
            isSingleMedia: this.eventToUpdate.isSingleMedia,
            hasEmbargoDate: !!this.eventToUpdate.embargoDate,
            embargoDate: this.eventToUpdate.embargoDate,
            producerLanguageId: this.eventToUpdate.producerLanguage?.id,
            isCoreLanguages: isEventCoreLanguages,
            isTv: this.eventToUpdate.isTv,
            isMultiplex: this.eventToUpdate.isMultiplex,
            isDigital: this.eventToUpdate.isDigital,
            instructions: this.eventToUpdate.productionNote || '',
        }
        this.eventForm.patchValue(eventFormPatchObject)
    }

    private setAlertDisplayStatuses(): void {
        this.isChangingProgramNotRecommended =
            this.changeEventProgramIsNotRecommended() &&
            !this.isChangeEventProgramForbidden() &&
            !this.eventService.saving

        this.isChangingProgramForbidden = this.isChangeEventProgramForbidden() && !this.eventService.saving
    }

    private languageExistsInEventStories(isoCode: LanguageIsoCode): boolean {
        return this.currentEventIsoCodes.includes(isoCode)
    }

    private eventHasCoreLanguages(): boolean {
        return this.containsCoreLanguage(this.currentEventIsoCodes)
    }

    private programDeliverableListContainsDefaultDeliverable(programDeliverables: ProgramDeliverableModel[]): boolean {
        return programDeliverables.some(({ id }) => id === defaultProgramDeliverableId)
    }

    private containsCoreLanguage(languageIsoCodes: LanguageIsoCode[]): boolean {
        return languageIsoCodes.some(isoCode => this.languagesService.isCoreLanguageIsoCode(isoCode))
    }

    private isChangeEventProgramForbidden(): boolean {
        if (!this.eventToUpdate || !this.selectedProgram) {
            return false
        }
        const eventToUpdateLanguageIds = this.eventToUpdate.stories.map(story => story.language.id)
        const selectedProgramLanguageTranslationIds = this.selectedProgram.translations.map(
            translation => translation.language.id,
        )
        const translatedStoriesLanguageIds = eventToUpdateLanguageIds.filter(eventLanguageId =>
            selectedProgramLanguageTranslationIds.includes(eventLanguageId),
        )

        const eventUntranslatedStories = this.eventToUpdate.stories.filter(
            story => !translatedStoriesLanguageIds.includes(story.language.id),
        )
        const isTranslationsForbidden = eventUntranslatedStories.some(
            story => !FORBIDDEN_STATUS_FOR_CHANGING_PROGRAM.includes(story.publicationStatus!.id),
        )

        return isTranslationsForbidden && this.changeEventProgramIsNotRecommended()
    }

    private changeEventProgramIsNotRecommended(): boolean {
        if (!this.selectedProgram) {
            return false
        }

        if (!this.eventToUpdate) {
            const eventFormLanguageIsoCodes = Object.keys(this.eventForm.controls.languages.value).filter(
                languageKey => this.eventForm.controls.languages.value[languageKey],
            )
            const eventFormSelectedLanguageIsoCodes = this.languagesService.languageIsoCodeList.filter(isoCode =>
                eventFormLanguageIsoCodes.includes(isoCode),
            )
            if (eventFormLanguageIsoCodes.includes(LanguageIsoCode.CORE_LANGUAGES)) {
                eventFormSelectedLanguageIsoCodes.push(...this.languagesService.coreLanguageIsoCodeList)
            }

            return eventFormSelectedLanguageIsoCodes.some(isoCode => !this.selectedProgramIsoCodes.includes(isoCode))
        } else if (this.disabledLanguagesList && this.eventToUpdate.program.id !== this.eventForm.value.programId) {
            const disabledLanguagesIsoCodeList = this.disabledLanguagesList.map(language => language.isoCode)

            return disabledLanguagesIsoCodeList.some(isoCode => !this.selectedProgramIsoCodes.includes(isoCode))
        }

        return false
    }

    private setEmbargoAlertMessageOnUpdateEvent(): void {
        const publishedStories = this.eventToUpdate.stories.filter(s =>
            [PublicationStatus.ONLINE, PublicationStatus.TO_REPUBLISH].includes(s.publicationStatus?.id || 0),
        )

        if (publishedStories.length > 0) {
            this.embargoDateAlertMessage = publishedStories.map(s => s.language.isoCode.toUpperCase()).join(', ')
        }
    }

    private setDisabledLanguageOnUpdateEvent(): void {
        this.eventToUpdate.stories.reduce((disabledLanguages, story) => {
            if (story.language) {
                disabledLanguages.push(story.language)
            }

            return disabledLanguages
        }, this.disabledLanguagesList)
    }

    private mutationResultCb(mutationResult: MutationResult): void {
        this.showLoader.event = false
        if (mutationResult.res) {
            // Update dashboard table.
            this.storiesEditionService.refresh.next()

            this.resetErrors().resetSuccess()

            this.onOpenChanged({
                resetSearchFilters: this.isEventCreation(),
                refreshDashboard: true,
                noScrollToTop: !this.isEventCreation(),
            })
        } else {
            this.formErrors.add(mutationResult.msg !== undefined ? mutationResult.msg : AlertMessage.UNEXPECTED_ERROR)
        }
    }

    public onOpenChanged(options?: {
        resetSearchFilters?: boolean
        refreshDashboard?: boolean
        noScrollToTop?: boolean
    }): void {
        this.open = false
        this.router.navigate(['/', 'dashboard-galaxy'], {
            queryParamsHandling: options?.resetSearchFilters ? '' : 'preserve',
            state: { refreshDashboard: options?.refreshDashboard, noScrollToTop: options?.noScrollToTop },
        })
    }

    private prepareCreateOrUpdatePayload(
        existingEventStoriesLanguagesIsoCodes?: LanguageIsoCode[],
    ): EventCreateOrUpdate {
        let selectedLanguages = (this.getEventFormSelectedIsoCodes() as LanguageIsoCode[]).filter(
            isoCode => !this.containsCoreLanguage([isoCode]),
        )

        if (existingEventStoriesLanguagesIsoCodes?.length) {
            selectedLanguages = selectedLanguages.filter(lang => !existingEventStoriesLanguagesIsoCodes.includes(lang))
        }

        const eventFormRawValues = this.eventForm.getRawValue()

        const payload: EventCreateOrUpdate = {
            id: this.eventToUpdate?.id,
            name: eventFormRawValues.name!,
            editorialDesk: eventFormRawValues.editorialDesk!,
            languages: selectedLanguages,
            programId: eventFormRawValues.programId!,
            programDeliverableId: +eventFormRawValues.programDeliverableId!,
            isBreakingNews: eventFormRawValues.isBreakingNews,
            isSingleMedia: eventFormRawValues.isSingleMedia,
            embargoDate: eventFormRawValues.embargoDate,
            producerLanguageId: +eventFormRawValues.producerLanguageId!,
            isCoreLanguages: this.eventForm.value.isCoreLanguages || false,
            isTv: eventFormRawValues.isTv,
            isMultiplex: eventFormRawValues.isMultiplex,
            isDigital: eventFormRawValues.isDigital,
            instructions: eventFormRawValues.instructions!,
        }

        return payload
    }

    public onSelectedProgramChange(): void {
        this.updateLanguageControlsStatuses()
    }

    public onSubmit(): void {
        this.showLoader.event = true
        this.resetErrors().resetSuccess()
        let existingEventStoriesLanguagesIsoCodes: LanguageIsoCode[] = []
        if (this.eventToUpdate?.id) {
            const isSelectedProgramIsAfricanewsProgram = this.isSelectedProgramOwnedBy(PublicationOwnerEnum.Africanews)
            existingEventStoriesLanguagesIsoCodes = this.currentEventIsoCodes
            const allCoreLanguagesHasBeenCreated = this.coreLanguagesList.every(coreLanguage =>
                existingEventStoriesLanguagesIsoCodes.includes(coreLanguage.isoCode),
            )
            const isCoreLanguagesPatchValue =
                isSelectedProgramIsAfricanewsProgram || allCoreLanguagesHasBeenCreated
                    ? false
                    : this.eventForm.controls.isCoreLanguages.value
            this.eventForm.controls.isCoreLanguages.patchValue(isCoreLanguagesPatchValue)
        }
        const updateEventFormPayload = this.prepareCreateOrUpdatePayload(existingEventStoriesLanguagesIsoCodes)
        this.eventService.createOrUpdate(updateEventFormPayload).subscribe(this.mutationResultCb.bind(this))
    }

    ngOnDestroy(): void {
        super.ngOnDestroy()
    }
}
