import { gql } from '@apollo/client/core'
import { workflowFragment } from './workflow.fragment'

export const workflowQuery = gql`
    ${workflowFragment}

    query workflow($inputId: Int, $inputVersion: Int) {
        workflow(inputId: $inputId, inputVersion: $inputVersion) {
            ...workflowInfo
        }
    }
`
