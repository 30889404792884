import { gql } from '@apollo/client/core'
import { storyWithRelatedFragment } from '../find-by-id.query'

export const setStoryReadyToPublish = gql`
    ${storyWithRelatedFragment}

    mutation setStoryReadyToPublish($storyId: Int!, $url: String, $publishedAt: DateTime!) {
        setStoryReadyToPublish(storyId: $storyId, url: $url, publishedAt: $publishedAt) {
            ...storyWithRelatedInfo
        }
    }
`
