import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { BaseAbstractComponent } from 'app/shared/base.component'
import { takeUntil } from 'rxjs/operators'
import { UserRight, UserRole } from '../../enums/user-role'
import { AuthService } from '../../services/auth/auth'

@Component({
    selector: 'cms-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent extends BaseAbstractComponent implements OnInit {
    counter = 0

    public canAccessContents: boolean = false
    public canAccessDevs: boolean = false

    private contentsRights: UserRight[] = [
        UserRight.ACCESS_BCS,
        UserRight.ACCESS_WIRES,
        UserRight.ACCESS_NATIVE_ADS_MANAGEMENT,
        UserRight.ACCESS_GEOLOCATION,
        UserRight.ACCESS_LIVESTREAM_DASHBOARD,
    ]
    private devsRights: UserRight[] = [
        UserRight.ACCESS_NOTIFICATIONS,
        UserRight.DELETE_MESSAGES,
        UserRight.EDIT_MESSAGES,
    ]

    constructor(protected router: Router, protected auth: AuthService) {
        super()
    }

    ngOnInit(): void {
        this.auth.me.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.canAccessContents = this.contentsRights.some((right: UserRight) => this.auth.userHasRight(right))
            this.canAccessDevs =
                this.auth.userHasRole(UserRole.DEVELOPER) &&
                this.devsRights.some((right: UserRight) => this.auth.userHasRight(right))
        })
    }
}
