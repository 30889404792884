<article
    *ngIf="story || image"
    class="c-vignette"
    [ngClass]="{
        'c-vignette--standard': vignetteDisplayTypes.Standard === displayType,
        'c-vignette--list': vignetteDisplayTypes.List === displayType
    }"
    [attr.data-story-id]="story?.id"
    [dir]="(story ? story : null) | direction">
    <div class="c-vignette__ratio">
        <!-- Base vignett. Not a widget -->
        <figure class="c-vignette__content" *ngIf="!isWidgetEuronews && !isWidgetGallery">
            <img
                class="c-vignette__image"
                [src]="story?.images?.length ? (story!.images![0].url | imageUrl : 200 : 113) : ''" />
            <ng-container *ngTemplateOutlet="figureCaption"></ng-container>
        </figure>

        <!-- Widget Euronews. Video grid -->
        <figure class="c-vignette__content" *ngIf="isWidgetEuronews">
            <div
                class="c-overlay-vignette-video"
                (click)="addVideo(story!)"
                [ngClass]="{ 'c-add-video-button': !isSelected, 'c-added-video-button': isSelected }"></div>
            <div class="image__ratio image__ratio--16x9" *ngIf="previewVideo">
                <ng-template [ngIf]="!previewVideo" [ngIfElse]="playerVideo"></ng-template>
                <ng-template #playerVideo>
                    <video
                        #player
                        controls
                        poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                        [ngStyle]="{
                            'background-image':
                                'linear-gradient(transparent, #000), url(' + (videoPoster | imageUrl : 269 : 151) + ')'
                        }">
                        <source [src]="previewVideo.preview" [type]="'video/' + previewVideo.format.slug" />
                    </video>
                </ng-template>
            </div>
            <ng-container *ngTemplateOutlet="figureCaption"></ng-container>
        </figure>

        <!-- Gallery Widget. Image grid -->
        <figure class="c-vignette__content" *ngIf="isWidgetGallery">
            <div
                class="c-overlay-vignette-image"
                (click)="addImage(image)"
                [ngClass]="{ 'c-add-image-button': !isSelected, 'c-added-image-button': isSelected }"></div>
            <div class="image__ratio image__ratio--16x9">
                <img class="c-vignette__image" [src]="image?.url || '' | imageUrl : 278 : 156" />
            </div>
        </figure>
    </div>
    <ng-template #figureCaption>
        <ng-container *ngIf="displayCaption">
            <figcaption
                [ngClass]="{
                    'euronews-widget-caption': vignetteDisplayTypes.EuronewsWidget === displayType,
                    'c-vignette__caption':
                        vignetteDisplayTypes.Standard === displayType || vignetteDisplayTypes.List === displayType
                }">
                <p
                    [class.c-vignette-title-euronews-widget]="isWidgetEuronews"
                    [class.c-vignette__title]="!isWidgetEuronews"
                    [dir]="story | direction"
                    [title]="storyHeadline">
                    <a
                        *ngIf="isPublished && vignetteDisplayTypes.Standard === displayType; else noLink"
                        [href]="storyURL"
                        target="_blank"
                        >{{ storyHeadline }}</a
                    >
                    <ng-template #noLink>
                        <span>{{ storyHeadline }}</span>
                    </ng-template>
                </p>
                <p
                    [class.c-vignette-euronews-widget]="isWidgetEuronews"
                    [class.c-vignette__metadata]="!isWidgetEuronews">
                    <span class="c-vignette__language">{{ story!.language.isoCode }}</span>
                    <span class="c-vignette__story-id"
                        >{{ story!.totalChars === null ? 'unknown' : story!.totalChars }} chars</span
                    >
                    <time class="c-vignette__publication-date">{{ story!.publishedAt | formatDate }}</time>
                    <span class="c-vignette__program" [title]="'Program: ' + story!.event.program?.slug">{{
                        story!.event.program?.slug
                    }}</span>
                </p>
                <p *ngIf="isPublished && vignetteDisplayTypes.Standard !== displayType" class="c-vignette__link">
                    <a [href]="storyURL" (click)="$event.stopPropagation()" target="_blank" class="c-link--online"
                        >Open on website</a
                    >
                    <clr-icon shape="pop-out"></clr-icon>
                </p>
            </figcaption>
        </ng-container>
    </ng-template>
</article>
