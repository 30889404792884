import { Component, ElementRef, ViewChild } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { ClrDatagrid, ClrDatagridStateInterface } from '@clr/angular'
import { BaseAbstractComponent } from 'app/shared/base.component'
import { StorySearchUniqueFormId } from 'enums/story-search'
import { VignetteDisplayTypes } from 'enums/vignette-display-types'
import { SearchOptions } from 'models/advanced-search.model'
import { distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators'
import { AdvancedStorySearchService } from 'services/story/advanced-search/query'
import { decodeJSON, encodeJSON } from 'tools/json-encoder'

@Component({
    selector: 'cms-header-search-results',
    templateUrl: './header-search-results.component.html',
    styleUrls: ['./header-search-results.component.scss'],
})
export class HeaderSearchResultsComponent extends BaseAbstractComponent {
    @ViewChild(ClrDatagrid, { read: ElementRef }) public datagrid: ElementRef<HTMLElement>
    public vignetteDisplayTypes = VignetteDisplayTypes
    public searchResults: any[] = []
    public loadingResults: boolean = false
    public paginationTotal: number = 0
    public paginationPageSize: number = 10
    public errorMessage: string = ''
    public searchOptions?: SearchOptions

    constructor(
        private advancedStorySearchService: AdvancedStorySearchService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        super()
        this.initRouterEventsSubscription()
    }

    public closeModal() {
        this.router.navigate([{ outlets: { modal: null } }])
    }

    public refresh(state: ClrDatagridStateInterface) {
        const options: SearchOptions = {
            options: this.searchOptions!.options,
            limit: this.paginationPageSize,
            offset: Math.max(state?.page?.from || 0, 0),
        }
        this.router.navigate([{ outlets: { modal: ['search', encodeJSON(options)] } }], {
            replaceUrl: true,
        })
    }

    private initRouterEventsSubscription() {
        this.router.onSameUrlNavigation = 'reload'
        this.router.events
            .pipe(
                takeUntil(this.ngUnsubscribe),
                // Trigger only once per navigation
                filter(event => event instanceof NavigationEnd),
                // Get the options param from the url
                map(() => this.route.snapshot.params.options),
                // Trigger only when the options change
                distinctUntilChanged(),
                switchMap(options => {
                    this.errorMessage = ''
                    this.loadingResults = true
                    this.datagridScrollTop()

                    this.searchOptions = decodeJSON(options)

                    // On the first load, the total is 0,
                    // so we need to fake it so the page we try to reach exists
                    const totalNeededToDisplayPage = this.searchOptions.offset + this.searchOptions.limit
                    if (this.paginationTotal < totalNeededToDisplayPage) {
                        this.paginationTotal = totalNeededToDisplayPage
                    }

                    this.advancedStorySearchService.setCachedSearchBoxData(
                        StorySearchUniqueFormId.MAIN_HEADER,
                        this.searchOptions.options,
                    )

                    return this.advancedStorySearchService.search(
                        this.searchOptions.options,
                        this.searchOptions.limit,
                        this.searchOptions.offset,
                    )
                }),
            )
            .subscribe(
                data => {
                    this.searchResults = data.stories.results
                    this.paginationTotal = data.stories.pagination.total
                    this.loadingResults = false
                },
                () => {
                    this.searchResults = []
                    this.paginationTotal = 0
                    this.errorMessage = 'Yikes, there was a problem loading search results from the server…'
                    this.loadingResults = false
                },
            )
    }

    private datagridScrollTop() {
        const scrollableDatagridElement = this.datagrid?.nativeElement?.querySelector('.datagrid')

        if (scrollableDatagridElement) {
            scrollableDatagridElement.scrollTop = 0
        }
    }
}
