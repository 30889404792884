import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter } from 'rxjs/operators'
import { cacheAndNetworkQuery } from 'tools/apollo'
import { PaginationInterface, ResultPaginationInterface } from '../../interfaces/mercury'
import { AlertTypesModel } from '../../models/alert.model'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { alertTypesQuery } from './alert-types.query'

@Injectable()
export class AlertTypesService extends BaseService {
    /**
     *
     * @type BehaviorSubject<LocationsModel[]>
     */
    protected _alertTypes: BehaviorSubject<AlertTypesModel[]> = new BehaviorSubject([])

    /**
     * @type Observable<LocationsModel[]>
     */
    public alertTypes: Observable<AlertTypesModel[]> = this._alertTypes.asObservable().pipe(filter(value => !!value))

    /**
     *
     * @type BehaviorSubject<any>
     */
    protected _pagination: BehaviorSubject<ResultPaginationInterface | undefined> = new BehaviorSubject(undefined)

    /**
     * @type Observable<any>
     */
    public pagination: Observable<any> = this._pagination.asObservable().pipe(filter(value => !!value))

    /**
     *
     * @param apollo
     */
    constructor(apollo: Apollo, errorsMessagesService: ErrorsMessagesService) {
        super(apollo, errorsMessagesService)
    }

    /**
     *
     * @param pagination
     * @returns {Observable<any>}
     */
    getAlertTypes(pagination?: PaginationInterface) {
        cacheAndNetworkQuery(
            this.apollo,
            {
                query: alertTypesQuery,
                variables: {
                    pagination,
                },
            },
            ['data'],
        ).subscribe(
            (data: { alertTypes }) => {
                this._alertTypes.next(data.alertTypes.results)
                this._pagination.next(data.alertTypes.pagination)
            },
            error => {
                // returned an empty alert types' array
                this._alertTypes.next([])
                // add an error
                this.errorMessagesServices.addErrorsMessage(error)
            },
        )
    }
}
