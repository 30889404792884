<span *ngIf="story">
    <ng-container *ngIf="story.isIndexed">
        <div *ngIf="displayPublicationChannels; then pubStatusWithTooltip; else pubStatus"></div>
        <ng-template #pubStatusWithTooltip>
            <div role="tooltip" aria-haspopup="true" class="tooltip tooltip-bottom-left">
                <ng-template [ngTemplateOutlet]="pubStatus"></ng-template>
                <span class="tooltip-content">
                    Publication Channels:
                    <ul>
                        <li *ngFor="let publicationChannel of story.publicationChannels">
                            {{ publicationChannel.labelLong }}
                        </li>
                    </ul>
                </span>
            </div>
        </ng-template>
        <ng-template #pubStatus>
            <a
                href="{{ story.getStoryOnlineUrl() }}"
                class="c-link indexation-status indexation-status--online"
                target="_blank"
                title="View on website"
                (click)="$event.stopPropagation()">
                <clr-icon shape="pop-out" *ngIf="!story.isAwaitingVideoAttachement()"></clr-icon>
            </a>
        </ng-template>
    </ng-container>
</span>
