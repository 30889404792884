<h1 class="dashboard-section-title">
    <strong>All stories</strong>
</h1>

<cms-contributor-filters
    [datagridFilters]="activeDatagridFilters"
    [disableAddfilterButton]="isLoading"
    (selectedFilters)="onContributorFiltersSelected($event)"></cms-contributor-filters>

<!-- Toolbar Filters -->
<div class="u-no-gutter-right toolbar-filters">
    <h3 class="c-dashboard__subtitle">Filters :</h3>

    <!-- Filters list -->
    <div class="u-flex">
        <div class="clr-checkbox-wrapper toolbar-filters__items" *ngFor="let opt of toolbarFiltersList">
            <input
                class="clr-checkbox"
                type="checkbox"
                #toolbarCkechboxes
                id="opt-{{ opt.label }}-event"
                [name]="opt.label"
                [checked]="opt.selected"
                (change)="onToolbarFilterSelected(opt)" />
            <label
                class="toolbar-filters__label"
                id="opt-label-{{ opt.label }}-event"
                for="opt-{{ opt.label }}-event"
                >{{ opt.label }}</label
            >
        </div>
    </div>

    <!-- Deliverables filter -->
    <clr-dropdown [clrCloseMenuOnItemClick]="false" class="toolbar-filters__items">
        <button clrDropdownTrigger class="toolbar-filters__label">
            <span class="toolbar-filters__deliverable-title">Deliverables</span>
            <clr-icon shape="caret down" size="16"></clr-icon>
        </button>

        <clr-dropdown-menu *clrIfOpen>
            <div *ngFor="let deliverable of programDeliverablesList" class="toolbar-filters__dropdown-item">
                <clr-checkbox-wrapper>
                    <input
                        type="checkbox"
                        clrCheckbox
                        id="{{ deliverable.label }}-{{ deliverable.id }}"
                        [checked]="deliverable.selected"
                        (change)="onDeliverableSelected(deliverable)" />
                    <label for="{{ deliverable.label }}-{{ deliverable.id }}">{{ deliverable.label }}</label>
                </clr-checkbox-wrapper>
            </div>
        </clr-dropdown-menu>
    </clr-dropdown>
</div>

<!-- Datagrid -->
<clr-datagrid (clrDgRefresh)="onDatagridRefresh($event)" [clrDgLoading]="isLoading" class="c-dashboard-pyramid">
    <!-- Placeholder -->
    <clr-dg-placeholder>We couldn't find any stories to display!</clr-dg-placeholder>

    <!-- COLUMNS -->

    <!-- Channel & Event name -->
    <clr-dg-column class="eventName"
        >Channel & Story name
        <clr-dg-filter [clrDgFilter]="channelsFilter" [(clrDgFilterOpen)]="channelsFilter.open">
            <cms-datagrid-composite-filter #channelsFilter [filterName]="FilterName.CHANNELS_AND_EVENTS_COMPOSITE">
                <cms-datagrid-filterable-filter
                    [title]="'Channel'"
                    [filterTitle]="'Channel'"
                    [type]="FilterTypes.CHECKBOX"
                    [list]="channelsList"
                    [filterName]="FilterName.CHANNELS"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.CHANNELS]"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.CHANNELS, $event)"
                    #injectedFilters></cms-datagrid-filterable-filter>

                <cms-datagrid-filterable-filter
                    [title]="'Search a story by name or ID'"
                    [filterTitle]="'Story'"
                    [displayList]="false"
                    [filterName]="FilterName.EVENTS"
                    [searchPlaceholder]="'Type a story name or ID'"
                    [useList]="false"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.EVENTS]"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.EVENTS, $event)"
                    #injectedFilters></cms-datagrid-filterable-filter>
            </cms-datagrid-composite-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <!-- Desk -->
    <clr-dg-column
        >Desk
        <clr-dg-filter [clrDgFilter]="desksFilter" [(clrDgFilterOpen)]="desksFilter.open">
            <cms-datagrid-filterable-filter
                [title]="'Filter by desk'"
                [filterTitle]="'Desk'"
                [type]="FilterTypes.CHECKBOX"
                [list]="desksList"
                [filterName]="FilterName.DESKS"
                [contributorFilter]="contributorDatagridFilters[FilterName.DESKS]"
                (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.DESKS, $event)"
                #desksFilter></cms-datagrid-filterable-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <!-- Assigned to -->
    <clr-dg-column
        >Assigned to
        <clr-dg-filter [clrDgFilter]="contributorsFilter" [(clrDgFilterOpen)]="contributorsFilter.open">
            <cms-datagrid-composite-filter #contributorsFilter [filterName]="FilterName.CONTRIBUTORS_COMPOSITE">
                <cms-datagrid-filterable-filter
                    [list]="currentContributorList"
                    [type]="FilterTypes.CHECKBOX"
                    [filterName]="FilterName.CURRENT_CONTRIBUTOR"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.CURRENT_CONTRIBUTOR]"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.CURRENT_CONTRIBUTOR, $event)"
                    #injectedFilters></cms-datagrid-filterable-filter>

                <cms-datagrid-filterable-filter
                    [title]="'Search by name'"
                    [filterTitle]="'Authors'"
                    [type]="FilterTypes.CHECKBOX"
                    [displayList]="false"
                    [filterName]="FilterName.CONTRIBUTORS"
                    [searchPlaceholder]="'Type a name'"
                    [showSelectedItemsAboveTheList]="true"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.CONTRIBUTORS]"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.CONTRIBUTORS, $event)"
                    #injectedFilters></cms-datagrid-filterable-filter>
            </cms-datagrid-composite-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <!-- Program & Vertical -->
    <clr-dg-column
        >Program & Vertical
        <clr-dg-filter [clrDgFilter]="programAndVerticalFilter" [(clrDgFilterOpen)]="programAndVerticalFilter.open">
            <cms-datagrid-composite-filter
                #programAndVerticalFilter
                [filterName]="FilterName.PROGRAMS_AND_VERTICALS_COMPOSITE">
                <cms-datagrid-filterable-filter
                    [title]="'Search by vertical'"
                    [filterTitle]="'Verticals'"
                    [type]="FilterTypes.SELECT"
                    [list]="verticalsList"
                    [filterName]="FilterName.VERTICALS"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.VERTICALS]"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.VERTICALS, $event)"
                    #injectedFilters
                    #groupFilter></cms-datagrid-filterable-filter>

                <cms-datagrid-filterable-filter
                    [title]="'Search by program'"
                    [filterTitle]="'Programs'"
                    [type]="FilterTypes.GROUPED"
                    [list]="programsList"
                    [displayList]="false"
                    [filterName]="FilterName.PROGRAMS"
                    [searchPlaceholder]="'Program name...'"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.PROGRAMS]"
                    [groupFilter]="groupFilter.value[0]?.label || ''"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.PROGRAMS, $event)"
                    #injectedFilters
                    #filteredGroupedList></cms-datagrid-filterable-filter>
            </cms-datagrid-composite-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <!-- Production Lang. -->
    <clr-dg-column
        >Production Lang.
        <clr-dg-filter [clrDgFilter]="languagesFilter" [(clrDgFilterOpen)]="languagesFilter.open">
            <cms-datagrid-composite-filter #languagesFilter [filterName]="FilterName.LANGUAGES">
                <cms-datagrid-filterable-filter
                    [list]="withoutLanguageList"
                    [filterTitle]="'Language'"
                    [type]="FilterTypes.CHECKBOX"
                    [filterName]="FilterName.WITHOUT_PRODUCER_LANG"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.WITHOUT_PRODUCER_LANG]"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.WITHOUT_PRODUCER_LANG, $event)"
                    #injectedFilters></cms-datagrid-filterable-filter>

                <section class="all-languages-filter">
                    <ul class="list-unstyled">
                        <li class="filterable-list-item">
                            <clr-checkbox-wrapper>
                                <input
                                    type="checkbox"
                                    clrCheckbox
                                    [checked]="checkedAllLanguages"
                                    (change)="toggleAllLanguages()" />
                                <label [class.itemActive]="checkedAllLanguages">All languages</label>
                            </clr-checkbox-wrapper>
                        </li>
                    </ul>
                </section>

                <hr class="lang-filter-hr" />

                <cms-datagrid-filterable-filter
                    [list]="languagesList"
                    [filterTitle]="'Languages'"
                    [type]="FilterTypes.CHECKBOX"
                    [filterName]="FilterName.LANGUAGES"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.LANGUAGES]"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.LANGUAGES, $event)"
                    #injectedFilters
                    #languagesListFilter></cms-datagrid-filterable-filter>
            </cms-datagrid-composite-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <!-- Creation Date -->
    <clr-dg-column class="u-w135p">
        <clr-dg-filter [clrDgFilter]="createdAtDate" [(clrDgFilterOpen)]="createdAtDate.open">
            <cms-datagrid-date-range-filter
                [filterName]="FilterName.CREATION_DATE"
                [contributorFilter]="contributorDatagridFilters[FilterName.CREATION_DATE]"
                #createdAtDate></cms-datagrid-date-range-filter>
        </clr-dg-filter>
        Creation Date
    </clr-dg-column>

    <!-- Last Update -->
    <clr-dg-column class="u-w135p">
        <clr-dg-filter [clrDgFilter]="UpdatedAtDate" [(clrDgFilterOpen)]="UpdatedAtDate.open">
            <cms-datagrid-date-range-filter
                [filterName]="FilterName.LAST_UPDATE_DATE"
                [contributorFilter]="contributorDatagridFilters[FilterName.LAST_UPDATE_DATE]"
                #UpdatedAtDate></cms-datagrid-date-range-filter>
        </clr-dg-filter>
        Last Update
    </clr-dg-column>

    <!-- Status -->
    <clr-dg-column class="u-w100p"
        >Status
        <clr-dg-filter [clrDgFilter]="statusFilter" [(clrDgFilterOpen)]="statusFilter.open">
            <cms-datagrid-filterable-filter
                [list]="statusesList"
                [filterTitle]="'Status'"
                [type]="FilterTypes.CHECKBOX"
                [filterName]="FilterName.EVENT_PYRAMID_STATUSES"
                [contributorFilter]="contributorDatagridFilters[FilterName.EVENT_PYRAMID_STATUSES]"
                (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.EVENT_PYRAMID_STATUSES, $event)"
                #statusFilter></cms-datagrid-filterable-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <!-- ROWS -->
    <clr-dg-row
        *ngFor="let event of events"
        [ngClass]="{ 'embargo-date': event.embargoDate, 'status-breakingNews': event.isBreakingNews }">
        <!-- Edit story button -->
        <clr-dg-action-overflow>
            <button class="action-item" [routerLink]="['event', event.id]" [queryParamsHandling]="'preserve'">
                Edit story
            </button>
        </clr-dg-action-overflow>

        <!-- Event name -->
        <clr-dg-cell class="eventName u-cell-flex">
            <img
                class="c-img"
                src="assets/images/search-box/{{ event.publicationOwner?.slug }}-96x96.png"
                width="20"
                height="20" />

            <a [routerLink]="getRouterLink(event)" [title]="event.daletTitleName" class="eventName eventName__link">
                {{ event.daletTitleName }}
            </a>

            <clr-tooltip *ngIf="event.productionNote" class="eventName__tooltip">
                <cds-icon
                    clrTooltipTrigger
                    role="img"
                    shape="info-circle"
                    size="20"
                    aria-label="Production note tooltip"></cds-icon>
                <clr-tooltip-content clrPosition="right" clrSize="md" *clrIfOpen>
                    {{ event.productionNote }}
                </clr-tooltip-content>
            </clr-tooltip>
        </clr-dg-cell>

        <!-- Desk -->
        <clr-dg-cell>
            <span title="{{ event.pyramidDesk?.title }}">{{ event.pyramidDesk?.title }}</span>
        </clr-dg-cell>

        <!-- Assigned to -->
        <clr-dg-cell>
            {{ event.assignee | contributorGetName }}
        </clr-dg-cell>

        <!-- Program -->
        <clr-dg-cell>
            <span>{{ event.program?.translations | translationFilter : 'title' | titlecase }}</span>
        </clr-dg-cell>

        <!-- Language -->
        <clr-dg-cell>
            <span>{{ event.producerLanguage?.isoCode }}</span>
        </clr-dg-cell>

        <!-- Creation date -->
        <clr-dg-cell class="date u-w135p">
            <span *ngIf="event.createdAt">
                {{ event.createdAt | formatDate }}
            </span>
        </clr-dg-cell>

        <!-- Last update date -->
        <clr-dg-cell class="date u-w135p">
            <span *ngIf="event.updatedAt">
                {{ event.updatedAt | formatDate }}
            </span>
        </clr-dg-cell>

        <!-- Publication status -->
        <clr-dg-cell class="u-w100p" [ngClass]="getStatusClass(event.pyramidStatus?.abbreviation)">
            <clr-tooltip>
                <span clrTooltipTrigger>
                    {{ event.pyramidStatus?.abbreviation }}
                </span>
                <clr-tooltip-content [clrPosition]="'top-left'" [clrSize]="'sm'">
                    {{ event.pyramidStatus?.title }}
                </clr-tooltip-content>
            </clr-tooltip>
        </clr-dg-cell>
    </clr-dg-row>

    <!-- Footer -->
    <clr-dg-footer>
        <clr-dg-pagination #pagination [clrDgTotalItems]="total" [clrDgPageSize]="itemsPerPage" [clrDgPage]="page">
            {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} stories
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>
