import { EventEmitter, Injectable, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { GraphQLError } from 'graphql'
import { ErrorMessage } from 'models/messages-notification.model'
import { BehaviorSubject } from 'rxjs'
import { InAppMessageModel } from '../../models/inAppMessage.model'

@Injectable()
export class ErrorsMessagesService implements OnDestroy {
    protected _errorsMessages: BehaviorSubject<ErrorMessage[]> = new BehaviorSubject([] as ErrorMessage[])
    public errorsMessages = this._errorsMessages.asObservable()

    private messages: ErrorMessage[] = []

    changeDetectionEmitter: EventEmitter<void> = new EventEmitter<void>()

    constructor(protected router: Router) {
        // if the route change we must clear the errors messages
        this.router.events.subscribe(() => {
            this.clearErrors()
        })
    }

    ngOnDestroy() {
        this.clearErrors()
    }

    clearErrors(typeError = 'GraphQLError') {
        this.messages = this.messages.filter(message =>
            typeError === 'GraphQLError'
                ? Object.prototype.hasOwnProperty.call(message, 'id')
                : !Object.prototype.hasOwnProperty.call(message, 'id'),
        )
        this._errorsMessages.next(this.messages)
        this.changeDetectionEmitter.emit()
    }

    public clearAllMessages = (): void => {
        this.messages = []
        this._errorsMessages.next(this.messages)
        this.changeDetectionEmitter.emit()
    }

    addErrorsMessage(message: GraphQLError | InAppMessageModel) {
        const errorMessage: ErrorMessage = {
            message: message.message,
            ...(message['buttonLabel'] && { buttonLabel: message['buttonLabel'] }),
            ...(message['buttonLink'] && { buttonLink: message['buttonLink'] }),
            ...(message['id'] && { id: message['id'] }),
            ...(message['level'] && { level: message['level'] }),
        }

        // before adding a graphql error we check if the message is already set
        if (Object.prototype.hasOwnProperty.call(errorMessage, 'id') === false) {
            const similarMessage = this.messages.find(mesg => {
                return mesg.message === message.message
            })
            if (!similarMessage) {
                this.messages.push(errorMessage)
                this._errorsMessages.next(this.messages)
            }
        } else {
            if (this.messages.some(item => errorMessage.id && item.id === errorMessage.id) === false) {
                this.messages.push(errorMessage)
                this._errorsMessages.next(this.messages)
            }
        }
    }

    getListMessagesIds() {
        return this.messages
            .filter(message => Object.prototype.hasOwnProperty.call(message, 'id'))
            .map((currentMessage: InAppMessageModel) => currentMessage.id)
    }
}
