import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
    ElementRef,
    ChangeDetectorRef,
} from '@angular/core'
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms'
import { ClrDatagridFilterInterface, ClrDatagridStateInterface } from '@clr/angular'
import { FilterName } from 'enums/filters'
import { Subject } from 'rxjs'
import { debounceTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators'
import { ContributorsListService } from 'services/contributors/list'
import { DatagridFilterInterface, FilterableGroup, FilterableInterface } from './filterables.interface'

@Component({
    selector: 'cms-datagrid-filterable-filter',
    templateUrl: './datagrid-filterable.component.html',
    styleUrls: ['./datagrid-filterable.component.scss'],
})
export class DatagridFilterableComponent implements ClrDatagridFilterInterface<any>, OnInit, OnDestroy, OnChanges {
    @Input() list: FilterableInterface[] = []
    @Input() type: string
    @Input() displayList = true
    @Input() useList = true
    @Input() displayFooter = true
    @Input() multipleSelection = true
    @Input() title: string
    @Input() searchPlaceholder: string
    @Input() filterName: string
    @Input() showSelectedItemsAboveTheList = false
    @Input() contributorFilter: DatagridFilterInterface
    @Input() filterTitle: string
    @Input() groupFilter: string

    @Output() datagridSelectedFilter = new EventEmitter<DatagridFilterInterface | null>()

    @ViewChild('selectOptions') selectOptions: ElementRef<HTMLSelectElement>
    @ViewChild('filterList') filterList: ElementRef<HTMLSelectElement>

    filterablesByGroup: FilterableGroup[]
    form: FormGroup
    formBuilder: FormBuilder
    value: FilterableInterface[]
    listItems: FilterableInterface[]
    private destroy$ = new Subject()
    private searchTermMinLength = 3
    private searchTermWithoutListing: string

    // This is the Clarity interface, we only ever use the ‘isActive’
    changes: EventEmitter<any> = new EventEmitter<any>(false)
    open = false

    /**
     * Return the filters from the ClrDatagridStateInterface or null if no filters are set
     *
     * @param {ClrDatagridStateInterface} state
     * @return {{}|null}
     */
    static getDatagridFilters(state?: ClrDatagridStateInterface): Record<string, any> | null {
        if (!state || !state.filters) {
            return null
        }

        let filters = {}
        state.filters.forEach((el: DatagridFilterableComponent) => {
            filters = Object.assign(filters, el.getFilter())
        })

        return filters
    }

    get checkboxControl(): AbstractControl {
        return this.form.get('checkbox') as AbstractControl
    }

    get inputSearchTerm(): AbstractControl {
        return this.form.get('searchTerm') as AbstractControl
    }

    get searchTerm(): string {
        return this.value?.[0]?.label || ''
    }

    get selectedListValue(): string {
        return this.listItems.findIndex(item => item.selected).toString()
    }

    constructor(
        formBuilder: FormBuilder,
        private contributorsListService: ContributorsListService,
        private cdr: ChangeDetectorRef,
    ) {
        this.formBuilder = formBuilder
        this.value = []
    }

    ngOnInit(): void {
        this.initForm()
        this.listItems = this.list
        this.initLists()
    }

    initLists(): void {
        this.filterablesByGroup = this.buildFilterableGroups(this.listItems)
        if (this.searchPlaceholder && this.useList) {
            if (this.filterName === FilterName.CONTRIBUTORS && !this.searchTermWithoutListing) {
                // For filters with autocomplete (send an api request) - example "Authors"
                this.onSearchAuthors()
            } else {
                // For filters that should show a list without autocomplete (list is already loaded) - example "Program & vertical"
                this.onSearchInputChange()
            }
        } else {
            // For filter with a term to search but do not have a list to show - example: "Channel & events"
            this.getSearchInputChangeValue()
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        // When a contributor filter has been activated
        if (changes.contributorFilter?.currentValue) {
            this.value = changes.contributorFilter.currentValue.data
            const selectedIds = this.value.map(item => item.id)
            this.listItems = this.listItems.map(item =>
                Object.assign(item, { selected: selectedIds.includes(item.id) }),
            )
            this.inputSearchTerm.patchValue(this.searchTerm, { emitEvent: false })
            if (this.selectOptions) {
                this.selectOptions.nativeElement.value = this.selectedListValue
            }
        }

        // When a contributor filter has been removed
        if (
            changes.contributorFilter &&
            !changes.contributorFilter?.currentValue &&
            !changes.contributorFilter?.firstChange
        ) {
            this.value = []
        }

        // Whenever a contributor filter has been changed : activated or removed
        if (changes.contributorFilter && !changes.contributorFilter.firstChange) {
            this.filter()
        }

        if (changes.groupFilter && !changes.groupFilter.firstChange) {
            const groupFilter = changes.groupFilter.currentValue
            this.onGroupFilterChange(groupFilter)
        }

        const listsChanges = Object.keys(changes).filter(key => key !== 'contributorFilter' && key !== 'groupFilter')
        for (const changedKey of listsChanges) {
            if (
                !changes[changedKey].firstChange &&
                changes[changedKey].currentValue !== changes[changedKey].previousValue
            ) {
                this.list = changes[changedKey].currentValue
                this.listItems = this.list
                this.value = this.listItems.filter(item => item.selected)
                this.initLists()
            }
        }
    }

    private initForm(): void {
        this.form = this.formBuilder.group({
            searchTerm: this.formBuilder.control(''),
            checkbox: this.formBuilder.control(false),
        })
    }

    getSearchInputChangeValue(): void {
        this.inputSearchTerm?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(term => {
            this.searchTermWithoutListing = term?.toLowerCase() || ''
        })
    }

    onSearchInputChange(): void {
        this.inputSearchTerm?.valueChanges
            .pipe(
                takeUntil(this.destroy$),
                filter(term => {
                    if (term?.length >= this.searchTermMinLength) {
                        return true
                    } else {
                        this.displayList = !this.multipleSelection
                        this.list = this.listItems
                        this.filterablesByGroup = this.buildFilterableGroups(this.list)

                        return false
                    }
                }),
                map(term => {
                    term = term?.toLowerCase()

                    return this.list.filter(element => {
                        return element.label.toLowerCase().includes(term) || element.group.toLowerCase().includes(term)
                    })
                }),
            )
            .subscribe(result => {
                this.list = result
                this.filterablesByGroup = this.buildFilterableGroups(result)
                this.displayList = true
            })
    }

    onSearchAuthors(): void {
        if (this.filterName !== FilterName.CONTRIBUTORS) {
            return
        }
        this.inputSearchTerm.valueChanges
            .pipe(
                takeUntil(this.destroy$),
                tap(_ => {
                    this.list = []
                    this.displayList = false
                }),
                debounceTime(500),
                filter(term => term && term.length >= this.searchTermMinLength),
                map(term => term.trim()),
                switchMap(term => {
                    this.contributorsListService.findByTerm({ term }, 1000, 0)

                    return this.contributorsListService.contributors
                }),
                map(result => {
                    const filteredResult = result.filter(item => item.translations?.length)
                    const selectedIds = this.value.map(val => val.id)

                    return filteredResult
                        .map(author => ({
                            id: author.id!,
                            label: `${author.translations![0].forename} ${author.translations![0].surname}`,
                            selected: selectedIds.includes(author.id!),
                            group: '',
                        }))
                        .sort(this.compareFilterableByLabel)
                }),
            )
            .subscribe(authors => {
                this.list = authors
                this.displayList = !!authors.length
            })
    }

    private onGroupFilterChange(groupFilter: FilterableInterface[]): void {
        this.resetValue({ filter: false })

        this.filterablesByGroup = this.buildFilterableGroups(this.listItems)

        if (groupFilter) {
            this.displayList = true
            this.cdr.detectChanges()
            this.filterList.nativeElement.scrollTo({ top: 0 })
        } else {
            this.displayList = false
        }
    }

    private compareFilterableByLabel(filterableA: FilterableInterface, filterableB: FilterableInterface): number {
        return filterableA.label.localeCompare(filterableB.label)
    }

    /**
     * Not used here, only with non server driving datagrid
     * @param _
     * @return {boolean}
     */
    accepts(_: any): boolean {
        return true
    }

    /**
     * Inform the datagrid if a filter is effectively active
     *
     * @return {boolean}
     */
    isActive(): boolean {
        return this.customIsActive()
    }

    /**
     * Prepare checked values and then emit the change event
     */
    filter(): void {
        this.prepareValue()
        this.changes.emit(true)
        this.open = false
    }

    /**
     * Return the filter value as an object with a property 'filterName' with the value of the filter
     *
     * @return {{}}
     */
    getFilter(): Record<string, any> {
        const filters = {}
        filters[this.filterName] = this.value

        return filters
    }

    private filterableGroupReducer(groups: FilterableGroup[], filterable: FilterableInterface): FilterableGroup[] {
        // We check if the current group already exist in the groups accumulator
        const currentFilterableGroup = groups.find(group => group.name.toLowerCase() === filterable.group.toLowerCase())
        // If it exist, we just add the current filterable in his corresponding group items
        if (currentFilterableGroup) {
            const index = groups.indexOf(currentFilterableGroup)
            groups[index].items.push(filterable)
        } else {
            // If not, we create the current group in the groups accumulator
            groups.push({
                name: filterable.group,
                items: [filterable],
            })
        }

        return groups
    }

    protected buildFilterableGroups(filterableList: FilterableInterface[]): FilterableGroup[] {
        const filterablesWithGroup = filterableList.filter(
            element => element.group && (!this.groupFilter || element.group === this.groupFilter),
        )
        const filterablesWithoutGroup = filterableList.filter(element => !element.group && !this.groupFilter)

        const formattedFilterableWithGroups = filterablesWithGroup
            .reduce(this.filterableGroupReducer, [])
            .map(group => {
                group.items = group.items.sort()

                return group
            })
        const formattedFilterablesWithoutGroup = filterablesWithoutGroup.map<FilterableGroup>(item => ({
            name: item.label,
            items: [],
        }))

        return [...formattedFilterableWithGroups, ...formattedFilterablesWithoutGroup]
    }

    protected customIsActive(): boolean {
        return !!this.value.length
    }

    protected prepareValue(): void {
        if (this.searchTermWithoutListing) {
            const filterWithSearchTermAlreadyExist = this.value.find(
                item => item.label.toLowerCase() === this.searchTermWithoutListing.toLowerCase(),
            )
            if (!filterWithSearchTermAlreadyExist) {
                this.value = [
                    {
                        id: 0,
                        label: this.searchTermWithoutListing.toLowerCase(),
                        group: '',
                        selected: true,
                    },
                ]
            } else {
                this.value.push({
                    id: 0,
                    label: this.searchTermWithoutListing.toLowerCase(),
                    group: '',
                    selected: true,
                })
            }
            this.searchTermWithoutListing = ''
        }
        this.initLists()
        this.filterablesByGroup = this.buildFilterableGroups(this.list)

        if (!this.value.length) {
            if (this.filterName === FilterName.CONTRIBUTORS) {
                this.list = []
                this.initForm()
                this.initLists()
            }
            this.datagridSelectedFilter.emit(null)

            return
        }
        let selectedValues = this.listItems.reduce((accumulator: string[], filterable: FilterableInterface) => {
            if (filterable.selected) {
                accumulator.push(filterable.label)
            }

            return accumulator
        }, [])
        if (!this.useList || this.filterName === FilterName.CONTRIBUTORS) {
            this.list = []
            selectedValues = this.value.map(item => item.label)
        }

        this.datagridSelectedFilter.emit({
            filterTitle: this.filterTitle,
            label: selectedValues.join(', '),
            data: this.value,
        })
    }

    resetFilterable(): void {
        this.filterablesByGroup.forEach(filterable => {
            filterable.selected = false
            filterable.items.forEach(item => {
                item.selected = false
            })
        })

        this.list.forEach(item => {
            item.selected = false
        })

        this.listItems.forEach(item => {
            item.selected = false
        })
    }

    resetValue(options = { filter: true }): void {
        if (this.selectOptions) {
            this.selectOptions.nativeElement.value = ''
        }
        this.form.reset()
        this.resetFilterable()
        this.value = []
        this.initForm()
        this.listItems = this.list
        this.initLists()

        if (options.filter) {
            this.filter()
        }
    }

    onSelectItem(item: FilterableInterface): void {
        if (item.selected) {
            this.value = this.value.filter(el => el.id !== item.id)
            item.selected = false

            return
        }
        item.selected = true
        this.value.push(item)
        this.value = this.value.sort(this.compareFilterableByLabel)
    }

    onChangeItem(item: FilterableInterface | null): void {
        this.listItems.forEach(listItem => (listItem.selected = false))

        if (!item) {
            this.value = []

            return
        }

        item.selected = true
        this.value = [item]
    }

    ngOnDestroy() {
        this.destroy$.next()
        this.destroy$.complete()
    }
}
