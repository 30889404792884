import { Component, HostBinding } from '@angular/core'

@Component({
    selector: 'cms-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
})
export class ContentComponent {
    @HostBinding('class') class = 'content-area'

    constructor() {}
}
