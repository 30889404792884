export enum EventPyramidStatus {
    NEW = 1,
    PITCH = 2,
    DESK_ASSIGNED = 3,
    WORK_IN_PROGRESS = 4,
    PRODUCTION_SUBMITTED = 5,
    PRODUCTION_APPROVED = 6,
    LOCALISING = 7,
    APPROVED_IN_ALL_LANGUAGES = 8,
    PUBLISHED_IN_ALL_LANGUAGES = 9,
    CLOSED = 10,
}

export enum EventPyramidStatusLabel {
    NEW = 'NEW',
    PITCH = 'PITCH',
    DESK_ASSIGNED = 'DESK ASSIGNED',
    WORK_IN_PROGRESS = 'WORK IN PROGRESS',
    PRODUCTION_SUBMITTED = 'PRODUCTION SUBMITTED',
    PRODUCTION_APPROVED = 'PRODUCTION APPROVED',
    LOCALISING = 'LOCALISING',
    APPROVED_IN_ALL_LANGUAGES = 'APPROVED IN ALL LANGUAGES',
    PUBLISHED_IN_ALL_LANGUAGES = 'PUBLISHED IN ALL LANGUAGES',
    CLOSED = 'CLOSED',
}

export enum EventPyramidStatusAbbreviation {
    NEW = 'NEW',
    PITCH = 'PTH',
    DESK_ASSIGNED = 'DAS',
    WORK_IN_PROGRESS = 'WIP',
    PRODUCTION_SUBMITTED = 'PSB',
    PRODUCTION_APPROVED = 'PAP',
    LOCALISING = 'LOC',
    APPROVED_IN_ALL_LANGUAGES = 'AAL',
    PUBLISHED_IN_ALL_LANGUAGES = 'PAL',
    CLOSED = 'CLS',
}
