import { gql } from '@apollo/client/core'
import { languageFragment } from '../languages/language.fragment'
import { paginationFragment } from '../shared/pagination.fragment'
import { contributorFragment } from './contributor.fragment'

export const findByNameQuery = gql`
    ${languageFragment}
    ${paginationFragment}
    ${contributorFragment}

    query contributorsByLastnameList($lastname: String!, $options: QueryOptions!) {
        contributorsByLastname(lastname: $lastname, options: $options) {
            pagination {
                ...paginationInfo
            }

            results {
                ...contributorInfo
            }
        }
    }
`
