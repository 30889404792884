import gql from 'graphql-tag'
import { ownerFragment } from 'services/publication-owner/publication-owner.fragment'
import { paginationFragment } from 'services/shared/pagination.fragment'

export const pushMessageFragment = `
    ${ownerFragment}

    fragment pushMessageInfo on PushMessage {
        id
        title
        message
        language {
            id
            isoCode
            fullname
        }
        createdBy {
            translations(language:en){
                language{
                    id
                    isoCode
                }
                surname
                forename
            }
        }
        createdAt
        pushedAt
        publicationOwner {
            ...ownerInfo
        }
        story {
            id
            event {
                id
                daletTitleName
            }
            articleHeadline
            language {
                id
                isoCode
                fullname
            }
        }
        pushResponse {
            status
        }
    }
`

export const pushMessagesQuery = gql`
    ${pushMessageFragment}
    ${paginationFragment}

    query pushMessages($search: SearchMessageAppInput, $options: QueryOptions) {
        pushMessages(search: $search, options: $options) {
            pagination {
                ...paginationInfo
            }
            results {
                ...pushMessageInfo
            }
        }
    }
`

export const deleteScheduledPushMessageQuery = gql`
    mutation deleteScheduledMessagePush($pushMessageId: Int!) {
        deleteScheduledPushMessage(pushMessageId: $pushMessageId)
    }
`
