<clr-alert
    *ngIf="alertMessage"
    [clrAlertType]="alertType!"
    [clrAlertClosable]="alertClosable!"
    [clrAlertClosed]="alertClosed!"
    [clrAlertSizeSmall]="alertSizeSmall!"
    [clrAlertAppLevel]="alertAppLevel!"
    (clrAlertClosedChange)="onClose()">
    <clr-alert-item>
        <span class="alert-text">{{ alertMessage }}</span>
        <div class="alert-actions" *ngIf="alertAction">
            <a [routerLink]="alertAction.url" class="alert-action">{{ alertAction.label }}</a>
        </div>
    </clr-alert-item>
</clr-alert>
