import { Injectable } from '@angular/core'
import { gql } from '@apollo/client/core'
import { Apollo } from 'apollo-angular'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter } from 'rxjs/operators'
import { LanguagesService } from 'services/languages/languages'
import { cacheAndNetworkQuery } from 'tools/apollo'
import { StoryType } from '../../models/story.model'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { languageFragment } from '../languages/language.fragment'

export const storyTypesQuery = gql`
    ${languageFragment}

    query storyTypesList($options: QueryOptions!) {
        storyTypes(options: $options) {
            results {
                id
                slug
                translations {
                    storyTypeId
                    langId
                    language {
                        ...languageInfo
                    }
                    title
                    slug
                }
            }
        }
    }
`

// @todo change all when the route will be available
@Injectable()
export class StoryTypesListService extends BaseService {
    protected limit = 100
    protected _storyTypes: BehaviorSubject<StoryType[]> = new BehaviorSubject([])
    public storyTypes = this._storyTypes
        .asObservable()
        .pipe(filter(storyType => Boolean(storyType && storyType.length)))

    constructor(
        apollo: Apollo,
        errorsMessagesService: ErrorsMessagesService,
        private languagesService: LanguagesService,
    ) {
        super(apollo, errorsMessagesService)
    }

    getAllStoryTypes(): Observable<StoryType[]> {
        const options = {
            pagination: {
                limit: this.limit,
                offset: 0,
            },
        }

        return cacheAndNetworkQuery(
            this.apollo,
            {
                query: storyTypesQuery,
                variables: {
                    options,
                },
            },
            ['data', 'storyTypes', 'results'],
        )
    }

    loadStoryTypes() {
        const options = {
            pagination: {
                limit: this.limit,
                offset: 0,
            },
        }

        return cacheAndNetworkQuery(
            this.apollo,
            {
                query: storyTypesQuery,
                variables: {
                    options,
                },
            },
            ['data'],
        ).subscribe(
            (data: { storyTypes: { results: StoryType[] } }) => {
                const storyTypes = data.storyTypes.results.map(type => ({
                    ...type,
                    ...{
                        trans: type.translations!.find(
                            trans => trans.language.isoCode === this.languagesService.defaultLanguage.isoCode,
                        ),
                    },
                }))

                this._storyTypes.next(storyTypes)
            },
            error => {
                // returned an empty types
                this._storyTypes.next([])
                // add an error
                this.errorMessagesServices.addErrorsMessage(error)
            },
        )
    }
}
