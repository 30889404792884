import { gql } from '@apollo/client/core'
import { contributorFilterFragment } from './contributor-filter.fragment'

export const createContributorFilter = gql`
    ${contributorFilterFragment}
    mutation createContributorFilter($label: String!, $filters: String!) {
        createContributorFilter(label: $label, filters: $filters) {
            ...contributorFilter
        }
    }
`
export const deleteContributorFilter = gql`
    mutation deleteContributorFilter($id: Int!) {
        deleteContributorFilter(id: $id)
    }
`
export const myFiltersQuery = gql`
    ${contributorFilterFragment}
    query myFiltersQuery {
        myFilters {
            ...contributorFilter
        }
    }
`
