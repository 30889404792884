import { Injectable } from '@angular/core'
import { CompleterItem } from 'ng2-completer'
import { Subject } from 'rxjs'
import { map } from 'rxjs/operators'
import { ContributorType } from '../../enums/contributor-type'
import { ContributorModel } from '../../models/contributor.model'
import { ContributorGetNamePipe } from '../../pipes/contributor'
import { ContributorsAutocompleteByTermService } from '../contributors/autocomplete-by-term'

// @todo implements CompleterData disabled coz since Typescript 2.4* there is some crappy misconception that are thrown by the linter
// and ng2completer has huge error : an interface that extends a class WTF !!!
@Injectable()
export abstract class ContributorsData extends Subject<CompleterItem[]> /* implements CompleterData */ {
    type: ContributorType = ContributorType.all

    constructor(
        protected contributorsService: ContributorsAutocompleteByTermService,
        protected helperContributorGetName: ContributorGetNamePipe,
    ) {
        super()
    }

    public search(term: string): void {
        const termToFind = term.toLowerCase()
        this.contributorsService.contributorsAutocomplete[this.type]
            .pipe(
                map((contributors: ContributorModel[]) => {
                    if (!contributors || !contributors.length) {
                        return []
                    }
                    let matches: CompleterItem[] = contributors.map((contributor: ContributorModel) => {
                        return {
                            title: this.helperContributorGetName.transform(contributor),
                            originalObject: contributor,
                        }
                    })

                    matches = matches.filter(contributor => {
                        return contributor.title.toLowerCase().includes(termToFind)
                    })

                    return matches
                }),
            )
            .subscribe(matches => {
                this.next(matches)
            })

        this.contributorsService.getContributorsByTerm({ term: `%${term.toLowerCase()}%` }, this.type)
    }

    public cancel() {
        // Handle cancel
    }
}

@Injectable()
export class AllContributorsData extends ContributorsData {
    constructor(
        protected contributorsService: ContributorsAutocompleteByTermService,
        protected helperContributorGetName: ContributorGetNamePipe,
    ) {
        super(contributorsService, helperContributorGetName)

        this.type = ContributorType.all
    }
}

@Injectable()
export class ProducersData extends ContributorsData {
    constructor(
        protected contributorsService: ContributorsAutocompleteByTermService,
        protected helperContributorGetName: ContributorGetNamePipe,
    ) {
        super(contributorsService, helperContributorGetName)

        this.type = ContributorType.producer
    }
}

@Injectable()
export class AuthorsData extends ContributorsData {
    constructor(
        protected contributorsService: ContributorsAutocompleteByTermService,
        protected helperContributorGetName: ContributorGetNamePipe,
    ) {
        super(contributorsService, helperContributorGetName)

        this.type = ContributorType.author
    }
}

@Injectable()
export class VideoEditorsData extends ContributorsData {
    constructor(
        protected contributorsService: ContributorsAutocompleteByTermService,
        protected helperContributorGetName: ContributorGetNamePipe,
    ) {
        super(contributorsService, helperContributorGetName)

        this.type = ContributorType.videoEditor
    }
}
