import { gql } from '@apollo/client/core'
import { storyMutateFragment, storyWithRelatedFragment } from '../find-by-id.query'

export const storyPublicationFragment = `
  fragment storyPublicationInfos on Story {
    urlTitleSlug
    publishedAt
    expiresAt
    createdAt
    createdBy
    event {
        id
        embargoDate
    }
    type {
        id
        slug
    }
    publicationChannels {
      id
      type {
        id
        slug
      }
      owner {
        id
        slug
        labelLong
        labelShort
        canonicalUrl
      }
      labelLong
      labelShort
    }
    alert {
      id
      title
      publishedAt
      updatedAt
      isActive
      type {
        id
        slug
      }
      targetType{
        id
        slug
      }
      publicationChannels {
        id
        type {
          id
          slug
        }
        owner {
          id
          slug
          labelLong
          labelShort
          canonicalUrl
        }
        labelLong
        labelShort
      }
    }
    alertsHistory {
        id
        title
        publishedAt
        isActive
    }
    pushMessages {
        id
        title
        message
        createdAt
        pushedAt
    }
  }
`

export const publish = gql`
    ${storyWithRelatedFragment}

    mutation publish(
        $storyId: Int!
        $channels: [Int!]!
        $publishedAt: DateTime!
        $alert: AlertInput
        $pushMessage: PushMessageInput
        $unpublishAlert: Boolean
        $fieldsToReplicate: [StoryPublicationReplicatedFields]
    ) {
        publish(
            storyId: $storyId
            channels: $channels
            publishedAt: $publishedAt
            alert: $alert
            pushMessage: $pushMessage
            unpublishAlert: $unpublishAlert
            fieldsToReplicate: $fieldsToReplicate
        ) {
            ...storyWithRelatedInfo
        }
    }
`

export const storyUrlExists = gql`
    query storyUrlExists($url: String!, $langId: Int!, $exceptForStory: Int!) {
        storyUrlExists(url: $url, langId: $langId, exceptForStory: $exceptForStory)
    }
`
export const getStoryPublication = gql`
    ${storyPublicationFragment}
    ${storyMutateFragment}

    query getStoryRelated($storyId: Int!) {
        story(id: $storyId) {
            id
            ...storyPublicationInfos
            ...storyMutateInfos
        }
    }
`

export const updateStoryStatusToEdit = gql`
    mutation updateStoryStatusToEdit($storyId: Int!) {
        updateStoryStatusToEdit(storyId: $storyId) {
            id
            publicationStatus {
                id
                slug
            }
        }
    }
`

export const submitStory = gql`
    ${storyWithRelatedFragment}

    mutation submitStory($storyId: Int!) {
        submitStory(storyId: $storyId) {
            ...storyWithRelatedInfo
        }
    }
`

export const approveStory = gql`
    ${storyWithRelatedFragment}

    mutation approveStory($storyId: Int!) {
        approveStory(storyId: $storyId) {
            ...storyWithRelatedInfo
        }
    }
`

export const declineStory = gql`
    ${storyWithRelatedFragment}

    mutation declineStory($storyId: Int!) {
        declineStory(storyId: $storyId) {
            ...storyWithRelatedInfo
        }
    }
`
