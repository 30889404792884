import { gql } from '@apollo/client/core'
import { storyFragment, storyMutateFragment } from '../find-by-id.query'

export const upsertStoryRelated = gql`
    ${storyFragment}
    ${storyMutateFragment}

    mutation updateStoryRelated($storyId: Int!, $related: [Int!], $fieldsToReplicate: [StoryRelatedReplicatedFields]) {
        updateStoryRelated(storyId: $storyId, related: $related, fieldsToReplicate: $fieldsToReplicate) {
            related {
                ...storyInfo
            }
            ...storyMutateInfos
        }
    }
`
export const getStoryRelated = gql`
    ${storyFragment}
    ${storyMutateFragment}

    query getStoryRelated($storyId: Int!) {
        story(id: $storyId) {
            id
            related {
                ...storyInfo
            }
            ...storyMutateInfos
        }
    }
`
