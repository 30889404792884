<div class="dashboard-unmoderated-tags">
    <clr-alert
        *ngIf="numberOfUnmoderatedTags"
        cmsIfAuthorized
        [userRight]="UserRight.MODERATE_TAGS"
        clrAlertType="warning"
        [clrAlertClosable]="false">
        <clr-alert-item>
            <span class="alert-text"
                >You have {{ numberOfUnmoderatedTags }} new tags to moderate.
                <a routerLink="/general/tags-and-hot-topics" [queryParams]="{ onlyUnmoderatedTags: true }">
                    Go to the Tags and Hot Topics section</a
                >
            </span>
        </clr-alert-item>
    </clr-alert>
</div>

<div class="dashboard-latest-tags">
    <div cmsIfAuthorized [userRight]="UserRight.ACCESS_DASHBOARD_LATEST_TAGS">
        <h1 class="dashboard-section-title">
            <strong>Latest editorial tags</strong>
        </h1>

        <div class="tags">
            <a class="tag" *ngFor="let tag of lastTags" [routerLink]="['/', 'general', 'tags-and-hot-topics', tag.id]">
                <ng-container *ngIf="tag.title; else defaultTitle">
                    <clr-icon shape="tag" size="20" class="icon"></clr-icon>
                    <span class="title">{{ tag.title }}</span>
                </ng-container>

                <ng-template #defaultTitle>
                    <clr-tooltip>
                        <clr-icon clrTooltipTrigger shape="tag" size="20" class="icon has-alert"></clr-icon>
                        <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
                            Edit this tag to add a translation in&nbsp;your language.
                        </clr-tooltip-content>
                    </clr-tooltip>
                    <span class="title missing-translation">{{ tag.defaultTitle }}</span>
                </ng-template>
            </a>
        </div>
    </div>
</div>
