import { QueryList } from '@angular/core'
import { PublicationOwnerName } from 'enums/publication-owner-name'
import { fastClone } from 'tools/fast-clone'
import { DatagridAbstractFilterComponent } from '../../app/shared/filter/datagrid-abstract-filter.component'
import {
    ContributorDatagridFiltersInterface,
    DatagridFilterInterface,
    FilterableInterface,
} from '../../app/shared/filter/datagrid-filterables/filterables.interface'
import { User } from '../../entities/user'
import {
    BaseDashboardSearchFilters,
    DashboardSearchFilters,
    DashboardToolbar,
    FilterKey,
    FilterName,
    MappedFilterName,
} from '../../enums/filters'
import { ProgramModel } from '../../models/program.model'
import { StoryType, StoryTypeTranslation } from '../../models/story.model'

export class DashboardFilterHelper {
    static compareFilterableByLabel(filterableA: FilterableInterface, filterableB: FilterableInterface): number {
        return filterableA.label.localeCompare(filterableB.label)
    }

    static getLanguageObject(languageId: number, storyType: StoryType): StoryTypeTranslation | null {
        return storyType.translations?.find(translation => translation.langId === languageId) || null
    }

    static isEmpty(object: object): boolean {
        return !Object.keys(object).length
    }

    static isActiveFilter(filter: any, filterName: string): boolean {
        return filter && filter[filterName]
    }

    static updateSelectedValueInFilterList(
        filter: FilterableInterface,
        list: FilterableInterface[],
    ): FilterableInterface[] {
        const newList = fastClone(list)

        return newList.map(item => {
            if (filter.id === item.id) {
                item.selected = filter.selected
            }

            return item
        })
    }

    static filterIsActiveContributorFilter(
        filterName: string,
        activeContributorFilterNames: ContributorDatagridFiltersInterface,
    ): boolean {
        return Object.keys(activeContributorFilterNames).includes(filterName)
    }

    static getDateFromFilters(dateFilterValue: any): Date {
        return new Date(dateFilterValue.setDate(dateFilterValue.getDate() + 1))
    }

    static getFilterIds(filters: FilterableInterface[]): number[] {
        return filters.map(filter => filter.id)
    }

    static getDisabledDashboardFilters(args: {
        contributorFilters: ContributorDatagridFiltersInterface
        datagridDashboardFilters: QueryList<DatagridAbstractFilterComponent<any>>
        datagridDashboardDateFilters: QueryList<DatagridAbstractFilterComponent<any>>
    }) {
        if (!Object.keys(args.contributorFilters).length) {
            return null
        }
        const filterNames = Object.keys(args.contributorFilters)
        const inactiveFilters = args.datagridDashboardFilters.toArray().filter(dashboardFilter => {
            return !filterNames.includes(dashboardFilter.filterName)
        })

        const inactiveDatesFilters = args.datagridDashboardDateFilters.toArray().filter(dashboardDatesFilter => {
            return !filterNames.includes(dashboardDatesFilter.filterName)
        })

        return [...inactiveFilters, ...inactiveDatesFilters]
    }

    static getFilterNameFromMapping(filterId: number, mappedObject: MappedFilterName[]): string {
        // TODO: Remove the disable and fix the problem
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        return mappedObject.find(item => item.id === filterId)?.filterName!
    }

    static buildFilterData(filter: FilterableInterface): DatagridFilterInterface | null {
        return filter.selected
            ? {
                  filterTitle: '',
                  label: filter.label,
                  data: [filter],
              }
            : null
    }

    static setFilterValue<T>(dashboardSearchFilters: T, filterKey: string, value: any): T {
        const newDashboardSearchFilters: T = fastClone(dashboardSearchFilters)
        newDashboardSearchFilters[filterKey] = value

        return newDashboardSearchFilters
    }

    static formatProgramAsFilterable(programs: ProgramModel[], contributor: User): FilterableInterface[] {
        const filterables: FilterableInterface[] = []

        programs.forEach(program => {
            const { id, isDisabledCms, isSelectableForSearch, translations, verticals } = program

            if (!isDisabledCms && isSelectableForSearch && verticals?.length) {
                // TODO: Remove the disable and fix the problem
                // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                const label = translations.find(
                    translation => translation.language.isoCode === contributor.getDefaultLanguageIsoCode(),
                )?.title!

                if (label) {
                    // Change case to title case and add africanews to distinguish euronews and africanews programs
                    const firstLettersRegex = /(?:^|\s)\w/g
                    const formattedLabel =
                        label.replace(firstLettersRegex, match => match.toUpperCase()) +
                        (program.publicationOwner?.slug === PublicationOwnerName.Africanews
                            ? ` (${program.publicationOwner?.slug})`
                            : '')

                    const formatedFilterable: FilterableInterface = {
                        id,
                        label: formattedLabel,
                        group: verticals[0].translations[0].title!,
                    }

                    filterables.push(formatedFilterable)
                }
            }
        })

        return filterables
    }

    static setDeliverableFilterValueInDashboardSearchFilters(args: {
        deliverableFilter: FilterableInterface
        deliverableFilterKey: string
        dashboardSearchFilters: DashboardSearchFilters
    }): DashboardSearchFilters {
        return args.deliverableFilter.selected
            ? this.addDeliverableToStoriesRequestObject({
                  deliverableId: args.deliverableFilter.id,
                  deliverableFilterKey: args.deliverableFilterKey,
                  dashboardSearchFilters: args.dashboardSearchFilters,
              })
            : this.removeDeliverableFromStoriesRequestObject({
                  deliverableId: args.deliverableFilter.id,
                  deliverableFilterKey: args.deliverableFilterKey,
                  dashboardSearchFilters: args.dashboardSearchFilters,
              })
    }

    static addDeliverableToStoriesRequestObject(args: {
        deliverableId: number
        deliverableFilterKey: string
        dashboardSearchFilters: DashboardSearchFilters
    }): DashboardSearchFilters {
        const newDashboardSearchFilters: DashboardSearchFilters = fastClone(args.dashboardSearchFilters)

        const isDeliverableFilterAlreadyActived = newDashboardSearchFilters[args.deliverableFilterKey]?.length
        if (isDeliverableFilterAlreadyActived) {
            if (!newDashboardSearchFilters[args.deliverableFilterKey]?.includes(args.deliverableId)) {
                newDashboardSearchFilters[args.deliverableFilterKey]?.push(args.deliverableId)
            }
        } else {
            newDashboardSearchFilters[args.deliverableFilterKey] = [args.deliverableId]
        }

        return newDashboardSearchFilters
    }

    static removeDeliverableFromStoriesRequestObject(args: {
        deliverableId: number
        deliverableFilterKey: string
        dashboardSearchFilters: DashboardSearchFilters
    }): DashboardSearchFilters {
        const newDashboardSearchFilters: DashboardSearchFilters = fastClone(args.dashboardSearchFilters)

        newDashboardSearchFilters[args.deliverableFilterKey] = newDashboardSearchFilters[
            args.deliverableFilterKey
        ]?.filter(id => id !== args.deliverableId)

        if (!newDashboardSearchFilters[args.deliverableFilterKey]?.length) {
            newDashboardSearchFilters[args.deliverableFilterKey] = null
        }

        return newDashboardSearchFilters
    }

    static setToolbarFilterValue<T extends BaseDashboardSearchFilters>(
        dashboardSearchFilters: T,
        filter: FilterableInterface,
    ): T {
        const newDashboardSearchFilters: T = fastClone(dashboardSearchFilters)

        newDashboardSearchFilters.options.forEach(option => {
            if (option.id === filter.id) {
                option.value = filter.selected!
            }
        })

        return newDashboardSearchFilters
    }

    static applyBaseDatagridFiltersToSearchFilters(
        dashboardSearchFilters: BaseDashboardSearchFilters,
        filters: Record<string, any>,
    ): BaseDashboardSearchFilters {
        let newBaseDashboardSearchFilters: BaseDashboardSearchFilters = fastClone(dashboardSearchFilters)

        // CONTRIBUTOR FILTER
        if (DashboardFilterHelper.isActiveFilter(filters, FilterName.CONTRIBUTORS_COMPOSITE)) {
            const contributorsCompositionFilters = Object.values<FilterableInterface[]>(
                filters[FilterName.CONTRIBUTORS_COMPOSITE],
            )
            const contributorIds: number[] = []
            contributorsCompositionFilters.forEach(filter => {
                contributorIds.push(...DashboardFilterHelper.getFilterIds(filter))
            })
            newBaseDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newBaseDashboardSearchFilters,
                FilterKey.ASSIGN_TO,
                contributorIds,
            )
        } else {
            newBaseDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newBaseDashboardSearchFilters,
                FilterKey.ASSIGN_TO,
                null,
            )
        }

        // PROGRAMS & VERTICAL FILTER
        if (DashboardFilterHelper.isActiveFilter(filters, FilterName.PROGRAMS_AND_VERTICALS_COMPOSITE)) {
            const { programsFilter, verticalsFilter } = filters[FilterName.PROGRAMS_AND_VERTICALS_COMPOSITE]
            if (programsFilter?.length) {
                newBaseDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                    newBaseDashboardSearchFilters,
                    FilterKey.PROGRAM_ID,
                    DashboardFilterHelper.getFilterIds(programsFilter),
                )
            } else {
                newBaseDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                    newBaseDashboardSearchFilters,
                    FilterKey.PROGRAM_ID,
                    null,
                )
            }
            if (verticalsFilter?.length) {
                newBaseDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                    newBaseDashboardSearchFilters,
                    FilterKey.VERTICAL_ID,
                    DashboardFilterHelper.getFilterIds(verticalsFilter),
                )
            } else {
                newBaseDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                    newBaseDashboardSearchFilters,
                    FilterKey.VERTICAL_ID,
                    null,
                )
            }
        } else {
            newBaseDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newBaseDashboardSearchFilters,
                FilterKey.PROGRAM_ID,
                null,
            )
            newBaseDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newBaseDashboardSearchFilters,
                FilterKey.VERTICAL_ID,
                null,
            )
        }

        return newBaseDashboardSearchFilters
    }

    static applyDatagridFiltersToSearchFilters(
        dashboardSearchFilters: DashboardSearchFilters,
        filters: Record<string, any>,
        dashboardToolbar: DashboardToolbar,
    ): DashboardSearchFilters {
        let newDashboardSearchFilters: DashboardSearchFilters = fastClone(dashboardSearchFilters)
        newDashboardSearchFilters = DashboardFilterHelper.applyBaseDatagridFiltersToSearchFilters(
            newDashboardSearchFilters,
            filters,
        )

        // CHANNELS & EVENTS FILTERS
        if (DashboardFilterHelper.isActiveFilter(filters, FilterName.CHANNELS_AND_EVENTS_COMPOSITE)) {
            const { channelsFilter, eventsFilter } = filters[FilterName.CHANNELS_AND_EVENTS_COMPOSITE]
            if (channelsFilter?.length) {
                newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                    newDashboardSearchFilters,
                    FilterKey.PUBLICATION_OWNER_ID,
                    DashboardFilterHelper.getFilterIds(channelsFilter),
                )
            } else {
                newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                    newDashboardSearchFilters,
                    FilterKey.PUBLICATION_OWNER_ID,
                    null,
                )
            }
            if (eventsFilter?.length) {
                newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                    newDashboardSearchFilters,
                    FilterKey.EVENT_NAME,
                    eventsFilter[0].label.toLowerCase(),
                )
            } else {
                newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                    newDashboardSearchFilters,
                    FilterKey.EVENT_NAME,
                    '',
                )
            }
        } else {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.PUBLICATION_OWNER_ID,
                null,
            )
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.EVENT_NAME,
                '',
            )
        }

        // HEADLINES FILTERS
        if (DashboardFilterHelper.isActiveFilter(filters, FilterName.HEADLINES)) {
            const headlineSearchTerm = filters[FilterName.HEADLINES]
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.SEARCH,
                headlineSearchTerm[0].label.toLowerCase(),
            )
        } else {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.SEARCH,
                '',
            )
        }

        // STORY TYPES FILTER
        if (DashboardFilterHelper.isActiveFilter(filters, FilterName.STORY_TYPES)) {
            const storyTypeFilter = filters[FilterName.STORY_TYPES]
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.TYPE_ID,
                DashboardFilterHelper.getFilterIds(storyTypeFilter)[0],
            )
        } else {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.TYPE_ID,
                null,
            )
        }

        // LANGUAGE & WITH_VIDEO FILTERS
        if (DashboardFilterHelper.isActiveFilter(filters, FilterName.LANGUAGE_AND_WITH_VIDEO_COMPOSITE)) {
            const { languagesFilter, storiesWithVideoFilter } = filters[FilterName.LANGUAGE_AND_WITH_VIDEO_COMPOSITE]
            if (languagesFilter?.length) {
                newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                    newDashboardSearchFilters,
                    FilterKey.LANG_ID,
                    DashboardFilterHelper.getFilterIds(languagesFilter),
                )
            } else {
                newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                    newDashboardSearchFilters,
                    FilterKey.LANG_ID,
                    dashboardToolbar.langId,
                )
            }

            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.WITH_VIDEO_ONLY,
                !!storiesWithVideoFilter?.length,
            )
        } else {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.LANG_ID,
                dashboardToolbar.langId,
            )
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.WITH_VIDEO_ONLY,
                false,
            )
        }

        // PUBLICATION_DATE & LAST_UPDATE_DATE FILTERS
        if (DashboardFilterHelper.isActiveFilter(filters, FilterName.PUBLICATION_DATE)) {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                dashboardSearchFilters,
                FilterKey.PUBLISHED_AT,
                filters[FilterName.PUBLICATION_DATE],
            )
        } else {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.PUBLISHED_AT,
                null,
            )
        }
        if (DashboardFilterHelper.isActiveFilter(filters, FilterName.LAST_UPDATE_DATE)) {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                dashboardSearchFilters,
                FilterKey.LAST_PUBLISHED_AT,
                filters[FilterName.LAST_UPDATE_DATE],
            )
        } else {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.LAST_PUBLISHED_AT,
                null,
            )
        }

        // PUBLICATION_STATUSES FILTER
        if (DashboardFilterHelper.isActiveFilter(filters, FilterName.PUBLICATION_STATUSES)) {
            const publicationStatusesFilter = filters[FilterName.PUBLICATION_STATUSES]
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.PUBLICATION_STATUS,
                DashboardFilterHelper.getFilterIds(publicationStatusesFilter),
            )
        } else {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.PUBLICATION_STATUS,
                null,
            )
        }

        newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
            newDashboardSearchFilters,
            FilterKey.IS_INDEXED,
            DashboardFilterHelper.isActiveFilter(filters, FilterName.INDEXED) ? true : null,
        )

        return newDashboardSearchFilters
    }

    static applyDatagridFiltersToSearchFiltersEvents<T extends BaseDashboardSearchFilters>(
        dashboardSearchFilters: T,
        filters: Record<string, any>,
    ): T {
        let newDashboardSearchFilters: T = fastClone(dashboardSearchFilters)
        newDashboardSearchFilters = DashboardFilterHelper.applyBaseDatagridFiltersToSearchFilters(
            newDashboardSearchFilters,
            filters,
        ) as T

        // CHANNELS & EVENTS FILTERS
        if (DashboardFilterHelper.isActiveFilter(filters, FilterName.CHANNELS_AND_EVENTS_COMPOSITE)) {
            const { channelsFilter, eventsFilter } = filters[FilterName.CHANNELS_AND_EVENTS_COMPOSITE]
            if (channelsFilter?.length) {
                newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                    newDashboardSearchFilters,
                    FilterKey.PUBLICATION_OWNER_ID,
                    DashboardFilterHelper.getFilterIds(channelsFilter),
                )
            } else {
                newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                    newDashboardSearchFilters,
                    FilterKey.PUBLICATION_OWNER_ID,
                    null,
                )
            }
            if (eventsFilter?.length) {
                newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                    newDashboardSearchFilters,
                    FilterKey.SEARCH,
                    eventsFilter[0].label.toLowerCase(),
                )
            } else {
                newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                    newDashboardSearchFilters,
                    FilterKey.SEARCH,
                    '',
                )
            }
        } else {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.PUBLICATION_OWNER_ID,
                null,
            )
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.SEARCH,
                '',
            )
        }

        // LANGUAGES
        if (DashboardFilterHelper.isActiveFilter(filters, FilterName.LANGUAGES)) {
            const { languagesFilter, withoutProducerLangFilter } = filters[FilterName.LANGUAGES]
            if (languagesFilter?.length) {
                newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                    newDashboardSearchFilters,
                    FilterKey.LANG_ID,
                    DashboardFilterHelper.getFilterIds(languagesFilter),
                )
            } else {
                newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                    newDashboardSearchFilters,
                    FilterKey.LANG_ID,
                    [],
                )
            }

            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.WITHOUT_PRODUCER_LANG,
                !!withoutProducerLangFilter?.length,
            )
        } else {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.LANG_ID,
                [],
            )
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.WITHOUT_PRODUCER_LANG,
                false,
            )
        }

        // DESKS FILTER
        if (DashboardFilterHelper.isActiveFilter(filters, FilterName.DESKS)) {
            const deskFilter = filters[FilterName.DESKS]
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.DESK_ID,
                DashboardFilterHelper.getFilterIds(deskFilter),
            )
        } else {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.DESK_ID,
                null,
            )
        }

        // UPDATED DATE & CREATED DATE FILTERS
        if (DashboardFilterHelper.isActiveFilter(filters, FilterName.CREATION_DATE)) {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                dashboardSearchFilters,
                FilterKey.CREATED_AT,
                filters[FilterName.CREATION_DATE],
            )
        } else {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.CREATED_AT,
                null,
            )
        }
        if (DashboardFilterHelper.isActiveFilter(filters, FilterName.LAST_UPDATE_DATE)) {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                dashboardSearchFilters,
                FilterKey.UPDATED_AT,
                filters[FilterName.LAST_UPDATE_DATE],
            )
        } else {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.UPDATED_AT,
                null,
            )
        }

        // PYRAMID STATUS FILTER
        if (DashboardFilterHelper.isActiveFilter(filters, FilterName.EVENT_PYRAMID_STATUSES)) {
            const pyramidStatusFilter = filters[FilterName.EVENT_PYRAMID_STATUSES]
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.EVENT_PYRAMID_STATUS_ID,
                DashboardFilterHelper.getFilterIds(pyramidStatusFilter),
            )
        } else {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.EVENT_PYRAMID_STATUS_ID,
                null,
            )
        }

        newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
            newDashboardSearchFilters,
            FilterKey.IS_INDEXED,
            DashboardFilterHelper.isActiveFilter(filters, FilterName.INDEXED) ? true : null,
        )

        return newDashboardSearchFilters
    }

    static applyDatagridFiltersToSearchFiltersStories<T extends BaseDashboardSearchFilters>(
        dashboardSearchFilters: T,
        filters: Record<string, any>,
        contribuorLanguageId: number,
    ): T {
        let newDashboardSearchFilters: T = fastClone(dashboardSearchFilters)
        newDashboardSearchFilters = DashboardFilterHelper.applyBaseDatagridFiltersToSearchFilters(
            newDashboardSearchFilters,
            filters,
        ) as T

        // CHANNELS & EVENTS FILTERS -- HEADLINES FILTERS
        const isChannelAndStoryFilterActive = DashboardFilterHelper.isActiveFilter(
            filters,
            FilterName.CHANNELS_AND_EVENTS_COMPOSITE,
        )
        const isHeadlineFilterActive = DashboardFilterHelper.isActiveFilter(filters, FilterName.HEADLINES)

        if (isChannelAndStoryFilterActive || isHeadlineFilterActive) {
            const { channelsFilter, eventsFilter } = isChannelAndStoryFilterActive
                ? filters[FilterName.CHANNELS_AND_EVENTS_COMPOSITE]
                : { channelsFilter: [], eventsFilter: [] }
            const headlineSearchTerm = isHeadlineFilterActive ? filters[FilterName.HEADLINES] : []

            if (channelsFilter?.length) {
                newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                    newDashboardSearchFilters,
                    FilterKey.PUBLICATION_OWNER_ID,
                    DashboardFilterHelper.getFilterIds(channelsFilter),
                )
            } else {
                newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                    newDashboardSearchFilters,
                    FilterKey.PUBLICATION_OWNER_ID,
                    null,
                )
            }
            if (eventsFilter?.length || headlineSearchTerm?.length) {
                newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                    newDashboardSearchFilters,
                    FilterKey.SEARCH,
                    eventsFilter[0]?.label.toLowerCase() || headlineSearchTerm[0]?.label.toLowerCase(),
                )
            } else {
                newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                    newDashboardSearchFilters,
                    FilterKey.SEARCH,
                    '',
                )
            }
        } else {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.PUBLICATION_OWNER_ID,
                null,
            )
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.SEARCH,
                '',
            )
        }

        // DESKS FILTER
        if (DashboardFilterHelper.isActiveFilter(filters, FilterName.DESKS)) {
            const deskFilter = filters[FilterName.DESKS]
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.DESK_ID,
                DashboardFilterHelper.getFilterIds(deskFilter),
            )
        } else {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.DESK_ID,
                null,
            )
        }

        // STORY TYPES FILTER
        if (DashboardFilterHelper.isActiveFilter(filters, FilterName.STORY_TYPES)) {
            const storyTypeFilter = filters[FilterName.STORY_TYPES]
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.TYPE_ID,
                DashboardFilterHelper.getFilterIds(storyTypeFilter)[0],
            )
        } else {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.TYPE_ID,
                null,
            )
        }

        // LANGUAGE & WITH_VIDEO FILTERS
        if (DashboardFilterHelper.isActiveFilter(filters, FilterName.LANGUAGE_AND_WITH_VIDEO_COMPOSITE)) {
            const { languagesFilter, storiesWithVideoFilter } = filters[FilterName.LANGUAGE_AND_WITH_VIDEO_COMPOSITE]
            if (languagesFilter?.length) {
                newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                    newDashboardSearchFilters,
                    FilterKey.LANG_ID,
                    DashboardFilterHelper.getFilterIds(languagesFilter),
                )
            } else {
                newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                    newDashboardSearchFilters,
                    FilterKey.LANG_ID,
                    [contribuorLanguageId],
                )
            }

            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.WITH_VIDEO_ONLY,
                !!storiesWithVideoFilter?.length,
            )
        } else {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.LANG_ID,
                [contribuorLanguageId],
            )
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.WITH_VIDEO_ONLY,
                false,
            )
        }

        // PUBLICATION_DATE & LAST_UPDATE_DATE FILTERS
        if (DashboardFilterHelper.isActiveFilter(filters, FilterName.PUBLICATION_DATE)) {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                dashboardSearchFilters,
                FilterKey.PUBLISHED_AT,
                filters[FilterName.PUBLICATION_DATE],
            )
        } else {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.PUBLISHED_AT,
                null,
            )
        }
        if (DashboardFilterHelper.isActiveFilter(filters, FilterName.LAST_UPDATE_DATE)) {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                dashboardSearchFilters,
                FilterKey.LAST_PUBLISHED_AT,
                filters[FilterName.LAST_UPDATE_DATE],
            )
        } else {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.LAST_PUBLISHED_AT,
                null,
            )
        }

        // PUBLICATION_STATUSES FILTER
        if (DashboardFilterHelper.isActiveFilter(filters, FilterName.PUBLICATION_STATUSES)) {
            const publicationStatusesFilter = filters[FilterName.PUBLICATION_STATUSES]
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.PUBLICATION_STATUS,
                DashboardFilterHelper.getFilterIds(publicationStatusesFilter),
            )
        } else {
            newDashboardSearchFilters = DashboardFilterHelper.setFilterValue(
                newDashboardSearchFilters,
                FilterKey.PUBLICATION_STATUS,
                null,
            )
        }

        return newDashboardSearchFilters
    }
}
