import { Component, HostBinding, OnInit } from '@angular/core'
import { User } from 'entities/user'
import { AuthService } from '../../services/auth/auth'
import { BaseAbstractComponent } from '../shared/base.component'

@Component({
    selector: 'cms-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends BaseAbstractComponent implements OnInit {
    @HostBinding('class') class = 'content-container'

    public currentUser: User

    constructor(private authService: AuthService) {
        super()
    }

    ngOnInit(): void {
        this.currentUser = this.authService.currentUser!
    }
}
