import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { Contributor } from '../../entities/contributors'
import { ContributorType } from '../../enums/contributor-type'
import { ContributorModel } from '../../models/contributor.model'
import { ContributorGetNamePipe } from '../../pipes/contributor'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { ContributorsListService } from './list'

@Injectable()
export class ContributorsAutocompleteByTermService extends ContributorsListService {
    protected limit = 10
    protected offset = 0
    protected _contributorsAutocomplete: BehaviorSubject<ContributorModel[]>[] = [] as BehaviorSubject<
        ContributorModel[]
    >[]
    public contributorsAutocomplete: Observable<ContributorModel[]>[] = []
    protected contributorGetNamePipe: ContributorGetNamePipe

    /**
     *
     * @param api
     */
    constructor(
        apollo: Apollo,
        protected errorMessagesServices: ErrorsMessagesService,
        contributorGetNamePipe: ContributorGetNamePipe,
    ) {
        super(apollo, errorMessagesServices)

        this.contributorGetNamePipe = contributorGetNamePipe

        const types = [
            ContributorType.all,
            ContributorType.producer,
            ContributorType.author,
            ContributorType.videoEditor,
        ]

        types.forEach(type => {
            this._contributorsAutocomplete[type] = new BehaviorSubject([] as ContributorModel[])
            this.contributorsAutocomplete[type] = this._contributorsAutocomplete[type]
                .asObservable()
                .pipe(filter(contributors => Boolean(contributors)))
        })
    }

    /**
     *
     * @param search
     * @returns {void}
     */
    getContributorsByTerm(search: { term: string; language?: string }, type: ContributorType): void {
        this.prepareFindByTerm(search, this.limit, this.offset)
            .pipe(
                map(data => {
                    return data.contributorsByTerm.results
                }),
            )
            .subscribe((contributors: [ContributorModel]) => {
                const foundContributors = this._contributorsAutocomplete[type].getValue()

                contributors.forEach(contributor => {
                    // push only if not already in the stack !!!
                    const contributorsWithSameId = foundContributors.filter(currentContributor => {
                        return contributor.id === currentContributor.id
                    })

                    if (!contributorsWithSameId.length && contributor.isActive) {
                        foundContributors.push(new Contributor(contributor))
                    }
                })

                this._contributorsAutocomplete[type].next(foundContributors)
            })
    }
}
