import { gql } from '@apollo/client/core'
import { galleryImageFragment, galleryImageWithMetaFragment } from 'services/shared/image.fragment'
import { tagFragment } from 'services/tags/tags.fragment'
import { paginationFragment } from '../shared/pagination.fragment'

export const galleryInputFragmentGpl = gql`
    fragment galleryInputInfo on GalleryInput {
        id
        privateTitle
        translations {
            title
            description
            isMetaDisplayed
            lang {
                id
            }
        }
        images {
            id
            tags
            translations {
                altText
                caption
                lang {
                    id
                }
                copyright {
                    link
                    text
                }
                source {
                    link
                    text
                }
                callToAction {
                    text
                    link
                }
            }
        }
    }
`

export const galleriesQuery = gql`
    ${paginationFragment}

    query galleries($searchInput: SearchGalleryInput, $options: QueryOptions) {
        galleries(search: $searchInput, options: $options) {
            results {
                id
                privateTitle
                key
                createdAt
                updatedAt
                isActive
                images {
                    id
                }
            }
            pagination {
                ...paginationInfo
            }
        }
    }
`

export const galleryByIdQuery = gql`
    ${tagFragment}
    ${galleryImageWithMetaFragment}

    query gallery($id: Int!) {
        gallery(id: $id) {
            id
            privateTitle
            key
            createdAt
            updatedAt
            isActive
            translations {
                langId
                publicTitle
                description
                isMetaDisplayed
            }
            images {
                ...galleryImageWithMetaInfo
                provider {
                    id
                }
                tags {
                    ...tagInfo
                }
            }
        }
    }
`

export const galleryByKeyQuery = gql`
    ${tagFragment}
    ${galleryImageWithMetaFragment}

    query galleryByKey($key: String!) {
        galleryByKey(key: $key) {
            id
            privateTitle
            key
            createdAt
            updatedAt
            isActive
            translations {
                langId
                publicTitle
                description
                isMetaDisplayed
            }
            images {
                ...galleryImageWithMetaInfo
                tags {
                    ...tagInfo
                }
            }
        }
    }
`

export const galleryByKeyForPreviewQuery = gql`
    ${galleryImageFragment}

    query galleryByKeyForPreviewQuery($key: String!) {
        galleryByKey(key: $key) {
            id
            key
            images {
                ...galleryImageInfo
            }
        }
    }
`

export const upsertGalleryQuery = gql`
    ${galleryImageWithMetaFragment}

    mutation upsertGallery($gallery: GalleryInput!) {
        upsertGallery(gallery: $gallery) {
            id
            privateTitle
            key
            createdAt
            updatedAt
            isActive
            translations {
                langId
                publicTitle
                description
                isMetaDisplayed
            }
            images {
                ...galleryImageWithMetaInfo
            }
        }
    }
`
