import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { ClrModal } from '@clr/angular'
import { LocalStorageWrapper } from 'apollo3-cache-persist'
import * as dayjs from 'dayjs'
import { SurveyLinkService } from 'services/survey-link/survey-link.service'
import { UserSurveyLink, SurveyLink } from '../../../interfaces/survey-link'
import { BaseAbstractComponent } from '../../shared/base.component'

@Component({
    selector: 'cms-feedback-survey',
    templateUrl: './feedback-survey.component.html',
    styleUrls: ['./feedback-survey.component.scss'],
})
export class FeedbackSurveyComponent extends BaseAbstractComponent implements OnInit, OnDestroy {
    @ViewChild('modal', { static: false }) modal: ClrModal

    public userSurveyLink: UserSurveyLink | null = null
    public safeSurveyLinkUrl: SafeResourceUrl
    public isModalOpen: boolean = true
    public displaySurveyModal: boolean = false
    public displayModalBody: boolean = false
    public modalSize: string = 'md'
    public displaySpinner: boolean = true

    private readonly NUMBER_OF_DAYS_TO_REMIND_IN = 3
    private localStorage = new LocalStorageWrapper(window.localStorage)

    constructor(private surveyLinkService: SurveyLinkService, private sanitizer: DomSanitizer) {
        super()
    }

    public ngOnInit(): void {
        this.userSurveyLink = this.fetchSurveyLinkInLocalStorage()

        this.surveyLinkService.getLastSurveyLink().subscribe((surveyLink: SurveyLink) => {
            if (!surveyLink || !surveyLink.isActive || this.isSurveyLinkExpired(surveyLink)) {
                this.userSurveyLink = null
            } else if (surveyLink.id !== this.userSurveyLink?.id) {
                this.userSurveyLink = Object.assign({}, surveyLink)
            } else {
                this.userSurveyLink = Object.assign(this.userSurveyLink, surveyLink)
            }

            this.setSafeSurveyLinkUrl()
            this.setSurveyLinkInLocalStorage()
            this.updateDisplayCondition()
        })
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy()
    }

    public showSurveyForm(): void {
        this.userSurveyLink!.alreadyClickedYes = true
        this.setSurveyLinkInLocalStorage()

        this.displayModalBody = true
        this.modalSize = 'lg'
    }

    public remindMeLater(): void {
        this.userSurveyLink!.remindMeLater = dayjs()
            .add(this.NUMBER_OF_DAYS_TO_REMIND_IN, 'day')
            .startOf('day')
            .toDate()
        this.setSurveyLinkInLocalStorage()

        this.isModalOpen = false
    }

    public finishedLoadingIframe(): void {
        this.displaySpinner = false
    }

    private setSafeSurveyLinkUrl(): void {
        if (this.userSurveyLink?.link) {
            this.safeSurveyLinkUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.userSurveyLink.link)
        }
    }

    private updateDisplayCondition(): void {
        this.displaySurveyModal = Boolean(
            this.userSurveyLink &&
                !this.isSurveyLinkExpired(this.userSurveyLink) &&
                !this.userSurveyLink?.alreadyClickedYes &&
                this.shouldRemind(),
        )
    }

    private shouldRemind(): boolean {
        const remindMeLater = this.userSurveyLink?.remindMeLater

        if (remindMeLater) {
            const today = dayjs().startOf('day')

            return today.isSame(remindMeLater) || today.isAfter(remindMeLater)
        }

        return true
    }

    private fetchSurveyLinkInLocalStorage() {
        return JSON.parse(this.localStorage.getItem('survey') || '{}')
    }

    private setSurveyLinkInLocalStorage() {
        this.localStorage.setItem('survey', JSON.stringify(this.userSurveyLink))
    }

    private isSurveyLinkExpired(surveyLink: SurveyLink): boolean {
        return Boolean(surveyLink.expiresAt && new Date(surveyLink.expiresAt).getTime() < Date.now())
    }
}
