import { Component, Input } from '@angular/core'

@Component({
    selector: 'cms-header-link',
    templateUrl: './header-link.component.html',
    styleUrls: ['./header-link.component.scss'],
})
export class HeaderLinkComponent {
    @Input() link = ''
    @Input() linkActive = ''
}
