import { verticalFragment, verticalWithTranslationsFragment } from 'services/verticals/verticals.fragment'
import { languageFragment } from '../languages/language.fragment'

export const themeTranslationFragment = `
${languageFragment}

fragment themeTranslationInfo on ThemeTranslation {
    title
    slug
    language {
        ...languageInfo
    }
}
`

export const themeFragment = `
${themeTranslationFragment}
${verticalFragment}

fragment themeInfo on Theme {
    id
    slug
    isSelectableCms
    translations {
        ...themeTranslationInfo
    }
    verticals {
        ...verticalInfo
    }
}
`

export const themeWithTranslatedVerticalsFragment = `
${verticalWithTranslationsFragment}

fragment themeWithTranslatedVerticalsInfo on Theme {
    id
    slug
    verticals {
        ...verticalWithTranslationsInfo
    }
}
`

export const themeWithTranslatedVerticalsAndTranslationsFragment = `
${themeTranslationFragment}
${verticalWithTranslationsFragment}

fragment themeWithTranslatedVerticalsAndTranslationsInfo on Theme {
    id
    slug
    translations {
        ...themeTranslationInfo
    }
    verticals {
        ...verticalWithTranslationsInfo
    }
}
`
