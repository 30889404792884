export const workflowFragment = `
fragment workflowInfo on Workflow {
    id
    inputId
    inputVersion
      steps {
        id
        workflowId
        info
        createdAt
        status
      }
}
`
