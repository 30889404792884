import { Injectable } from '@angular/core'
import { CloseMessagePayload, EMessagesNotification } from 'models/messages-notification.model'
import { Observable } from 'rxjs'
import { SocketIoClientService } from './clients/socket-io-client.service'
import { MessagesNotificationInterface } from './messages-notification.interface'

@Injectable()
export class MessagesNotificationService implements MessagesNotificationInterface {
    constructor(private socketService: SocketIoClientService) {}

    /**
     * Allows to receive all currently active messages.
     */
    public onReadAllMessages(): Observable<unknown> {
        return this.socketService.on(EMessagesNotification.ON_READ_ALL_MESSAGES)
    }

    /**
     * Allows to receive the messages.
     */
    public subscribeToMessages(): void {
        this.socketService.emit(EMessagesNotification.SUBSCRIBE_TO_MESSAGES)
    }

    /**
     * Notifies the notification service that a contributor has closed a message.
     */
    public onCloseMessage(currentUsername: CloseMessagePayload): void {
        this.socketService.emit(EMessagesNotification.CLOSE_MESSAGE, currentUsername)
    }
}
