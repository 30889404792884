import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { QueryOptionsInterface, QueryResultWithPaginationInterface } from 'interfaces/mercury'
import { SurveyLink } from 'interfaces/survey-link'
import { Observable, Subject } from 'rxjs'
import { pluck, take } from 'rxjs/operators'
import { cacheAndNetworkQuery, networkQuery } from 'tools/apollo'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { lastSurveyLink, surveyLink, surveyLinks, upsertSurveyLink } from './survey-link.query'

@Injectable()
export class SurveyLinkService extends BaseService {
    public refresh = new Subject()

    constructor(apollo: Apollo, protected errorsMessagesService: ErrorsMessagesService) {
        super(apollo, errorsMessagesService)
    }

    public getSurveyLinkById(id: number) {
        return networkQuery<SurveyLink>(
            this.apollo,
            {
                query: surveyLink,
                variables: {
                    id,
                },
            },
            ['data', 'surveyLink'],
        ).pipe(take(1))
    }

    public getLastSurveyLink() {
        return networkQuery<SurveyLink>(
            this.apollo,
            {
                query: lastSurveyLink,
            },
            ['data', 'lastSurveyLink'],
        ).pipe(take(1))
    }

    public getSurveyLinks(options: QueryOptionsInterface) {
        return cacheAndNetworkQuery<QueryResultWithPaginationInterface<SurveyLink>>(
            this.apollo,
            {
                query: surveyLinks,
                variables: {
                    options,
                },
            },
            ['data', 'surveyLinks'],
        )
    }

    public upsertSurveyLink(surveyLink: SurveyLink): Observable<SurveyLink> {
        return this.apollo
            .mutate({
                mutation: upsertSurveyLink,
                variables: {
                    surveyLink,
                },
            })
            .pipe(take(1), pluck('data', 'upsertSurveyLink'))
    }
}
