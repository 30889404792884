import { Pipe, PipeTransform } from '@angular/core'
import { StoryText } from '../models/story.model'

@Pipe({ name: 'storyText' })
export class StoryTextPipe implements PipeTransform {
    transform(texts: StoryText[], format: string = 'text'): string {
        if (!texts || !texts.length) {
            return 'No article content available'
        }

        const filteredText = texts.find((text: StoryText) => text.format.slug === format)

        return filteredText ? filteredText.text : texts[0].text
    }
}
