import { ClrDatagridStateInterface } from '@clr/angular'
import { SortInterface } from 'interfaces/mercury'
import { BaseAbstractComponent } from '../base.component'

export abstract class DashboardPaginationAbstract extends BaseAbstractComponent {
    public total: number = 0
    public pageOffset: number = 30
    public itemsPerPage: number = 10
    public order: SortInterface = { key: 'id', direction: 'DESC' }

    constructor() {
        super()
    }

    /**
     * Get the limit and offset to apply for pagination
     *
     * Return an array with respectively the limit and the offset
     *
     * @param state
     * @returns {[number, number]}
     */
    protected getPaginationOption(state?: ClrDatagridStateInterface): number[] {
        // needed because we don't use state variable yet
        let limit: number = this.itemsPerPage
        let offset: number = 0

        if (state && state.page) {
            if (state.page.size) {
                limit = state.page.size
            }
            if (state.page.from !== undefined && state.page.from >= 0) {
                offset = state.page.from
            }
        }

        return [limit, offset]
    }
}
