import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { ProgramsCategory } from 'enums/programs-category'
import { PublicationOwnerEnum } from 'enums/publication-owner'
import { ProgramModel } from 'models/program.model'
import { Subject } from 'rxjs'
import { map } from 'rxjs/operators'
import { ProgramListService, ProgramsResult } from 'services/programs/list'
import { getQueryOptions } from 'services/shared/options.query'

@Component({
    selector: 'cms-input-programs-select-list',
    templateUrl: './input-programs-select-list.component.html',
    styleUrls: ['./input-programs-select-list.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => InputProgramsSelectListComponent),
        },
    ],
})
export class InputProgramsSelectListComponent implements ControlValueAccessor, OnDestroy, OnInit {
    /**
     * Disable the select list
     */
    @Input() disabled: boolean = false

    @Input() selectId: string

    /**
     * Type of the return
     */
    @Input() returnType: 'model' | 'number' = 'number'

    /**
     * Display the optgroup africanews
     */
    @Input() displayAfricanewsPgm: boolean = true

    /**
     * Display only disabled programs
     */
    @Input() isDisabledCms: boolean = false

    /**
     * Display only programs selectable for creation
     */
    @Input() isSelectableForCreation?: boolean

    /**
     * Display only programs selectable for update
     */
    @Input() isSelectableForUpdate?: boolean

    /**
     * Display only programs selectable for search
     */
    @Input() isSelectableForSearch?: boolean

    /**
     * Display only programs of "program_page" category
     */
    @Input() isCategoryProgram: boolean = false

    /**
     * Label of the first option for the null value
     */
    @Input() firstOptionString: string = 'Choose a program'

    /**
     * Show a loader while the program list is loading
     */
    @Output() showLoader = new EventEmitter()

    public showLoaderState: boolean = true

    /**
     * List to make the input select
     */
    public programsEuronews: ProgramModel[]
    public programsAfricanews: ProgramModel[]
    public programsEuronewsByVertical: Record<string, ProgramModel[]>

    public selectedProgram: number | null = null

    /**
     * Subject to handle subscriptions until the component is destroyed
     */
    destroy: Subject<boolean> = new Subject<boolean>()

    onChange = (_: any) => {}
    onTouched = () => {}

    constructor(private programListService: ProgramListService) {}

    trackByVertical(index, _) {
        return index
    }

    ngOnInit() {
        const programSearchOptions = {
            ...{ isDisabledCms: this.isDisabledCms },
            ...(this.isSelectableForCreation !== undefined && {
                isSelectableForCreation: this.isSelectableForCreation,
            }),
            ...(this.isSelectableForUpdate !== undefined && { isSelectableForUpdate: this.isSelectableForUpdate }),
            ...(this.isSelectableForSearch !== undefined && { isSelectableForSearch: this.isSelectableForSearch }),
            ...(this.isCategoryProgram && { categoryId: ProgramsCategory.Program }),
        }
        this.programListService
            .callApi({
                search: programSearchOptions,
                options: getQueryOptions(400, 0),
            })
            .pipe(
                map((data: { programs: ProgramsResult }) =>
                    this.programListService
                        .formatProgramList(data.programs.results)
                        .filter(el => el.translations.length > 0),
                ),
            )
            .subscribe(programsList => {
                this.programsEuronews = programsList.filter(
                    el => el.publicationOwner!.id === PublicationOwnerEnum.Euronews,
                )
                this.programsAfricanews = programsList.filter(
                    el => el.publicationOwner!.id === PublicationOwnerEnum.Africanews,
                )
                this.programsEuronewsByVertical = this.programsByVertical(this.programsEuronews)

                this.showLoaderState = false
                this.showLoader.emit(this.showLoaderState)
            })
    }

    ngOnDestroy(): void {
        this.destroy.next(true)
        this.destroy.complete()
    }

    /**
     * Program changed in list
     * Notify observers for the change
     *
     * @param evt
     */
    programChange(evt) {
        this.selectedProgram = evt

        this.onChange(this.selectedProgram)
    }

    /**
     * Inherit from ControlValueAccessor
     * @param fn
     */
    registerOnChange(fn: any): void {
        this.onChange = fn
    }

    /**
     * Inherit from ControlValueAccessor
     * @param fn
     */
    registerOnTouched(fn: any): void {
        this.onTouched = fn
    }

    /**
     * Inherit from ControlValueAccessor
     * @param isDisabled
     */
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled
    }

    /**
     * Inherit from ControlValueAccessor
     * @param obj
     */
    writeValue(obj): void {
        if (!obj) {
            return
        }

        this.selectedProgram = obj

        this.onChange(this.selectedProgram)
    }

    /**
     * Return the value of the field
     */
    get value(): ProgramModel | number | null {
        if (!this.selectedProgram) {
            return null
        }

        return this.returnType === 'number' ? this.selectedProgram : this.findProgram(this.selectedProgram)
    }

    /**
     * Return the program object for the given id
     * @param id
     */
    protected findProgram(id: number): ProgramModel | null {
        return this.programsEuronews.concat(this.programsAfricanews).find(el => el.id === id) || null
    }

    protected programsByVertical(programList: ProgramModel[]): Record<string, ProgramModel[]> {
        const ret = {}
        programList.forEach(el => {
            const verticalKey = el.info || 'unknown'
            if (!Object.prototype.hasOwnProperty.call(ret, verticalKey)) {
                ret[verticalKey] = []
            }
            ret[verticalKey].push(el)
        })

        return ret
    }
}
