<div class="c-custom-filter qa-checkbox-filter filterable-component">
    <div class="c-custom-filter__arrow"></div>
    <div class="c-custom-filter__header-title filter-title" *ngIf="title">
        {{ title }}
    </div>
    <div *ngIf="showSelectedItemsAboveTheList && value.length" class="selected-items-wrapper">
        <ul class="filterable-list list-unstyled compact">
            <li *ngFor="let item of value" class="filterable-list__items">
                <div>
                    <clr-icon shape="check" size="12" class="items-checked"></clr-icon>
                    <span class="items-checked-label">
                        {{ item.label | titlecase }}
                    </span>
                </div>
                <div>
                    <clr-icon shape="trash" size="12" class="delete-item" (click)="onSelectItem(item)"></clr-icon>
                </div>
            </li>
        </ul>
    </div>

    <form [formGroup]="form" *ngIf="form">
        <div class="searchBar" *ngIf="searchPlaceholder">
            <div class="search">
                <label for="searchTerm" class="storySearchBar__label">
                    <input
                        type="text"
                        size="25"
                        id="searchTerm"
                        formControlName="searchTerm"
                        [placeholder]="searchPlaceholder"
                        autocomplete="off"
                        class="c-searchBar__input clr-input" />
                </label>
            </div>
        </div>

        <div *ngIf="displayList" class="c-custom-filter__list" [ngSwitch]="type" #filterList>
            <ng-container *ngSwitchCase="'grouped'" [ngTemplateOutlet]="grouped"></ng-container>
            <ng-container *ngSwitchCase="'select'" [ngTemplateOutlet]="select"></ng-container>
            <ng-container *ngSwitchCase="'checkbox'" [ngTemplateOutlet]="checkbox"></ng-container>
        </div>
    </form>

    <div class="c-custom-filter__footer" *ngIf="displayFooter">
        <button type="button" class="btn btn-outline" (click)="resetValue()">Clear</button>
        <button type="button" class="btn btn-primary" (click)="filter()">Apply</button>
    </div>
</div>

<ng-template #grouped>
    <div class="c-custom-filter__arrow"></div>

    <section>
        <ul class="list list-unstyled compact">
            <li *ngFor="let group of filterablesByGroup; index as i" class="filterable-list-item">
                <span
                    class="filterable-list-item-title color__{{ group.name.replace(' ', '').toLowerCase() }}"
                    id="{{ group.name.toLowerCase() }}"
                    >{{ group.name | titlecase }}</span
                >
                <ul *ngIf="group.items.length" class="list list-unstyled compact">
                    <li
                        *ngFor="let item of group.items; index as j"
                        class="filterable-list-item grouped-items"
                        [class.itemActive]="item.selected"
                        id="{{ item.label.toLowerCase() }}-{{ item.id }}"
                        (click)="onSelectItem(item)">
                        {{ item.label }}
                    </li>
                </ul>
            </li>
        </ul>
    </section>
</ng-template>

<ng-template #checkbox>
    <section>
        <ul class="filterable-list list-unstyled compact">
            <li *ngFor="let item of list; index as i" class="filterable-list-item">
                <clr-checkbox-wrapper>
                    <input
                        type="checkbox"
                        clrCheckbox
                        class="listed-checkbox"
                        [checked]="item.selected"
                        id="lst-{{ item.id }}-{{ item.label }}"
                        (change)="onSelectItem(item)" />
                    <label
                        for="lst-{{ item.id }}-{{ item.label }}"
                        [class.itemActive]="item.selected"
                        class="{{ item.group }} lst-{{ item.label?.replace(' ', '_')?.toLowerCase() }}"
                        >{{ item.label }}</label
                    >
                </clr-checkbox-wrapper>
            </li>
        </ul>
    </section>
</ng-template>

<ng-template #select>
    <section>
        <select
            #selectOptions
            clrSelect
            class="filterable-list list-unstyled compact"
            (change)="onChangeItem(selectOptions.value ? list[selectOptions.value] : null)">
            <option value=""></option>
            <option *ngFor="let item of list; index as i" value="{{ i }}">
                {{ item.label }}
            </option>
        </select>
    </section>
</ng-template>
