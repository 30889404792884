import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { Observable, BehaviorSubject } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable()
export class BreakingNewsDefaultImage {
    private _breakingnewsFile: File
    private _breakingnewsBase64: BehaviorSubject<SafeUrl> = new BehaviorSubject('')

    constructor(private _sanitizer: DomSanitizer, private _http: HttpClient) {
        this.loadBreakingnewsImage().subscribe(image => {
            this._breakingnewsFile = new File([image], 'breakingnews.jpg', { type: 'image/jpeg' })
            const reader = new FileReader()
            reader.onloadend = () => {
                this._breakingnewsBase64.next(this._sanitizer.bypassSecurityTrustUrl(reader.result as string))
            }
            reader.readAsDataURL(this._breakingnewsFile)
        })
    }

    getBreakingAsBase64String(): Observable<SafeUrl> {
        return this._breakingnewsBase64.asObservable()
    }

    getBreakingAsFile(): File {
        return this._breakingnewsFile
    }

    private loadBreakingnewsImage(): Observable<Blob> {
        return this._http
            .get('assets/images/breakingnews.jpg', { responseType: 'blob' })
            .pipe(map(e => new Blob([e], { type: 'image/jpeg' })))
    }
}
