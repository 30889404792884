import { Directive, ElementRef, HostListener } from '@angular/core'

@Directive({ selector: '[cmsTrim]' })
export class TrimDirective {
    constructor(private elementRef: ElementRef) {}

    @HostListener('blur', ['$event.target'])
    onBlur(input: HTMLInputElement) {
        this.elementRef.nativeElement.value = input.value.trim()
        input.dispatchEvent(new Event('input'))
    }
}
