import { gql } from '@apollo/client/core'
import { contributorFragment } from '../../contributors/contributor.fragment'
import { storyMutateFragment } from '../find-by-id.query'

export const storyContributorsInfosFragment = `
  ${contributorFragment}

  fragment storyContributorsInfos on Story {
    signature {
      name
      twitter
    }
    creator {
        ...contributorInfo
    }
    author {
        ...contributorInfo
    }
    videoEditor {
        ...contributorInfo
    }
    producer {
        ...contributorInfo
    }
    additionalReporting {
      storyId
      agencies
      contributors
      sourceMessage
      contributionMessages
    }
  }
`

export const updateStoryContributors = gql`
    ${storyContributorsInfosFragment}
    ${storyMutateFragment}

    mutation updateStoryContributors(
        $storyId: Int!
        $contributors: StoryContributorInput!
        $fieldsToReplicate: [StoryContributorsReplicatedFields]
    ) {
        updateStoryContributors(storyId: $storyId, contributors: $contributors, fieldsToReplicate: $fieldsToReplicate) {
            ...storyContributorsInfos
            ...storyMutateInfos
        }
    }
`

export const updateStoryAdditionalReporting = gql`
    ${storyContributorsInfosFragment}
    ${storyMutateFragment}

    mutation updateStoryAdditionalReporting(
        $storyId: Int!
        $agencies: String
        $contributors: String
        $sourceMessage: String
        $contributionMessages: String
    ) {
        updateStoryAdditionalReporting(
            storyId: $storyId
            agencies: $agencies
            contributors: $contributors
            sourceMessage: $sourceMessage
            contributionMessages: $contributionMessages
        ) {
            ...storyContributorsInfos
            ...storyMutateInfos
        }
    }
`
export const getStoryContributors = gql`
    ${storyContributorsInfosFragment}
    ${storyMutateFragment}

    query getStoryTitlesAndSettings($storyId: Int!) {
        story(id: $storyId) {
            id
            ...storyContributorsInfos
            ...storyMutateInfos
        }
    }
`
