import { gql } from '@apollo/client/core'
import { languageFragment } from '../languages/language.fragment'
import { cmsUserFragment } from './cms-user.fragment'

export const meQuery = gql`
    ${languageFragment}
    ${cmsUserFragment}

    query meQuery {
        me {
            ...contributorUserInfo
        }
    }
`
