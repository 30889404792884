import { gql } from '@apollo/client/core'
import { categoriesFragment, categoryAssetFragment } from './files-selection.fragment'

export const rootSubCategoriesQuery = gql`
    ${categoriesFragment}

    query rootSubCategories {
        rootSubCategories {
            ...categoriesInfo
        }
    }
`

export const subCategoriesQuery = gql`
    ${categoriesFragment}

    query subCategories($categoryId: Int!) {
        subCategories(categoryId: $categoryId) {
            ...categoriesInfo
        }
    }
`

export const categoryAssetsQuery = gql`
    ${categoryAssetFragment}

    query categoryAssets($categoryId: Int!, $assetType: String) {
        categoryAssets(categoryId: $categoryId, assetType: $assetType) {
            ...categoryAssetInfo
        }
    }
`
