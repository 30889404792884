import { gql } from '@apollo/client/core'
import { youtubeCategoriesFragment } from './youtube-categories.fragment'

export const youtubeCategoriesQuery = gql`
    ${youtubeCategoriesFragment}

    query youtubeCategories {
        youtubeCategories {
            ...youtubeCategory
        }
    }
`
