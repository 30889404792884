import { Pipe, PipeTransform } from '@angular/core'
import { LanguageIsoCode } from 'enums/language'
import { LanguagesService } from 'services/languages/languages'

@Pipe({ name: 'translationFilter' })
export class TranslationFilterPipe implements PipeTransform {
    constructor(private languagesService: LanguagesService) {}

    transform(
        translations: Record<string, any>[] = [],
        key: string,
        collateLocaleCMS: LanguageIsoCode = this.languagesService.defaultLanguage.collateLocaleCMS,
        langKey: string = 'language',
    ): string {
        if (translations === null) {
            return ''
        }
        const languageId = this.getLanguageId(collateLocaleCMS)

        const hasTranslation = translations.filter(trans => {
            if (!Object.prototype.hasOwnProperty.call(trans, langKey)) {
                return false
            }

            return trans[langKey]!['id'] === languageId
        })

        if (hasTranslation.length) {
            return Object.prototype.hasOwnProperty.call(hasTranslation[0], key) ? hasTranslation[0][key] : ''
        }

        return ''
    }

    private getLanguageId(collateLocaleCMS: LanguageIsoCode): number {
        const language = this.languagesService.languageByCollateLocaleCms[collateLocaleCMS]

        if (language) {
            return language.id
        }

        throw new Error('Unable to find language "' + collateLocaleCMS + '"')
    }
}
