<clr-modal
    [(clrModalOpen)]="isModalOpen"
    clrModalSize="xl"
    [clrModalClosable]="!eventService.saving"
    (clrModalOpenChange)="onOpenChanged()"
    [clrModalStaticBackdrop]="true"
    data-testid="modal-create-event">
    <h3 class="modal-title">
        <clr-icon shape="sync" size="24" class="c-icon-title"></clr-icon>
        {{ modalTitle | titlecase }}
    </h3>
    <div class="modal-body">
        <!-- ALERT MESSAGES -->
        <cms-alert-item
            *ngIf="eventToUpdate?.isMagazine && !eventService.saving"
            alertType="info"
            [alertClosable]="false"
            [alertMessage]="alertMessages.CHANGING_EVENT_PROGRAM">
        </cms-alert-item>

        <cms-alert-item
            *ngIf="isChangingProgramNotRecommended"
            [alertType]="'warning'"
            [alertClosable]="false"
            [alertMessage]="alertMessages.ALL_LANGUAGES_NOT_SUPPORTED"></cms-alert-item>

        <cms-alert-item
            *ngIf="isChangingProgramForbidden"
            alertType="danger"
            [alertClosable]="false"
            [alertMessage]="alertMessages.CAN_NOT_CHANGE_EVENT_PROGRAM"></cms-alert-item>

        <!-- SPINNER -->
        <ng-container *ngIf="showLoader.event; else modalContent">
            <cms-progress-bar [isDisplayed]="showLoader.event" [isLoading]="false"></cms-progress-bar>
        </ng-container>

        <!-- MODAL CONTENT -->
        <ng-template #modalContent>
            <form
                clrForm
                clrLayout="horizontal"
                class="u-form-fullwidth"
                *ngIf="!showLoader.event"
                [formGroup]="eventForm"
                (ngSubmit)="onSubmit()"
                id="create-dig-story"
                [class.loadingForm]="showLoader.event"
                #modalForm>
                <!-- EVENT NAME -->
                <clr-input-container>
                    <label for="name" class="clr-col-md-3 required">Event Name</label>
                    <input
                        clrInput
                        class="clr-col-4"
                        type="text"
                        id="eventName"
                        name="name"
                        size="45"
                        formControlName="name"
                        cmsTrim
                        autocomplete="off"
                        placeholder="Type Event Name" />
                    <clr-control-error>Event name is required</clr-control-error>
                </clr-input-container>
                <!-- DIGITAL & IsTV -->
                <clr-checkbox-container clrInline *ngIf="isWorkflowMagazine">
                    <label class="clr-col-md-3">Digital & TV</label>
                    <clr-checkbox-wrapper>
                        <input
                            type="checkbox"
                            clrCheckbox
                            id="isDigital"
                            name="isDigital"
                            formControlName="isDigital" />
                        <label for="isDigital">Digital</label>
                    </clr-checkbox-wrapper>
                    <clr-checkbox-wrapper>
                        <input type="checkbox" clrCheckbox id="isTv" name="isTv" formControlName="isTv" />
                        <label for="isTv">TV</label>
                    </clr-checkbox-wrapper>
                </clr-checkbox-container>
                <!-- EDITORIAL DESK -->
                <clr-radio-container clrInline *ngIf="!isWorkflowMagazine">
                    <label class="clr-col-md-3 required">Editorial Desk</label>
                    <clr-radio-wrapper>
                        <input
                            type="radio"
                            clrRadio
                            class="clr-col-4"
                            id="headlineDesk"
                            name="editorialDesk"
                            formControlName="editorialDesk"
                            value="headlineDesk" />
                        <label for="headlineDesk">Headline Desk</label>
                    </clr-radio-wrapper>
                    <clr-radio-wrapper>
                        <input
                            type="radio"
                            clrRadio
                            class="clr-col-4"
                            id="euronewsStories"
                            name="editorialDesk"
                            formControlName="editorialDesk"
                            value="euronewsStories" />
                        <label for="euronewsStories">Euronews Stories</label>
                    </clr-radio-wrapper>
                    <clr-radio-wrapper>
                        <input
                            type="radio"
                            clrRadio
                            id="editorialDesk-none"
                            name="editorialDesk"
                            formControlName="editorialDesk"
                            value="" />
                        <label for="editorialDesk-none">None</label>
                    </clr-radio-wrapper>
                    <clr-control-error>This field is required</clr-control-error>
                </clr-radio-container>
                <!-- PROGRAMS -->
                <clr-select-container>
                    <label for="programId" class="clr-col-md-3 required">Program</label>
                    <cms-input-programs-select-list
                        *ngIf="!showLoader.programs"
                        clrSelect
                        class="clr-col-4"
                        formControlName="programId"
                        name="programId"
                        selectId="programId"
                        (change)="onSelectedProgramChange()"
                        (showLoader)="showLoader.programs = $event"
                        [displayAfricanewsPgm]="displayAfricanewsPrograms"
                        [isCategoryProgram]="isCategoryProgram"
                        [isSelectableForCreation]="isSelectableForCreation"
                        [isSelectableForUpdate]="isSelectableForUpdate">
                    </cms-input-programs-select-list>
                    <clr-control-helper
                        *ngIf="showLoader.programs"
                        data-testid="input-program-loader"
                        class="clr-col-md-2">
                        <cms-spinner class="create-event-spinners" type="inline"></cms-spinner>
                    </clr-control-helper>
                    <clr-control-error class="clr-col-md-8"> Program is required</clr-control-error>
                </clr-select-container>
                <!-- DELIVERABLES -->
                <clr-select-container>
                    <label for="programDeliverableId" class="clr-col-md-3 required">Program Deliverable</label>
                    <select
                        clrSelect
                        class="clr-col-4"
                        *ngIf="!showLoader.programDeliverables"
                        id="programDeliverableId"
                        name="programDeliverableId"
                        formControlName="programDeliverableId">
                        <option [value]="0">Choose a program deliverable&#8230;</option>
                        <option
                            *ngFor="let programDeliverable of programDeliverableList"
                            [value]="programDeliverable.id">
                            {{ programDeliverable.title }}
                        </option>
                    </select>
                    <clr-control-helper
                        *ngIf="showLoader.programDeliverables"
                        data-testid="input-program-deliverable-loader"
                        class="clr-col-md-2">
                        <cms-spinner class="create-event-spinners" type="inline"></cms-spinner>
                    </clr-control-helper>
                    <clr-control-error class="clr-col-md-8"> Program deliverable is required</clr-control-error>
                </clr-select-container>
                <!-- BREAKING NEWS -->
                <clr-toggle-container>
                    <label for="isBreakingNews" class="clr-col-md-3">Breaking News</label>
                    <clr-toggle-wrapper>
                        <input
                            type="checkbox"
                            clrToggle
                            class="clr-col-4"
                            id="isBreakingNews"
                            name="isBreakingNews"
                            formControlName="isBreakingNews"
                            value="option1" />
                    </clr-toggle-wrapper>
                </clr-toggle-container>
                <!-- SINGLE MEDIA -->
                <clr-toggle-container>
                    <label for="isSingleMedia" class="clr-col-md-3">Single Media</label>
                    <clr-toggle-wrapper>
                        <input
                            type="checkbox"
                            clrToggle
                            class="clr-col-4"
                            id="isSingleMedia"
                            name="isSingleMedia"
                            formControlName="isSingleMedia"
                            value="option1" />
                    </clr-toggle-wrapper>
                </clr-toggle-container>
                <!-- MULTIPLEX -->
                <clr-toggle-container *ngIf="eventToUpdate?.isMultiplex">
                    <label class="clr-col-md-3" for="isMultiplex">Multiplex Event</label>
                    <clr-toggle-wrapper>
                        <input
                            clrToggle
                            class="clr-col-md-9"
                            type="checkbox"
                            id="isMultiplex"
                            name="isMultiplex"
                            formControlName="isMultiplex"
                            [attr.disabled]="true" />
                    </clr-toggle-wrapper>
                </clr-toggle-container>
                <!-- EMBARGO DATE -->
                <div class="clr-row clr-form-control">
                    <label for="hasEmbargoDate" class="clr-control-label clr-col-md-3">Embargo Date</label>
                    <div class="clr-row u-flex u-no-padding-right clr-col-md-9">
                        <clr-toggle-wrapper class="clr-col-1">
                            <input
                                type="checkbox"
                                clrToggle
                                id="hasEmbargoDate"
                                name="hasEmbargoDate"
                                formControlName="hasEmbargoDate"
                                value="option1" />
                        </clr-toggle-wrapper>
                        <div class="c-input__owl-date-time">
                            <input
                                type="text"
                                id="embargoDate"
                                name="embargoDate"
                                formControlName="embargoDate"
                                [selectMode]="'single'"
                                [owlDateTimeTrigger]="embargoDateOwl"
                                [owlDateTime]="embargoDateOwl"
                                [min]="now" />
                            <clr-control-helper *ngIf="embargoDateAlertMessage">
                                <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                                Impossible to set embargo date - Stories {{ embargoDateAlertMessage }} already
                                published.</clr-control-helper
                            >
                            <owl-date-time #embargoDateOwl></owl-date-time>
                        </div>
                    </div>
                </div>

                <!-- PRODUCER LANGUAGE -->
                <clr-select-container *ngIf="!isWorkflowMagazine">
                    <label for="producerLanguageId" class="clr-col-md-3 required">Producer Language</label>
                    <select
                        clrSelect
                        id="producerLanguageId"
                        name="producerLanguageId"
                        class="clr-col-4"
                        formControlName="producerLanguageId">
                        <option *ngFor="let language of allLanguagesList" [value]="language.id">
                            {{ language.fullname }}
                        </option>
                    </select>
                    <clr-control-error class="clr-col-md-8">Producer language is required</clr-control-error>
                </clr-select-container>
                <!-- LANGUAGES -->
                <clr-checkbox-container clrInline>
                    <label class="clr-control-label clr-col-md-3 required">Language(s)</label>
                    <clr-checkbox-wrapper *ngFor="let language of selectableLanguagesList" formGroupName="languages">
                        <input
                            type="checkbox"
                            clrCheckbox
                            [id]="language.isoCode"
                            [name]="language.isoCode"
                            [formControlName]="language.isoCode"
                            [value]="language.isoCode"
                            [attr.disabled]="languagesEventForm.get(language.isoCode)!.disabled || null"
                            [attr.checked]="languagesEventForm.get(language.isoCode)!.value || null" />
                        <label
                            [for]="language.isoCode"
                            class="clr-control-label"
                            [ngClass]="{
                                'tooltip tooltip-bottom-right': language.isoCode === 'core_languages'
                            }">
                            {{ language.fullname }}
                            <span *ngIf="language.isoCode === 'core_languages'" class="tooltip-content">{{
                                coreLanguagesTooltipContent
                            }}</span>
                        </label>
                    </clr-checkbox-wrapper>
                </clr-checkbox-container>
                <!-- INFOS -->
                <div class="clr-form-control">
                    <label for="instructions" class="clr-col-md-3 clr-control-label">Info</label>
                    <div class="clr-col-md-9 clr-control-container">
                        <div class="clr-textarea-wrapper">
                            <textarea
                                #instructions
                                id="instructions"
                                name="instructions"
                                formControlName="instructions"
                                [maxLength]="instructionsMaxLength"
                                placeholder="e.g. Please hide the video, do not publish to youTube"
                                class="contributor-bio__textarea clr-textarea"></textarea>
                        </div>
                        <span class="clr-subtext">
                            <cms-char-counter
                                class="clr-subtext"
                                [value]="instructions.value"
                                [maxLength]="instructionsMaxLength">
                            </cms-char-counter>
                        </span>
                    </div>
                </div>
            </form>
        </ng-template>
    </div>
    <div class="modal-footer">
        <cms-alert-item
            *ngFor="let error of formErrors"
            [alertClosable]="true"
            [alertType]="'danger'"
            [alertMessage]="error"
            (clrAlertClosedChange)="resetErrors()"></cms-alert-item>

        <cms-alert-item
            *ngFor="let success of formSuccess"
            [alertClosable]="true"
            [alertType]="'success'"
            [alertMessage]="success"
            (clrAlertClosedChange)="resetSuccess()"></cms-alert-item>

        <button
            type="button"
            class="btn btn-outline"
            [attr.disabled]="eventService.saving || null"
            (click)="onOpenChanged()">
            Cancel
        </button>
        <button
            type="submit"
            form="create-dig-story"
            class="btn btn-primary"
            [attr.disabled]="isSaveButtonDisabled || null">
            Save
        </button>
    </div>
</clr-modal>
