import {
    Component,
    Input,
    OnChanges,
    AfterViewInit,
    ChangeDetectorRef,
    ElementRef,
    OnDestroy,
    Output,
    EventEmitter,
} from '@angular/core'
import { Story } from 'entities/story'
import { VignetteDisplayTypes } from 'enums/vignette-display-types'
import { environment } from 'environments/environment'
import { Video, Image } from 'models/media.model'
import { Subject } from 'rxjs'

@Component({
    selector: 'cms-story-vignette',
    templateUrl: './story-vignette.component.html',
    styleUrls: ['./story-vignette.component.scss'],
})
export class StoryVignetteComponent implements OnChanges, AfterViewInit, OnDestroy {
    @Input() story?: Story
    @Input() displayType: VignetteDisplayTypes

    @Output() videoAdded = new EventEmitter<Story>()
    @Output() mainImageAdded = new EventEmitter<Image>()

    @Input() isSelected: boolean
    @Input() image?: Image
    @Input() videoPoster?: string

    public vignetteDisplayTypes = VignetteDisplayTypes
    public storyHeadline: string = ''
    public storyURL: string | null = ''
    public isPublished: boolean | undefined = false
    public previewVideo: Video | null = null
    public displayCaption: boolean = false

    public isPlay: boolean = false
    public isWidgetEuronews: boolean = false
    public isWidgetGallery: boolean = false

    protected ngUnsubscribe: Subject<void> = new Subject()

    constructor(private cdRef: ChangeDetectorRef, private elementRef: ElementRef) {}

    ngOnDestroy(): void {
        this.ngUnsubscribe.next()
        this.ngUnsubscribe.complete()
    }

    ngAfterViewInit(): void {
        this.isWidgetEuronews = this.displayType === this.vignetteDisplayTypes.EuronewsWidget
        this.isWidgetGallery = this.displayType === this.vignetteDisplayTypes.GalleryWidget
        this.cdRef.detectChanges()

        if (!this.isWidgetGallery) {
            this.initVideos()
            this.updateVignette()
        }
    }

    ngOnChanges() {
        if (this.displayType !== this.vignetteDisplayTypes.GalleryWidget) {
            this.displayCaption = true
            this.updateVignette()
        }
    }

    protected updateVignette() {
        this.story = new Story(this.story!)
        this.storyHeadline = !this.story.articleHeadline
            ? this.story.event.daletTitleName
                ? 'Event: ' + this.story.event.daletTitleName
                : 'No headline available'
            : this.story.articleHeadline
        this.storyURL = this.story.getStoryOnlineUrl()
        this.isPublished = this.story.isOnline()

        this.cdRef.detectChanges()
    }

    protected initVideos(): void {
        if (!this.story!.videos) {
            return
        }

        const generatePreview = (video: Video): Video => ({
            ...video,
            preview: `${environment.medias.urlWebsiteVideo}${video.url}`,
        })

        const listVideos = this.story!.videos.filter(video => video.url).map(generatePreview)
        this.previewVideo = listVideos.find(video => Boolean(video.preview)) || null
    }

    protected addVideo(story: Story) {
        this.videoAdded.emit(story)
    }

    protected addImage(image) {
        this.mainImageAdded.emit(image)
    }
}
