import { gql } from '@apollo/client/core'

export const partnerFeedOverridesQuery = gql`
    query partnerFeedOverrides {
        partnerFeedOverrides {
            id
            slug
            title
            isActiveCms
        }
    }
`

export const partnerFeedOutputFormatsQuery = gql`
    query partnerFeedOutputFormats {
        partnerFeedOutputFormats {
            id
            slug
            title
        }
    }
`

export const partnerFeedTextFormatsQuery = gql`
    query partnerFeedTextFormats {
        partnerFeedTextFormats {
            id
            slug
            title
            order
        }
    }
`

export const partnerFeedVideoQualitiesQuery = gql`
    query partnerFeedVideoQualities {
        partnerFeedVideoQualities {
            id
            slug
            title
        }
    }
`

export const partnerFeedAddMediasQuery = gql`
    query partnerFeedAddMedias {
        partnerFeedAddMedias {
            id
            slug
            title
        }
    }
`

export const partnerFeedSponsoredStoriesQuery = gql`
    query partnerFeedSponsoredStories {
        partnerFeedSponsoredStories {
            id
            slug
            title
        }
    }
`

export const partnerFeedTypesQuery = gql`
    query partnerFeedTypes {
        partnerFeedTypes {
            id
            slug
            title
        }
    }
`

export const partnerFeedMostViewedCategoriesQuery = gql`
    query partnerFeedMostViewedCategories {
        partnerFeedMostViewedCategories {
            id
            slug
            title
        }
    }
`

export const partnerTypesQuery = gql`
    query partnerTypes {
        partnerTypes {
            id
            slug
            title
        }
    }
`
