import { Component, Input, OnInit } from '@angular/core'
import { User } from 'entities/user'
import { TagsModerationStatus } from 'enums/tags-moderation-status'
import { UserRight } from 'enums/user-role'
import { TagModel } from 'models/tag.model'
import { TranslationFilterPipe } from 'pipes/translation-filter.pipe'
import { TagsService } from 'services/tags/tags'
import { BaseAbstractComponent } from '../base.component'

@Component({
    selector: 'cms-dashboard-tags',
    templateUrl: './dashboard-tags.component.html',
    styleUrls: ['./dashboard-tags.component.scss'],
})
export class DashboardTagsComponent extends BaseAbstractComponent implements OnInit {
    @Input() contributor: User

    public numberOfUnmoderatedTags: number = 0
    public lastTags: { id: number; title: string; defaultTitle: string }[] = []
    public UserRight = UserRight

    constructor(private tagsService: TagsService, private translationFilterPipe: TranslationFilterPipe) {
        super()
    }

    public ngOnInit(): void {
        this.getNumberOfUnmoderatedTags()
        this.loadLastTags()
    }

    private loadLastTags(): void {
        const contributorLanguage = this.contributor.getDefaultLanguageIsoCode()

        this.tagsService
            .getAllTags(
                TagsModerationStatus.MODERATED,
                { limit: 10, offset: 0 },
                { key: 'moderatedAt', direction: 'DESC' },
            )
            .subscribe(tags => {
                this.lastTags = tags.results.map((tag: TagModel) => ({
                    id: tag.id,
                    title: this.translationFilterPipe.transform(tag.translations, 'title', contributorLanguage),
                    defaultTitle: this.translationFilterPipe.transform(tag.translations, 'title'),
                }))
            })
    }

    private getNumberOfUnmoderatedTags(): void {
        this.tagsService.getAllTags(TagsModerationStatus.PENDING).subscribe(tags => {
            this.numberOfUnmoderatedTags = tags.pagination.total
        })
    }
}
