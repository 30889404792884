/**
 * Returns a deep clone of an object or array using `JSON.parse(JSON.stringify())`.
 * This function should be used only for simple objects, because functions, dates, etc. will be lost.
 */
export function fastClone<T>(obj: T): T {
    if (!obj) {
        return obj
    }

    return JSON.parse(JSON.stringify(obj)) as T
}
