import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { ClrDatagridStateInterface } from '@clr/angular'
import { DashboardAbstract } from 'app/shared/dashboard/dashboard-abstract.component'
import { DASHBOARD_TYPE } from 'app/shared/dashboard/dashboard.enums'
import { DatagridAbstractFilterComponent } from 'app/shared/filter/datagrid-abstract-filter.component'
import {
    ContributorDatagridFiltersInterface,
    FilterableInterface,
} from 'app/shared/filter/datagrid-filterables/filterables.interface'
import { IAppConfig } from 'app/shared/i-app-config'
import { APP_CONFIG } from 'app/shared/shared.config'
import { DashboardSearchFiltersEvents, DeliverableFilterName, FilterKey } from 'enums/filters'
import { EventModel } from 'models/event.model'
import { combineLatest, Observable } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, take, takeUntil } from 'rxjs/operators'
import { AuthService } from 'services/auth/auth'
import { DashboardConstantsService } from 'services/dashboard/dashboard-constants.service'
import { EventsService } from 'services/dashboard/events-service'
import { DashboardFiltersListService } from 'services/dashboard/filters-list/dashboard-filters-list.service'
import { DashboardFilterHelper } from 'services/helpers/dashboard-filters.helper'
import { LanguagesService } from 'services/languages/languages'
import { VerticalService } from 'services/verticals/verticals.service'
import { decodeJSON } from 'tools/json-encoder'

@Component({
    selector: 'cms-dashboard-events',
    templateUrl: './dashboard-events.component.html',
    styleUrls: ['../dashboard.component.scss', './dashboard-events.component.scss'],
})
export class DashboardEventsComponent extends DashboardAbstract<DashboardSearchFiltersEvents> implements OnInit {
    dashboardType: DASHBOARD_TYPE = DASHBOARD_TYPE.EVENTS

    // Datagrid filters
    public programDeliverablesList: FilterableInterface[] = []
    public withoutLanguageList: FilterableInterface[] = [
        {
            id: 1,
            label: 'Without producer language',
            group: '',
        },
    ]

    public events: EventModel[] = []

    constructor(
        private cdr: ChangeDetectorRef,
        private elementRef: ElementRef,
        private eventsService: EventsService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected dashboardFiltersListService: DashboardFiltersListService,
        protected dashboardConstantsService: DashboardConstantsService,
        protected authService: AuthService,
        protected languagesService: LanguagesService,
        protected verticalsService: VerticalService,
        @Inject(APP_CONFIG) private sharedConfig: IAppConfig,
    ) {
        super(
            route,
            router,
            dashboardFiltersListService,
            dashboardConstantsService,
            authService,
            languagesService,
            verticalsService,
        )
        this.itemsPerPage = this.sharedConfig.itemPerPage.dashboardEvents
    }

    ngOnInit(): void {
        this.setDefaultFilterValues()

        // Load filters lists
        this.loadChannelsList()
        this.loadDesksList()
        this.loadCurrentContributorList()
        this.loadLanguagesList()
        this.loadStatusesList()

        combineLatest([
            // Load async filters lists
            this.loadProgramDeliverablesList(),
            this.loadProgramsList(),
            this.loadVerticalsList(),
        ])
            .pipe(take(1))
            .subscribe(() => {
                // Init search subscriptions
                this.initNextSearchSubscription()
                this.initRouterEventsSubscription()
                this.initRefreshSubscription(this.eventsService)
            })
    }

    /**
     * Trigger a new search when the toolbar deliverable filter is updated
     */
    public onDeliverableSelected(selectedDeliverable: FilterableInterface): void {
        selectedDeliverable.selected = !selectedDeliverable.selected

        const mappedFilter = this.dashboardConstantsService.DELIVERABLE_FILTERS_NAMES_MAPPING.find(
            item => item.id === selectedDeliverable.id,
        )

        this.programDeliverablesList = DashboardFilterHelper.updateSelectedValueInFilterList(
            selectedDeliverable,
            this.programDeliverablesList,
        )

        this.dashboardSearchFilters = DashboardFilterHelper.setDeliverableFilterValueInDashboardSearchFilters({
            deliverableFilter: selectedDeliverable,
            deliverableFilterKey: FilterKey.PROGRAM_DELIVERABLE_ID,
            dashboardSearchFilters: this.dashboardSearchFilters,
        })

        this.onDatagridFilterSelected(
            mappedFilter?.filterName || '',
            DashboardFilterHelper.buildFilterData(selectedDeliverable),
        )

        this.pageOffset = 0

        this.nextSearch.next()
    }

    public getRouterLink(event: EventModel): any[] | null {
        if (event.producerLanguage) {
            const story = event.stories.find((story: any) => event.producerLanguage?.id === story.language.id)
            if (story) {
                return ['/story', story.id]
            }
        }

        return null
    }

    protected loadStatusesList(): void {
        this.statusesList = this.dashboardFiltersListService.loadEventPyramidStatusFilterList()
    }

    /**
     * Get the dashboard search filters from the datagrid state
     */
    protected getDashboardSearchFiltersFromDatagrid(state: ClrDatagridStateInterface): DashboardSearchFiltersEvents {
        const filters = DatagridAbstractFilterComponent.getDatagridFilters(state) || {}
        this.setContributorDateFilter(this.FilterName.CREATION_DATE, filters)
        this.setContributorDateFilter(this.FilterName.LAST_UPDATE_DATE, filters)

        return DashboardFilterHelper.applyDatagridFiltersToSearchFiltersEvents(this.dashboardSearchFilters, filters)
    }

    /**
     * Toggle the languages filter to select all
     */
    protected toggleAllLanguages(): void {
        // Clone the languages list to trigger the change detection with the selected elements
        this.languagesList = this.languagesList.map(language => {
            return {
                ...language,
                selected: !this.checkedAllLanguages,
            }
        })
    }

    /**
     * Init the subscription to the nextSearch subject
     * to trigger the search when the filters are updated
     */
    private initNextSearchSubscription(): void {
        // Small debounce time to group the calls
        // when changing multiple filters at the same time
        const SIMULTANEOUS_FILTERS_DEBOUNCE_TIME = 1

        this.nextSearch
            .pipe(
                takeUntil(this.ngUnsubscribe),
                debounceTime(SIMULTANEOUS_FILTERS_DEBOUNCE_TIME),
                switchMap(() => this.reloadWithQueryParamFilters()),
            )
            .subscribe()
    }

    /**
     * Init the subscription to the router events
     * to trigger the search when the dashboard is loaded
     * using the query params
     */
    private initRouterEventsSubscription(): void {
        this.router.onSameUrlNavigation = 'reload'
        this.router.events
            .pipe(
                takeUntil(this.ngUnsubscribe),
                // Trigger only once per navigation
                filter(event => event instanceof NavigationEnd),
                // Trigger the search when the component is loaded the first time
                startWith(null),
                // Get the query params
                map(() => ({
                    page: this.route.snapshot.queryParams.eventsPage,
                    filters: this.route.snapshot.queryParams.eventsFilters,
                })),
                // Trigger when the query params change
                // Or when the dashboard needs to be reset (creation of new event)
                distinctUntilChanged(
                    (prev, curr) =>
                        !this.router.getCurrentNavigation()?.extras.state?.refreshDashboard &&
                        JSON.stringify(prev) === JSON.stringify(curr),
                ),
                switchMap(params => {
                    this.isLoading = true

                    if (!this.router.getCurrentNavigation()?.extras.state?.noScrollToTop) {
                        setTimeout(() => {
                            // Scroll to the top of the page when the datagrid is refreshed
                            // A timeout is needed to wait for the datagrid to be rendered so the scroll is not stopped
                            this.elementRef.nativeElement.parentElement.scrollTo({
                                top: this.elementRef.nativeElement.offsetTop,
                                left: 0,
                                behavior: 'smooth',
                            })
                        })
                    }

                    try {
                        const decodedFilters = decodeJSON<ContributorDatagridFiltersInterface>(params.filters)
                        this.onContributorFiltersSelected(decodedFilters)
                    } catch (_) {
                        this.clearAllFilters()
                    }

                    // Detect changes to trigger datagrid refresh and dashboardSearchFilters update
                    this.cdr.detectChanges()

                    // Set the page offset after detectChanges because the datagrid resets it on filter change
                    this.pageOffset = params.page ? (+params.page - 1) * this.itemsPerPage : 0

                    // On the first load, the total is 0,
                    // so we need to fake it so the page we try to reach exists
                    const totalNeededToDisplayPage = this.pageOffset + this.itemsPerPage
                    if (this.total < totalNeededToDisplayPage) {
                        this.total = totalNeededToDisplayPage
                    }

                    return this.eventsService.list({
                        filters: this.dashboardSearchFilters,
                        limit: this.itemsPerPage,
                        offset: this.pageOffset,
                        order: this.order,
                    })
                }),
            )
            .subscribe(result => {
                this.events = result.results
                this.total = result.pagination.total || 0
                this.isLoading = false
            })
    }

    private loadProgramDeliverablesList(): Observable<void> {
        const limit = 150
        const offset = 0
        this.dashboardFiltersListService.runProgramDeliverableListLoader(limit, offset)

        return this.dashboardFiltersListService.loadProgramDeliverablesList().pipe(
            takeUntil(this.ngUnsubscribe),
            map(deliverableList => {
                this.programDeliverablesList = deliverableList
            }),
        )
    }

    /**
     * This method is used in the Dashboard abstract class.
     * Here, we override it to apply the deliverable filters
     */
    protected loadToolbarFiltersList(contributorFilters: ContributorDatagridFiltersInterface): void {
        if (DashboardFilterHelper.isEmpty(contributorFilters)) {
            this.resetDeliverableFilter()
            this.clearAllFilters()

            return
        }
        this.applyToolbarFilters(contributorFilters)
        this.applyDeliverableFilters(contributorFilters)
    }

    private resetDeliverableFilter(): void {
        this.dashboardSearchFilters = DashboardFilterHelper.setFilterValue(
            this.dashboardSearchFilters,
            FilterKey.PROGRAM_DELIVERABLE_ID,
            null,
        )

        this.programDeliverablesList = this.programDeliverablesList.map(item => {
            item.selected = false

            return item
        })

        Object.values(DeliverableFilterName).forEach(currentDeliverableFilterName => {
            this.onDatagridFilterSelected(currentDeliverableFilterName, null)
        })
    }

    private applyDeliverableFilters(contributorFilters: ContributorDatagridFiltersInterface): void {
        this.programDeliverablesList = this.programDeliverablesList.map(deliverableFilter => {
            const currentFilterName =
                this.dashboardConstantsService.DELIVERABLE_FILTERS_NAMES_MAPPING.find(
                    item => item.id === deliverableFilter.id,
                )?.filterName || ''

            const isCurrentFilterIsActive = DashboardFilterHelper.filterIsActiveContributorFilter(
                currentFilterName,
                contributorFilters,
            )

            deliverableFilter.selected = isCurrentFilterIsActive

            this.dashboardSearchFilters = DashboardFilterHelper.setDeliverableFilterValueInDashboardSearchFilters({
                deliverableFilter,
                deliverableFilterKey: FilterKey.PROGRAM_DELIVERABLE_ID,
                dashboardSearchFilters: this.dashboardSearchFilters,
            })
            this.onDatagridFilterSelected(currentFilterName, DashboardFilterHelper.buildFilterData(deliverableFilter))

            return deliverableFilter
        })
    }
}
