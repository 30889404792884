import { environment } from 'environments/environment'

let cb = () => {}

if (!environment.production) {
    if (environment.images.staticProd === environment.images.static) {
        console.warn('check the environment : images static and staticProd are similar')
    } else {
        // pour les images ayant un attribut data-usefallbackperenv
        // qui sont en erreurs
        // et qui sont sur un environnement != prod
        // alors tenter de passer sur une url de prod
        // mais si c'est une url de prod, alors on bloque l'event
        cb = () => {
            document.addEventListener(
                'error',
                e => {
                    const img: any = e.target as any
                    if (
                        img &&
                        img.nodeName === 'IMG' &&
                        img.dataset.fallbackenv &&
                        img.src.startsWith(environment.images.static)
                    ) {
                        img.src = img.src.replace(environment.images.static, environment.images.staticProd)
                    }
                },
                // Capture phase
                true,
            )
        }
    }
}

export function initImgError() {
    cb()
}
