import './polyfills.ts'

import { enableProdMode, NgModuleRef, PlatformRef, TestabilityRegistry } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from './app'
import { environment } from './environments/environment'
import './tools/console'
import { initImgError } from './tools/image-error'

if (environment.production) {
    enableProdMode()
}

/**
 * Code below is a hack to avoid an increasing memory due to a memory leak in angular core referenced by issue :
 * https://github.com/angular/angular/issues/22106
 *
 * I know some console log are never displayed :)
 *
 * When the code will fix we can replace all the code below by this line :
 *
 * platformBrowserDynamic().bootstrapModule(AppModule).then(initImgError)
 *
 */
TestabilityRegistry.prototype['ngOnDestroy'] = function () {
    this._applications.clear()
}

// eslint-disable-next-line no-var
declare var window: any

// this has to bee the unique tag name of the angular app
const ROOT_NODE: string = 'cms-root'
let platform: PlatformRef | null

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((ref: NgModuleRef<AppModule>) => {
        console.log('NG: Bootstrapped. Watching for root node to be removed')
        // Watch the app's parent node for changes to its children. If anything
        // is removed check to see if the removed node is the application root
        // and if so destroy the platform and remove all the references on the window
        // that get left behind
        platform = ref.injector.get(PlatformRef)

        const observer = new MutationObserver((e: any) => {
            let shouldDestroy = false
            if (e[0].removedNodes.length > 0) {
                e[0].removedNodes.forEach((node: { nodeName: string }) => {
                    if (node.nodeName.toLowerCase() === ROOT_NODE) {
                        shouldDestroy = true
                    }
                })
            }

            if (shouldDestroy) {
                if (platform) {
                    platform.destroy()
                    platform = null
                }

                delete window.webpackJsonp
                delete window.frameworkStabilizers
                delete window.getAngularTestability
                delete window.getAllAngularTestabilities
                delete window.getAllAngularRootElements
                delete window.ng

                observer.disconnect()

                // remove all the nodes from the body just to simulate a blank page
                document.body.innerHTML = 'Blank page'
            }
        })

        const rootEl = document.getElementsByTagName(ROOT_NODE)
        if (rootEl.length && rootEl[0] && rootEl[0].parentNode) {
            const node: Node | null = rootEl[0].parentNode
            // comment this to see the difference
            if (node) {
                observer.observe(node, { childList: true })
            }
        }
    })
    .then(initImgError)
