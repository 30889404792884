import { gql } from '@apollo/client/core'
import { storyWithRelatedFragment } from '../find-by-id.query'

export const upsertTakeStoryOffline = gql`
    ${storyWithRelatedFragment}

    mutation unpublish($storyId: Int!) {
        unpublish(storyId: $storyId) {
            ...storyWithRelatedInfo
        }
    }
`
