<div class="c-stories-status">
    <clr-tooltip class="c-stories-status__info" *ngFor="let story of getStories()">
        <a [ngClass]="getStatusClass(story)" [routerLink]="['/story', story.id]" target="_blank">
            <span clrTooltipTrigger>{{ story.language.isoCode }}</span>
        </a>

        <clr-tooltip-content clrPosition="top-right" clrSize="md" class="background-tooltips-dashboard" *clrIfOpen>
            <span class="c-stories-status__tooltip-lang" clrTooltipTrigger>
                <strong>{{ story.language.fullname }}</strong>
            </span>

            <span class="c-stories-status__tooltip-status" [ngClass]="getStatusClass(story)">
                <strong>{{ publicationStatusLabelsById[story.publicationStatus!.id] }}</strong>
            </span>

            <span class="c-stories-status__tooltip-video videoStatus" [ngClass]="getStatusClass(story)">
                <strong>Video</strong>
            </span>
        </clr-tooltip-content>
    </clr-tooltip>
</div>
