import { gql } from '@apollo/client/core'

export const settingsQuery = gql`
    query aiSettings {
        aiSettings {
            id
            slug
            title
            systemInstructionsPrompt
            taskPrompt
            instructionsPrompt
            charLimitMax
            charLimitRecoMin
            charLimitRecoMax
            temperature
        }
    }
`

export const updateSettingsMutation = gql`
    mutation updateAISettings($aiSettings: AISettingsInput!) {
        updateAISettings(aiSettings: $aiSettings)
    }
`

export const generateMainHeadlineQuery = gql`
    query aiGenerateMainHeadline($storyId: Int!) {
        aiGenerateMainHeadline(storyId: $storyId)
    }
`

export const generateRunningTitleQuery = gql`
    query aiGenerateRunningTitle($mainHeadline: String!) {
        aiGenerateRunningTitle(mainHeadline: $mainHeadline)
    }
`

export const generateStoryTwitterTextQuery = gql`
    query aiGenerateStoryTwitterText($storyId: Int!) {
        aiGenerateStoryTwitterText(storyId: $storyId)
    }
`

export const generateSummaryQuery = gql`
    query aiGenerateSummary($storyId: Int!) {
        aiGenerateSummary(storyId: $storyId)
    }
`
