<div>
    <strong [ngClass]="getStatusClass()">
        {{ value.length }}
    </strong>
    <span>/{{ maxLength }}</span>
    <ng-container *ngIf="recommendedString || recommendedLength">
        <span class="recommended">
            Recommended: <strong>{{ recommendedString || recommendedLength }}</strong>
            <abbr title="characters">chars</abbr>
        </span>
    </ng-container>
</div>
