<cms-header-link link="/dashboard-galaxy" linkActive="active" data-test-e2e="header-navlink-dashboard">
    Dashboard
</cms-header-link>

<cms-header-link link="/dashboard" linkActive="active"> Dashboard Pyramid </cms-header-link>

<cms-header-link link="/story" linkActive="active" fake data-test-e2e="header-navlink-digital-publishing">
    Digital publishing
</cms-header-link>

<cms-header-link link="/general" linkActive="active" data-test-e2e="header-navlink-general"> General </cms-header-link>

<cms-header-link *ngIf="canAccessContents" link="/contents" linkActive="active" data-test-e2e="header-navlink-contents">
    Contents
</cms-header-link>

<cms-header-link *ngIf="canAccessDevs" link="/dev" linkActive="active" data-test-e2e="header-navlink-devs">
    Devs
</cms-header-link>
