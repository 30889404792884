import { SortInterface } from 'interfaces/mercury'
import { DaletVersionId } from './dalet'
import { LanguageIsoCode } from './language'

export enum FilterName {
    DESKS = 'desksFilter',
    PROGRAMS = 'programsFilter',
    VERTICALS = 'verticalsFilter',
    CONTRIBUTORS_COMPOSITE = 'contributorsCompositionFilter',
    CONTRIBUTORS = 'contributorsFilter',
    CURRENT_CONTRIBUTOR = 'currentContributorFilter',
    INDEXED = 'indexedFilter',
    PUBLICATION_STATUSES = 'publicationStatusesFilter',
    EVENT_PYRAMID_STATUSES = 'eventPyramidStatusesFilter',
    LANGUAGE_AND_WITH_VIDEO_COMPOSITE = 'languagesCompositionFilter',
    STORIES_WITH_VIDEO = 'storiesWithVideoFilter',
    LANGUAGES = 'languagesFilter',
    WITHOUT_PRODUCER_LANG = 'withoutProducerLangFilter',
    STORY_TYPES = 'storyTypesFilter',
    CHANNELS_AND_EVENTS_COMPOSITE = 'channelAndEventNameCompositionFilter',
    PROGRAMS_AND_VERTICALS_COMPOSITE = 'programsAndVerticalsCompositionFilter',
    EVENTS = 'eventsFilter',
    CHANNELS = 'channelsFilter',
    HEADLINES = 'headlineFilter',
    CREATION_DATE = 'Creation Date',
    PUBLICATION_DATE = 'Pub. Date',
    LAST_UPDATE_DATE = 'Last Update',
}

export enum DeliverableFilterName {
    DELIVERABLE_ARTICLE = 'article',
    DELIVERABLE_ARTICLE_AND_VIDEO = 'articleAndVideo',
    DELIVERABLE_LIVEBLOGGING = 'liveblogging',
    DELIVERABLE_MASTER = 'master',
    DELIVERABLE_PODCAST = 'podcast',
    DELIVERABLE_SUJET = 'sujet',
    DELIVERABLE_SUJET_WEB = 'sujetWeb',
    DELIVERABLE_SUJET_BRUSSELS = 'sujetBrussels',
    DELIVERABLE_SUJET_VIDEO = 'sujetVideo',
    DELIVERABLE_VIDEO = 'video',
    DELIVERABLE_WEB_BONUS = 'webBonus',
}

export enum ToolbarFilter {
    BREAKING_NEWS = 106,
    IS_MAGAZINE = 108,
    NEWS = 0,
    EMBARGO = 107,
}

export enum ToolbarFilterLabel {
    BREAKING_NEWS = 'Breaking news',
    IS_MAGAZINE = 'Magazine',
    NEWS = 'News',
    EMBARGO = 'Embargo',
}

export enum ToolbarFilterName {
    BREAKING_NEWS = 'breakingNews',
    IS_MAGAZINE = 'magazine',
    NEWS = 'news',
    EMBARGO = 'embargo',
    DELIVERABLE = 'deliverableFilter',
}

export enum FilterKey {
    DESK_ID = 'deskId',
    CONTRIBUTOR_ID = 'contributorId',
    LANG_ID = 'langId',
    WITHOUT_PRODUCER_LANG = 'eventsWithoutProducerLang',
    PROGRAM_ID = 'programId',
    VERTICAL_ID = 'verticalId',
    TYPE_ID = 'typeId',
    PUBLICATION_OWNER_ID = 'publicationOwnerId',
    CREATED_AT = 'createdAt',
    UPDATED_AT = 'updatedAt',
    PUBLISHED_AT = 'publishedAt',
    LAST_PUBLISHED_AT = 'lastPublishedAt',
    SEARCH = 'search',
    ASSIGN_TO = 'assignedTo',
    PUBLICATION_STATUS = 'publicationStatus',
    EVENT_PYRAMID_STATUS_ID = 'eventPyramidStatusId',
    WITH_VIDEO_ONLY = 'withVideoOnly',
    PROGRAM_DELIVERABLE_ID = 'programDeliverableId',
    EVENT_NAME = 'daletTitleName',
    BREAKING_NEWS = 'isBreakingNews',
    IS_INDEXED = 'isIndexed',
}

export enum FilterTypes {
    SELECT = 'select',
    CHECKBOX = 'checkbox',
    GROUPED = 'grouped',
    SEARCH = 'search',
}

export interface MappedFilterName {
    id: number
    label: string
    filterName: string
}

export interface BaseDashboardSearchFilters {
    daletVersionId?: DaletVersionId
    publicationOwnerId?: number | null
    assignedTo?: number[] | null
    langId?: number[] | null
    programId?: number | number[] | null
    verticalId?: number | number[] | null
    search?: string
    options: DashboardFiltersOptions[]
}

export interface DashboardSearchFilters extends BaseDashboardSearchFilters {
    daletTitleName?: string
    isBreakingNews?: boolean
    programDeliverableId?: number[] | null
    typeId?: number | null
    withVideoOnly?: boolean
    publishedAt?: Date | null
    lastPublishedAt?: Date | null
    publicationStatus?: number[] | null
    isIndexed?: boolean
}

export interface DashboardSearchFiltersEvents extends BaseDashboardSearchFilters {
    deskId?: number | number[] | null
    eventsWithoutProducerLang?: boolean
    eventsWithoutProducerLangStory?: boolean
    programDeliverableId?: number[] | null
    updatedAt?: Date | null
    createdAt?: Date | null
    eventPyramidStatusId?: number | number[] | null
}

export interface DashboardSearchFiltersStories extends BaseDashboardSearchFilters {
    deskId?: number | number[] | null
    typeId?: number | null
    withVideoOnly?: boolean
    publishedAt?: Date | null
    lastPublishedAt?: Date | null
    publicationStatus?: number[] | null
    isBreakingNews?: boolean
}

interface DashboardFiltersOptions {
    id: number
    value: boolean
}

export interface DashboardSearchParams<T> {
    filters: T
    limit: number
    offset: number
    order: SortInterface
}

export interface DashboardToolbar {
    displayedLanguages: LanguageIsoCode[]
    showSearch: boolean
    showPrograms: boolean
    showVerticals: boolean
    showStoryType: boolean
    showPublicationChannel: boolean
    langId: number[]
    searchOpt: number[]
}
