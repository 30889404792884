export enum ModalTitlePrefix {
    LOADING = 'Loading...',
    EDIT_EVENT = 'Edit Story',
}

export enum AlertMessage {
    UNEXPECTED_ERROR = 'Unexpected error happened, contact STI for more information.',
}

export enum FormControlNames {
    IS_BREAKING_NEWS = 'isBreakingNews',
    HAS_EMBARGO_DATE = 'hasEmbargoDate',
    EMBARGO_DATE = 'embargoDate',
    INSTRUCTIONS = 'instructions',
}
