import { gql } from '@apollo/client/core'
import { storyMutateFragment, storyTextFragment } from '../find-by-id.query'

export const upsertStoryText = gql`
    ${storyTextFragment}
    ${storyMutateFragment}

    mutation updateStoryText($storyId: Int!, $formatId: Int!, $text: String, $totalChars: Int) {
        updateStoryText(storyId: $storyId, formatId: $formatId, text: $text, totalChars: $totalChars) {
            totalChars
            texts {
                ...storyText
            }
            ...storyMutateInfos
        }
    }
`
