import gql from 'graphql-tag'
import { paginationFragment } from 'services/shared/pagination.fragment'
import { surveyLinkFragment } from './survey-link.fragment'

export const surveyLink = gql`
    ${surveyLinkFragment}

    query surveyLink($id: Int!) {
        surveyLink(id: $id) {
            ...surveyLinkInfo
        }
    }
`

export const lastSurveyLink = gql`
    ${surveyLinkFragment}

    query lastSurveyLink {
        lastSurveyLink {
            ...surveyLinkInfo
        }
    }
`

export const surveyLinks = gql`
    ${paginationFragment}
    ${surveyLinkFragment}

    query surveyLinks($options: QueryOptions) {
        surveyLinks(options: $options) {
            pagination {
                ...paginationInfo
            }
            results {
                ...surveyLinkInfo
            }
        }
    }
`

export const upsertSurveyLink = gql`
    ${surveyLinkFragment}

    mutation upsertSurveyLink($surveyLink: SurveyLinkInput!) {
        upsertSurveyLink(surveyLink: $surveyLink) {
            ...surveyLinkInfo
        }
    }
`
