export const categoriesFragment = `
fragment categoriesInfo on Category {
   id
   itemsCount
   childCount
   name
}
`

export const mediaInfosFragment = `
fragment mediaInfo on MediaInfo {
    id
    filesFullPath
    format {
     id
     name
     type
     patterns
     rate
     scale
     usedInSite
     alias
    }
}
`
export const categoryAssetFragment = `
${mediaInfosFragment}

fragment categoryAssetInfo on CategoryAsset {
    id
    type
    name
    fullTextRights
    rightsMusics
    withheld
    embargoedAfricanews
    isTV
    isDigital
    isThirdParty
    warrantyForTV
    warrantyForDigital
    warrantyForThirdParties
    mediaInfos {
        ...mediaInfo
    }
}
`
