import { gql } from '@apollo/client/core'
import { languageFragment } from '../languages/language.fragment'
import { contributorFragment } from './contributor.fragment'

export const upsertContributor = gql`
    ${languageFragment}
    ${contributorFragment}

    mutation upsertContributor($contributor: ContributorInput!) {
        upsertContributor(contributor: $contributor) {
            ...contributorInfo
        }
    }
`
