import {
    AfterContentChecked,
    AfterContentInit,
    ChangeDetectorRef,
    ContentChild,
    Directive,
    ElementRef,
    Input,
} from '@angular/core'
import { PRIMARY_OUTLET, Route, Router, RouterLink, RouterLinkWithHref, Routes, UrlSegmentGroup } from '@angular/router'
import { UserRight, UserRole } from 'enums/user-role'
import { AuthService } from 'services/auth/auth'

/**
 * Removes the element from the DOM if the user doesn't have access.
 * Access is controlled by the `role` and `right` inputs.
 * Without these inputs, the access is controlled by the route
 * of the first routerLink found in the children of that element.
 */
@Directive({
    selector: '[cmsIfAuthorized]',
})
export class IfAuthorizedDirective implements AfterContentInit, AfterContentChecked {
    @Input('userRole') public role: UserRole
    @Input('userRight') public right: UserRight

    @ContentChild(RouterLink) private readonly childRouterLink: RouterLink
    @ContentChild(RouterLinkWithHref) private readonly childRouterLinkWithHref: RouterLinkWithHref

    private linkUrl: string
    private isAuthorized: boolean | undefined

    constructor(
        private readonly elementRef: ElementRef,
        private readonly router: Router,
        private readonly authService: AuthService,
        private readonly cdr: ChangeDetectorRef,
    ) {}

    public ngAfterContentInit(): void {
        if (this.role || this.right) {
            this.setIsAuthorized({ role: this.role, right: this.right })

            return
        }

        const link = this.childRouterLink || this.childRouterLinkWithHref
        this.linkUrl = link?.urlTree?.toString() || ''

        this.setIsAuthorizedFromRouteCache()
        this.setIsAuthorizedFromRoute()
    }

    public ngAfterContentChecked(): void {
        if (this.isAuthorized === false) {
            this.elementRef.nativeElement.remove()
        }
    }

    private setIsAuthorized(config: { role?: UserRole; right?: UserRight; parent?: boolean }): void {
        // Save authorization so the element can be removed later during the lifecycle
        const hasAccess = this.authService.userHasAccess({ right: config.right, role: config.role })

        if (!config.parent || hasAccess === false) {
            this.isAuthorized = hasAccess
        }

        this.cdr.detectChanges()
    }

    private setIsAuthorizedFromRouteCache(): void {
        this.isAuthorized = this.authService.getRouteAuthorizationFromCache(this.linkUrl)
    }

    /**
     * Parse the router config, try to match the url to the routes.
     * On a match with a route or a parent route, check access to the route or its children,
     * And remove the element if the user does not have access.
     *
     * Works with basic routes, routes with a param, and routes with a matcher.
     */
    private setIsAuthorizedFromRoute(
        url: string = this.linkUrl,
        routes: Routes = this.router.config,
    ): Route | undefined {
        if (this.isAuthorized !== undefined) {
            return
        }

        // Remove the first / to match the url on the route path
        if (url[0] === '/') {
            url = url.slice(1)
        }

        for (const route of routes) {
            if (this.matchUrlWithRoutePath(url, route)) {
                return
            }

            const urlSegmentGroup = this.router.parseUrl(url).root.children[PRIMARY_OUTLET]
            if (!urlSegmentGroup) {
                continue
            }

            if (this.matchUrlWithRouteMatcher(urlSegmentGroup, route)) {
                return
            }

            if (urlSegmentGroup.segments.length <= 1) {
                continue
            }

            // Match a url with a multi level path
            const pathSegmentGroup = this.router.parseUrl(route.path || '').root.children[PRIMARY_OUTLET]
            if (!pathSegmentGroup) {
                continue
            }

            const matchingSegmentsCount = this.getMatchingSegmentsCount(urlSegmentGroup, pathSegmentGroup)

            if (!matchingSegmentsCount) {
                continue
            }

            if (matchingSegmentsCount === urlSegmentGroup.segments.length) {
                this.onMatchedRoute(route)

                return
            }

            // If the first segments of the url matches the route path,
            // try to match on this route's children.
            const routeChildren = this.getRouteChildren(route)
            if (!routeChildren) {
                continue
            }

            this.onMatchedParent(route)

            const childUrl = urlSegmentGroup.segments.slice(matchingSegmentsCount).join('/')
            this.setIsAuthorizedFromRoute(childUrl, routeChildren)

            return
        }
    }

    /**
     * Returns true on a direct match between url and route path
     */
    private matchUrlWithRoutePath(url: string, route: Route): boolean {
        if (route.path !== url) {
            return false
        }

        this.onMatchedRoute(route)

        return true
    }

    /**
     * Returns true if url matches the route's custom matcher function
     */
    private matchUrlWithRouteMatcher(urlSegmentGroup: UrlSegmentGroup, route: Route): boolean {
        if (!route.matcher || !route.matcher(urlSegmentGroup.segments, urlSegmentGroup, route)) {
            return false
        }

        this.onMatchedRoute(route)

        return true
    }

    /**
     * Returns the number of segments at the beginning of the url that match the route path.
     * Parameter segments of the route path are matched to any string at the same level of the url path.
     */
    private getMatchingSegmentsCount(urlSegmentGroup: UrlSegmentGroup, pathSegmentGroup: UrlSegmentGroup): number {
        const urlSegments = urlSegmentGroup.segments
        const pathSegments = pathSegmentGroup.segments

        let matchingSegmentsCount = 0

        // The url can only match if it is the same size as the route path,
        // and it can be a child only if it is longer
        if (urlSegments.length >= pathSegments.length) {
            for (let i = 0; i < pathSegments.length; i++) {
                if (
                    !urlSegments[i] ||
                    (urlSegments[i].path !== pathSegments[i].path && !pathSegments[i].path.includes(':'))
                ) {
                    break
                }

                matchingSegmentsCount++
            }
        }

        return matchingSegmentsCount
    }

    private onMatchedRoute(route: Route): void {
        if (!route.canActivate?.length) {
            return
        }

        this.setIsAuthorized({ role: route.data?.role, right: route.data?.right })

        if (this.isAuthorized !== undefined) {
            this.authService.addRouteAuthorizationToCache(this.linkUrl, this.isAuthorized)
        }
    }

    private onMatchedParent(route: Route): void {
        if (!route.canActivateChild?.length) {
            return
        }

        this.setIsAuthorized({ role: route.data?.role, right: route.data?.right, parent: true })

        if (this.isAuthorized === false) {
            this.authService.addRouteAuthorizationToCache(this.linkUrl, this.isAuthorized)
        }
    }

    private getRouteChildren(route: Route): Routes {
        // It's dirty to access an undocumented private property,
        // but it's the only way to make the directive work and keep the rest clean...
        return route.children || (route['_loadedRoutes']?.length && route['_loadedRoutes'][0].children)
    }
}
