import { gql } from '@apollo/client/core'
import { paginationFragment } from '../shared/pagination.fragment'
import { continentFragment, countryFragment, locationFragment } from './location.fragment'

export const continentsQuery = gql`
    ${paginationFragment}
    ${continentFragment}

    query continents($pagination: PaginationInput) {
        continents(options: { pagination: $pagination }) {
            results {
                ...continentInfo
            }
            pagination {
                ...paginationInfo
            }
        }
    }
`

export const countriesQuery = gql`
    ${paginationFragment}
    ${countryFragment}

    query countries($pagination: PaginationInput) {
        countries(options: { pagination: $pagination }) {
            results {
                ...countryInfo
            }
            pagination {
                ...paginationInfo
            }
        }
    }
`

export const countriesByContinentQuery = gql`
    ${countryFragment}

    query countriesByContinent($id: Int!) {
        countriesByContinent(id: $id) {
            results {
                ...countryInfo
            }
        }
    }
`

export const locationsQuery = gql`
    ${paginationFragment}
    ${locationFragment}

    query locations($pagination: PaginationInput) {
        locations(options: { pagination: $pagination }) {
            results {
                ...locationInfo
            }
            pagination {
                ...paginationInfo
            }
        }
    }
`
// for a superior limit we must change the mercury api first
// that limit the maximum pagination limit to 200
export const locationsByCountryQuery = gql`
    ${locationFragment}

    query locationsByCountry($id: Int!) {
        locationsByCountry(id: $id, options: { pagination: { limit: 200 } }) {
            results {
                ...locationInfo
            }
        }
    }
`
