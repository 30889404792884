import { Component, Input } from '@angular/core'
import { PublicationStatusLabelsById } from 'enums/publication-status'
import { Story } from '../../../entities/story'
import { Video } from '../../../models/media.model'
import { StoryModel } from '../../../models/story.model'

@Component({
    selector: 'cms-stories-status',
    templateUrl: './stories-status.component.html',
    styleUrls: ['./stories-status.component.scss'],
})
export class StoriesStatusComponent {
    @Input() story: Story

    public publicationStatusLabelsById = PublicationStatusLabelsById

    constructor() {}

    public getStories(): StoryModel[] {
        if (!this.story || !this.story.event) {
            return []
        }

        return this.story.event.stories || []
    }

    /**
     * Chjeck if the entity as an attached video
     * @returns {boolean}
     */
    protected hasVideo(story: StoryModel): boolean {
        if (!story.videos || story.videos.length < 1) {
            return false
        }

        const found = story.videos.find((el: Video) => {
            return Boolean(el.url)
        })

        return typeof found !== 'undefined'
    }

    protected isCurrentLanguage(story: StoryModel) {
        return this.story.language.isoCode === story.language.isoCode
    }

    /**
     * Return the status class name to switch status colo
     *
     * @param story
     * @returns {string}
     */
    public getStatusClass(story: StoryModel): string {
        const classStatus = ['storyStatus']

        if (story && story.publicationStatus && story.publicationStatus.slug) {
            classStatus.push('storyStatus--' + story.publicationStatus.slug)
        } else {
            classStatus.push('storyStatus--todo')
        }

        if (this.hasVideo(story)) {
            classStatus.push(' storyStatus--video-ok')
        } else {
            classStatus.push(' storyStatus--video-no')
        }

        if (this.isCurrentLanguage(story)) {
            classStatus.push(' storyStatus--selected')
        }

        return classStatus.join(' ')
    }
}
