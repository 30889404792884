import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core'
import { DatagridAbstractFilterComponent } from 'app/shared/filter/datagrid-abstract-filter.component'
import * as dayjs from 'dayjs'
import { DatagridFilterInterface } from '../datagrid-filterables/filterables.interface'

export type DatagridDateRange = [Date, Date]
@Component({
    selector: 'cms-datagrid-date-range-filter',
    templateUrl: './datagrid-date-range-filter.component.html',
    styleUrls: ['./datagrid-date-range-filter.component.scss'],
    providers: [
        {
            provide: DatagridDateRangeFilterComponent,
            useExisting: forwardRef(() => DatagridDateRangeFilterComponent),
        },
    ],
})
export class DatagridDateRangeFilterComponent
    extends DatagridAbstractFilterComponent<DatagridDateRange | undefined>
    implements OnChanges
{
    @Input() dateField?: string
    @Input() contributorFilter: DatagridFilterInterface

    ngOnChanges(changes: SimpleChanges): void {
        // When a contributor filter has been activated
        if (changes.contributorFilter?.currentValue) {
            this.value = changes.contributorFilter.currentValue.data
        }

        // When a contributor filter has been removed
        if (
            changes.contributorFilter &&
            !changes.contributorFilter?.currentValue &&
            !changes.contributorFilter?.firstChange
        ) {
            delete this.value
        }

        // Whenever a contributor filter has been changed : activated or removed
        if (changes.contributorFilter && !changes.contributorFilter.firstChange) {
            this.filter()
        }
    }

    protected customIsActive(): boolean {
        return this.value !== undefined
    }

    protected prepareValue() {}

    accepts(item: any): boolean {
        // Filter client side only if a date field is passed and date range is defined
        if (!this.dateField || !this.value?.length) {
            return true
        }

        const itemDate = dayjs(item[this.dateField] ?? null)
        const from = dayjs(this.value[0] ?? null).startOf('day')
        const to = dayjs(this.value[1] ?? null).endOf('day')

        const isAfterFrom = from.isValid() && !itemDate.isBefore(dayjs(from))
        const isBeforeOptionalTo = !to.isValid() || (to.isValid() && !itemDate.isAfter(to))

        return itemDate.isValid() && isAfterFrom && isBeforeOptionalTo
    }

    resetValue() {
        this.value = undefined
        this.filter()
    }
}
