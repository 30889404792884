import { Injectable } from '@angular/core'
import { gql } from '@apollo/client/core'
import { Apollo } from 'apollo-angular'
import { BehaviorSubject } from 'rxjs'
import { filter } from 'rxjs/operators'
import { cacheAndNetworkQuery } from 'tools/apollo'
import { ResultPaginationInterface, SortInterface } from '../../interfaces/mercury'
import { ProgramDeliverableModel } from '../../models/program-deliverable.model'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { getQueryOptions } from '../shared/options.query'
import { paginationFragment } from '../shared/pagination.fragment'

export const listQuery = gql`
    ${paginationFragment}

    query programDeliverableList($isActive: Boolean, $options: QueryOptions) {
        programDeliverables(isActive: $isActive, options: $options) {
            pagination {
                ...paginationInfo
            }
            results {
                id
                slug
                title
                isActive
                format
            }
        }
    }
`

interface ProgramDeliverablesResult {
    pagination: ResultPaginationInterface
    results: ProgramDeliverableModel[]
}

@Injectable()
export class ProgramDeliverableListService extends BaseService {
    protected _programDeliverables: BehaviorSubject<ProgramDeliverableModel[]> = new BehaviorSubject([])
    public programDeliverables = this._programDeliverables
        .asObservable()
        .pipe(filter(programDeliverableModel => Boolean(programDeliverableModel.length)))

    protected _pagination: BehaviorSubject<ResultPaginationInterface | undefined> = new BehaviorSubject(undefined)
    public pagination = this._pagination.asObservable().pipe(filter(pagination => Boolean(pagination !== undefined)))

    constructor(apollo: Apollo, errorMessageService: ErrorsMessagesService) {
        super(apollo, errorMessageService)
    }

    get(
        search: {
            isActive?: boolean
        },
        limit: number,
        offset: number,
        order?: SortInterface,
    ) {
        const variables = {
            options: getQueryOptions(limit, offset, order),
            ...search,
        }

        return cacheAndNetworkQuery(
            this.apollo,
            {
                query: listQuery,
                variables,
            },
            ['data'],
        ).subscribe(
            (data: { programDeliverables: ProgramDeliverablesResult }) => {
                const pagination = data.programDeliverables.pagination

                this._programDeliverables.next(data.programDeliverables.results)
                this._pagination.next(pagination)
            },
            error => {
                // returned an empty programDeliverables' array
                this._programDeliverables.next([])
                // add an error
                this.errorMessagesServices.addErrorsMessage(error)
            },
        )
    }
}
