import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter } from 'rxjs/operators'
import { networkQuery } from 'tools/apollo'
import { WorkflowModel } from '../../models/workflow.model'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { workflowQuery } from './workflow.query'

@Injectable()
export class WorkflowService extends BaseService {
    /**
     *
     * @type BehaviorSubject<WorkflowModel>
     */
    protected _workflow: BehaviorSubject<WorkflowModel> = new BehaviorSubject({} as WorkflowModel)

    /**
     * @type Observable<WorkflowModel>
     */
    public workflow: Observable<WorkflowModel> = this._workflow.asObservable().pipe(filter(value => !!value))

    /**
     *
     * @param apollo
     */
    constructor(apollo: Apollo, errorsMessagesService: ErrorsMessagesService) {
        super(apollo, errorsMessagesService)
    }

    /**
     *
     * @param pagination
     * @returns {Observable<any>}
     */
    getWorkflow(inputId: number, inputVersion: number) {
        // reinit workflow
        this._workflow.next({} as WorkflowModel)
        networkQuery(
            this.apollo,
            {
                query: workflowQuery,
                variables: {
                    inputId,
                    inputVersion,
                },
            },
            ['data'],
        ).subscribe(
            (data: { workflow }) => {
                this._workflow.next(data.workflow)
            },
            error => {
                // returned an empty workflow' array
                this._workflow.next({} as WorkflowModel)
                // add an error
                this.errorMessagesServices.addErrorsMessage(error)
            },
        )
    }
}
