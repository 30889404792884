export const languageFragment = `
fragment languageInfo on Language {
    id
    fullname
    isoCode
    daletLocale
    isRTL
    isActive
    isUsingEnSlug
    websiteSubdomain
    collateLocale
    priority
    collateLocaleCMS
    hasLive
    isCoreLanguage
}
`
