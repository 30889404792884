import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core'
import { Story } from 'entities/story'
import { LanguageIsoCode } from 'enums/language'
import { takeUntil } from 'rxjs/operators'
import { EventModel } from '../../../../models/event.model'
import { EventService } from '../../../../services/events/event'
import { BaseAbstractComponent } from '../../../shared/base.component'

@Component({
    selector: 'cms-dashboard-galaxy-edition-detail',
    templateUrl: './dashboard-galaxy-edition-detail.component.html',
    styleUrls: [
        '../../dashboard-galaxy.component.scss',
        '../dashboard-galaxy-edition.component.scss',
        './dashboard-galaxy-edition-detail.component.scss',
    ],
})
export class DashboardGalaxyEditionDetailComponent extends BaseAbstractComponent implements AfterViewInit, OnDestroy {
    @Input() story: Story

    public loading: boolean = true
    public LanguageIsoCode = LanguageIsoCode

    constructor(protected eventService: EventService) {
        super()
    }

    ngAfterViewInit() {
        if (!this.story.event) {
            return
        }

        this.eventService
            .getEvent(this.story.event.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((event: EventModel) => {
                if (event) {
                    this.story.event.stories = event.stories
                }
                this.loading = false
            })
    }

    ngOnDestroy() {
        super.ngOnDestroy()
    }
}
