import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { Breakpoint } from '../../enums/breakpoint'

@Injectable()
export class BreakpointService {
    protected _bp: BehaviorSubject<string> = new BehaviorSubject('')
    public bp: Observable<string> = this._bp.asObservable()
    protected defaultBreakpoint: string = Breakpoint[Breakpoint.xlarge]

    breakpointList: Map<number, string> = new Map([
        [1440, 'xxlarge'],
        [1200, 'xlarge'],
        [1024, 'large'],
        [640, 'medium'],
        [0, 'small'],
    ])

    constructor() {
        this.setBp(this.defaultBreakpoint)
    }

    setBp(bp?: string) {
        this._bp.next(this.getBpName(bp ? bp : ''))
    }

    setBpByViewportWidth(width: number) {
        for (const [key, value] of this.breakpointList.entries()) {
            if (width >= key) {
                this.setBp(value)
                break
            }
        }
    }

    protected getBpName(bp?: string) {
        const root = 'breakpoint--'
        let className = ''

        switch (bp) {
            case Breakpoint[Breakpoint.xlarge]:
            case Breakpoint[Breakpoint.large]:
            case Breakpoint[Breakpoint.medium]:
            case Breakpoint[Breakpoint.small]:
                className = bp
                break
            default:
                className = this.defaultBreakpoint
                console.warn('Unknown breakpoint detected: ' + bp)
        }

        return root + className
    }
}
