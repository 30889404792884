import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { EventEnglishGuideModel } from 'models/event-english-guide.model'
import { Observable, of } from 'rxjs'
import { catchError, pluck, switchMap } from 'rxjs/operators'
import { MutationResult } from '../../interfaces/query-result'
import { getEventEnglishGuide, generateEventEnglishGuide } from './event-english-guide.query'

@Injectable()
export class EventEnglishGuideService {
    constructor(protected apollo: Apollo) {}

    getEventEnglishGuide(eventId: number): Observable<EventEnglishGuideModel> {
        return this.apollo
            .query({
                query: getEventEnglishGuide,
                variables: {
                    eventId,
                },
            })
            .pipe(pluck('data'), pluck('getEventEnglishGuide'))
    }

    generateEventEnglishGuide(eventId: number): Observable<MutationResult> {
        return this.apollo
            .mutate({
                mutation: generateEventEnglishGuide,
                variables: {
                    eventId,
                },
            })
            .pipe(
                switchMap(() => of({ res: true })),
                catchError(err => {
                    return of({ res: false, msg: err })
                }),
            )
    }
}
