import { Injectable } from '@angular/core'
import { cloneDeep } from '@apollo/client/utilities'
import { CompleterItem } from 'ng2-completer'
import { Subject } from 'rxjs'
import { map } from 'rxjs/operators'
import { LanguagesService } from 'services/languages/languages'
import { TagsModerationStatus, TagsSelectableStatus } from '../../enums/tags-moderation-status'
import { TagModel } from '../../models/tag.model'
import { TagsAutocompleteByTitleAndLanguageService } from '../tags/autocomplete-by-title-and-language'

// @todo implements CompleterData disabled coz since Typescript 2.4* there is some crappy misconception that are thrown by the linter
// and ng2completer has huge error : an interface that extends a class WTF !!!
@Injectable()
export abstract class TagsData extends Subject<CompleterItem[]> /* implements CompleterData */ {
    type: string
    languageId: number
    tagsStatus: TagsModerationStatus
    selectableStatus: TagsSelectableStatus

    constructor(
        protected tagService: TagsAutocompleteByTitleAndLanguageService,
        protected languagesService: LanguagesService,
    ) {
        super()

        this.languageId = this.languagesService.defaultLanguage.id
    }

    public search(term: string): void {
        const termToFind = term.toLowerCase()
        this.tagService.tagsAutocomplete[this.type]
            .pipe(
                map(t => cloneDeep(t)),
                map((tags: TagModel[]) => {
                    if (!tags || !tags.length) {
                        return []
                    }

                    return tags.map((tag: TagModel) => {
                        const translation = tag.translations.filter(
                            tagTranslation => tagTranslation.language.id === this.languageId,
                        )[0]
                        tag.translations = [translation]

                        return {
                            title: translation && translation.title ? translation.title : '',
                            originalObject: tag,
                        }
                    })
                }),
            )
            .subscribe(matches => {
                this.next(matches)
            })

        this.tagService.getTagByTitleAndLanguageAutoComplete(
            termToFind,
            this.languageId,
            this.tagsStatus,
            this.selectableStatus,
            this.type,
            {
                offset: 0,
                limit: 100,
            },
        )
    }

    public cancel() {
        // Handle cancel
    }
}

@Injectable()
export class StoryTagsData extends TagsData {
    constructor(
        protected tagService: TagsAutocompleteByTitleAndLanguageService,
        protected languagesService: LanguagesService,
    ) {
        super(tagService, languagesService)
        this.tagsStatus = TagsModerationStatus.MODERATED
        this.selectableStatus = TagsSelectableStatus.ALL
        this.type = 'tags'
    }
}

@Injectable()
export class StoryMainTagsData extends TagsData {
    constructor(
        protected tagService: TagsAutocompleteByTitleAndLanguageService,
        protected languagesService: LanguagesService,
    ) {
        super(tagService, languagesService)
        this.tagsStatus = TagsModerationStatus.MODERATED
        this.selectableStatus = TagsSelectableStatus.SELECTABLE
        this.type = 'main'
    }
}

@Injectable()
export class AllTagsData extends TagsData {
    constructor(
        protected tagService: TagsAutocompleteByTitleAndLanguageService,
        protected languagesService: LanguagesService,
    ) {
        super(tagService, languagesService)
        this.tagsStatus = TagsModerationStatus.ALL
        this.selectableStatus = TagsSelectableStatus.ALL
        this.type = 'tags'
    }
}
