import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { Observable, of } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { cacheAndNetworkQuery } from 'tools/apollo'
import { YoutubeCategoriesModel } from '../../models/youtube-categories.model'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { youtubeCategoriesQuery } from './youtube-categories.query'

@Injectable()
export class YoutubeCategoriesService extends BaseService {
    protected _youtubeCategories: YoutubeCategoriesModel[] = []

    constructor(apollo: Apollo, errorsMessagesService: ErrorsMessagesService) {
        super(apollo, errorsMessagesService)
    }

    public fetchYoutubeCategories(): Observable<YoutubeCategoriesModel[]> {
        if (this.youtubeCategories.length !== 0) {
            return of(this.youtubeCategories)
        }

        return cacheAndNetworkQuery<YoutubeCategoriesModel[]>(
            this.apollo,
            {
                query: youtubeCategoriesQuery,
            },
            ['data', 'youtubeCategories'],
        ).pipe(
            tap(data => (this._youtubeCategories = data)),
            catchError(this.handleError<YoutubeCategoriesModel[]>()),
        ) as Observable<YoutubeCategoriesModel[]>
    }

    public get youtubeCategories(): YoutubeCategoriesModel[] {
        return this._youtubeCategories
    }

    public set youtubeCategories(v: YoutubeCategoriesModel[]) {
        this._youtubeCategories = v
    }

    private handleError<T>() {
        return (error: any): Observable<T> => {
            // Let the app keep running by returning an empty result.
            throw error
        }
    }
}
