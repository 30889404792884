import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { Story } from 'entities/story'
import { StoryModel } from '../../../models/story.model'

@Component({
    selector: 'cms-indexation-status',
    templateUrl: './indexation-status.component.html',
    styleUrls: ['./indexation-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndexationStatusComponent {
    @Input() displayPublicationChannels = false

    private _story: Story

    @Input()
    set story(story: StoryModel) {
        this._story = new Story(story)
    }

    get story(): Story {
        return this._story
    }

    isPublishedOnChannel(story: Story, channel: string) {
        return story.publicationChannels?.some(pc => pc.labelShort === channel)
    }
}
