import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { QueryResultWithPaginationInterface, QueryOptionsInterface } from 'interfaces/mercury'
import { PushMessageSearchOption } from 'interfaces/push-messages'
import { PushMessageModel } from 'models/push-message.model'
import { Observable } from 'rxjs'
import { catchError, filter, pluck } from 'rxjs/operators'
import { networkQuery } from 'tools/apollo'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { deleteScheduledPushMessageQuery, pushMessagesQuery } from './push-messages.query'

@Injectable()
export class PushMessagesService extends BaseService {
    constructor(apollo: Apollo, errorsMessagesService: ErrorsMessagesService) {
        super(apollo, errorsMessagesService)
    }

    public getPushNotifications(
        search: PushMessageSearchOption,
        options: QueryOptionsInterface,
    ): Observable<QueryResultWithPaginationInterface<PushMessageModel>> {
        return networkQuery(
            this.apollo,
            {
                query: pushMessagesQuery,
                variables: {
                    search,
                    options,
                },
            },
            ['data', 'pushMessages'],
        )
    }

    public deleteScheduledPushMessage(pushMessage: PushMessageModel): Observable<boolean> {
        return this.apollo
            .mutate<{ deleteScheduledPushMessage: boolean }>({
                mutation: deleteScheduledPushMessageQuery,
                variables: { pushMessageId: pushMessage.id },
            })
            .pipe(
                pluck('data', 'deleteScheduledPush'),
                catchError(this.handleError<boolean>()),
                filter((res: boolean) => res),
            )
    }

    private handleError<T>() {
        return (error: any): Observable<T> => {
            // Let the app keep running by returning an empty result.
            throw error
        }
    }
}
