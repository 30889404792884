import { gql } from '@apollo/client/core'
import { paginationFragment } from '../shared/pagination.fragment'
import { ownerFragment } from './publication-owner.fragment'

export const publicationOwnerQuery = gql`
    ${paginationFragment}
    ${ownerFragment}

    query publicationOwners($pagination: PaginationInput) {
        publicationOwners(options: { pagination: $pagination }) {
            results {
                ...ownerInfo
            }
            pagination {
                ...paginationInfo
            }
        }
    }
`
