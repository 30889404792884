<clr-modal
    *ngIf="searchOptions"
    class="c-advanced-search__result"
    [clrModalOpen]="true"
    (clrModalOpenChange)="closeModal()"
    [clrModalStaticBackdrop]="true"
    [clrModalClosable]="true"
    [clrModalSize]="'lg'">
    <h3 *ngIf="this.searchOptions.options.search !== ''; else other_content" class="modal-title">
        Search results for &#8220;{{ this.searchOptions.options.search }}&#8221;
    </h3>
    <ng-template #other_content>
        <h3 class="modal-title">Search results</h3>
    </ng-template>
    <div class="modal-body">
        <cms-alert-item *ngIf="errorMessage !== ''" [alertMessage]="errorMessage" [alertType]="'danger'">
        </cms-alert-item>

        <clr-datagrid
            *ngIf="errorMessage === ''"
            class="modal-datagrid no-header"
            (clrDgRefresh)="refresh($event)"
            [clrDgLoading]="loadingResults">
            <clr-dg-placeholder>Yikes&#8230; we couldn't find any stories that match the query!</clr-dg-placeholder>

            <clr-dg-row *ngFor="let result of searchResults">
                <clr-dg-cell [routerLink]="['', { outlets: { primary: ['story', result.id], modal: null } }]">
                    <cms-story-vignette [story]="result" [displayType]="vignetteDisplayTypes.List"></cms-story-vignette>
                </clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer class="modal-footer">
                <clr-dg-pagination
                    #articleHeaderPagination
                    [clrDgPageSize]="searchOptions.limit"
                    [clrDgTotalItems]="paginationTotal"
                    [clrDgPage]="searchOptions.offset / searchOptions.limit + 1">
                    {{ articleHeaderPagination.firstItem + 1 }} - {{ articleHeaderPagination.lastItem + 1 }} of
                    {{ paginationTotal }} stories
                </clr-dg-pagination>
            </clr-dg-footer>
        </clr-datagrid>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeModal()">Close</button>
    </div>
</clr-modal>
