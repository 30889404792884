export enum StoryPublicationReplicatedFields {
    PUBLICATION = 'PUBLICATION',
}

export enum StoryRelatedReplicatedFields {
    RELATED = 'RELATED',
}

export enum StoryTagsAndMetadataReplicatedFields {
    TAGS = 'TAGS',
}

export enum StorySettingsReplicatedFields {
    SPONSOR = 'SPONSOR',
    STORY_TYPE = 'STORY_TYPE',
}

export enum StoryMainHeaderReplicatedFields {
    IMAGE = 'IMAGE',
}

export enum StoryContributorsReplicatedFields {
    PRODUCER = 'PRODUCER',
    VIDEO_EDITOR = 'VIDEO_EDITOR',
}

export type StoryReplicatedFields =
    | StoryMainHeaderReplicatedFields
    | StorySettingsReplicatedFields
    | StoryTagsAndMetadataReplicatedFields
    | StoryRelatedReplicatedFields
    | StoryPublicationReplicatedFields
    | StoryContributorsReplicatedFields
