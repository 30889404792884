<div class="c-contributor_filters_wrapper">
    <section
        class="c-contributor_filters qa-contributor_filters"
        *ngIf="dashboardHasFilters() || contributorFilters.length">
        <h3>My favorites filters:</h3>
        <div class="c-contributor_filters_labels">
            <a
                *ngFor="let contributorFilter of contributorFilters"
                (click)="activateSelectedFilter(contributorFilter)"
                [ngClass]="{ active: activeContributorFilter === contributorFilter.id }"
                class="label contributor-filter set-contributor-filter tooltip"
                role="tooltip"
                aria-haspopup="true">
                <clr-icon shape="star" class="is-solid is-blue" size="14"></clr-icon>
                {{ contributorFilter.label }}
                <span
                    class="badge delete is-blue"
                    (click)="removeContributorFilter(contributorFilter.id); $event.stopPropagation()">
                    <clr-icon shape="close" size="12"></clr-icon>
                </span>
                <span class="tooltip-content">
                    <ul>
                        <li *ngFor="let filter of parseFilters(contributorFilter) | keyvalue">
                            <span>{{ filter.value.label }}</span>
                        </li>
                    </ul>
                </span>
            </a>
            <a
                *ngIf="dashboardHasFilters() && !activeContributorFilter"
                class="label contributor-filter add-contributor-filter tooltip"
                [ngClass]="{ open: openFormState }"
                aria-haspopup="true">
                <input
                    #newFilterInput
                    type="text"
                    name="newFilterName"
                    class="form-control"
                    maxlength="80"
                    placeholder="Add a filter"
                    autocomplete="off"
                    [(ngModel)]="newFilterName"
                    [disabled]="disableAddfilterButton"
                    (keyup.enter)="submit()" />
                <span class="badges-container badge">
                    <span class="badge submit is-blue" (click)="submit()">
                        <clr-icon [attr.shape]="newFilterName ? 'check' : 'close'" size="12"></clr-icon>
                    </span>
                    <span class="badge add is-blue" (click)="openForm()">
                        <clr-icon shape="plus" size="12"></clr-icon>
                    </span>
                </span>
            </a>
        </div>
        <div class="active-filters" *ngIf="(activeFilters | keyvalue).length">
            <span
                class="label contributor-filter active-contributor-filter"
                *ngFor="let filter of activeFilters | keyvalue">
                <a (click)="removeActiveFilter(filter.key)">
                    <clr-icon shape="close" class="contributor-filter__close-icon" size="12"></clr-icon>
                </a>
                <div role="tooltip" aria-haspopup="true" class="tooltip tooltip-bottom-right">
                    <span>
                        <strong>{{ filter.value.filterTitle ? filter.value.filterTitle + ':' : '' }}</strong>
                        {{ filter.value.label }}
                    </span>
                </div>
            </span>
            <a
                class="label contributor-filter active-contributor-filter clear-contributor-filters"
                (click)="clearAll()">
                <clr-icon shape="trash" size="12"></clr-icon>
                <span>Clear all</span>
            </a>
        </div>
    </section>
</div>
