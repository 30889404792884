import { AfterContentInit, Component, ContentChildren, OnDestroy, QueryList } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { DatagridAbstractFilterComponent } from '../datagrid-abstract-filter.component'
import { FilterableInterface } from '../datagrid-filterables/filterables.interface'

@Component({
    selector: 'cms-datagrid-composite-filter',
    templateUrl: './datagrid-composite-filter.component.html',
    styleUrls: ['./datagrid-composite-filter.component.scss'],
})
export class DatagridCompositeFilterComponent
    extends DatagridAbstractFilterComponent<any[]>
    implements AfterContentInit, OnDestroy
{
    @ContentChildren('injectedFilters')
    injectedFilters: QueryList<DatagridAbstractFilterComponent<FilterableInterface[]>>

    private destroy$ = new Subject()

    constructor() {
        super()
        this.value = []
    }

    customIsActive(): boolean {
        return this.value.length > 0
    }

    ngAfterContentInit(): void {
        // Use to react on activate filters coming from db (simulating composite APPLY the click button)
        this.injectedFilters.toArray().forEach(child => {
            child.changes.pipe(takeUntil(this.destroy$)).subscribe(filterChange => {
                if (child.value?.length) {
                    const filterExistInValue = this.value.some(filter => filter.filterName === child.filterName)
                    if (filterExistInValue) {
                        this.value.forEach(filter => {
                            if (filter.filterName === child.filterName) {
                                filter.value = child.value
                            }

                            return filter
                        })
                    } else {
                        this.value.push(child)
                    }
                } else {
                    this.value = this.value.filter(item => item.filterName !== child.filterName)
                }

                this.changes.emit(filterChange)
            })
        })
    }

    prepareValue() {
        this.injectedFilters.forEach(filter => {
            if (filter.isActive()) {
                this.value.push(filter)
            }
            filter.filter()
        })
    }

    resetValue() {
        this.value = []
        this.injectedFilters.forEach(filter => {
            filter.resetValue()
        })
        this.filter()
    }

    /**
     * Return the filter value as an object with a property 'filterName' with the value of the filter
     *
     * @return {{}}
     */
    getFilter(): Record<string, any> {
        const ret = {}
        ret[this.filterName] = {}
        this.value.forEach(filter => {
            ret[this.filterName] = Object.assign(ret[this.filterName], filter.getFilter())
        })

        return ret
    }

    ngOnDestroy() {
        this.destroy$.next()
        this.destroy$.complete()
    }
}
