import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { StorySearchUniqueFormId } from 'enums/story-search'
import { AdvancedStorySearchService } from 'services/story/advanced-search/query'
import { encodeJSON } from 'tools/json-encoder'
import { AdvancedSearchBoxData, AdvancedSearchOptions } from '../../../models/advanced-search.model'
import { BaseAbstractComponent } from '../base.component'

@Component({
    selector: 'cms-header-search',
    templateUrl: './header-search.component.html',
    styleUrls: ['./header-search.component.scss'],
})
export class HeaderSearchComponent extends BaseAbstractComponent {
    public options: AdvancedSearchOptions = {
        display: {
            uniqueFormId: StorySearchUniqueFormId.MAIN_HEADER,
            contributor: true,
            publicationOwner: true,
            language: true,
            dateRange: true,
            program: true,
            placeholder: 'Search the article archive…',
            theme: true,
            withVideoOnly: true,
            vertical: true,
            tag: true,
        },
    }

    constructor(private advancedStorySearchService: AdvancedStorySearchService, private router: Router) {
        super()
        this.options.defaults = this.advancedStorySearchService.getCachedSearchOptions(
            StorySearchUniqueFormId.MAIN_HEADER,
        )
    }

    public lookup(searchData: AdvancedSearchBoxData) {
        this.router.navigate([{ outlets: { modal: ['search', encodeJSON({ options: searchData })] } }])
    }
}
