import { gql } from '@apollo/client/core'
import { languageFragment } from '../languages/language.fragment'
import { contributorFragment } from './contributor.fragment'

export const findByIdQueryAdmin = gql`
    ${languageFragment}
    ${contributorFragment}

    query contributorById($id: Int!) {
        contributor(id: $id) {
            ...contributorInfo
            acl {
                roles
                rights
                languages
            }
        }
    }
`

export const findByIdQuery = gql`
    ${languageFragment}
    ${contributorFragment}

    query contributorById($id: Int!) {
        contributor(id: $id) {
            ...contributorInfo
            acl {
                languages
            }
        }
    }
`
