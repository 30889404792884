import { ApolloQueryResult, QueryOptions, WatchQueryOptions } from '@apollo/client/core'
import { cloneDeep } from '@apollo/client/utilities'
import { Apollo } from 'apollo-angular'
import { Observable } from 'rxjs'
import { pipeFromArray } from 'rxjs/internal/util/pipe'
import { distinctUntilChanged, filter, map, pluck, take } from 'rxjs/operators'

/**
 * Execute a query and return the cache and network results.
 * If the result is in the cache, it is returned immediately.
 * Then the network result is returned only if it is different from the cache result.
 * The network result is saved in the cache.
 */
export function cacheAndNetworkQuery<T>(
    apolloClient: Apollo,
    query: WatchQueryOptions,
    pluckPath?: string[],
): Observable<T> {
    const pipeOperators = [
        take(2), // The cache and the network results, then complete the observable
        filter((res: ApolloQueryResult<unknown>) => !!res.data), // Filter ou empty cache data
        ...(pluckPath ? [pluck(...pluckPath)] : []),
        distinctUntilChanged(), // Don't emit if network data is the same as cache data (avoid double rendering)
        map(t => cloneDeep(t)),
    ]

    return apolloClient.watchQuery(query).valueChanges.pipe(pipeFromArray(pipeOperators)) as Observable<T>
}

/**
 * Execute a query and return the network result.
 * The result is saved in the cache.
 */
export function networkQuery<T>(apolloClient: Apollo, query: QueryOptions, pluckPath?: string[]): Observable<T> {
    const pipeOperators = [take(1), ...(pluckPath ? [pluck(...pluckPath)] : []), map(t => cloneDeep(t))]

    return apolloClient.query(query).pipe(pipeFromArray(pipeOperators)) as Observable<T>
}
