import { Routes } from '@angular/router'
import { UserRight, UserRole } from 'enums/user-role'
import { AuthGuard } from '../guards/auth'
import { DashboardComponent } from './dashboard/dashboard.component'
import { EditEventComponent } from './dashboard/edit-event/edit-event.component'
import { AssignContributorComponent } from './dashboard-galaxy/assign-contributor/assign-contributor.component'
import { CreateEventComponent } from './dashboard-galaxy/create-event/create-event.component'
import { DashboardGalaxyComponent } from './dashboard-galaxy/dashboard-galaxy.component'
import { LoginComponent } from './login/login.component'
import { HeaderSearchResultsComponent } from './shared/header-search/header-search-results/header-search-results.component'
import { UnauthorizedComponent } from './unauthorized/unauthorized.component'

export const PRE_TITLE = 'Euronews – CMS –'

export const ROUTES: Routes = [
    { path: '', redirectTo: '/dashboard-galaxy', pathMatch: 'full', title: `${PRE_TITLE} Dashboard` },
    { path: 'login', component: LoginComponent, outlet: '', title: `${PRE_TITLE} Login` },
    { path: 'unauthorized', component: UnauthorizedComponent, title: `${PRE_TITLE} Unauthorized` },
    {
        path: 'dashboard-galaxy',
        component: DashboardGalaxyComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'event',
                component: CreateEventComponent,
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: {
                    right: UserRight.CREATE_EVENT,
                },
            },
            {
                path: 'event/:id',
                component: CreateEventComponent,
                canActivate: [AuthGuard],
                data: {
                    right: UserRight.UPDATE_EVENT,
                },
            },
            {
                path: 'assign-contributor/:id',
                component: AssignContributorComponent,
            },
        ],
        title: `${PRE_TITLE} Dashboard`,
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'event/:id',
                component: EditEventComponent,
                canActivate: [AuthGuard],
                data: {
                    right: UserRight.UPDATE_EVENT,
                },
            },
        ],
        title: `${PRE_TITLE} Dashboard Pyramid`,
    },
    {
        path: 'story/:id',
        loadChildren: () => import('./story/story.module').then(m => m.StoryModule),
    },
    {
        path: 'general',
        loadChildren: () => import('./general/general.module').then(m => m.GeneralModule),
    },
    {
        path: 'contents',
        loadChildren: () => import('./contents/contents.module').then(m => m.ContentsModule),
    },
    {
        path: 'dev',
        loadChildren: () => import('./dev/dev.module').then(m => m.DevModule),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {
            role: UserRole.DEVELOPER,
        },
    },
    {
        path: 'search/:options',
        component: HeaderSearchResultsComponent,
        canActivate: [AuthGuard],
        outlet: 'modal',
        title: `${PRE_TITLE} Search`,
    },
    { path: '**', redirectTo: '/dashboard-galaxy' },
]
