import { gql } from '@apollo/client/core'
import { paginationFragment } from '../shared/pagination.fragment'
import { languageFragment } from './language.fragment'

export const languagesQuery = gql`
    ${paginationFragment}
    ${languageFragment}

    query languages {
        languages {
            pagination {
                ...paginationInfo
            }
            results {
                ...languageInfo
            }
        }
    }
`
