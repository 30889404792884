<div class="clr-select-wrapper">
    <select
        [id]="selectId"
        class="clr-select"
        (ngModelChange)="programChange($event)"
        [ngModel]="selectedProgram"
        [disabled]="disabled"
        [ngModelOptions]="{ standalone: true }"
        *ngIf="!showLoaderState">
        <option [ngValue]="-1">{{ firstOptionString }}&#8230;</option>
        <ng-container *ngFor="let pgmEuronews of programsEuronewsByVertical | keyvalue; trackBy: trackByVertical">
            <optgroup label="{{ pgmEuronews.key }}">
                <option *ngFor="let program of pgmEuronews.value" [ngValue]="program.id">
                    {{ program.slug }} ({{ program.info }})
                </option>
            </optgroup>
        </ng-container>
        <optgroup *ngIf="displayAfricanewsPgm" label="Africanews">
            <option *ngFor="let program of programsAfricanews" [ngValue]="program.id">
                {{ program.slug }} ({{ program.info }})
            </option>
        </optgroup>
    </select>
</div>
