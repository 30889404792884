import gql from 'graphql-tag'
import { featureFlagFragment } from './feature-flag.fragment'

export const featureFlags = gql`
    ${featureFlagFragment}

    query featureFlags {
        featureFlags {
            ...featureFlagInfo
        }
    }
`

export const featureFlag = gql`
    ${featureFlagFragment}

    query featureFlag($slug: String!) {
        featureFlag(slug: $slug) {
            ...featureFlagInfo
        }
    }
`

export const updateFeatureFlag = gql`
    ${featureFlagFragment}

    mutation updateFeatureFlag($slug: String!, $value: String!) {
        updateFeatureFlag(slug: $slug, value: $value) {
            ...featureFlagInfo
        }
    }
`
