import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { LanguagesService } from 'services/languages/languages'
import { ThemesService } from 'services/themes/themes'
import { ThemeModel } from '../../../models/theme.model'
import { BaseAbstractComponent } from '../base.component'

@Component({
    selector: 'cms-input-themes-select-list',
    templateUrl: './input-themes-select-list.component.html',
    styleUrls: ['./input-themes-select-list.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => InputThemesSelectListComponent),
        },
    ],
})
export class InputThemesSelectListComponent
    extends BaseAbstractComponent
    implements ControlValueAccessor, OnDestroy, OnInit
{
    /**
     * Disable the select list
     */
    @Input() disabled: boolean = false

    @Input() selectId: string

    /**
     * Type of the return
     */
    @Input() returnType: 'model' | 'number' = 'number'

    /**
     * Label of the first option for the null value
     */
    @Input() firstOptionString: string = 'Choose a theme'

    /**
     * Show a loader while the theme list is loading
     */
    @Output() showLoader = new EventEmitter()

    public showLoaderState: boolean = true

    /**
     * List to make the input select
     */
    public themesList: ThemeModel[]
    public themesListByVertical: Record<string, ThemeModel[]>

    public selectedTheme: number | null = null

    /**
     * Subject to handle subscriptions until the component is destroyed
     */
    destroy: Subject<boolean> = new Subject<boolean>()

    onChange = (_: any) => {}
    onTouched = () => {}

    constructor(private themesService: ThemesService, private languagesService: LanguagesService) {
        super()
    }

    trackByVertical(index, _) {
        return index
    }

    ngOnInit() {
        this.themesService
            .getThemesForFilter()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(themes => {
                this.themesList = themes
                    .map(theme => {
                        let themeEntry
                        if (theme.translations && theme.translations?.length) {
                            themeEntry = theme.translations.reduce((o, v) => {
                                if (v?.slug && v?.language.isoCode === this.languagesService.defaultLanguage.isoCode) {
                                    return v
                                }

                                return o
                            })
                        }

                        let verticalEntry
                        if (theme?.verticals?.[0]?.translations && theme?.verticals?.[0]?.translations?.length) {
                            verticalEntry = theme?.verticals?.[0]?.translations.reduce((o, v) => {
                                if (v?.slug && v?.language?.isoCode === this.languagesService.defaultLanguage.isoCode) {
                                    return v
                                }

                                return o
                            })
                        }

                        return {
                            id: theme.id,
                            slug: theme.slug,
                            isSelectableCms: theme.isSelectableCms,
                            translations: theme.translations,
                            verticals: theme.verticals,
                            title:
                                themeEntry !== undefined &&
                                Object.prototype.hasOwnProperty.call(themeEntry, 'title') &&
                                themeEntry['title']
                                    ? themeEntry.title
                                    : theme.slug,
                            verticalTitle: verticalEntry?.title,
                        }
                    })
                    .sort((a: any, b: any) => {
                        return a.slug < b.slug ? -1 : a.slug > b.slug ? 1 : 0
                    })
                this.themesListByVertical = this.themesByVertical(this.themesList)

                this.showLoaderState = false
                this.showLoader.emit(this.showLoaderState)
            })
    }

    ngOnDestroy(): void {
        this.destroy.next(true)
        this.destroy.complete()
    }

    /**
     * Theme changed in list
     * Notify observers for the change
     *
     * @param evt
     */
    themeChange(evt) {
        this.selectedTheme = evt

        this.onChange(this.selectedTheme)
    }

    /**
     * Inherit from ControlValueAccessor
     * @param fn
     */
    registerOnChange(fn: any): void {
        this.onChange = fn
    }

    /**
     * Inherit from ControlValueAccessor
     * @param fn
     */
    registerOnTouched(fn: any): void {
        this.onTouched = fn
    }

    /**
     * Inherit from ControlValueAccessor
     * @param isDisabled
     */
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled
    }

    /**
     * Inherit from ControlValueAccessor
     * @param obj
     */
    writeValue(obj): void {
        if (!obj) {
            return
        }

        this.selectedTheme = obj

        this.onChange(this.selectedTheme)
    }

    /**
     * Return the value of the field
     */
    get value(): ThemeModel | number | null {
        if (!this.selectedTheme) {
            return null
        }

        return this.returnType === 'number' ? this.selectedTheme : this.findTheme(this.selectedTheme)
    }

    /**
     * Return the theme object for the given id
     * @param id
     */
    protected findTheme(id: number): ThemeModel | null {
        return this.themesList.find(el => el.id === id) || null
    }

    protected themesByVertical(themeList: ThemeModel[]): Record<string, ThemeModel[]> {
        const ret = {}
        themeList.forEach(el => {
            const verticalKey = el.verticalTitle || 'No Vertical (Africanews)'
            if (!Object.prototype.hasOwnProperty.call(ret, verticalKey)) {
                ret[verticalKey] = []
            }
            ret[verticalKey].push(el)
        })

        return ret
    }
}
