import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { DashboardSearchFilters, DashboardSearchParams } from 'enums/filters'
import { StoryModel } from 'models/story.model'
import { Observable, of, Subject } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { cacheAndNetworkQuery } from 'tools/apollo'
import { QueryResultWithPaginationInterface, ResultPaginationInterface } from '../../interfaces/mercury'
import { AuthService } from '../auth/auth'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { getQueryOptions } from '../shared/options.query'
import { DashboardAbstract } from './dashboard-abstract'
import { storiesQuery } from './stories.query'

@Injectable()
export class StoriesByEdition extends DashboardAbstract {
    protected authService: AuthService

    public refresh = new Subject()

    constructor(apollo: Apollo, authService: AuthService, errorMessageService: ErrorsMessagesService) {
        super(apollo, errorMessageService)
        this.authService = authService
    }

    protected getContributorLanguageId(): number | undefined {
        const language = this.authService.currentUser!.writtenLanguage

        if (language) {
            return language.id
        }

        return undefined
    }

    list(
        searchParams: DashboardSearchParams<DashboardSearchFilters>,
    ): Observable<QueryResultWithPaginationInterface<StoryModel>> {
        const { filters, limit, offset, order } = searchParams

        const variables = this.getDefaultQueryVariables(filters)

        if (filters.langId) {
            variables['search']['langId'] = filters.langId
        }

        if (filters.programDeliverableId) {
            variables['search']['programDeliverableId'] = filters.programDeliverableId
        }

        if (filters.daletTitleName) {
            variables['search']['daletTitleName'] = filters.daletTitleName
        }

        if (filters.assignedTo?.length) {
            variables['search']['assignedTo'] = filters.assignedTo
        }

        if (filters.publicationStatus?.length) {
            variables['search']['publicationStatus'] = filters.publicationStatus
        }

        if (filters.withVideoOnly) {
            variables['search']['withVideoOnly'] = filters.withVideoOnly
        }

        if (filters.isBreakingNews) {
            variables['search']['isBreakingNews'] = filters.isBreakingNews
        }

        if (filters.isIndexed) {
            variables['search']['isIndexed'] = filters.isIndexed
        }

        variables['search'] = Object.assign(this.getStoryFilters(filters), variables['search'])
        variables['options'] = getQueryOptions(limit, offset, order)

        return cacheAndNetworkQuery<QueryResultWithPaginationInterface<StoryModel>>(
            this.apollo,
            {
                query: storiesQuery,
                variables,
            },
            ['data', 'stories'],
        ).pipe(
            catchError(error => {
                // add an error
                this.errorMessagesServices.addErrorsMessage(error)

                return of({
                    pagination: {} as ResultPaginationInterface,
                    results: [] as StoryModel[],
                })
            }),
        )
    }
}
