export const defaultProgramDeliverableId: number = 40

export enum ProgramDeliverableSlug {
    ARTICLE = 'article',
    ARTICLE_AND_VIDEO = 'article-video',
    LIVEBLOGGING = 'liveblogging',
    MASTER = 'master',
    PODCAST = 'podcast',
    SUJET = 'sujet',
    SUJET_BRUSSELS = 'sujet-brussels',
    SUJET_WEB = 'sujet-web',
    SUJET_VIDEO = 'sujet-video',
    VIDEO = 'video',
    WEB_BONUS = 'web-bonus',
}
