import { gql } from '@apollo/client/core'
import { paginationFragment } from '../shared/pagination.fragment'
import { programCategoryFragment } from './program-categories.fragment'

export const programCategoriesQuery = gql`
    ${paginationFragment}
    ${programCategoryFragment}

    query programCategories($pagination: PaginationInput) {
        programCategories(options: { pagination: $pagination }) {
            results {
                ...programCategory
            }
            pagination {
                ...paginationInfo
            }
        }
    }
`
