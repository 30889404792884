import { imageMetaFragment } from 'services/images/image-meta.fragment'

export const imageFragment = `
fragment imageInfo on Image {
    id
    url
    maxWidth
    maxHeight
}
`

export const imageWithMetaFragment = `
${imageFragment}
${imageMetaFragment}

fragment imageWithMetaInfo on Image {
    ...imageInfo
    metas {
        ...imageMetaInfo
    }
}
`

export const galleryImageFragment = `
fragment galleryImageInfo on GalleryImage {
    id
    url
    maxWidth
    maxHeight
}
`

export const galleryImageWithMetaFragment = `
${galleryImageFragment}
${imageMetaFragment}

fragment galleryImageWithMetaInfo on GalleryImage {
    ...galleryImageInfo
    metas {
        ...imageMetaInfo
    }
}
`
