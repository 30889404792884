import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'imageRatioUrl' })
export class ImageRatioUrlPipe implements PipeTransform {
    transform(url: string = '', imageWidth = 320, imageHeight = 180, maxWidth = 320, maxHeight = 180): string {
        if (!url) {
            return ''
        }

        if (imageHeight === null || imageWidth === null) {
            imageWidth = maxWidth
            imageHeight = maxHeight
        }

        const ratioMax: number = maxWidth / maxHeight
        const ratioOrig: number = imageWidth / imageHeight

        let newWidth = maxWidth
        let newHeight = maxHeight

        if (ratioMax > ratioOrig) {
            newWidth = Math.floor(imageWidth * (maxHeight / imageHeight))
        } else {
            newHeight = Math.floor(imageHeight * (maxWidth / imageWidth))
        }

        return url.replace(/{{h}}/g, newHeight.toString()).replace(/{{w}}/g, newWidth.toString())
    }
}
