import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter } from 'rxjs/operators'
import { cacheAndNetworkQuery } from 'tools/apollo'
import { PaginationInterface, ResultPaginationInterface } from '../../interfaces/mercury'
import { LocationModel } from '../../models/location.model'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { locationsByCountryQuery, locationsQuery } from './location.query'

@Injectable()
export class LocationsService extends BaseService {
    /**
     *
     * @type BehaviorSubject<TagModel[]>
     */
    protected _locations: BehaviorSubject<LocationModel[]> = new BehaviorSubject([])

    /**
     * @type Observable<TagModel[]>
     */
    public locations: Observable<LocationModel[]> = this._locations.asObservable().pipe(filter(value => !!value))

    /**
     *
     * @type BehaviorSubject<any>
     */
    protected _pagination: BehaviorSubject<ResultPaginationInterface | undefined> = new BehaviorSubject(undefined)

    /**
     * @type Observable<any>
     */
    public pagination: Observable<any> = this._pagination.asObservable().pipe(filter(value => !!value))

    /**
     *
     * @param apollo
     */
    constructor(apollo: Apollo, errorsMessagesService: ErrorsMessagesService) {
        super(apollo, errorsMessagesService)
    }

    /**
     *
     * @param pagination
     * @returns {Observable<any>}
     */
    getAllLocations(pagination?: PaginationInterface) {
        cacheAndNetworkQuery(
            this.apollo,
            {
                query: locationsQuery,
                variables: {
                    pagination,
                },
            },
            ['data'],
        ).subscribe(
            (data: { locations }) => {
                this._locations.next(data.locations.results)
                this._pagination.next(data.locations.pagination)
            },
            error => {
                // returned an empty locations' array
                this._locations.next([])
                // add an error
                this.errorMessagesServices.addErrorsMessage(error)
            },
        )
    }
    getLocationByCountry(id: number) {
        cacheAndNetworkQuery(
            this.apollo,
            {
                query: locationsByCountryQuery,
                variables: {
                    id,
                },
            },
            ['data'],
        ).subscribe(
            (data: { locationsByCountry }) => {
                this._locations.next(data.locationsByCountry.results)
            },
            error => {
                // returned an empty locations' array
                this._locations.next([])
                // add an error
                this.errorMessagesServices.addErrorsMessage(error)
            },
        )
    }

    /**
     *
     * @param none
     * @returns reset private locations value
     */
    resetLocations() {
        this._locations.next([])
    }

    /**
     *
     * @param none
     * @returns the private locations value
     */
    getLocations() {
        return this._locations.value
    }
}
