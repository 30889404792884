import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { Image } from 'models/media.model'
import { BehaviorSubject, Observable, of as observableOf, of } from 'rxjs'
import { catchError, mergeMap, pluck, switchMap } from 'rxjs/operators'
import { AuthService } from 'services/auth/auth'
import { networkQuery } from 'tools/apollo'
import { MutationResult } from '../../interfaces/query-result'
import {
    updateStoryWidgetImage,
    uploadStoryWireImage,
    getImageByIdQuery,
    updateImageProviderAndUsageInfo,
    getImageByUrlQuery,
} from './main-header/query'

@Injectable()
export class UploadStoryImageService {
    protected _url: BehaviorSubject<{ id: number; url: string }> = new BehaviorSubject(
        {} as { id: number; url: string },
    )

    /**
     *
     * @type {Observable<string>}
     */
    public url: Observable<{ id: number; url: string }> = this._url.asObservable()

    /**
     *
     * @param api
     */
    constructor(protected apollo: Apollo, protected authService: AuthService) {}

    saveWidgetImage(
        mutationData: {
            storyId: number
            imageProviderId: number | null
            usageInfos: string
            metas: any[]
        },
        image: File | null,
    ): Observable<MutationResult> {
        // if there is no lang id specified with the image we use the lang of the current user as fallback
        // this shouldn't happen ???
        if (!Object.prototype.hasOwnProperty.call(mutationData.metas[0], 'langId') || !mutationData.metas[0].langId) {
            mutationData.metas[0]['langId'] = this.authService.getUserDefaultLanguageId()
        }

        // @todo use optimisticResponse to improve user experience
        const mutationOptions = {
            mutation: updateStoryWidgetImage,
            variables: {
                storyId: mutationData.storyId,
                imageProviderId: mutationData.imageProviderId,
                usageInfos: mutationData.usageInfos,
                metas: mutationData.metas,
            },
        }

        let upload = false
        if (image !== null) {
            upload = true
            // add File object to Variables
            mutationOptions['variables']['file'] = image
        }

        return this.getApollo(upload)
            .mutate(mutationOptions)
            .pipe(
                pluck('data'),
                switchMap((url: { uploadStoryWidgetImage: { id: number; url: string } }) => {
                    if (url.uploadStoryWidgetImage) {
                        this._url.next(url.uploadStoryWidgetImage)
                    }

                    return of({ res: true })
                }),
                catchError(err => {
                    return of({ res: false, msg: err })
                }),
            )
    }

    saveWireImage(mutationData: { storyId: number; fileUrl: string; isMainHeader?: boolean }) {
        const mainHeader: boolean = mutationData.isMainHeader ? mutationData.isMainHeader : false
        const mutationOptions = {
            mutation: uploadStoryWireImage,
            variables: {
                storyId: mutationData.storyId,
                fileUrl: mutationData.fileUrl,
                mainHeader,
            },
        }

        return this.apollo
            .mutate(mutationOptions)
            .pipe(
                pluck('data'),
                pluck('uploadStoryWireImage'),
                mergeMap(url => observableOf(url)),
            )
            .toPromise()
    }

    getImageById(id): Observable<Image> {
        return networkQuery(
            this.apollo,
            {
                query: getImageByIdQuery,
                variables: {
                    id,
                },
            },
            ['data', 'image'],
        )
    }

    getImageByUrl(url): Observable<Image> {
        return networkQuery(
            this.apollo,
            {
                query: getImageByUrlQuery,
                variables: {
                    url,
                },
            },
            ['data', 'imageByUrl'],
        )
    }

    updateImageProviderAndUsageInfo(imageId, imageProviderId, usageInfos): Observable<boolean> {
        const mutationOptions = {
            mutation: updateImageProviderAndUsageInfo,
            variables: {
                imageId,
                imageProviderId,
                usageInfos,
            },
        }

        return this.apollo.mutate(mutationOptions).pipe(pluck('data', 'updateImageProviderAndUsageInfo'))
    }

    protected getApollo(upload: boolean) {
        if (upload) {
            return this.apollo.use('upload')
        }

        return this.apollo
    }
}
