import { contributorFragment } from 'services/contributors/contributor.fragment'

export const logsFragment = `
    ${contributorFragment}

    fragment logsInfo on StoryLog {
        updatedAt
        updatedAction
        contributor {
            ...contributorInfo
        }
    }
`
