<clr-dg-row-detail [clrLoading]="loading" class="c-row-details">
    <!-- Event name -->
    <clr-dg-cell class="u-w250p">
        <span *ngIf="story.author | contributorGetName"
            ><strong>Created by : </strong>{{ story.author | contributorGetName }}</span
        ><br />
        <span class="p4"><strong>Event date : </strong>{{ story.createdAt | formatDate }}</span>
    </clr-dg-cell>

    <!-- Event headline -->
    <clr-dg-cell>
        <img class="c-dashboard-details__img" [src]="story | storyImageUrl : 120 : 65 | async" data-fallbackenv="1" />
        <br />
        <a [routerLink]="['/story', story.id]" target="_blank"
            >Story ID : <strong>#{{ story.id }}</strong>
        </a>
    </clr-dg-cell>

    <!-- Author -->
    <clr-dg-cell class="u-w150p"></clr-dg-cell>

    <!-- Story type -->
    <clr-dg-cell class="u-w120p"></clr-dg-cell>

    <!-- Language -->
    <clr-dg-cell class="langageStatus u-w80p">
        <cms-stories-status [story]="story"></cms-stories-status>
    </clr-dg-cell>

    <!-- Program -->
    <clr-dg-cell class="u-w160p">
        <span class="c-dashboard__detail__theme"><strong>Themes :</strong></span>
        <ng-template ngFor let-theme [ngForOf]="story.themes" let-last="last">
            <ng-template [ngIf]="theme.translations.length > 0">
                {{
                    (theme.translations | translationFilter : 'title' : story.language.isoCode) ||
                        (theme.translations | translationFilter : 'title')
                }}
            </ng-template>
            <ng-template [ngIf]="!theme.translations || theme.translations.length < 1">
                <span class="themeAlert">Status : {{ theme.slug }}</span>
            </ng-template>
            <span *ngIf="last === false">, </span>
        </ng-template>
    </clr-dg-cell>

    <!-- Publication date -->
    <clr-dg-cell class="u-w135p"></clr-dg-cell>

    <!-- Last update date -->
    <clr-dg-cell class="u-w135p"></clr-dg-cell>

    <!-- Publication status -->
    <clr-dg-cell class="u-w100p"></clr-dg-cell>

    <!-- Indexation status -->
    <clr-dg-cell class="u-w50p"></clr-dg-cell>
</clr-dg-row-detail>
