import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { switchMap, takeUntil } from 'rxjs/operators'
import { StoriesByEdition } from 'services/dashboard/stories-by-edition'
import { Story } from '../../../entities/story'
import { AuthorsData } from '../../../services/ng-completer/contributors.data'
import { StoryService } from '../../../services/story/story'
import { StoryModalBaseAbstractComponent } from '../../shared/base.component'

@Component({
    selector: 'cms-assign-contributor',
    templateUrl: './assign-contributor.component.html',
    styleUrls: ['./assign-contributor.component.scss'],
})
export class AssignContributorComponent extends StoryModalBaseAbstractComponent implements OnInit, OnDestroy {
    storyId: number
    contributorId: number

    constructor(
        protected router: Router,
        private route: ActivatedRoute,
        protected storyService: StoryService,
        public authorCompleterService: AuthorsData,
        protected storiesEditionService: StoriesByEdition,
    ) {
        super()
    }

    setAssignee(evt) {
        this.contributorId = evt.originalObject.id
    }

    onSubmit() {
        if (!this.storyId || !this.contributorId) {
            this.formErrors.add('Story id or contributor id is not set.')

            return false
        }

        this.storyService
            .saveAssignee(this.storyId, this.contributorId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(result => {
                if (result.res) {
                    this.storiesEditionService.refresh.next()
                    this.onOpenChanged()
                } else {
                    this.formErrors.add('An error occurred while saving the data. Please try again.')
                }
            })
    }

    ngOnInit() {
        this.storyService.story.pipe(takeUntil(this.ngUnsubscribe)).subscribe(story => {
            // Because we use a BehaviourSubject in the StoryService, then it send us a stored story before the
            // requested story (maybe a wrong pattern used here)
            if (this.storyId === story.id) {
                this.story = new Story(story)
                if (this.story.assignee && this.story.assignee.id) {
                    this.contributorId = this.story.assignee.id
                }
            }
        })

        // Observe the params from activatedRoute AND then load the story
        this.route.paramMap
            .pipe(
                takeUntil(this.ngUnsubscribe),
                switchMap(params => {
                    const storyId = params.get('id')

                    if (storyId === null) {
                        throw new Error('Cannot access this window without selecting a story')
                    }

                    this.storyId = parseInt(storyId, 10)

                    return this.storyService.loadStory(this.storyId)
                }),
            )
            .subscribe(() => {
                this.open = true
            })
    }

    ngOnDestroy() {
        super.ngOnDestroy()
    }

    onOpenChanged() {
        this.open = false
        this.router.navigate(['/', 'dashboard-galaxy'], { queryParamsHandling: 'preserve' })
    }
}
