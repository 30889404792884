import { Pipe, PipeTransform } from '@angular/core'
import { LanguageModel } from '../models/language.model'

@Pipe({ name: 'direction' })
export class DirectionPipe implements PipeTransform {
    isRTL(language: LanguageModel) {
        return language && language.isRTL ? 'rtl' : 'ltr'
    }

    transform(input: any): any {
        if (!input) {
            return 'ltr'
        }

        if (typeof input === 'string') {
            return input
        }

        if (input.isRTL) {
            return this.isRTL(input)
        }

        return this.isRTL(input.language)
    }
}
