import { gql } from '@apollo/client/core'
import { logsFragment } from 'services/shared/logs.fragment'

export const getStoryLogsQuery = gql`
    ${logsFragment}

    query getStoryLogs($storyId: Int!) {
        story(id: $storyId) {
            id
            logs {
                ...logsInfo
            }
        }
    }
`
