import { gql } from '@apollo/client/core'
import { paginationFragment } from 'services/shared/pagination.fragment'
import { themeWithTranslatedVerticalsFragment } from 'services/themes/themes.fragment'
import { eventFragment, eventSearchFragment } from './event.fragment'

export const eventsSearchQuery = gql`
    ${paginationFragment}
    ${themeWithTranslatedVerticalsFragment}
    ${eventFragment}
    ${eventSearchFragment}

    query eventsSearchQuery($search: EventsSearchInput, $options: QueryOptions) {
        events(search: $search, options: $options) {
            pagination {
                ...paginationInfo
            }
            results {
                ...eventSearchInfos
            }
        }
    }
`

export const getEvent = gql`
    ${eventFragment}

    query getEvent($id: Int!) {
        event(id: $id) {
            ...eventInfos
        }
    }
`

export const createEvent = gql`
    ${eventFragment}

    mutation createEvent(
        $name: String!
        $programId: Int!
        $programDeliverableId: Int!
        $isTv: Boolean!
        $isDigital: Boolean!
        $isBreakingNews: Boolean!
        $isSingleMedia: Boolean!
        $isMultiplex: Boolean!
        $languages: [LangIso639_1!]
        $isCoreLanguages: Boolean!
        $instructions: String
        $eventSourceId: Int
        $isCmsOrigin: Boolean
        $editorialDesk: String!
        $embargoDate: DateTime
        $producerLanguageId: Int!
    ) {
        createEvent(
            name: $name
            programId: $programId
            programDeliverableId: $programDeliverableId
            isTv: $isTv
            isDigital: $isDigital
            isBreakingNews: $isBreakingNews
            isSingleMedia: $isSingleMedia
            isMultiplex: $isMultiplex
            languages: $languages
            isCoreLanguages: $isCoreLanguages
            instructions: $instructions
            eventSourceId: $eventSourceId
            isCmsOrigin: $isCmsOrigin
            editorialDesk: $editorialDesk
            embargoDate: $embargoDate
            producerLanguageId: $producerLanguageId
        ) {
            ...eventInfos
        }
    }
`

export const updateEvent = gql`
    ${eventFragment}

    mutation updateEvent(
        $id: Int!
        $programId: Int!
        $programDeliverableId: Int!
        $embargoDate: DateTime
        $languages: [LangIso639_1!]
        $isCoreLanguages: Boolean!
        $instructions: String!
        $editorialDesk: String!
    ) {
        updateEvent(
            id: $id
            programId: $programId
            programDeliverableId: $programDeliverableId
            embargoDate: $embargoDate
            languages: $languages
            isCoreLanguages: $isCoreLanguages
            instructions: $instructions
            editorialDesk: $editorialDesk
        ) {
            ...eventInfos
        }
    }
`

export const changeProgram = gql`
    mutation changeProgram($id: Int!, $programId: Int!) {
        changeProgram(id: $id, programId: $programId)
    }
`
