export enum TagsModerationStatus {
    ALL = 'ALL',
    MODERATED = 'MODERATED',
    PENDING = 'PENDING',
}

export enum TagsSelectableStatus {
    ALL = 'ALL',
    SELECTABLE = 'SELECTABLE',
    NOT_SELECTABLE = 'NOT_SELECTABLE',
}
