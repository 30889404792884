import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter } from 'rxjs/operators'
import { cacheAndNetworkQuery } from 'tools/apollo'
import { PaginationInterface, ResultPaginationInterface } from '../../interfaces/mercury'
import { LocationModel } from '../../models/location.model'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { continentsQuery } from './location.query'

@Injectable()
export class ContinentsService extends BaseService {
    /**
     *
     * @type BehaviorSubject<LocationsModel[]>
     */
    protected _continents: BehaviorSubject<LocationModel[]> = new BehaviorSubject([])

    /**
     * @type Observable<LocationsModel[]>
     */
    public continents: Observable<LocationModel[]> = this._continents.asObservable().pipe(filter(value => !!value))

    /**
     *
     * @type BehaviorSubject<any>
     */
    protected _pagination: BehaviorSubject<ResultPaginationInterface | undefined> = new BehaviorSubject(undefined)

    /**
     * @type Observable<any>
     */
    public pagination: Observable<any> = this._pagination.asObservable().pipe(filter(value => !!value))

    /**
     *
     * @param apollo
     */
    constructor(apollo: Apollo, errorsMessagesService: ErrorsMessagesService) {
        super(apollo, errorsMessagesService)
    }

    /**
     *
     * @param pagination
     * @returns {Observable<any>}
     */
    getAllContinents(pagination?: PaginationInterface) {
        cacheAndNetworkQuery(
            this.apollo,
            {
                query: continentsQuery,
                variables: {
                    pagination,
                },
            },
            ['data'],
        ).subscribe(
            (data: { continents }) => {
                this._continents.next(data.continents.results)
                this._pagination.next(data.continents.pagination)
            },
            error => {
                // returned an empty continents' array
                this._continents.next([])
                // add an error
                this.errorMessagesServices.addErrorsMessage(error)
            },
        )
    }

    /**
     *
     * @param none
     * @returns the private continent value
     */
    getContinents() {
        return this._continents.value
    }
}
