export enum ModalTitlePrefix {
    LOADING = 'Loading...',
    CREATE_EVENT = 'Create A Product Event',
    UPDATE_EVENT = 'Update A Product Event',
}

export enum AlertMessage {
    CHANGING_EVENT_PROGRAM = 'Changing the program of an event will force the desynchronisation with Dalet and also update the program for all language versions of the story within the CMS.',
    ALL_LANGUAGES_NOT_SUPPORTED = 'You are about to select a program for which all the languages are not supported, some stories will be locked for editing.',
    CAN_NOT_CHANGE_EVENT_PROGRAM = 'You cannot change the program because one of its story has been edited in a language not supported by this program.',
    UNEXPECTED_ERROR = 'Unexpected error happened, contact STI for more information.',
}

export enum FormControlNames {
    NAME = 'name',
    EDITORIAL_DESK = 'editorialDesk',
    PROGRAM_ID = 'programId',
    PROGRAM_DELIVERABLE_ID = 'programDeliverableId',
    IS_BREAKING_NEWS = 'isBreakingNews',
    IS_SINGLE_MEDIA = 'isSingleMedia',
    HAS_EMBARGO_DATE = 'hasEmbargoDate',
    EMBARGO_DATE = 'embargoDate',
    PRODUCER_LANGUAGE_ID = 'producerLanguageId',
    LANGUAGES = 'languages',
    IS_CORE_LANGUAGES = 'isCoreLanguages',
    IS_TV = 'isTv',
    IS_MULTIPLEX = 'isMultiplex',
    IS_DIGITAL = 'isDigital',
    INSTRUCTIONS = 'instructions',
}
