import { Injectable } from '@angular/core'
import { gql } from '@apollo/client/core'
import { Apollo } from 'apollo-angular'
import { VideoSearch } from 'enums/video-search'
import { AdvancedSearchBoxData } from 'models/advanced-search.model'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'
import { networkQuery } from 'tools/apollo'
import { HelpersDates } from '../../helpers/helpersDates'
import { paginationFragment } from '../../shared/pagination.fragment'
import { storySearchFragment } from '../story.fragment'

export const advancedStorySearchQuery = gql`
    ${paginationFragment}
    ${storySearchFragment}

    query stories($search: SearchInput, $options: QueryOptions) {
        stories(search: $search, options: $options) {
            results {
                ...storySearchInfo
            }
            pagination {
                ...paginationInfo
            }
        }
    }
`

interface DateRangeInterface {
    from: null | string
    to: null | string
}

@Injectable()
export class AdvancedStorySearchService {
    private searchOptionsCache: Record<string, AdvancedSearchBoxData> = {}
    /**
     *
     * @param api
     */
    constructor(protected apollo: Apollo) {}

    public getCachedSearchOptions(origin: string): AdvancedSearchBoxData {
        return this.searchOptionsCache[origin] || {}
    }

    public setCachedSearchBoxData(origin: string, options: AdvancedSearchBoxData): void {
        this.searchOptionsCache[origin] = options
    }

    /**
     * F..Y.I. Not using a BehaviourSubject to store results as we don't want to share them across components
     *
     * @param searchterm
     * @returns {Observable<any>}
     */
    public search(options: AdvancedSearchBoxData, limit: number, offset: number): Observable<any> {
        const variables: any = {
            search: {
                term: options.search,
            },
            options: {
                pagination: {
                    offset: typeof offset === 'undefined' ? 0 : offset,
                    limit: typeof limit === 'undefined' ? 100 : limit,
                },
                order: [
                    {
                        key: 'publishedAt',
                        direction: 'DESC',
                        nullPriority: true,
                    },
                    {
                        key: 'createdAt',
                        direction: 'DESC',
                    },
                ],
            },
        }

        if (options.publicationChannel !== undefined) {
            variables.search.publicationChannelId = [options.publicationChannel]
        }

        if (options.publicationOwner !== undefined) {
            variables.search.publicationOwnerId = [options.publicationOwner]
        }

        if (options.contributorId !== undefined && +options.contributorId !== -1) {
            variables.search.contributorId = options.contributorId
        }

        if (options.languageId !== undefined && +options.languageId !== -1) {
            variables.search.langId = options.languageId
        }

        if (options.programId !== undefined && +options.programId !== -1) {
            if (Array.isArray(options.programId)) {
                variables.search.programId = options.programId
            } else {
                variables.search.programId = [options.programId]
            }
        }

        if (options.themeId !== undefined && +options.themeId !== -1) {
            variables.search.themeId = [options.themeId]
        }

        if (options.verticalId !== undefined && +options.verticalId !== -1) {
            variables.search.verticalId = [options.verticalId]
        }

        if (options.tagId !== undefined && +options.tagId !== -1) {
            variables.search.tagId = [options.tagId]
        }

        if (options.typeId !== undefined && +options.typeId !== -1) {
            variables.search.typeId = [options.typeId]
        }

        if (options.publicationStatus !== undefined) {
            if (Array.isArray(options.publicationStatus)) {
                variables.search.publicationStatus = options.publicationStatus
            } else {
                variables.search.publicationStatus = [options.publicationStatus]
            }
        }

        if (options.dateRange !== undefined && options.dateRange !== 0) {
            variables.search.dateRange = this.calculateDateRange(options.dateRange)
        }

        if (options.technicalTagId !== undefined && +options.technicalTagId !== -1) {
            variables.search.technicalTagId = [options.technicalTagId]
        }

        if (options.withVideoOnly !== undefined) {
            if (+options.withVideoOnly === VideoSearch.WITH_GALLERY_IMAGES) {
                variables.search.withGalleryImages = true
            }

            if (+options.withVideoOnly !== -1 && +options.withVideoOnly !== VideoSearch.ALL_STORIES) {
                variables.search.withVideoOnly = Boolean(options.withVideoOnly)
            }
        }

        return networkQuery(
            this.apollo,
            {
                query: advancedStorySearchQuery,
                variables,
            },
            ['data'],
        ).pipe(take(1))
    }

    protected calculateDateRange(dateRange): DateRangeInterface {
        const range: DateRangeInterface = {
            from: null,
            to: null,
        }
        const backDate = new Date()

        // future requested
        if (dateRange === '-1') {
            const futureDate = new Date()
            futureDate.setFullYear(futureDate.getFullYear() + 1)
            range.from = HelpersDates.formatDate(backDate)
            range.to = HelpersDates.formatDate(futureDate)
        } else {
            // we add one day because mysql add a time to 00:00:00
            // when usinfg a date to perform a search on a datetime column
            backDate.setDate(backDate.getDate() + 1)

            // was bugged, the to must be current date
            range.to = HelpersDates.formatDate(backDate)
            backDate.setDate(backDate.getDate() - dateRange)
            range.from = HelpersDates.formatDate(backDate)
        }

        return range
    }
}
