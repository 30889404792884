<div class="main-container">
    <div class="alert alert-app-level">
        <cms-errors-messages class="u-w100"></cms-errors-messages>
    </div>
    <header
        class="header o-main-header"
        data-test-e2e="main-header"
        [ngClass]="{ devEnv: !isProd }"
        *ngIf="isAuth() && getUserId()">
        <div class="branding" [ngClass]="{ 'branding-dev': !isProd }">
            <img src="assets/images/logo-en.png" width="40" alt="branding" />
        </div>
        <div class="header-nav">
            <cms-menu></cms-menu>
        </div>
        <div class="header-actions">
            <cms-header-search class="c-header-search" data-test-e2e="main-header-search"></cms-header-search>
            <div class="header-time-link">
                <cms-today-date></cms-today-date>
            </div>
            <clr-dropdown>
                <button class="nav-text" clrDropdownToggle data-test-e2e="main-dropdown-menu">
                    <clr-icon shape="user"></clr-icon>
                    <span class="username" *ngIf="currentUser">{{
                        currentUser.translations | translationFilter : 'forename'
                    }}</span>
                    <clr-icon shape="caret down"></clr-icon>
                </button>
                <clr-dropdown-menu clrPosition="bottom-right">
                    <a [routerLink]="['/general/contributors/edit/', getUserId()]" clrDropdownItem>Profile</a>
                    <a (click)="logout($event)" clrDropdownItem>Log out</a>
                </clr-dropdown-menu>
            </clr-dropdown>
        </div>
    </header>
    <router-outlet></router-outlet>
    <router-outlet name="modal"></router-outlet>
</div>
