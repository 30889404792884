export enum StoryTypeSlug {
    BREAKINGNEWS = 'breaking_news',
    NORMAL = 'normal',
    EURONEWS_NBC = 'euronews_nbc',
    REPLAY = 'replay',
}

export enum StoryTypeId {
    BREAKINGNEWS = 9,
    NORMAL = 16,
    EURONEWS_NBC = 17,
    REPLAY = 20,
}

export enum StoryMasterCms {
    V1 = 'v1',
    V2 = 'v2',
}

export enum StoryProgramNameSlug {
    TOP_NEWS_STORIES_TODAY = 'top-news-stories-today',
}

export enum StoryBlockedUIMessages {
    TRANSLATED_STORY = 'This story has been locked because it is currently being duplicated.',
    NO_PROGRAM_LANGUAGE = "This story has been locked because its program doesn't exist in this language.",
}

export enum StoryTranslationStatus {
    LOADING = 'loading',
    ERROR = 'error',
    SUCCESS = 'success',
    TRANSLATED = 'duplicated',
    LOCKED = 'locked',
}

export enum StoryVerificationMessages {
    NO_TEXTUAL_CONTENT = 'This story has no textual content',
    UNSAVED_CHANGES = 'There are unsaved changes to the story text',
    BREAKINGNEWS_HAS_NOT_THE_REQUIRED_LENGTH = 'Breaking news stories have to contain more than 20 characters before being published',
}
