import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { User } from 'entities/user'
import { LanguageIsoCode } from 'enums/language'
import { UserRight, UserRole } from 'enums/user-role'
import { ContributorGetNamePipe } from 'pipes/contributor'
import { BehaviorSubject, Observable, of as observableOf } from 'rxjs'
import { AuthService } from 'services/auth/auth'
import { ContributorService } from 'services/contributors/one'
import { ErrorsMessagesService } from 'services/errors-messages/errors-messages.service'
import { LanguagesService } from 'services/languages/languages'
import { contributors } from '../contributors'

@Injectable()
export class AuthMock extends AuthService {
    isAuthentified: boolean = true

    currentUser: User

    protected _user: BehaviorSubject<User> = new BehaviorSubject({} as User)
    public me = this._user.asObservable()

    constructor(
        apollo: Apollo,
        errorsMessagesService: ErrorsMessagesService,
        http: HttpClient,
        contributorService: ContributorService,
        contributorGetNamePipe: ContributorGetNamePipe,
        languagesService: LanguagesService,
    ) {
        super(apollo, errorsMessagesService, http, contributorService, contributorGetNamePipe, languagesService)

        const contrib = contributors[0]
        this.currentUser = new User(contrib)
        this.currentUser
            .setRoles([UserRole.DEVELOPER])
            .setRights([UserRight.ACCESS_DIGITAL_PUBLISHING])
            .setLanguageIsoCodes([LanguageIsoCode.ENGLISH, LanguageIsoCode.FRENCH])

        this._user.next(this.currentUser)
    }

    async isAuth() {
        return Promise.resolve(this.isAuthentified)
    }

    getCurrentUser(): Observable<boolean> {
        return observableOf(true)
    }
}
