<clr-modal
    [(clrModalOpen)]="open"
    [clrModalSize]="'md'"
    [clrModalClosable]="true"
    (clrModalOpenChange)="onOpenChanged()"
    [clrModalStaticBackdrop]="true">
    <h3 class="modal-title">
        <clr-icon shape="users" size="36" class="c-icon-title"></clr-icon>
        Assign a contributor to {{ story?.event?.daletTitleName }}
    </h3>
    <div class="modal-body">
        <cms-alert-item *ngFor="let error of formErrors" [alertMessage]="error" [alertType]="'danger'">
        </cms-alert-item>
        <form clrForm #assignContributorForm="ngForm" id="assign-contributor-form" (ngSubmit)="onSubmit()">
            <clr-input-container>
                <label class="clr-col-xs-3" for="author">Assign story to</label>
                <ng2-completer
                    clrInput
                    #assigneeNameInput
                    name="author"
                    class="clr-col-xs-9 c-ng-completer u-input c-assign-contributor__input"
                    [ngModel]="story?.assignee | contributorGetName"
                    [datasource]="authorCompleterService"
                    [minSearchLength]="3"
                    [pause]="500"
                    (selected)="setAssignee($event)"
                    required></ng2-completer>
            </clr-input-container>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="onOpenChanged()">Cancel</button>
        <button
            type="submit"
            form="assign-contributor-form"
            class="btn btn-primary"
            [disabled]="!assignContributorForm.valid">
            Save
        </button>
    </div>
</clr-modal>
