import { DOCUMENT } from '@angular/common'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable()
export class BadCookieInterceptor implements HttpInterceptor {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(this.removeBadCookie(request))
    }

    removeBadCookie(request: HttpRequest<any>) {
        const delete_cookie = (name, path, domain) => {
            if (get_cookie(name)) {
                this.document.cookie =
                    name +
                    '=' +
                    (path ? ';path=' + path : '') +
                    (domain ? ';domain=' + domain : '') +
                    ';expires=Thu, 01 Jan 1970 00:00:01 GMT'
            }
        }

        const get_cookie = name => {
            return this.document.cookie.split(';').some(c => {
                return c.trim().startsWith(name + '=')
            })
        }

        delete_cookie('FCNEC', '/', '.euronews.com')
        delete_cookie('FCNEC', '', '.euronews.com')
        delete_cookie('connectId', '', '.euronews.com')
        delete_cookie('connectId', '/', '.euronews.com')

        return request
    }
}
