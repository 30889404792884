import { Injectable } from '@angular/core'
import { gql } from '@apollo/client/core'
import { Apollo } from 'apollo-angular'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter } from 'rxjs/operators'
import { BaseService } from 'services/base'
import { ErrorsMessagesService } from 'services/errors-messages/errors-messages.service'
import { cacheAndNetworkQuery } from 'tools/apollo'
import { StoryTextFormatsType } from '../../enums/story-text-format'
import { StoryTextFormat } from '../../models/story.model'

export const storyTextFormatsQuery = gql`
    query storyTextFormatList {
        textFormats {
            results {
                id
                slug
            }
        }
    }
`

@Injectable()
export class StoryTextFormatsListService extends BaseService {
    protected limit = 100
    protected _storyTextFormats: BehaviorSubject<StoryTextFormat[]> = new BehaviorSubject([])
    public storyTextFormats: Observable<StoryTextFormat[]> = this._storyTextFormats
        .asObservable()
        .pipe(filter(storyTextFormats => Boolean(storyTextFormats && storyTextFormats.length)))

    constructor(apollo: Apollo, errorsMessagesService: ErrorsMessagesService) {
        super(apollo, errorsMessagesService)
    }

    loadStoryTextFormats() {
        return cacheAndNetworkQuery(
            this.apollo,
            {
                query: storyTextFormatsQuery,
            },
            ['data', 'textFormats', 'results'],
        ).subscribe((storyTextFormats: StoryTextFormat[]) => {
            this._storyTextFormats.next(storyTextFormats)
        })
    }

    /**
     * Get text in the specified format
     *
     * @param StoryTextFormatsType format
     *
     * @returns {StoryTextFormat}
     */
    getStoryTextFormat(format: StoryTextFormatsType): StoryTextFormat {
        const storyTextFormats = this._storyTextFormats.getValue()
        if (!storyTextFormats) {
            console.warn('you have to retreive the storyTextFormats before doing this !')
            throw new Error('you have to retreive the storyTextFormats before doing this !')
        }

        const formatSlug = StoryTextFormatsType[format]
        const storyFormat = storyTextFormats.filter(el => el.slug === formatSlug)

        if (storyFormat.length < 1) {
            throw new Error('Story text format not found : ' + format)
        }

        return storyFormat[0]
    }
}
