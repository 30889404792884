import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { DashboardSearchFiltersEvents, DashboardSearchParams } from 'enums/filters'
import { EventModel } from 'models/event.model'
import { Observable, of, Subject } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { eventsSearchQuery } from 'services/events/event.query'
import { cacheAndNetworkQuery } from 'tools/apollo'
import { QueryResultWithPaginationInterface, ResultPaginationInterface } from '../../interfaces/mercury'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { HelpersDates } from '../helpers/helpersDates'
import { getQueryOptions } from '../shared/options.query'
import { DashboardAbstract } from './dashboard-abstract'

@Injectable()
export class EventsService extends DashboardAbstract {
    public refresh = new Subject()

    constructor(apollo: Apollo, errorMessageService: ErrorsMessagesService) {
        super(apollo, errorMessageService)
    }

    list(
        searchParams: DashboardSearchParams<DashboardSearchFiltersEvents>,
    ): Observable<QueryResultWithPaginationInterface<EventModel>> {
        const { filters, limit, offset, order } = searchParams

        const variables = this.getDefaultQueryVariables(filters)

        // add search pattern if needed
        if (filters.search) {
            variables['search']['term'] = filters.search
        }

        if (filters.assignedTo) {
            variables['search']['assignedContributorId'] = filters.assignedTo
        }

        if (filters.publicationOwnerId) {
            variables['search']['publicationOwnerId'] = filters.publicationOwnerId
        }

        if (filters.createdAt) {
            variables['search']['dateRange'] = {
                from: HelpersDates.formatDate(filters.createdAt[0]),
                to: HelpersDates.formatDate(filters.createdAt[1]),
            }
        }

        if (filters.updatedAt) {
            variables['search']['lastUpdateDateRange'] = {
                from: HelpersDates.formatDate(filters.updatedAt[0]),
                to: HelpersDates.formatDate(filters.updatedAt[1]),
            }
        }

        if (filters.programDeliverableId) {
            variables['search']['programDeliverableId'] = filters.programDeliverableId
        }

        if (filters.deskId) {
            variables['search']['pyramidDeskId'] = filters.deskId
        }

        if (filters.eventPyramidStatusId) {
            variables['search']['eventPyramidStatusId'] = filters.eventPyramidStatusId
        }

        if (filters.langId) {
            variables['search']['producerLangId'] = filters.langId
        }

        if (filters.eventsWithoutProducerLang) {
            variables['search']['eventsWithoutProducerLang'] = filters.eventsWithoutProducerLang
        }

        if (filters.eventsWithoutProducerLangStory) {
            variables['search']['eventsWithoutProducerLangStory'] = filters.eventsWithoutProducerLangStory
        }

        variables['options'] = getQueryOptions(limit, offset, order)

        return cacheAndNetworkQuery<QueryResultWithPaginationInterface<EventModel>>(
            this.apollo,
            {
                query: eventsSearchQuery,
                variables,
            },
            ['data', 'events'],
        ).pipe(
            catchError(error => {
                // add an error
                this.errorMessagesServices.addErrorsMessage(error)

                return of({
                    pagination: {} as ResultPaginationInterface,
                    results: [] as EventModel[],
                })
            }),
        )
    }
}
