import { Pipe, PipeTransform } from '@angular/core'
import { SafeUrl } from '@angular/platform-browser'
import { Observable, of } from 'rxjs'
import { Story } from '../entities/story'
import { BreakingNewsDefaultImage } from '../services/breaking-news-default-image/image'
import { ImageUrlPipe } from './image-url.pipe'

/**
 * Takes a Story as input and:
 *
 * 1. Returns a resized image if one exists
 * 2. If no image exists and story is of type Breaking News, then it returns a Breaking News image
 * 3. If no image exists and not breaking News, returns a fallback image
 *
 * As it returns a SafeUrl, it has to be used as such in the template:
 *
 * [src]="(story | storyImageUrl:800:460) | async"
 *
 * and not like this:
 *
 * src={{(story | storyImageUrl:800:460) | async}}
 */
@Pipe({ name: 'storyImageUrl' })
export class StoryImageUrlPipe implements PipeTransform {
    constructor(protected transformer: ImageUrlPipe, protected breakingNewsImageService: BreakingNewsDefaultImage) {}

    transform(input: Story, width = 150, height = 150): Observable<SafeUrl> {
        input = input as Story
        const image = input.getMainImage()
        if (image && image.url) {
            return of(this.transformer.transform(image.url, width, height) as SafeUrl)
        }
        if (input.isBreakingNews) {
            return this.breakingNewsImageService.getBreakingAsBase64String()
        }

        return of('' as SafeUrl)
    }
}
