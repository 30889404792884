import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter, pluck } from 'rxjs/operators'
import { LanguagesService } from 'services/languages/languages'
import { TagsModerationStatus, TagsSelectableStatus } from '../../enums/tags-moderation-status'
import { PaginationInterface } from '../../interfaces/mercury'
import { ContributorModel } from '../../models/contributor.model'
import { TagModel } from '../../models/tag.model'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { TagsService } from './tags'

@Injectable()
export class TagsAutocompleteByTitleAndLanguageService extends TagsService {
    protected limit = 100
    protected offset = 0
    protected _tagsAutocomplete: BehaviorSubject<TagModel[]>[] = [] as BehaviorSubject<TagModel[]>[]
    public tagsAutocomplete: Observable<TagModel[]>[] = []

    /**
     *
     * @param apollo
     */
    constructor(
        apollo: Apollo,
        protected errorMessagesServices: ErrorsMessagesService,
        protected languagesService: LanguagesService,
    ) {
        super(apollo, errorMessagesServices, languagesService)

        const types = ['main', 'tags']

        types.forEach(type => {
            this._tagsAutocomplete[type] = new BehaviorSubject([] as ContributorModel[])
            this.tagsAutocomplete[type] = this._tagsAutocomplete[type]
                .asObservable()
                .pipe(filter(tags => Boolean(tags)))
        })
    }

    getTagByTitleAndLanguageAutoComplete(
        title: string,
        language: number,
        moderationStatus: TagsModerationStatus,
        selectableStatus: TagsSelectableStatus,
        type: string,
        pagination?: PaginationInterface,
    ) {
        if (!language) {
            language = this.languagesService.defaultLanguage.id
        }

        this.getTagsByTitleAndLanguage(title, language, moderationStatus, selectableStatus, false, pagination)
            .pipe(pluck('results'))
            .subscribe((tags: [TagModel]) => {
                this._tagsAutocomplete[type].next(tags)
            })
    }
}
