import { Injectable } from '@angular/core'
import { EContributorsNotification, ERoomType, EPurgeContributorsNotification } from 'enums/contributors-notification'
import { JoinChannelPayload, DisconnectedContributor } from 'models/contributors-notification.model'
import { Observable } from 'rxjs'
import { SocketIoClientService } from './clients/socket-io-client.service'
import { ContributorsNotificationInterface } from './contributors-notification.interface'

@Injectable()
export class ContributorsNotificationService implements ContributorsNotificationInterface {
    constructor(private socketService: SocketIoClientService) {}

    /**
     * Fetch all data for a type (story, translation, etc.)
     */
    public onFetchData(type: ERoomType): Observable<unknown> {
        this.socketService.emit(EContributorsNotification.REFRESH_EVENT, type)

        return this.socketService.on(`ON_READ_ALL_DATA_FOR_${type.toUpperCase()}`)
    }

    /**
     * Fetch all data.
     */
    public onFetchAllData(): Observable<unknown> {
        this.socketService.emit(EContributorsNotification.ON_FETCH_ALL_DATA)

        return this.socketService.on(EContributorsNotification.ON_READ_ALL_DATA)
    }

    /**
     * Fetch all data for a room (story-1, translation-2, etc.)
     */
    public onReadAllDataForARoom(): Observable<unknown> {
        return this.socketService.on(EContributorsNotification.ON_READ_ALL_DATA_FOR_A_ROOM)
    }

    /**
     * Sends a payload to warn that a contributor has just joined a room.
     */
    public eventJoined(payload: JoinChannelPayload): void {
        this.socketService.emit(EContributorsNotification.EVENT_JOINED, payload)
    }

    /**
     * Sends a payload to warn that a contributor has just left a room.
     */
    public eventLeft(): void {
        this.socketService.emit(EContributorsNotification.EVENT_LEFT)
    }

    /**
     * Allows to purge an entire room type.
     */
    public purgeRoomType(purgeEvent: ERoomType): void {
        this.socketService.emit(EPurgeContributorsNotification.PURGE_ROOM_TYPE, purgeEvent)
    }

    /**
     * Allows to purge an array of contributors.
     */
    public purgeContributorsSelection(contributors: DisconnectedContributor[]): void {
        this.socketService.emit(EPurgeContributorsNotification.PURGE_CONTRIBUTORS_SELECTION, contributors)
    }
}
