<div
    class="connected-contributors connected-contributors--{{ display }} connected-contributors--{{ display }}--{{
        type
    }}"
    *ngIf="(display === 'dashboard' && totalOpened > 0) || display === 'element'">
    <clr-signpost class="connected-contributors__signpost">
        <span
            class="connected-contributors--{{ display }}__icon"
            [ngClass]="{ 'connected-contributors--element__icon-highlight': display === 'element' && totalOpened > 1 }"
            clrSignpostTrigger>
            <clr-icon shape="users" class="" [ngClass]="{ 'is-solid': configuration.isIconSolid }"></clr-icon>
        </span>
        <ng-container *ngIf="(display === 'element' && totalOpened > 1) || display === 'dashboard'">
            <clr-signpost-content
                [clrPosition]="configuration.clrPosition"
                *clrIfOpen="this.display === 'element' && this.totalOpened > 1">
                <p class="connected-contributors--{{ display }}__title">{{ configuration.signPostTitle }}</p>
                <ul class="connected-contributors--{{ display }}__list">
                    <li
                        class="connected-contributors--{{ display }}__list-element"
                        *ngFor="let contributor of connectedContributorData">
                        {{ contributor.username }}
                        <span *ngIf="contributor.numberOfConnections > 1">
                            ({{ contributor.numberOfConnections }})
                        </span>
                        <span> : {{ contributor.connectedSince }}</span>
                    </li>
                </ul>
                <p class="connected-contributors--{{ display }}__footer" *ngIf="configuration.needFooter">
                    <span><clr-icon shape="warning-standard"></clr-icon> Warning</span> - Editing {{ type }} at the same
                    time as other contributors can lead to saving conflicts.
                </p>
            </clr-signpost-content>
        </ng-container>
    </clr-signpost>
</div>
