import { gql } from '@apollo/client/core'
import { storyWithRelatedFragment } from '../find-by-id.query'

export const upsertAssignee = gql`
    ${storyWithRelatedFragment}

    mutation updateStoryAssignee($storyId: Int!, $assigneeId: Int!) {
        updateStoryAssignee(storyId: $storyId, assigneeId: $assigneeId) {
            ...storyWithRelatedInfo
        }
    }
`
