import { Injectable } from '@angular/core'
import { cloneDeep } from '@apollo/client/utilities'
import { Apollo } from 'apollo-angular'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { filter } from 'rxjs/operators'
import { cacheAndNetworkQuery } from 'tools/apollo'
import { ResultPaginationInterface, SortInterface } from '../../interfaces/mercury'
import { ProgramModel } from '../../models/program.model'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { getQueryOptions } from '../shared/options.query'
import { programsEmhCategoriesQuery, programsMenuQuery, programsQuery } from './programs.query'

export interface ProgramsResult {
    pagination: ResultPaginationInterface
    results: ProgramModel[]
}
export interface ProgramSearchInputInterface {
    isDisabledCms?: boolean
    isSelectableForCreation?: boolean
    isSelectableForUpdate?: boolean
    isSelectableForSearch?: boolean
    isActive?: boolean
    themeId?: number
    categoryId?: number
    publicationOwnerId?: number
    verticalId?: number
    langId?: number[]
    titleSearch?: string
    displayedInMenu?: boolean
}
@Injectable()
export class ProgramListService extends BaseService {
    protected _programs: Subject<ProgramModel[]> = new Subject()

    public programs = this._programs.asObservable().pipe(filter(programModel => Boolean(programModel.length)))
    protected _pagination: BehaviorSubject<ResultPaginationInterface | undefined> = new BehaviorSubject(undefined)
    public pagination = this._pagination.asObservable().pipe(filter(pagination => Boolean(pagination !== undefined)))

    protected _programsForProgramPage: Subject<ProgramModel[]> = new Subject()
    public programsForProgramPage = this._programsForProgramPage.asObservable()

    protected _programsEmhCategories: Subject<string[]> = new Subject()
    public programsEmhCategories = this._programsEmhCategories.asObservable()

    protected _paginationForProgramPage: BehaviorSubject<ResultPaginationInterface | undefined> = new BehaviorSubject(
        undefined,
    )
    public paginationForProgramPage = this._paginationForProgramPage
        .asObservable()
        .pipe(filter(pagination => Boolean(pagination !== undefined)))

    constructor(apollo: Apollo, errorMessageService: ErrorsMessagesService) {
        super(apollo, errorMessageService)
    }

    formatProgramList(list: ProgramModel[]): ProgramModel[] {
        return list.map(el => {
            const program: ProgramModel = cloneDeep(el)

            program.info = 'No vertical !'
            if (program.verticals && program.verticals[0] && program.verticals[0].translations[0]) {
                program.info = program.verticals[0].translations[0].title
            } else if (program.verticals && program.verticals[0]) {
                program.info = program.verticals[0].slug
            }

            return program
        })
    }

    callApi(variables): Observable<any> {
        return cacheAndNetworkQuery(
            this.apollo,
            {
                query: programsQuery,
                variables,
            },
            ['data'],
        )
    }

    get(search: ProgramSearchInputInterface, limit: number, offset: number, order?: SortInterface) {
        const variables = {
            search,
            options: getQueryOptions(limit, offset, order),
        }

        return this.callApi(variables).subscribe(
            (data: { programs: ProgramsResult }) => {
                const pagination = data.programs.pagination
                const programs = this.formatProgramList(data.programs.results)

                this._programs.next(programs)
                this._pagination.next(pagination)
            },
            error => {
                // returned an empty programs' array
                this._programs.next([])
                // add an error
                this.errorMessagesServices.addErrorsMessage(error)
            },
        )
    }

    getListForProgramPage(search: ProgramSearchInputInterface, limit: number, offset: number, order?: SortInterface) {
        const variables = {
            search,
            options: getQueryOptions(limit, offset, order),
        }

        return cacheAndNetworkQuery(
            this.apollo,
            {
                query: programsMenuQuery,
                variables,
            },
            ['data'],
        ).subscribe(
            (data: { programs: ProgramsResult }) => {
                const pagination = data.programs.pagination
                const programs = this.formatProgramList(data.programs.results)
                programs.map(program => {
                    const listLangMenu = program.translations.filter(tr => tr.websiteMenu === true)
                    program.websiteMenuLang = listLangMenu.map(tr => tr.language)
                })
                this._programsForProgramPage.next(programs)
                this._paginationForProgramPage.next(pagination)
            },
            error => {
                // returned an empty programs' array
                this._programsForProgramPage.next([])
                // add an error
                this.errorMessagesServices.addErrorsMessage(error)
            },
        )
    }

    getProgramsEmhCategoriesList() {
        const search: ProgramSearchInputInterface = {}

        return cacheAndNetworkQuery(
            this.apollo,
            {
                query: programsEmhCategoriesQuery,
                variables: { search },
            },
            ['data'],
        ).subscribe(
            (data: { programs: ProgramsResult }) => {
                let emhCategories = data.programs.results.map(program => program.emhCategory!)
                emhCategories = [...new Set(emhCategories)]
                this._programsEmhCategories.next(emhCategories)
            },
            error => {
                // returned an empty programs' array
                this._programsEmhCategories.next([])
                // add an error
                this.errorMessagesServices.addErrorsMessage(error)
            },
        )
    }
}
