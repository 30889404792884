import { gql } from '@apollo/client/core'
import { paginationFragment } from '../shared/pagination.fragment'

export const alertTypesQuery = gql`
    ${paginationFragment}

    query alertTypes($pagination: PaginationInput) {
        alertTypes(options: { pagination: $pagination }) {
            results {
                id
                slug
            }
            pagination {
                ...paginationInfo
            }
        }
    }
`
