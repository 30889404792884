import { Pipe, PipeTransform } from '@angular/core'
import * as slug from 'slug'

@Pipe({ name: 'slugify' })
export class SlugifyPipe implements PipeTransform {
    transform(input: any, removeTrailing: boolean = true, maxLength?: number | undefined): any {
        if (!(typeof input === 'string')) {
            return input
        }
        slug.charmap['_'] = '-'

        return slug(input, { trim: removeTrailing }).slice(0, maxLength)
    }
}
