import { gql } from '@apollo/client/core'

export const replicateStoryDataToLangs = gql`
    mutation replicateStoryDataToLangs($storyId: Int!, $langIds: [Int!]!) {
        replicateStoryDataToLangs(storyId: $storyId, langIds: $langIds) {
            langId
            success
            errorMessage
        }
    }
`

export const producerStoryUpdates = gql`
    query producerStoryUpdates($storyId: Int!) {
        producerStoryUpdates(storyId: $storyId)
    }
`

export const removeProducerStoryUpdatedWarning = gql`
    mutation removeProducerStoryUpdatedWarning($storyId: Int!) {
        removeProducerStoryUpdatedWarning(storyId: $storyId)
    }
`
