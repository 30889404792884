import { Pipe, PipeTransform } from '@angular/core'
import { LanguagesService } from 'services/languages/languages'
import { ContributorModel } from '../models/contributor.model'

@Pipe({ name: 'contributorGetName' })
export class ContributorGetNamePipe implements PipeTransform {
    constructor(private languagesService: LanguagesService) {}

    transform(
        contributor?: ContributorModel | ContributorModel[],
        isoCode = this.languagesService.defaultLanguage.isoCode,
    ): string {
        if (contributor instanceof Array) {
            contributor = contributor[0]
        }

        if (!contributor || contributor.translations === undefined || contributor.translations === null) {
            return ''
        }

        let person = contributor.translations.filter(translation => {
            return translation.language?.isoCode === isoCode
        })

        // if no result we take the default language contributor
        if (!person.length || (!person[0].forename && !person[0].surname)) {
            person = contributor.translations.filter(translation => {
                return translation.language?.isoCode === this.languagesService.defaultLanguage.isoCode
            })
        }

        if (!person.length) {
            return ''
        }

        return `${person[0].forename} ${person[0].surname}`
    }
}
