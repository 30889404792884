import { Injectable } from '@angular/core'
import { SizeForStyle, Utils } from 'app/story/wysiwyg/utils/utils'
import { Story } from 'entities/story'
import { Image } from 'models/media.model'
import { take } from 'rxjs/operators'
import { GalleryService } from 'services/gallery/galleries.service'

@Injectable()
export class GalleryPreviewUtils {
    protected story: Story

    constructor(protected galleryService: GalleryService) {}

    public async loadGallery(galleryWidget: Element): Promise<Element | null> {
        const galleryKey = galleryWidget.getAttribute('data-gallery_key')
        const mainImageId = galleryWidget.getAttribute('data-featuredImageId')

        if (!galleryKey) {
            return Promise.resolve(null)
        }

        return this.galleryService
            .byKeyForPreview(galleryKey)
            .pipe(take(1))
            .toPromise()
            .then(galleryResult => {
                if (!galleryResult.images) {
                    return null
                }

                const main_image =
                    galleryResult.images.find(x => String(x.id) === mainImageId) || galleryResult.images[0]
                this.displayImage(galleryWidget, main_image)

                const galleryLength: number = galleryResult.images.length

                galleryWidget.querySelector('.widgetGallery__button__length')!.textContent =
                    galleryLength.toString() + ` Photo${galleryLength > 1 ? 's' : ''}`

                return galleryWidget
            })
    }

    /**
     * Display the main image selected or the first image of the gallery.
     */
    protected displayImage(galleryWidget: Element, main_image: Image) {
        const sizesForStyles: SizeForStyle = Utils.widget.getSizesForStyles(
            Utils.widget.calculateRatio(main_image.maxWidth, main_image.maxHeight),
        )

        const url = main_image
            .url!.replace(/\{\{h\}\}/g, sizesForStyles['fullscreen'].height.toString())
            .replace(/\{\{w\}\}/g, sizesForStyles['fullscreen'].width.toString())
        galleryWidget.querySelector('.widgetGallery__gallery')!.setAttribute('src', url)
        galleryWidget
            .querySelector('.widgetGallery__gallery')!
            .setAttribute('style', `background-image: url('${url}');`)
    }
}
