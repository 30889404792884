import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core'
import { EDisplayType } from 'enums/contributors-notification'
import { ConnectedContributor, ConnectedContributorConfiguration } from 'models/contributors-notification.model'

@Component({
    selector: 'cms-connected-contributors',
    templateUrl: './connected-contributors.component.html',
    styleUrls: ['./connected-contributors.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectedContributorsComponent implements OnInit, OnChanges {
    @Input() display: string
    @Input() type: string
    @Input() connectedContributorData: ConnectedContributor[]

    public totalOpened: number = 0

    public configuration: ConnectedContributorConfiguration

    ngOnInit(): void {
        this.getConfiguration()
    }

    ngOnChanges(): void {
        this.totalOpened = 0
        if (this.connectedContributorData?.length) {
            this.connectedContributorData.forEach((contributor: ConnectedContributor) => {
                this.totalOpened += contributor.numberOfConnections
            })
        }
    }

    public getConfiguration(): void {
        switch (this.display) {
            case EDisplayType.DASHBOARD:
                this.configuration = {
                    signPostTitle: `Contributors on this ${this.type} :`,
                    isIconSolid: false,
                    clrPosition: 'right-middle',
                    needFooter: false,
                }
                break
            case EDisplayType.ELEMENT:
                this.configuration = {
                    signPostTitle: `Connected contributors to this ${this.type}`,
                    isIconSolid: true,
                    clrPosition: 'top-left',
                    needFooter: true,
                }
                break
        }
    }
}
