<h1 class="dashboard-section-title">
    <strong>All digital events & stories</strong>
</h1>

<cms-contributor-filters
    [datagridFilters]="activeDatagridFilters"
    [disableAddfilterButton]="isLoading"
    (selectedFilters)="onContributorFiltersSelected($event)"></cms-contributor-filters>

<div class="toolbar">
    <div class="toolbar__filters">
        <!-- Details toggle -->
        <div class="u-no-gutter-right toolbar__show-details">
            <h3 class="c-dashboard__subtitle c-dashboard__subtitle--details toolbar__show-details-title">
                Show the details :
            </h3>

            <button
                class="btn storyToolBar__btn--collapse toolbar__show-details-btn"
                (click)="showRowDetails = !showRowDetails">
                <clr-icon *ngIf="showRowDetails === false" shape="caret right"></clr-icon>
                <clr-icon *ngIf="showRowDetails === true" shape="caret down"></clr-icon>
            </button>
        </div>

        <!-- Toolbar Filters -->
        <div class="u-no-gutter-right toolbar__show-details">
            <h3 class="c-dashboard__subtitle c-dashboard__subtitle--filters">Filters :</h3>

            <!-- Filters list -->
            <div class="checkbox-list u-no-margin-left toolbar__filters-list">
                <div
                    class="checkbox-inline clr-checkbox-wrapper toolbar__filters-list--items"
                    *ngFor="let opt of toolbarFiltersList">
                    <input
                        class="clr-checkbox storyToolBar__options--input"
                        type="checkbox"
                        #toolbarCkechboxes
                        id="opt-{{ opt.label }}"
                        [name]="opt.label"
                        [checked]="opt.selected"
                        [disabled]="disableNewsAndMagazineFilters(opt.label)"
                        (change)="onToolbarFilterSelected(opt)" />
                    <label
                        class="storyToolBar__options--label"
                        [ngClass]="{
                            isDisabled: disableNewsAndMagazineFilters(opt.label)
                        }"
                        id="opt-label-{{ opt.label }}"
                        for="opt-{{ opt.label }}"
                        >{{ opt.label }}</label
                    >
                </div>
            </div>

            <!-- Deliverables filter -->
            <clr-dropdown [clrCloseMenuOnItemClick]="false" class="toolbar__deliverable-filters">
                <button clrDropdownTrigger class="storyToolBar__options--label">
                    <span class="toolbar__deliverable-title">Deliverables</span>
                    <clr-icon shape="caret down" size="16"></clr-icon>
                </button>

                <clr-dropdown-menu *clrIfOpen>
                    <div *ngFor="let deliverable of programDeliverablesList" class="toolbar__dropdown-item">
                        <clr-checkbox-wrapper>
                            <input
                                type="checkbox"
                                clrCheckbox
                                id="{{ deliverable.label }}-{{ deliverable.id }}"
                                [checked]="deliverable.selected"
                                (change)="onDeliverableSelected(deliverable)" />
                            <label for="{{ deliverable.label }}-{{ deliverable.id }}">{{ deliverable.label }}</label>
                        </clr-checkbox-wrapper>
                    </div>
                </clr-dropdown-menu>
            </clr-dropdown>
        </div>
    </div>

    <!-- Create button -->
    <div class="c-btn_createStory" cmsIfAuthorized>
        <a
            class="btn btn-primary"
            data-testid="button-create-event"
            [routerLink]="['event']"
            [queryParamsHandling]="'preserve'">
            <clr-icon shape="plus-circle"></clr-icon> Create an event
        </a>
    </div>
</div>

<!-- Datagrid -->
<clr-datagrid (clrDgRefresh)="onDatagridRefresh($event)" [clrDgLoading]="isLoading" class="c-dashboard-edition">
    <!-- Placeholder -->
    <clr-dg-placeholder>We couldn't find any stories to display!</clr-dg-placeholder>

    <!-- Columns -->
    <clr-dg-column class="u-w250p"
        >Channel & Event name
        <clr-dg-filter [clrDgFilter]="channelsFilter" [(clrDgFilterOpen)]="channelsFilter.open">
            <cms-datagrid-composite-filter #channelsFilter [filterName]="FilterName.CHANNELS_AND_EVENTS_COMPOSITE">
                <cms-datagrid-filterable-filter
                    [title]="'Channel'"
                    [filterTitle]="'Channel'"
                    [type]="FilterTypes.CHECKBOX"
                    [list]="channelsList"
                    [filterName]="FilterName.CHANNELS"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.CHANNELS]"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.CHANNELS, $event)"
                    #injectedFilters></cms-datagrid-filterable-filter>

                <cms-datagrid-filterable-filter
                    [title]="'Search an event by name'"
                    [filterTitle]="'Event'"
                    [displayList]="false"
                    [filterName]="FilterName.EVENTS"
                    [searchPlaceholder]="'Type an event name'"
                    [useList]="false"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.EVENTS]"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.EVENTS, $event)"
                    #injectedFilters></cms-datagrid-filterable-filter>
            </cms-datagrid-composite-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column
        >Headline & CMS story ID
        <clr-dg-filter [clrDgFilter]="headlineFilter" [(clrDgFilterOpen)]="headlineFilter.open">
            <cms-datagrid-filterable-filter
                #headlineFilter
                [title]="'Search by headline & CMS story ID'"
                [displayList]="false"
                [filterTitle]="'Headline'"
                [filterName]="FilterName.HEADLINES"
                [searchPlaceholder]="'Type a headline & CMS story ID'"
                [useList]="false"
                [contributorFilter]="contributorDatagridFilters[FilterName.HEADLINES]"
                (datagridSelectedFilter)="
                    onDatagridFilterSelected(FilterName.HEADLINES, $event)
                "></cms-datagrid-filterable-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column class="u-w150p"
        >Author
        <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-right">
            <cds-icon shape="info-circle" size="16"></cds-icon>
            <span class="tooltip-content">This filter will display signed or assigned articles</span>
        </a>
        <clr-dg-filter [clrDgFilter]="contributorsFilter" [(clrDgFilterOpen)]="contributorsFilter.open">
            <cms-datagrid-composite-filter #contributorsFilter [filterName]="FilterName.CONTRIBUTORS_COMPOSITE">
                <cms-datagrid-filterable-filter
                    [list]="currentContributorList"
                    [type]="FilterTypes.CHECKBOX"
                    [filterName]="FilterName.CURRENT_CONTRIBUTOR"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.CURRENT_CONTRIBUTOR]"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.CURRENT_CONTRIBUTOR, $event)"
                    #injectedFilters></cms-datagrid-filterable-filter>

                <cms-datagrid-filterable-filter
                    [title]="'Search by name'"
                    [filterTitle]="'Authors'"
                    [type]="FilterTypes.CHECKBOX"
                    [list]="contributorsList"
                    [displayList]="false"
                    [filterName]="FilterName.CONTRIBUTORS"
                    [searchPlaceholder]="'Type a name'"
                    [showSelectedItemsAboveTheList]="true"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.CONTRIBUTORS]"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.CONTRIBUTORS, $event)"
                    #injectedFilters></cms-datagrid-filterable-filter>
            </cms-datagrid-composite-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column class="u-w120p"
        >Story Type
        <clr-dg-filter [clrDgFilter]="storyTypesFilter" [(clrDgFilterOpen)]="storyTypesFilter.open">
            <cms-datagrid-filterable-filter
                [title]="'Filter by story type'"
                [filterTitle]="'Story type'"
                [type]="FilterTypes.CHECKBOX"
                [list]="storyTypesList"
                [filterName]="FilterName.STORY_TYPES"
                [contributorFilter]="contributorDatagridFilters[FilterName.STORY_TYPES]"
                (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.STORY_TYPES, $event)"
                #storyTypesFilter></cms-datagrid-filterable-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column class="u-w80p"
        >Lang.
        <clr-dg-filter [clrDgFilter]="languagesFilter" [(clrDgFilterOpen)]="languagesFilter.open">
            <cms-datagrid-composite-filter #languagesFilter [filterName]="FilterName.LANGUAGE_AND_WITH_VIDEO_COMPOSITE">
                <cms-datagrid-filterable-filter
                    [list]="storiesWithVideoList"
                    [filterTitle]="'Stories with video'"
                    [type]="FilterTypes.CHECKBOX"
                    [filterName]="FilterName.STORIES_WITH_VIDEO"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.STORIES_WITH_VIDEO]"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.STORIES_WITH_VIDEO, $event)"
                    #injectedFilters></cms-datagrid-filterable-filter>

                <hr class="lang-filter-hr" />

                <section class="all-languages-filter">
                    <ul class="filterable-list list-unstyled compact">
                        <li class="filterable-list-item">
                            <clr-checkbox-wrapper>
                                <input
                                    type="checkbox"
                                    clrCheckbox
                                    class="listed-checkbox"
                                    [checked]="checkedAllLanguages"
                                    id="lst-langs-check-all"
                                    (change)="toggleAllLanguages()" />
                                <label
                                    for="lst-langs-check-all"
                                    [class.itemActive]="checkedAllLanguages"
                                    class="lst-langs-check-all"
                                    >All languages</label
                                >
                            </clr-checkbox-wrapper>
                        </li>
                    </ul>
                </section>

                <hr class="lang-filter-hr" />

                <cms-datagrid-filterable-filter
                    [list]="languagesList"
                    [filterTitle]="'Languages'"
                    [type]="FilterTypes.CHECKBOX"
                    [filterName]="FilterName.LANGUAGES"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.LANGUAGES]"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.LANGUAGES, $event)"
                    #injectedFilters
                    #languagesListFilter></cms-datagrid-filterable-filter>
            </cms-datagrid-composite-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column class="u-w160p"
        >Program & Vertical
        <clr-dg-filter [clrDgFilter]="programAndVerticalFilter" [(clrDgFilterOpen)]="programAndVerticalFilter.open">
            <cms-datagrid-composite-filter
                #programAndVerticalFilter
                [filterName]="FilterName.PROGRAMS_AND_VERTICALS_COMPOSITE">
                <cms-datagrid-filterable-filter
                    [title]="'Search by vertical'"
                    [filterTitle]="'Verticals'"
                    [type]="FilterTypes.SELECT"
                    [list]="verticalsList"
                    [filterName]="FilterName.VERTICALS"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.VERTICALS]"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.VERTICALS, $event)"
                    #injectedFilters
                    #groupFilter></cms-datagrid-filterable-filter>

                <cms-datagrid-filterable-filter
                    [title]="'Search by program'"
                    [filterTitle]="'Programs'"
                    [type]="FilterTypes.GROUPED"
                    [list]="programsList"
                    [displayList]="false"
                    [filterName]="FilterName.PROGRAMS"
                    [searchPlaceholder]="'Program name...'"
                    [displayFooter]="false"
                    [contributorFilter]="contributorDatagridFilters[FilterName.PROGRAMS]"
                    [groupFilter]="groupFilter.value[0]?.label || ''"
                    (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.PROGRAMS, $event)"
                    #injectedFilters
                    #filteredGroupedList></cms-datagrid-filterable-filter>
            </cms-datagrid-composite-filter>
        </clr-dg-filter>
    </clr-dg-column>

    <clr-dg-column class="u-w135p">
        <clr-dg-filter [clrDgFilter]="myDate" [(clrDgFilterOpen)]="myDate.open">
            <cms-datagrid-date-range-filter
                [filterName]="FilterName.PUBLICATION_DATE"
                [contributorFilter]="contributorDatagridFilters[FilterName.PUBLICATION_DATE]"
                #myDate></cms-datagrid-date-range-filter>
        </clr-dg-filter>
        Pub. Date
    </clr-dg-column>

    <clr-dg-column class="u-w135p">
        <clr-dg-filter [clrDgFilter]="lastPublishedAtDate" [(clrDgFilterOpen)]="lastPublishedAtDate.open">
            <cms-datagrid-date-range-filter
                [filterName]="FilterName.LAST_UPDATE_DATE"
                [contributorFilter]="contributorDatagridFilters[FilterName.LAST_UPDATE_DATE]"
                #lastPublishedAtDate></cms-datagrid-date-range-filter>
        </clr-dg-filter>
        Last Update
    </clr-dg-column>

    <clr-dg-column class="u-w100p"
        >Status
        <clr-dg-filter [clrDgFilter]="statusFilter" [(clrDgFilterOpen)]="statusFilter.open">
            <cms-datagrid-filterable-filter
                [list]="statusesList"
                [filterTitle]="'Status'"
                [type]="FilterTypes.CHECKBOX"
                [filterName]="FilterName.PUBLICATION_STATUSES"
                [contributorFilter]="contributorDatagridFilters[FilterName.PUBLICATION_STATUSES]"
                (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.PUBLICATION_STATUSES, $event)"
                #statusFilter></cms-datagrid-filterable-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column class="u-w50p">
        <clr-dg-filter [clrDgFilter]="indexedFilter" [(clrDgFilterOpen)]="indexedFilter.open">
            <cms-datagrid-filterable-filter
                [list]="indexationList"
                [filterTitle]="'Publication'"
                [type]="FilterTypes.CHECKBOX"
                [filterName]="FilterName.INDEXED"
                [contributorFilter]="contributorDatagridFilters[FilterName.INDEXED]"
                (datagridSelectedFilter)="onDatagridFilterSelected(FilterName.INDEXED, $event)"
                #indexedFilter></cms-datagrid-filterable-filter>
        </clr-dg-filter>
    </clr-dg-column>
    <!-- Row -->
    <clr-dg-row
        *ngFor="let story of stories"
        [ngClass]="{ 'status-breakingNews': story.isBreakingNews }"
        [clrDgExpanded]="showRowDetails">
        <!-- Update button -->
        <clr-dg-action-overflow>
            <button class="action-item" [routerLink]="['event', story.event.id]" [queryParamsHandling]="'preserve'">
                Update Event
            </button>
        </clr-dg-action-overflow>

        <!-- Event name -->
        <clr-dg-cell class="eventName u-cell-flex u-w250p">
            <img
                class="c-img"
                src="assets/images/search-box/{{ story.event.publicationOwner?.slug }}-96x96.png"
                width="20"
                height="20" />

            <a [routerLink]="['/story', story.id]" [title]="story.event.daletTitleName" class="eventName__link">
                {{ story.event.daletTitleName }}
            </a>

            <clr-tooltip *ngIf="story.event.productionNote" class="eventName__tooltip">
                <cds-icon
                    clrTooltipTrigger
                    role="img"
                    shape="info-circle"
                    size="20"
                    aria-label="Production note tooltip"></cds-icon>
                <clr-tooltip-content clrPosition="right" clrSize="md" *clrIfOpen>
                    {{ story.event.productionNote }}
                </clr-tooltip-content>
            </clr-tooltip>
        </clr-dg-cell>

        <!-- Event headline -->
        <clr-dg-cell class="eventHeadline">
            <form *ngIf="story.masterCms === 'v1'" ngNoForm action="http://{{ cmsv1Host }}/edition/cms/" method="post">
                <input type="hidden" name="currentCat" value="{{ story.event.program?.id }}" />

                <input type="hidden" name="loadArticle" value="{{ story.event.id }}" />

                <input class="cms-v1-link c-link--submit c-link--float" type="submit" value="Cms v1" />
            </form>
            <a
                [routerLink]="['/story', story.id]"
                [title]="story.articleHeadline ? story.articleHeadline : '*** NO HEADLINE FOR THIS STORY ***'"
                [innerHTML]="story.articleHeadline ? story.articleHeadline : '*** NO HEADLINE FOR THIS STORY ***'"
                class="c-link">
            </a>
        </clr-dg-cell>

        <!-- Author -->
        <clr-dg-cell class="u-w150p">
            <a
                *ngIf="!isPublicationStatusOnlineOrToRepublish(story) && !story.author"
                [routerLink]="['assign-contributor', story.id]"
                [queryParamsHandling]="'preserve'"
                class="btn btn-icon c-btn-icon--dashboard">
                <clr-icon *ngIf="story.assignee" shape="edit" size="10" class="is-solid"></clr-icon>
                <clr-icon *ngIf="!story.assignee" shape="i-plus" size="10" class="is-solid"></clr-icon>
            </a>
            {{ story.author || story.firstPublisher || story.assignee | contributorGetName }}
        </clr-dg-cell>

        <!-- Story type -->
        <clr-dg-cell class="storyType u-w120p" [ngClass]="{ 'datagrid__storyType--alert': story.isBreakingNews }">
            <span title="{{ getStoryType(story) }}">{{ getStoryType(story) }}</span>
        </clr-dg-cell>

        <!-- Language -->
        <clr-dg-cell class="narrow-column u-w80p">
            <span>{{ story.language.isoCode }}</span>
            <cms-connected-contributors
                [display]="'dashboard'"
                [type]="'story'"
                [connectedContributorData]="connectedContributorData[story.id]">
            </cms-connected-contributors>
        </clr-dg-cell>

        <!-- Program -->
        <clr-dg-cell class="txt-bold u-w160p">
            <span class="u-line-overflow">{{
                story.event.program?.translations | translationFilter : 'title' | titlecase
            }}</span>
        </clr-dg-cell>

        <!-- Publication date -->
        <clr-dg-cell class="date u-w135p">
            <span *ngIf="story.publishedAt">
                {{ story.publishedAt | formatDate }}
            </span>
        </clr-dg-cell>

        <!-- Last update date -->
        <clr-dg-cell class="date u-w135p">
            <span *ngIf="story.lastPublishedAt">
                {{ story.lastPublishedAt | formatDate }}
            </span>
        </clr-dg-cell>

        <!-- Publication status -->
        <clr-dg-cell class="u-w100p">
            <cms-publication-status [story]="story"></cms-publication-status>
        </clr-dg-cell>

        <!-- Indexation status -->
        <clr-dg-cell class="u-w50p">
            <cms-indexation-status [story]="story"></cms-indexation-status>
        </clr-dg-cell>

        <!-- Row details -->
        <cms-dashboard-galaxy-edition-detail
            *ngIf="showRowDetails"
            [story]="story"
            ngProjectAs="clr-dg-row-detail"></cms-dashboard-galaxy-edition-detail>
    </clr-dg-row>

    <!-- Footer -->
    <clr-dg-footer>
        <clr-dg-pagination #pagination [clrDgTotalItems]="total" [clrDgPageSize]="itemsPerPage" [clrDgPage]="page">
            {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} stories
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>

<cms-item-legend></cms-item-legend>
