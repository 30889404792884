import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'
import { Socket, SocketIoConfig } from 'ngx-socket-io'
import { SocketIoClientInterface } from 'services/notifications/clients/socket-io-client.interface'

@Injectable({ providedIn: 'root' })
export class SocketIoClientService implements SocketIoClientInterface {
    constructor(private socket: Socket) {}

    emit(eventName: string, data?: any): void {
        return this.socket.emit(eventName, data)
    }

    on(eventName: string) {
        return this.socket.fromEvent(eventName)
    }
}

export const socketIoConfig: SocketIoConfig = environment.socketIo
