import { Component, Input, OnInit } from '@angular/core'

@Component({
    selector: 'cms-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
    @Input() type: 'inline'
    @Input() size: 'sm' | 'md' | 'lg'
    @Input() inlineMessage: string

    ngOnInit(): void {
        this.inlineMessage = this.inlineMessage || 'Loading...'
    }
}
