<section class="c-custom-filter c-custom-filter--datepicker qa-daterange-filter">
    <div class="c-custom-filter__arrow"></div>
    <div class="c-custom-filter__header">
        <div class="c-custom-filter__header-title">Filter by date</div>
    </div>
    <div class="c-custom-filter__body">
        <owl-date-time-inline
            id="filterDate"
            [(ngModel)]="value"
            [pickerType]="'calendar'"
            [selectMode]="'range'"></owl-date-time-inline>
    </div>
    <div *ngIf="displayFooter" class="c-custom-filter__footer">
        <button type="button" class="btn btn-outline" (click)="resetValue()">Clear</button>
        <button type="button" class="btn btn-primary" (click)="filter()">Apply</button>
    </div>
</section>
