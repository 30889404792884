import { Component } from '@angular/core'
import { Router } from '@angular/router'

@Component({
    selector: 'cms-unauthorized',
    templateUrl: './unauthorized.component.html',
})
export class UnauthorizedComponent {
    constructor(private readonly router: Router) {}

    public redirect(): void {
        this.router.navigate(['/dashboard-galaxy'])
    }
}
