import { environment } from 'environments/environment'

const COOKIE_NAME = environment.debugCookie
const noop = () => {}

const url = new URL(window.location.href)
// This will allow to set the cookie from an url, for ease of use
const addDebugCookie = url.searchParams.get(COOKIE_NAME) || false

if (addDebugCookie) {
    document.cookie = `${COOKIE_NAME}=true`
}

// retrieving the cookies as { key : values }
const cookies = document.cookie.split(';').reduce((dict, cookie) => {
    const [key, value] = cookie.trim().split('=')
    dict[key] = value

    return dict
}, {})

// cookies are strings :(
const hasDebugCookie = cookies[COOKIE_NAME] === 'true'

if (!hasDebugCookie && environment.production) {
    Object.keys(console).forEach(method => {
        console[method] = noop
    })
}
