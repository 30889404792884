<div *ngIf="displaySurveyModal">
    <clr-modal [(clrModalOpen)]="isModalOpen" [clrModalSize]="'lg'">
        <div class="modal-title">
            <h1 class="title-styling">We'd like to hear from you</h1>
        </div>
        <div
            class="modal-body"
            [ngSwitch]="displayModalBody"
            [ngClass]="modalSize === 'lg' ? 'modal-body-xl' : 'modal-body-md'">
            <ng-container *ngSwitchCase="true">
                <div class="center-spinner">
                    <span class="spinner margin-spinner" *ngIf="displaySpinner"> Loading... </span>
                </div>
                <iframe [src]="safeSurveyLinkUrl" class="styled-iframe" (load)="finishedLoadingIframe()"></iframe>
            </ng-container>

            <ng-container *ngSwitchCase="false" class="">
                <h3 class="question-styling">Please answer the following short survey and help us improve your cms.</h3>
                <div class="entry-modal-body">
                    <button type="button" (click)="showSurveyForm()" class="btn btn-success success-button">
                        Yes, sure !
                    </button>
                    <button type="button" (click)="remindMeLater()" class="btn btn-link btn-sm">Maybe later</button>
                </div>
            </ng-container>
        </div>
    </clr-modal>
</div>
