import { DatePipe } from '@angular/common'
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core'

const DefaultDateFormat: string = 'dd/MM/yy HH:mm'

@Pipe({ name: 'formatDate' })
export class FormatDatePipe extends DatePipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) locale: string) {
        super(locale)
    }

    transform(value: any, timezone?: string, locale?: string, dateFormat?: string): any {
        return super.transform(value, dateFormat ?? DefaultDateFormat, timezone, locale)
    }
}
