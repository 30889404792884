/**
 * A shared Global config for the CMS app
 *
 * Apparently this is the "official" Angular 2 way to do constants and stuff. See here:
 *
 * http://stackoverflow.com/questions/34986922/define-global-constants-in-angular-2/40287063#40287063
 * https://angular.io/docs/ts/latest/guide/dependency-injection.html#!#dependency-injection-tokens
 *
 * Here's how to use it within your services...
 *
 * import { APP_CONFIG, IAppConfig } from '../path/to/shared/shared.config'
 *
 * @Injectable()
 * export class YourWonderfulService {
 *    constructor(@Inject(APP_CONFIG) private sharedConfig: IAppConfig) {
 *             // You can use sharedConfig properties now
 *    }
 * }
 */
import { InjectionToken } from '@angular/core'
import { LanguageIsoCode } from 'enums/language'
import { IAppConfig } from './i-app-config'

export const APP_CONFIG = new InjectionToken('app.config')

export const AppConfig: IAppConfig = {
    polling: {
        liveStreamDelay: 10000,
    },
    itemPerPage: {
        dashboardEdition: 30,
        dashboardEvents: 10,
        dashboardLiveStreamSection: 5,
    },
    breakingNewsDefaultSummary: [
        {
            isoCode: LanguageIsoCode.ENGLISH,
            title: 'Breaking news',
            summary:
                'Our journalists are working on this story and will update it as soon as more information becomes available.',
        },
        {
            isoCode: LanguageIsoCode.FRENCH,
            title: 'Dernière minute',
            summary: 'Nos journalistes suivent cette information et l’actualiseront dès qu’ils en sauront davantage.',
        },
        {
            isoCode: LanguageIsoCode.GERMAN,
            title: 'Eilmeldung',
            summary: `Unsere Journalisten arbeiten gerade an dieser Meldung. Sobald es mehr Informationen gibt, halten
             wir Sie auf dem Laufenden.`,
        },
        {
            isoCode: LanguageIsoCode.ITALIAN,
            title: "Ultim'ora",
            summary: `I nostri giornalisti stanno lavorando su questa notizia e l’aggiorneranno non appena saranno
             disponibili ulteriori informazioni.`,
        },
        {
            isoCode: LanguageIsoCode.SPANISH,
            title: 'Última hora',
            summary: 'Nuestros periodistas trabajan en esta noticia y la actualizarán en cuanto haya mas información.',
        },
        {
            isoCode: LanguageIsoCode.PORTUGUESE,
            title: 'Última hora',
            summary: 'Os nossos jornalistas estão a acompanhar a notícia que será atualizada sempre que se justifique.',
        },
        {
            isoCode: LanguageIsoCode.TURKISH,
            title: 'Son Dakika',
            summary:
                'Gazetecilerimiz haber üzerinde çalışıyor. Yeni gelen bilgilerle haberi en kısa zamanda güncelleyeceğiz.',
        },
        {
            isoCode: LanguageIsoCode.UKRAINIAN,
            title: 'Гарячі новини',
            summary:
                'Наші журналісти працюють над цією історією, і ми подамо подробиці щойно з’явиться додаткова інформація.',
        },
        {
            isoCode: LanguageIsoCode.RUSSIAN,
            title: 'Срочная новость',
            summary:
                'Наши журналисты работают над этим сюжетом, он будет обновлён, как только появится дополнительная информация.',
        },
        {
            isoCode: LanguageIsoCode.ARABIC,
            title: 'عاجل',
            summary: 'الصحفيون يعملون على تحرير هذه القصة، سيتم التحديث بأسرع وقت بالمزيد من المعلومات فور ورودها',
        },
        {
            isoCode: LanguageIsoCode.PERSIAN,
            title: 'خبر فوری',
            summary: 'این خبر در حال تکمیل است و بزودی روزآمد خواهد شد',
        },
        {
            isoCode: LanguageIsoCode.GREEK,
            title: 'Έκτακτη Είδηση',
            summary:
                'H δημοσιογραφική μας ομάδα θα σας ενημερώνει συνεχώς για την έκτακτη αυτή είδηση, με όλες τις τελευταίες εξελίξεις.',
        },
        {
            isoCode: LanguageIsoCode.HUNGARIAN,
            title: 'Rendkívüli hír',
            summary: 'Folyamatosan dolgozunk a cikken, amint új fejlemény van, frissítjük!',
        },
        {
            isoCode: LanguageIsoCode.POLISH,
            title: 'Najświeższe wiadomości',
            summary:
                'Nasi dziennikarze pracują nad tą historią i zaktualizują ją, gdy tylko pojawią się dodatkowe informacje.',
        },
    ],
    messagesLibrary: {
        errorGettingData: 'An error occurred while fetching data.',
        errorSavingData: 'An error occurred while saving the data. Please try again.',
    },
}
