// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
    debugCookie: 'zogzog',
    production: false,
    useStoryMock: false,
    apollo: {
        baseUrl: 'http://10.3.70.211:3000/api/',
    },
    mercury: {
        baseUrl: 'https://api.qat.dev.cms.euronews.tech/graphql',
        baseUrlREST: 'https://api.qat.dev.cms.euronews.tech',
    },
    images: {
        storyFallback: '/assets/images/fallback.jpg',
        static: 'https://images.qat.euronews.com',
        staticProd: 'https://static.euronews.com',
    },
    medias: {
        urlDalet: 'http://assetfileserver.dalet.euronews.lan.int.local/STORAGE',
        WordStorage: 'STORAGE',
        urlWebsiteVideo: 'https://video.qat.euronews.com/mp4/',
        formatImage: 'BMP',
        formatVideo: 'mp4',
    },
    cmsv1: {
        host: 'cms.qat.africanews-staging.lan',
    },
    euronews: {
        host: 'qat.euronews.com',
        prodHost: 'euronews.com',
    },
    africanews: {
        host: 'qat.africanews.com',
        prodHost: 'africanews.com',
    },
    clarityProjectId: '',
    socketIo: {
        url: 'https://notification.qat.dev.cms.euronews.tech',
        options: {
            transports: ['websocket'],
            path: '',
        },
    },
    feedHub: {
        feedHubUrl: 'https://feedhub.qat.euronews.com/',
        documentationPath: 'documentation',
        feedHubGuideUrl:
            'https://euronews.sharepoint.com/:w:/s/T-PROC-KnowledgeBase/EZvL3JE_cGFHl77-CDVld2wBe9BKreoI_nahXoNIo0G22Q?e=njjeqW',
    },
    duplicationGuideUrl:
        'https://euronews.sharepoint.com/:w:/s/T-PROC-KnowledgeBase/EVaY0eAvXR1AgnnjygXeIP0B1n-UtduyBvIRngt11cT5dQ?e=qS69oY',
}
