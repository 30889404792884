import * as clone from 'clone'
import { UserRight, UserRole } from '../enums/user-role'
import { Contributor as ContributorInterface } from '../interfaces/contributors'
import {
    ContributorDepartment,
    ContributorJob,
    ContributorModel,
    ContributorTranslation,
} from '../models/contributor.model'
import { LanguageModel } from '../models/language.model'

export class Contributor implements ContributorInterface {
    id: number
    slug: string
    isImageActive: boolean
    isBioActive: boolean
    isBetaTester: boolean
    isActive: boolean
    twitterScreenName: string
    translations: ContributorTranslation[]
    writtenLanguage: LanguageModel
    job: ContributorJob | null
    department: ContributorDepartment
    acl: {
        roles?: UserRole[]
        rights?: UserRight[]
        languages?: string[]
        allowedTo?: UserRight[]
    }

    /**
     *
     * @param contributor
     */
    constructor(contributor?: ContributorModel) {
        if (contributor !== undefined) {
            const copy = clone(contributor)
            Object.assign(this, copy)
        }
    }

    getJobId(): number | null {
        if (this.job) {
            return this.job.id
        }

        return null
    }

    getDepartmentId(): number | null {
        if (this.department) {
            return this.department.id
        }

        return null
    }

    getWrittenLanguageId(): number | null {
        if (this.writtenLanguage) {
            return this.writtenLanguage.id
        }

        return null
    }

    getActiveStatus(): boolean {
        return this.isActive
    }

    getTwitterScreenName(): string {
        return this.twitterScreenName
    }
}
