<div class="c-legend">
    <div class="row">
        <div class="col-sm-12">
            <p class="c-legend__content">
                <span class="c-legend__title">Legend : </span>
                <span class="c-legend__new">New</span>
                <span class="c-legend__edit">Edit</span>
                <span class="c-legend__submitted">Submitted</span>
                <span class="c-legend__approved">Approved</span>
                <span class="c-legend__online">Online</span>
                <span class="c-legend__offline">Offline</span>
                <span class="c-legend__intranslation">In duplication | Duplicated</span>
                <span class="c-legend__scheduled">Scheduled | Awaiting</span>
                <span class="c-legend__torepublish">To republish | Ready to publish</span>
                <span class="c-legend__underline"><i>Underline</i> : selected lang.</span>
                <span class="c-legend__framed"><i>Framed</i> : with video</span>
            </p>
        </div>
    </div>
</div>
