<div class="login-wrapper c-login__bg">
    <form clrFrom [formGroup]="form" class="c-login login u-outer-shadow u-form-fullwidth" (submit)="onSubmit()">
        <label class="title">
            <h1>
                <img class="c-login__logo" src="assets/images/euronews_horizontal_flat.svg" />
                <span class="c-login__trademark">&#8482;</span>
            </h1>
            <h2>CMS <strong>v2</strong><span class="c-login__copyright"> &#174;</span></h2>
        </label>

        <div class="login-group">
            <clr-input-container>
                <input
                    clrInput
                    type="text"
                    id="login_username"
                    data-test-e2e="login-username"
                    name="username"
                    formControlName="username"
                    placeholder="Username"
                    required
                    autofocus />
            </clr-input-container>
            <clr-input-container>
                <input
                    clrInput
                    data-test-e2e="login-password"
                    type="password"
                    id="login_password"
                    name="password"
                    formControlName="password"
                    placeholder="Password"
                    required />
            </clr-input-container>

            <div data-test-e2e="login-error-message" class="error active" *ngIf="invalidCredential && !submitted">
                Invalid user name or password
            </div>

            <button type="submit" class="btn btn-primary" [disabled]="submitted" data-test-e2e="login-button">
                <span id="logging-in" class="c-login__spinner" *ngIf="submitted">
                    <cms-spinner type="inline" size="md" inlineMessage="LOGGING IN…"></cms-spinner>
                </span>
                <span *ngIf="!submitted">LOG IN</span>
            </button>
        </div>
    </form>
</div>
