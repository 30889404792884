import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { takeUntil } from 'rxjs/operators'
import { AuthService } from '../../services/auth/auth'
import { BaseAbstractComponent } from '../shared/base.component'

@Component({
    selector: 'cms-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseAbstractComponent implements OnInit, OnDestroy {
    form = new FormGroup({
        username: new FormControl('', { validators: Validators.required, nonNullable: true }),
        password: new FormControl('', { validators: Validators.required, nonNullable: true }),
    })
    submitted: boolean = false
    invalidCredential: boolean = false
    protected returnUrl: string

    constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService) {
        super()
    }

    onSubmit() {
        this.submitted = true
        this.authService
            .login(this.form.value.username!, this.form.value.password!) // not pretty, but gets the job done
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: res => {
                    if (res.status === 200) {
                        this.invalidCredential = false

                        this.authService
                            .getCurrentUser()
                            .pipe(takeUntil(this.ngUnsubscribe))
                            .subscribe(
                                () => {
                                    this.router.navigate([this.returnUrl])
                                },
                                () => {
                                    this.invalidCredential = true
                                    this.submitted = false
                                },
                            )
                    } else {
                        this.invalidCredential = true
                    }

                    this.submitted = false
                },
                error: () => {
                    this.invalidCredential = true
                    this.submitted = false
                },
            })
    }

    ngOnInit() {
        this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            this.returnUrl = params['returnUrl'] || '/dashboard-galaxy'
        })
    }

    ngOnDestroy() {
        super.ngOnDestroy()
    }
}
