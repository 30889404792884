import { gql } from '@apollo/client/core'
import { storyMutateFragment } from '../find-by-id.query'

export const getStoryDaletItemcodes = gql`
    ${storyMutateFragment}

    query getStoryDaletItemcodes($storyId: Int!) {
        story(id: $storyId) {
            id
            daletItemcodes {
                daletItemcode
                daletDigitalId
                createdAt
                notifiedAt
            }
            ...storyMutateInfos
        }
    }
`
