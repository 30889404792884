import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApolloLink, FetchResult, Observable as LinkObservable, Operation, RequestHandler } from '@apollo/client/core'
import { Context, fetch, mergeHeaders, Options, prioritize, Request } from 'apollo-angular-link-http-common'
import { print } from 'graphql/language/printer'
import RecursiveIterator from 'recursive-iterator'

export interface RequestOperation {
    query?: string
    variables?: Record<string, any>
    operationName?: string
    extensions?: Record<string, any>
}

// XXX find a better name for it
export class HttpUploadLinkHandler extends ApolloLink {
    public requester: RequestHandler

    constructor(private httpClient: HttpClient, private options: Options) {
        super()

        this.requester = (operation: Operation) =>
            new LinkObservable((observer: any) => {
                const context: Context = operation.getContext()

                // decides which value to pick, Context, Options or to just use the default
                const pick = <K extends keyof Context | keyof Options>(
                    key: K,
                    init?: Context[K] | Options[K],
                ): Context[K] | Options[K] => {
                    return prioritize(context[key], this.options[key], init)
                }

                const includeQuery = pick('includeQuery', true) as any
                const includeExtensions = pick('includeExtensions', false) as any
                const method = pick('method', 'POST') as any
                const url = pick('uri', 'graphql') as any
                const withCredentials = pick('withCredentials') as any

                const requestOperation: RequestOperation = {
                    operationName: operation.operationName,
                    variables: operation.variables,
                }
                if (includeExtensions) {
                    requestOperation.extensions = operation.extensions
                }

                if (includeQuery) {
                    requestOperation.query = print(operation.query)
                }

                const body: any = new FormData()

                // search for File objects on the request and set it as formData
                for (const { node, path } of new RecursiveIterator(operation.variables)) {
                    if (node instanceof File) {
                        body.append(path, node)
                    }
                }

                body.append('query', print(operation.query))
                body.append('variables', JSON.stringify(operation.variables))
                body.append('operationName', operation.operationName)

                const req: Request = {
                    method,
                    url,
                    body,
                    options: {
                        withCredentials,
                        headers: this.options.headers,
                    },
                }

                if (context.headers) {
                    if (req.options.headers) {
                        req.options.headers = mergeHeaders(req.options.headers, context.headers)
                    }
                }
                const extractFile: any = null
                const sub = fetch(req, this.httpClient, extractFile).subscribe({
                    next: result => observer.next(result.body),
                    error: err => observer.error(err),
                    complete: () => observer.complete(),
                })

                return () => {
                    if (!sub.closed) {
                        sub.unsubscribe()
                    }
                }
            })
    }

    public request(op: Operation): LinkObservable<FetchResult> | null {
        const forward: any = null

        return this.requester(op, forward)
    }
}

@Injectable()
export class HttpUploadLink {
    constructor(private httpClient: HttpClient) {}

    public create(options: Options): HttpUploadLinkHandler {
        return new HttpUploadLinkHandler(this.httpClient, options)
    }
}
