import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { ContributorFilterModel } from 'app/shared/filter/datagrid-filterables/filterables.interface'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter, map, pluck } from 'rxjs/operators'
import { BaseService } from 'services/base'
import { ErrorsMessagesService } from 'services/errors-messages/errors-messages.service'
import { networkQuery } from 'tools/apollo'
import { createContributorFilter, deleteContributorFilter, myFiltersQuery } from './filters.query'

@Injectable()
export class ContributorFiltersService extends BaseService {
    protected _contributorFilters: BehaviorSubject<any[]> = new BehaviorSubject([])
    public contributorFilters: Observable<any[]> = this._contributorFilters
        .asObservable()
        .pipe(filter(value => !!value))

    constructor(apollo: Apollo, errorsMessagesService: ErrorsMessagesService) {
        super(apollo, errorsMessagesService)
    }

    create(label: string, filters: string): Observable<ContributorFilterModel> {
        const data = {
            label,
            filters,
        }
        const mutation = createContributorFilter

        return this.apollo
            .mutate({
                mutation,
                variables: data,
            })
            .pipe(pluck('data', 'createContributorFilter'))
    }

    delete(id: number): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: deleteContributorFilter,
                variables: { id },
            })
            .pipe(map((res: any) => res.data !== undefined && res.data['deleteI18n'] === true))
    }

    list(): void {
        networkQuery(
            this.apollo,
            {
                query: myFiltersQuery,
            },
            ['data', 'myFilters'],
        ).subscribe((data: any[]) => {
            this._contributorFilters.next(data)
        })
    }
}
