import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { DashboardSearchFiltersStories, DashboardSearchParams } from 'enums/filters'
import { StoryModel } from 'models/story.model'
import { Observable, of, Subject } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { cacheAndNetworkQuery } from 'tools/apollo'
import { QueryResultWithPaginationInterface, ResultPaginationInterface } from '../../interfaces/mercury'
import { AuthService } from '../auth/auth'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { getQueryOptions } from '../shared/options.query'
import { DashboardAbstract } from './dashboard-abstract'
import { storiesQuery } from './stories.query'

@Injectable()
export class StoriesService extends DashboardAbstract {
    protected authService: AuthService

    public refresh = new Subject()

    constructor(apollo: Apollo, errorMessageService: ErrorsMessagesService) {
        super(apollo, errorMessageService)
    }

    list(
        searchParams: DashboardSearchParams<DashboardSearchFiltersStories>,
    ): Observable<QueryResultWithPaginationInterface<StoryModel>> {
        const { filters, limit, offset, order } = searchParams
        const variables = this.getDefaultQueryVariables(filters)

        if (filters.deskId) {
            variables['search']['pyramidDeskId'] = filters.deskId
        }

        if (filters.assignedTo?.length) {
            variables['search']['assignedTo'] = filters.assignedTo
        }

        if (filters.langId) {
            variables['search']['langId'] = filters.langId
        }

        variables['search'] = Object.assign(this.getStoryFilters(filters), variables['search'])
        variables['options'] = getQueryOptions(limit, offset, order)

        return cacheAndNetworkQuery<QueryResultWithPaginationInterface<StoryModel>>(
            this.apollo,
            {
                query: storiesQuery,
                variables,
            },
            ['data', 'stories'],
        ).pipe(
            catchError(error => {
                // add an error
                this.errorMessagesServices.addErrorsMessage(error)

                return of({
                    pagination: {} as ResultPaginationInterface,
                    results: [] as StoryModel[],
                })
            }),
        )
    }
}
