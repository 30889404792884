import { gql } from '@apollo/client/core'
import { ownerFragment } from '../publication-owner/publication-owner.fragment'
import { paginationFragment } from '../shared/pagination.fragment'
import { channelTypeFragment } from './publication-channel.fragment'

export const publicationChannelQuery = gql`
    ${paginationFragment}
    ${ownerFragment}
    ${channelTypeFragment}

    query publicationChannels($pagination: PaginationInput) {
        publicationChannels(isSelectableCms: true, options: { pagination: $pagination }) {
            results {
                id
                type {
                    ...channelTypeInfo
                }
                owner {
                    ...ownerInfo
                }
                labelLong
                labelShort
            }
            pagination {
                ...paginationInfo
            }
        }
    }
`

export const getPublicationChannelsByProgramDeliverableIdQuery = gql`
    ${ownerFragment}
    ${channelTypeFragment}

    query programDeliverableHasPublicationChannel($id: Int!) {
        programDeliverableHasPublicationChannel(programDeliverableId: $id) {
            id
            type {
                ...channelTypeInfo
            }
            owner {
                ...ownerInfo
            }
            labelLong
            labelShort
            isSelectableCms
        }
    }
`
