export enum EGeneralEvent {
    CONNECTION = 'connection',
    DISCONNECT = 'disconnect',
    RECONNECT = 'reconnect',
}

export enum EDisplayType {
    DASHBOARD = 'dashboard',
    ELEMENT = 'element',
}

export enum ERoomType {
    STORY = 'story',
    TRANSLATION = 'translation',
    GALLERY = 'gallery',
}

export enum EContributorsNotification {
    EVENT_JOINED = 'EVENT_JOINED',
    EVENT_LEFT = 'EVENT_LEFT',
    ON_FETCH_ALL_DATA = 'ON_FETCH_ALL_DATA',
    ON_READ_ALL_DATA = 'ON_READ_ALL_DATA',
    ON_READ_ALL_DATA_FOR_A_ROOM = 'ON_READ_ALL_DATA_FOR_A_ROOM',
    REFRESH_EVENT = 'REFRESH_EVENT',
}

export enum EPurgeContributorsNotification {
    PURGE_ROOM_TYPE = 'PURGE_ROOM_TYPE',
    PURGE_CONTRIBUTORS_SELECTION = 'PURGE_CONTRIBUTORS_SELECTION',
}
