import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'imageUrl' })
export class ImageUrlPipe implements PipeTransform {
    transform(input?: string, width = 150, height = 150): string {
        if (!input) {
            return ''
        }

        return input.replace(/{{h}}/g, height.toString()).replace(/{{w}}/g, width.toString())
    }
}
