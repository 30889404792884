export enum StoryTextFormatsType {
    textile = 1,
    markdown = 2,
    plaintext = 3,
    prosemirror = 4,
    html = 5,
}

export enum StoryTextFormatsSlug {
    TEXTILE = 'textile',
    MARKDOWN = 'markdown',
    PLAINTEXT = 'plaintext',
    PROSEMIRROR = 'prosemirror',
    HTML = 'html',
}
