import { Component, Input } from '@angular/core'

export type AlertType = 'success' | 'info' | 'warning' | 'danger'
export interface AlertAction {
    label: string
    url: string
}

@Component({
    selector: 'cms-alert-item',
    templateUrl: './alert-item.component.html',
    styleUrls: ['./alert-item.component.scss'],
})
export class AlertItemComponent {
    @Input() alertClosable?: boolean = false
    @Input() alertClosed?: boolean = false
    @Input() alertType?: AlertType = 'info'
    @Input() alertSizeSmall?: boolean = false
    @Input() alertAppLevel?: boolean = false
    @Input() alertMessage?: string
    @Input() alertAction?: AlertAction

    constructor() {}

    onClose(): void {
        this.alertMessage = undefined
        this.alertAction = undefined
    }
}
