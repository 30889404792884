import { gql } from '@apollo/client/core'
import { eventEnglishGuideFragment } from './event-english-guide.fragment'

export const getEventEnglishGuide = gql`
    ${eventEnglishGuideFragment}

    query getEventEnglishGuide($eventId: Int!) {
        getEventEnglishGuide(eventId: $eventId) {
            ...eventEnglishGuideInfos
        }
    }
`

export const generateEventEnglishGuide = gql`
    ${eventEnglishGuideFragment}

    mutation generateEventEnglishGuide($eventId: Int!) {
        generateEventEnglishGuide(eventId: $eventId) {
            ...eventEnglishGuideInfos
        }
    }
`
