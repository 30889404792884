import { gql } from '@apollo/client/core'
import { paginationFragment } from 'services/shared/pagination.fragment'
import { themeFragment } from 'services/themes/themes.fragment'
import { verticalWithEnTranslationFragment } from 'services/verticals/verticals.fragment'

export const englishVerticalsWithThemesQuery = gql`
    ${paginationFragment}
    ${verticalWithEnTranslationFragment}
    ${themeFragment}

    query verticals($options: QueryOptions) {
        verticals(options: $options) {
            pagination {
                ...paginationInfo
            }
            results {
                ...verticalWithEnTranslationInfo
                themes {
                    ...themeInfo
                }
            }
        }
    }
`
