import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core'
import { ClrAlerts } from '@clr/angular'
import { UserRole } from 'enums/user-role'
import { CloseMessagePayload, ErrorMessage } from 'models/messages-notification.model'
import { AuthService } from '../../../services/auth/auth'
import { ErrorsMessagesService } from '../../../services/errors-messages/errors-messages.service'
import { MessagesNotificationService } from '../../../services/notifications/messages-notification.service'

const ALERT_INTERVAL = 5000

@Component({
    selector: 'cms-errors-messages',
    templateUrl: './errors-messages.component.html',
    styleUrls: ['./errors-messages.component.scss'],
})
export class ErrorsMessagesComponent implements OnInit {
    @ViewChild(ClrAlerts) alertsContainer: ClrAlerts

    private interval: NodeJS.Timer

    constructor(
        public errorMessagesServices: ErrorsMessagesService,
        public authService: AuthService,
        private changeDetectorRef: ChangeDetectorRef,
        private notification: MessagesNotificationService,
    ) {}

    public ngOnInit(): void {
        this.notification.subscribeToMessages()

        this.notification.onReadAllMessages().subscribe((messages: ErrorMessage[]) => {
            const allMessagesList: ErrorMessage[] = []

            messages.forEach((message: ErrorMessage) => {
                if (this.shouldDisplayMessage(message)) {
                    allMessagesList.push(message)
                }
            })

            // We clear all displayed messages before updating them
            this.errorMessagesServices.clearAllMessages()

            allMessagesList.forEach((message: ErrorMessage) => {
                const mess = {
                    id: message.id,
                    message: message.message,
                    buttonLabel: message.buttonLabel,
                    buttonLink: message.buttonLink,
                    level: message.level,
                }
                this.errorMessagesServices.addErrorsMessage(mess)
            })

            clearInterval(this.interval)

            if (allMessagesList.length > 1) {
                this.interval = setInterval(() => {
                    this.alertsContainer.multiAlertService.next()
                }, ALERT_INTERVAL)
            }
        })

        // We need this to inform clarity that the messages change
        this.errorMessagesServices.changeDetectionEmitter.subscribe(() => {
            this.changeDetectorRef.detectChanges()
            if (!this.alertsContainer.currentAlert) {
                this.alertsContainer.multiAlertService.current = 0
            }
        })
    }

    public onClose(message: ErrorMessage): void {
        const payload: CloseMessagePayload = {
            username: this.authService.getUserContributorName(),
            messageId: message.id,
        }
        this.notification.onCloseMessage(payload)
    }

    public isExternalLink(link: string): boolean {
        return link.startsWith('http')
    }

    private shouldDisplayMessage(message: ErrorMessage): boolean {
        const userContributorName: string = this.authService.getUserContributorName()
        const userHasNotClosedMessage: boolean = !message.excludedUsers.includes(userContributorName)

        const targetRoles: UserRole[] = JSON.parse(message.targetRoles || '[]')
        const userIsDev: boolean = this.authService.userHasRole(UserRole.DEVELOPER)
        const userIsTargeted: boolean =
            !targetRoles.length || targetRoles.some(role => this.authService.userHasRole(role)) || userIsDev

        return this.authService.isAuthentified && userHasNotClosedMessage && userIsTargeted
    }
}
