import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { Contributor } from 'entities/contributors'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { filter } from 'rxjs/operators'
import { cacheAndNetworkQuery } from 'tools/apollo'
import { ResultPaginationInterface, SortInterface, QueryResultWithPaginationInterface } from '../../interfaces/mercury'
import { ContributorModel } from '../../models/contributor.model'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { getQueryOptions } from '../shared/options.query'
import { findAllContributorsQuery } from './find-all.query'
import { findByNameQuery } from './find-by-name.query'
import { findByTermQuery } from './find-by-term.query'

interface ContributorFindByResult {
    pagination: ResultPaginationInterface
    results: ContributorModel[]
}

@Injectable()
export class ContributorsListService extends BaseService {
    protected _contributors: BehaviorSubject<ContributorModel[]> = new BehaviorSubject([] as ContributorModel[])
    protected _pagination: BehaviorSubject<ResultPaginationInterface | undefined> = new BehaviorSubject(undefined)

    public refresh = new Subject()
    public contributors = this._contributors.asObservable()
    public pagination = this._pagination.asObservable().pipe(filter(pagination => Boolean(pagination !== undefined)))

    contributorQueryPagination = {
        limit: 2000,
        offset: 0,
    }

    constructor(apollo: Apollo, protected errorMessagesServices: ErrorsMessagesService) {
        super(apollo, errorMessagesServices)
    }

    /**
     * Search contributor by a term
     *
     * Perform research on firstname, lastname, department or job
     *
     * @param {string} term
     * @param {number} limit
     * @param {number} offset
     * @param {string} order
     *
     * @returns {Subscription}
     */
    protected prepareFindByTerm(
        search: { term: string; language?: string },
        limit: number,
        offset: number,
        order?: SortInterface,
    ): Observable<any> {
        if (!order) {
            order = {
                key: 'translations.surname',
                direction: 'ASC',
            }
        }
        const variables = {
            options: getQueryOptions(limit, offset, order),
            term: search.term,
        }
        if (Object.prototype.hasOwnProperty.call(search, 'language')) {
            variables['language'] = search.language
        }

        return cacheAndNetworkQuery(
            this.apollo,
            {
                query: findByTermQuery,
                variables,
            },
            ['data'],
        )
    }

    /**
     * Search contributor by a term
     *
     * Perform research on firstname, lastname, department or job
     *
     * @param {string} term
     * @param {number} limit
     * @param {number} offset
     * @param {string} order
     *
     * @returns {Subscription}
     */
    findByTerm(search: { term: string; language?: string }, limit: number, offset: number, order?: SortInterface) {
        return this.prepareFindByTerm(search, limit, offset, order).subscribe(
            (data: { contributorsByTerm: ContributorFindByResult }) => {
                this._contributors.next(data.contributorsByTerm.results)
                this._pagination.next(data.contributorsByTerm.pagination)
            },
            error => {
                // returned an empty contributors' array
                this._contributors.next([])
                // add an error
                this.errorMessagesServices.addErrorsMessage(error)
            },
            () => {},
        )
    }

    /**
     * Search contributor by his lastname
     *
     * You can use a sql wild card for the name like "A%"
     *
     * @param {string} name
     * @param {number} limit
     * @param {number} offset
     * @param {string} order
     *
     * @returns {Subscription}
     */
    findByLastname(name: string, limit: number, offset: number, order?: SortInterface) {
        if (!order) {
            order = {
                key: 'translations.surname',
                direction: 'ASC',
            }
        }

        return cacheAndNetworkQuery(
            this.apollo,
            {
                query: findByNameQuery,
                variables: {
                    options: getQueryOptions(limit, offset, order),
                    lastname: name,
                },
            },
            ['data'],
        ).subscribe(
            (data: { contributorsByLastname: ContributorFindByResult }) => {
                this._contributors.next(data.contributorsByLastname.results)
                this._pagination.next(data.contributorsByLastname.pagination)
            },
            error => {
                // returned an empty contributors' array
                this._contributors.next([])
                // add an error
                this.errorMessagesServices.addErrorsMessage(error)
            },
        )
    }

    findAllContributors(pagination): Observable<QueryResultWithPaginationInterface<Contributor>> {
        return cacheAndNetworkQuery(
            this.apollo,
            {
                query: findAllContributorsQuery,
                variables: {
                    search: { term: '' },
                    options: {
                        pagination,
                    },
                },
            },
            ['data', 'contributors'],
        )
    }
}
