import { languageFragment } from '../languages/language.fragment'

export const tagTranslationFragment = `
${languageFragment}

fragment tagTranslationInfo on TagTranslation {
    tagId
    title
    slug
    isHotTopic
    hotTopicStartAt
    hotTopicEndAt
    description
    language {
        ...languageInfo
    }
}
`

export const tagFragment = `
${tagTranslationFragment}

fragment tagInfo on Tag {
    id
    slug
    isSelectableCms
    isModerated
    showOpinionDisclaimer
    isSpecific
    warningMessage
    translations {
      ...tagTranslationInfo
    }
}
`
