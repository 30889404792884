import { Injectable } from '@angular/core'
import { InputImageMetadataDto } from 'app/shared/input-image/input-image-metadata/input-image-metadata-dto'
import { Story } from 'entities/story'
import { ImageFile, Image, MetasImage } from 'models/media.model'
import { TagModel } from 'models/tag.model'
import { LanguagesService } from 'services/languages/languages'

export class InputImageDto {
    id?: number
    imageProviderId: number | null = null
    usageInfos: string = ''
    metadata: InputImageMetadataDto[] = []
    tags: TagModel[] = []
    fileUploaded: ImageFile | null = null
    naturalWidth: number | null = null
    naturalHeight: number | null = null
    fileToUpload: File | null = null

    asImage(): Image {
        const image: Image = {
            imageProviderId: this.imageProviderId || undefined,
            usageInfos: this.usageInfos,
            metasStory: this.metadata.map(meta => meta.toMetasImage()),
        }

        if (this.id) {
            image['id'] = this.id
        }

        return image
    }

    asImageGallery(galleryId: number | undefined): Image {
        const image: Image = {
            imageProviderId: this.imageProviderId || undefined,
            usageInfos: this.usageInfos,
            metas: this.metadata.map(meta => meta.toMetasImage()),
        }

        if (this.id) {
            image['id'] = this.id
        }

        if (galleryId) {
            image['galleryId'] = galleryId
        }

        return image
    }
}

@Injectable()
export class InputImageDtoService {
    constructor(private languagesService: LanguagesService) {}

    public fromStory(story: Story): InputImageDto {
        const dto = new InputImageDto()
        const metadata = story.getMainImageMetas()
        const currentImage = story.getMainImage()

        dto.metadata.push(this.getMetadata(metadata, story.language.id))

        dto.usageInfos = (currentImage && currentImage.usageInfos) || ''
        dto.imageProviderId = currentImage && currentImage.provider ? currentImage.provider.id : null
        if (currentImage && currentImage.id) {
            dto.id = currentImage.id
        }

        return dto
    }

    public fromImage(image: Image, gallery: number | undefined, useMetadata: MetasImage[] | undefined): InputImageDto {
        const dto = new InputImageDto()
        dto.usageInfos = image.usageInfos || ''
        dto.imageProviderId = image.provider ? image.provider.id : null
        dto.tags = image.tags || []
        dto.id = image.id

        if (!useMetadata) {
            useMetadata = gallery ? image.metasGallery : image.metas
        }

        this.languagesService.languages.forEach(language => {
            const foundMetadata = useMetadata!.find(el => el.langId === language.id)
            let inputImageMetadata: InputImageMetadataDto

            if (foundMetadata) {
                inputImageMetadata = this.getMetadata(foundMetadata, language.id)
            } else {
                inputImageMetadata = new InputImageMetadataDto()
                inputImageMetadata.langId = language.id
            }

            inputImageMetadata.textDirection = language.isRTL ? 'rtl' : 'ltr'
            dto.metadata.push(inputImageMetadata)
        })

        return dto
    }

    /**
     * Prepare InputImageMetadataDto with the given metadata and language id
     * @param meta
     * @param langId
     */
    private getMetadata(meta: any, langId: number): InputImageMetadataDto {
        const inputImageMetadata = new InputImageMetadataDto()
        inputImageMetadata.altText = meta.altText || meta.caption || ''
        inputImageMetadata.langId = langId
        inputImageMetadata.copyright.text = (meta.copyright && meta.copyright.text) || ''
        inputImageMetadata.copyright.link = (meta.copyright && meta.copyright.link) || ''
        inputImageMetadata.source.text = (meta.source && meta.source.text) || ''
        inputImageMetadata.source.link = (meta.source && meta.source.link) || ''
        inputImageMetadata.callToAction.text = (meta.callToAction && meta.callToAction.text) || ''
        inputImageMetadata.callToAction.link = (meta.callToAction && meta.callToAction.link) || ''

        return inputImageMetadata
    }
}
