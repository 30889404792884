import { EventEmitter, Input, Directive } from '@angular/core'
import { ClrDatagridFilterInterface, ClrDatagridStateInterface } from '@clr/angular'

@Directive()
export abstract class DatagridAbstractFilterComponent<T> implements ClrDatagridFilterInterface<any> {
    @Input() displayFooter: boolean = true
    @Input() filterName: string

    value: T
    // This is the Clarity interface, we only ever use the ‘isActive’
    changes: EventEmitter<any> = new EventEmitter<any>(false)
    open = false

    /**
     * Return the filters from the ClrDatagridStateInterface or null if no filters are set
     *
     * @param {ClrDatagridStateInterface} state
     * @return {{}|null}
     */
    static getDatagridFilters(state?: ClrDatagridStateInterface): Record<string, any> | null {
        if (!state || !state.filters) {
            return null
        }

        let filters = {}
        state.filters.forEach((el: DatagridAbstractFilterComponent<any>) => {
            filters = Object.assign(filters, el.getFilter())
        })

        return filters
    }

    /**
     * Not used here, only with non server driving datagrid
     * @param _
     * @return {boolean}
     */
    accepts(_: any): boolean {
        return true
    }

    /**
     * Inform the datagrid if a filter is effectively active
     *
     * @return {boolean}
     */
    isActive() {
        return this.customIsActive()
    }

    /**
     * Prepare checked values and then emit the change event
     */
    filter() {
        this.prepareValue()
        this.changes.emit(true)
        this.open = false
    }

    /**
     * Return the filter value as an object with a property 'filterName' with the value of the filter
     *
     * @return {{}}
     */
    getFilter(): Record<string, any> {
        const ret = {}
        ret[this.filterName] = this.value

        return ret
    }

    /**
     * Return true if a filter is currently active
     *
     * @return {boolean}
     */
    protected abstract customIsActive(): boolean

    /**
     * Prepare the value just before sending event to datagrid
     */
    protected abstract prepareValue(): void

    /**
     * Reset the value to remove the filter
     */
    abstract resetValue(): void
}
