import { gql } from '@apollo/client/core'

export const youtubeVideoQuery = gql`
    query youtubeVideo($id: String!) {
        youtubeVideo(id: $id) {
            channelTitle
        }
    }
`
export const euronewsVideoFromYoutubeQuery = gql`
    query euronewsVideoFromYoutube($id: String!) {
        euronewsVideoFromYoutube(id: $id) {
            url
            langId
            eventId
        }
    }
`
