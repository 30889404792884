import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import { Apollo } from 'apollo-angular'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap } from 'rxjs/operators'
import { LanguagesService } from 'services/languages/languages'
import { cacheAndNetworkQuery, networkQuery } from 'tools/apollo'
import { TagsModerationStatus, TagsSelectableStatus } from '../../enums/tags-moderation-status'
import { PaginationInterface, QueryResultWithPaginationInterface, SortInterface } from '../../interfaces/mercury'
import { LanguageModel } from '../../models/language.model'
import { TagModel } from '../../models/tag.model'
import { TranslationTagModel } from '../../models/translation.model'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import {
    deleteTag,
    moderateTag,
    tagByIdQuery,
    tagsSearch,
    tagsQuery,
    upsertTag,
    toggleOpinionDisclaimer,
    specifyTag,
    disableTag,
} from './tags.query'

@Injectable()
export class TagsService extends BaseService {
    constructor(
        apollo: Apollo,
        protected errorMessagesServices: ErrorsMessagesService,
        protected languagesService: LanguagesService,
    ) {
        super(apollo, errorMessagesServices)
    }

    getAllTags(moderationStatus: TagsModerationStatus, pagination?: PaginationInterface, order?: SortInterface) {
        return cacheAndNetworkQuery<QueryResultWithPaginationInterface<TagModel>>(
            this.apollo,
            {
                query: tagsQuery,
                variables: {
                    moderationStatus,
                    pagination,
                    order,
                },
            },
            ['data', 'tags'],
        ).pipe(
            map(tags => {
                return {
                    results: this.prepareTags(tags.results),
                    pagination: tags.pagination,
                }
            }),
            catchError(error => {
                // add an error
                this.errorMessagesServices.addErrorsMessage(error)

                return of({
                    results: [],
                    pagination: {
                        total: 0,
                        limit: 0,
                        offset: 0,
                    },
                })
            }),
        )
    }

    getTagsByTitleAndLanguage(
        title: string,
        language: number | undefined,
        moderationStatus: TagsModerationStatus,
        selectableStatus: TagsSelectableStatus,
        isHotTopic: boolean,
        pagination?: PaginationInterface,
    ) {
        const variables = {
            search: {
                term: title,
                language,
                moderationStatus,
                selectableStatus,
            },
            pagination,
        }
        if (isHotTopic) {
            variables.search['isHotTopic'] = isHotTopic
        }

        return cacheAndNetworkQuery<QueryResultWithPaginationInterface<TagModel>>(
            this.apollo,
            {
                query: tagsSearch,
                variables,
            },
            ['data', 'tags'],
        ).pipe(
            map(tags => {
                return {
                    results: this.prepareTags(tags.results),
                    pagination: tags.pagination,
                }
            }),
            catchError(error => {
                // add an error
                this.errorMessagesServices.addErrorsMessage(error)

                return of({
                    results: [],
                    pagination: {
                        total: 0,
                        limit: 0,
                        offset: 0,
                    },
                })
            }),
        )
    }

    getTagById(id: number): Observable<TagModel> {
        return networkQuery(
            this.apollo,
            {
                query: tagByIdQuery,
                variables: {
                    id,
                },
            },
            ['data', 'tag'],
        ).pipe(map((tag: TagModel) => this.prepareTag(tag)))
    }

    prepareTags(tags: TagModel[]): TagModel[] {
        return tags.map((tag: TagModel) => {
            return this.prepareTag(tag)
        })
    }

    prepareTag(tag: TagModel): TagModel {
        const newTag = Object.assign({}, tag)
        if (newTag.translations) {
            newTag.translations = newTag.translations.map(translation => Object.assign({}, translation))

            // we only display translations in the config language
            newTag.translations = newTag.translations.filter(translation => this.translationIsDisplayed(translation))

            this.languagesService.languages.forEach((language: LanguageModel) => {
                if (!this.getTranslationForGeneral(language, newTag)) {
                    newTag.translations.push({
                        tagId: newTag.id,
                        language,
                        title: '',
                        slug: '',
                    })
                }
                // We sort the translation with the language id
                newTag.translations.sort(
                    (t1: TranslationTagModel, t2: TranslationTagModel) => t1.language.id - t2.language.id,
                )
            })
        }

        return newTag
    }

    translationIsDisplayed(tagTranslation: TranslationTagModel): boolean {
        return !!this.languagesService.languageById[tagTranslation.language.id]
    }

    getTranslation(language: LanguageModel, tag: TagModel): TranslationTagModel | null {
        if (!tag || !tag.translations) {
            return null
        }
        const hasTranslations = tag.translations.filter(
            (translations: TranslationTagModel) => translations.language.id === language.id && translations.slug !== '',
        )

        return hasTranslations.length ? hasTranslations[0] : null
    }

    getTranslationForGeneral(language: LanguageModel, tag: TagModel): TranslationTagModel | null {
        if (!tag || !tag.translations) {
            return null
        }
        const hasTranslations = tag.translations.filter(
            (translations: TranslationTagModel) => translations.language.id === language.id,
        )

        return hasTranslations.length ? hasTranslations[0] : null
    }

    updateTag(tag: any): Observable<TagModel> {
        return this.apollo
            .mutate({
                mutation: upsertTag,
                variables: { tag },
            })
            .pipe(
                map((res: ApolloQueryResult<{ upsertTag: TagModel }>) => {
                    return this.prepareTag(res.data.upsertTag)
                }),
            )
    }

    moderateTag(tagId: number, approved: boolean): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: moderateTag,
                variables: {
                    tagId,
                    approved,
                },
            })
            .pipe(
                mergeMap(result => {
                    return of(Boolean(result))
                }),
            )
    }

    disableTag(tagId: number, disabled: boolean): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: disableTag,
                variables: {
                    tagId,
                    disabled,
                },
            })
            .pipe(
                mergeMap(result => {
                    return of(Boolean(result))
                }),
            )
    }

    toggleOpinionDisclaimer(tagId: number, showOpinionDisclaimer: boolean): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: toggleOpinionDisclaimer,
                variables: {
                    tagId,
                    showOpinionDisclaimer,
                },
            })
            .pipe(
                mergeMap(result => {
                    return of(Boolean(result))
                }),
            )
    }

    specifyTag(tagId: number, isSpecific: boolean, message: string): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: specifyTag,
                variables: {
                    tagId,
                    isSpecific,
                    message,
                },
            })
            .pipe(
                mergeMap(result => {
                    return of(Boolean(result))
                }),
            )
    }

    insertTag(tag: any): Observable<TagModel> {
        return this.apollo
            .mutate({
                mutation: upsertTag,
                variables: { tag },
            })
            .pipe(
                map((res: ApolloQueryResult<{ upsertTag: TagModel }>) => {
                    return this.prepareTag(res.data.upsertTag)
                }),
            )
    }

    deleteTag(tagId: number): Observable<boolean> {
        return this.apollo
            .mutate({
                mutation: deleteTag,
                variables: { tagId },
            })
            .pipe(
                tap((data: any) => {
                    return of(Boolean(data.data['deleteTag']))
                }),
            )
    }
}
