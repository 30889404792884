import { Injectable } from '@angular/core'
import { TechnicalTagModel } from 'models/technical-tag.model'
import { CompleterItem } from 'ng2-completer'
import { Subject } from 'rxjs'
import { map } from 'rxjs/operators'
import { TechnicalTagsService } from './technical-tags'

@Injectable()
export class TechnicalTagsAutocompleter extends Subject<CompleterItem[]> {
    excludedId: number[] = []

    constructor(protected technicalTagsService: TechnicalTagsService) {
        super()
    }

    public addExcludedId(idList: number[]) {
        this.excludedId.push(...idList)
    }

    public removeExcludedId(idList: number[]) {
        this.excludedId = this.excludedId.filter(el => {
            return !idList.includes(el)
        })
    }

    public removeExcludeIdsByTag(tag: TechnicalTagModel) {
        this.removeExcludedId(this.buildTagExcludeIds(tag))
    }

    public addExcludeIdsByTag(tag: TechnicalTagModel) {
        this.addExcludedId(this.buildTagExcludeIds(tag))
    }

    public search(term: string): void {
        this.technicalTagsService
            .getTechnicalTagByName(term, this.excludedId)
            .pipe(
                map((technicalTagsList: TechnicalTagModel[]) => {
                    if (!technicalTagsList || technicalTagsList.length < 1) {
                        return []
                    }

                    const matches: CompleterItem[] = technicalTagsList.map((technicalTag: TechnicalTagModel) => {
                        return {
                            title: this.getTitle(technicalTag),
                            originalObject: technicalTag,
                        }
                    })

                    return matches
                }),
            )
            .subscribe(matches => {
                this.next(matches)
            })
    }

    public cancel(): void {
        this.next()
    }

    /**
     * Return the title to use in the autocompleter
     *
     * @param technicalTag
     */
    public getTitle(technicalTag: TechnicalTagModel, glue: string = ' > '): string {
        return this.buildTitle(technicalTag).reverse().join(glue)
    }

    /**
     * Recursively run through the tag hierarchy to build the array of title
     * @param technicalTag
     */
    protected buildTitle(technicalTag: TechnicalTagModel): string[] {
        const title: string[] = [technicalTag.name]

        if (technicalTag.parent) {
            title.push(...this.buildTitle(technicalTag.parent))
        }

        return title
    }

    /**
     * Recursively run through the tag hierarchy to build an array of id to exclude form the search
     *
     * @param tag
     */
    protected buildTagExcludeIds(technicalTag: TechnicalTagModel) {
        const ids: number[] = []
        if (technicalTag.id) {
            ids.push(technicalTag.id)
        }
        if (technicalTag.parent) {
            ids.push(...this.buildTagExcludeIds(technicalTag.parent))
        }

        return ids
    }
}
