import { gql } from '@apollo/client/core'
import { imageFragment } from 'services/shared/image.fragment'
import { themeWithTranslatedVerticalsFragment } from 'services/themes/themes.fragment'
import { paginationFragment } from '../shared/pagination.fragment'

export const storiesQuery = gql`
    ${paginationFragment}
    ${themeWithTranslatedVerticalsFragment}
    ${imageFragment}

    query eventsTodoQuery($search: SearchInput, $options: QueryOptions) {
        stories(search: $search, options: $options) {
            pagination {
                ...paginationInfo
            }
            results {
                id
                createdAt
                publishedAt
                lastPublishedAt
                isIndexed
                articleHeadline
                shortTitle
                urlTitleSlug
                masterCms
                isBreakingNews
                daletLink {
                    storyId
                    langId
                    daletEventId
                }
                event {
                    id
                    daletTitleName
                    isMagazine
                    publicationOwner {
                        id
                        slug
                        labelLong
                        canonicalUrl
                    }
                    program {
                        id
                        translations(language: en) {
                            language {
                                id
                                isoCode
                            }
                            title
                        }
                        canonicalUrl
                        theme {
                            ...themeWithTranslatedVerticalsInfo
                        }
                    }
                    programDeliverable {
                        id
                        slug
                    }
                    productionNote
                    deadlineAt
                    pyramidDesk {
                        id
                        title
                    }
                    pyramidStatus {
                        id
                        title
                        abbreviation
                    }
                    embargoDate
                }
                author {
                    id
                    translations(language: en) {
                        contributorId
                        language {
                            id
                            isoCode
                        }
                        surname
                        forename
                    }
                }
                firstPublisher {
                    id
                    translations(language: en) {
                        contributorId
                        language {
                            id
                            isoCode
                        }
                        surname
                        forename
                    }
                }
                assignee {
                    id
                    translations(language: en) {
                        contributorId
                        language {
                            id
                            isoCode
                        }
                        surname
                        forename
                    }
                }
                publicationStatus {
                    id
                    slug
                }
                language {
                    id
                    isoCode
                    websiteSubdomain
                }
                themes {
                    id
                    slug
                    translations(language: en) {
                        language {
                            id
                            isoCode
                        }
                        title
                    }
                }
                images {
                    ...imageInfo
                    storyImageTypeId
                }
                type {
                    id
                    slug
                    translations(language: en) {
                        title
                    }
                }
                publicationChannels {
                    id
                    labelLong
                    labelShort
                }
                lockedBy
                videos {
                    url
                }
            }
        }
    }
`
