import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter } from 'rxjs/operators'
import { cacheAndNetworkQuery } from 'tools/apollo'
import { PaginationInterface, ResultPaginationInterface } from '../../interfaces/mercury'
import { PublicationOwnerModel } from '../../models/publication.model'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { publicationOwnerQuery } from './publication-owner.query'

@Injectable()
export class PublicationOwnerService extends BaseService {
    /**
     *
     * @type BehaviorSubject<PublicationOwnerModel[]>
     */

    protected _publicationOwner: BehaviorSubject<PublicationOwnerModel[]> = new BehaviorSubject(
        [] as PublicationOwnerModel[],
    )
    public publicationOwner = this._publicationOwner
        .asObservable()
        .pipe(filter(publicationOwner => Boolean(publicationOwner && publicationOwner.length)))

    /**
     *
     * @type BehaviorSubject<any>
     */
    protected _pagination: BehaviorSubject<ResultPaginationInterface | undefined> = new BehaviorSubject(undefined)

    /**
     * @type Observable<any>
     */
    public pagination: Observable<any> = this._pagination.asObservable().pipe(filter(value => !!value))

    /**
     *
     * @param apollo
     */
    constructor(apollo: Apollo, errorsMessagesService: ErrorsMessagesService) {
        super(apollo, errorsMessagesService)
    }

    /**
     *
     * @param pagination
     * @returns {Observable<any>}
     */
    getPublicationOwner(pagination?: PaginationInterface) {
        cacheAndNetworkQuery(
            this.apollo,
            {
                query: publicationOwnerQuery,
                variables: {
                    pagination,
                },
            },
            ['data'],
        ).subscribe(
            (data: { publicationOwners }) => {
                this._publicationOwner.next(data.publicationOwners.results)
                this._pagination.next(data.publicationOwners.pagination)
            },
            error => {
                // returned an empty themes' array
                this._publicationOwner.next([])
                // add an error
                this.errorMessagesServices.addErrorsMessage(error)
            },
        )
    }
}
