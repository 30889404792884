<!-- ROW DETAILS -->
<clr-dg-row-detail [clrLoading]="loading" class="c-row-details">
    <!-- channel & story name details -->
    <clr-dg-cell>
        <span *ngIf="story.assignee | contributorGetName"
            ><strong>Created by : </strong>{{ story.assignee | contributorGetName }}</span
        ><br />
        <span class="p4"><strong>Creation date : </strong>{{ story.createdAt | formatDate }}</span>
    </clr-dg-cell>

    <!-- headline details -->
    <clr-dg-cell>
        <img class="c-dashboard-details__img" [src]="story | storyImageUrl : 120 : 65 | async" data-fallbackenv="1" />
        <br />
        <a [routerLink]="['/story', story.id]" target="_blank"
            >Article ID : <strong>#{{ story.id }}</strong>
        </a>
    </clr-dg-cell>

    <!-- desk details -->
    <clr-dg-cell></clr-dg-cell>

    <!-- assigned to details -->
    <clr-dg-cell></clr-dg-cell>

    <!-- program & vertical details -->
    <clr-dg-cell>
        <span class="c-dashboard__detail__theme"><strong>Themes :</strong></span>
        <ng-template ngFor let-theme [ngForOf]="story.themes" let-last="last">
            <ng-template [ngIf]="theme.translations.length > 0">
                {{
                    (theme.translations | translationFilter : 'title' : story.language.isoCode) ||
                        (theme.translations | translationFilter : 'title')
                }}
            </ng-template>
            <ng-template [ngIf]="!theme.translations || theme.translations.length < 1">
                <span class="themeAlert">Status : {{ theme.slug }}</span>
            </ng-template>
            <span *ngIf="last === false">, </span>
        </ng-template>
    </clr-dg-cell>
    <!-- lang details -->
    <clr-dg-cell class="langageStatus u-w80p">
        <cms-stories-status [story]="story"></cms-stories-status>
    </clr-dg-cell>

    <!-- story type details -->
    <clr-dg-cell></clr-dg-cell>
    <!-- pub date details -->
    <clr-dg-cell></clr-dg-cell>
    <!-- last update details -->
    <clr-dg-cell></clr-dg-cell>
    <!-- pub status details -->
    <clr-dg-cell></clr-dg-cell>
</clr-dg-row-detail>
