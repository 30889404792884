import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { FeedOverride } from 'models/partner.model'
import { Observable, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { BaseService } from 'services/base'
import { ErrorsMessagesService } from 'services/errors-messages/errors-messages.service'
import { partnerFeedOverridesQuery } from 'services/partner-feed/partner-feed-infos.query'
import { cacheAndNetworkQuery } from 'tools/apollo'

@Injectable()
export class PartnerFeedOverrideService extends BaseService {
    constructor(apollo: Apollo, protected errorsMessagesService: ErrorsMessagesService) {
        super(apollo, errorsMessagesService)
    }

    list(): Observable<FeedOverride[]> {
        return cacheAndNetworkQuery(
            this.apollo,
            {
                query: partnerFeedOverridesQuery,
                variables: {},
            },
            ['data', 'partnerFeedOverrides'],
        ).pipe(
            switchMap((list: FeedOverride[]) => {
                return of(list)
            }),
            catchError(error => {
                this.errorMessagesServices.addErrorsMessage(error)

                return of([])
            }),
        )
    }
}
