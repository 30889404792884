import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { ErrorsMessagesService } from './errors-messages/errors-messages.service'

@Injectable()
export class BaseService {
    protected apollo: Apollo

    constructor(apollo: Apollo, protected errorMessagesServices: ErrorsMessagesService) {
        this.apollo = apollo
    }
}
