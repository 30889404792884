import { languageFragment } from '../languages/language.fragment'

export const contributorFragment = `
${languageFragment}

fragment contributorInfo on Contributor {
    id,
    slug,
    imageId,
    twitterScreenName
    isActive
    isImageActive
    isBioActive
    isBetaTester
    writtenLanguage{
        ...languageInfo
    }
    department {
        id
        description
        translations {
            title
            language{
                ...languageInfo
            }
        }
    }
    job {
        id
        description
        translations {
            title
            language{
                ...languageInfo
            }
        }
    }
    translations {
        slug
        contributorId
        surname
        forename
        country
        biography
        onlineDescription
        language {
            ...languageInfo
        }
    }
}
`
