export const eventFragment = `
  fragment eventInfos on Event {
    id
    isEditable
    isMultiplex
    isMagazine
    isBreakingNews
    isSingleMedia
    isTv
    isDigital
    editorialDesk
    embargoDate
    eventSource {
        id
    }
    producerLanguage {
        id
        isoCode
    }
    daletTitleName
    productionNote
    daletLink {
        daletEventId
        langId
    }
    publicationOwner {
        id
        slug
        canonicalUrl
    }
    program {
        id
        slug
        canonicalUrl
        category {
            id
        }
    }
    programDeliverable {
        id
        slug
    }
    stories {
        id
        language {
            id
            isoCode
        }
        publicationStatus {
            id
            slug
        }
        language {
            id
            isoCode
            fullname
        }
        videos {
            url
            quality {
                id
                slug
            }
        }
    }
  }
`

export const eventSearchFragment = `
  fragment eventSearchInfos on Event {
    ...eventInfos
    program {
        id
        translations(language: en) {
            language {
                id
                isoCode
            }
            title
        }
        canonicalUrl
        theme {
            ...themeWithTranslatedVerticalsInfo
        }
    }
    createdAt
    updatedAt
    assignee {
        id
        translations(language: en) {
            contributorId
            language {
                id
                isoCode
            }
            surname
            forename
        }
    }
    pyramidDesk {
        id
        title
    }
    pyramidStatus {
        id
        title
        abbreviation
    }
  }
`
