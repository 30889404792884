import { QueryOptionsInterface, SortInterface } from '../../interfaces/mercury'

export function getQueryOptions(limit: number, offset: number, order?: SortInterface): QueryOptionsInterface {
    const options = {
        pagination: {
            limit,
            offset,
        },
    } as QueryOptionsInterface

    if (order) {
        options['order'] = order
    }

    return options
}
