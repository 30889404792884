import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { ClrDatagridStateInterface } from '@clr/angular'
import { DashboardAbstract } from 'app/shared/dashboard/dashboard-abstract.component'
import { DASHBOARD_TYPE } from 'app/shared/dashboard/dashboard.enums'
import { DatagridAbstractFilterComponent } from 'app/shared/filter/datagrid-abstract-filter.component'
import {
    ContributorDatagridFiltersInterface,
    FilterableInterface,
} from 'app/shared/filter/datagrid-filterables/filterables.interface'
import { IAppConfig } from 'app/shared/i-app-config'
import { APP_CONFIG } from 'app/shared/shared.config'
import { Story } from 'entities/story'
import { ERoomType } from 'enums/contributors-notification'
import { DashboardSearchFiltersStories } from 'enums/filters'
import { StoryTypeSlug } from 'enums/story'
import { ContributorOnPages } from 'models/contributors-notification.model'
import { StoryModel } from 'models/story.model'
import { combineLatest, Observable } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, take, takeUntil } from 'rxjs/operators'
import { AuthService } from 'services/auth/auth'
import { DashboardConstantsService } from 'services/dashboard/dashboard-constants.service'
import { DashboardFiltersListService } from 'services/dashboard/filters-list/dashboard-filters-list.service'
import { StoriesService } from 'services/dashboard/stories-service'
import { DashboardFilterHelper } from 'services/helpers/dashboard-filters.helper'
import { LanguagesService } from 'services/languages/languages'
import { ContributorsNotificationService } from 'services/notifications/contributors-notification.service'
import { VerticalService } from 'services/verticals/verticals.service'
import { decodeJSON } from 'tools/json-encoder'

@Component({
    selector: 'cms-dashboard-stories',
    templateUrl: './dashboard-stories.component.html',
    styleUrls: ['../dashboard.component.scss', './dashboard-stories.component.scss'],
})
export class DashboardStoriesComponent extends DashboardAbstract<DashboardSearchFiltersStories> implements OnInit {
    dashboardType: DASHBOARD_TYPE = DASHBOARD_TYPE.STORIES

    // Datagrid filters
    public storiesWithVideoList: FilterableInterface[] = []
    public storyTypesList: FilterableInterface[] = []

    public connectedContributorData: ContributorOnPages = {}

    public stories: Story[] = []

    constructor(
        private notification: ContributorsNotificationService,
        private cdr: ChangeDetectorRef,
        private elementRef: ElementRef,
        private storiesService: StoriesService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected dashboardFiltersListService: DashboardFiltersListService,
        protected dashboardConstantsService: DashboardConstantsService,
        protected authService: AuthService,
        protected languagesService: LanguagesService,
        protected verticalsService: VerticalService,
        @Inject(APP_CONFIG) private sharedConfig: IAppConfig,
    ) {
        super(
            route,
            router,
            dashboardFiltersListService,
            dashboardConstantsService,
            authService,
            languagesService,
            verticalsService,
        )
        this.itemsPerPage = this.sharedConfig.itemPerPage.dashboardEvents
        this.onFetchContributorsEditingStory()
    }

    ngOnInit(): void {
        this.setDefaultFilterValues()

        // Load filters lists
        this.loadChannelsList()
        this.loadDesksList()
        this.loadCurrentContributorList()
        this.loadLanguagesList()
        this.loadStatusesList()
        this.loadStoriesWithVideoList()

        combineLatest([
            // Load async filters lists
            this.loadStoryTypesList(),
            this.loadProgramsList(),
            this.loadVerticalsList(),
        ])
            .pipe(take(1))
            .subscribe(() => {
                // Init search subscriptions
                this.initNextSearchSubscription()
                this.initRouterStoriesSubscription()
                this.initRefreshSubscription(this.storiesService)
            })
    }

    protected loadStatusesList(): void {
        this.statusesList = this.dashboardFiltersListService.loadStatusFilterList()
    }

    /**
     * Toggle the languages filter to select all or just the default language
     */
    protected toggleAllLanguages(): void {
        // Clone the languages list to trigger the change detection with the selected elements
        this.languagesList = this.languagesList.map(language => {
            return {
                ...language,
                selected: !this.checkedAllLanguages || this.getContributorDefaultLanguage().id === language.id,
            }
        })
    }

    /**
     * Get the dashboard search filters from the datagrid state
     */
    protected getDashboardSearchFiltersFromDatagrid(state: ClrDatagridStateInterface): DashboardSearchFiltersStories {
        const filters = DatagridAbstractFilterComponent.getDatagridFilters(state) || {}
        this.setContributorDateFilter(this.FilterName.PUBLICATION_DATE, filters)
        this.setContributorDateFilter(this.FilterName.LAST_UPDATE_DATE, filters)

        return DashboardFilterHelper.applyDatagridFiltersToSearchFiltersStories(
            this.dashboardSearchFilters,
            filters,
            this.contributorLanguage,
        )
    }

    /**
     * Init the subscription to the nextSearch subject
     * to trigger the search when the filters are updated
     */
    private initNextSearchSubscription(): void {
        // Small debounce time to group the calls
        // when changing multiple filters at the same time
        const SIMULTANEOUS_FILTERS_DEBOUNCE_TIME = 1

        this.nextSearch
            .pipe(
                takeUntil(this.ngUnsubscribe),
                debounceTime(SIMULTANEOUS_FILTERS_DEBOUNCE_TIME),
                switchMap(() => this.reloadWithQueryParamFilters()),
            )
            .subscribe()
    }

    /**
     * Listen to the contributors notification service to get the connected contributors
     */
    private onFetchContributorsEditingStory(): void {
        this.notification
            .onFetchData(ERoomType.STORY)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((contributorsConnectedList: ContributorOnPages) => {
                this.connectedContributorData = contributorsConnectedList
            })
    }

    /**
     * Init the subscription to the router stories
     * to trigger the search when the dashboard is loaded
     * using the query params
     */
    private initRouterStoriesSubscription(): void {
        this.router.onSameUrlNavigation = 'reload'
        this.router.events
            .pipe(
                takeUntil(this.ngUnsubscribe),
                // Trigger only once per navigation
                filter(event => event instanceof NavigationEnd),
                // Trigger the search when the component is loaded the first time
                startWith(null),
                // Get the query params
                map(() => ({
                    page: this.route.snapshot.queryParams.storiesPage,
                    filters: this.route.snapshot.queryParams.storiesFilters,
                })),
                // Trigger when the query params change
                // Or when the dashboard needs to be reset (creation of new event)
                distinctUntilChanged(
                    (prev, curr) =>
                        !this.router.getCurrentNavigation()?.extras.state?.refreshDashboard &&
                        JSON.stringify(prev) === JSON.stringify(curr),
                ),
                switchMap(params => {
                    this.isLoading = true

                    if (!this.router.getCurrentNavigation()?.extras.state?.noScrollToTop) {
                        setTimeout(() => {
                            // Scroll to the top of the page when the datagrid is refreshed
                            // A timeout is needed to wait for the datagrid to be rendered so the scroll is not stopped
                            this.elementRef.nativeElement.parentElement.scrollTo({
                                top: this.elementRef.nativeElement.offsetTop,
                                left: 0,
                                behavior: 'smooth',
                            })
                        })
                    }

                    try {
                        const decodedFilters = decodeJSON<ContributorDatagridFiltersInterface>(params.filters)
                        this.onContributorFiltersSelected(decodedFilters)
                    } catch (_) {
                        this.clearAllFilters()
                    }

                    // Detect changes to trigger datagrid refresh and dashboardSearchFilters update
                    this.cdr.detectChanges()

                    // Set the page offset after detectChanges because the datagrid resets it on filter change
                    this.pageOffset = params.page ? (+params.page - 1) * this.itemsPerPage : 0

                    // On the first load, the total is 0,
                    // so we need to fake it so the page we try to reach exists
                    const totalNeededToDisplayPage = this.pageOffset + this.itemsPerPage
                    if (this.total < totalNeededToDisplayPage) {
                        this.total = totalNeededToDisplayPage
                    }

                    return this.storiesService.list({
                        filters: this.dashboardSearchFilters,
                        limit: this.itemsPerPage,
                        offset: this.pageOffset,
                        order: this.order,
                    })
                }),
            )
            .subscribe(result => {
                this.stories = result.results.map(story => new Story(story))
                this.total = result.pagination.total || 0
                this.isLoading = false
            })
    }

    private loadStoriesWithVideoList(): void {
        this.storiesWithVideoList = this.dashboardConstantsService.STORIES_WITH_VIDEO_LIST
    }

    private loadStoryTypesList(): Observable<void> {
        return this.dashboardFiltersListService.loadStoryTypeList(this.contributorLanguage).pipe(
            takeUntil(this.ngUnsubscribe),
            map(storyList => {
                this.storyTypesList = storyList
            }),
        )
    }

    public getStoryType(story: StoryModel): string {
        const storyTypeTranslations = story.type?.translations

        return story.type && storyTypeTranslations ? storyTypeTranslations[0].title : StoryTypeSlug.NORMAL
    }
}
