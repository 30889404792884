import { AlertType } from 'app/shared/alert-item/alert-item.component'

export interface ErrorMessage {
    id: number
    message: string
    buttonLabel: string
    buttonLink: string
    level: AlertType
    targetRoles: string
    excludedUsers: string[]
}
export interface CloseMessagePayload {
    username: string
    messageId: number
}

export enum EMessagesNotification {
    CLOSE_MESSAGE = 'CLOSE_MESSAGE',
    ON_READ_ALL_MESSAGES = 'ON_READ_ALL_MESSAGES',
    SUBSCRIBE_TO_MESSAGES = 'SUBSCRIBE_TO_MESSAGES',
}
