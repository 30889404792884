import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter } from 'rxjs/operators'
import { cacheAndNetworkQuery } from 'tools/apollo'
import { DaletFile, DaletFolder } from '../../models/media.model'
import { categoryAssetsQuery, rootSubCategoriesQuery, subCategoriesQuery } from './files-selection.query'

@Injectable()
export class FilesSelectionService {
    /**
     *
     * @type BehaviorSubject<>
     */
    protected _categories: BehaviorSubject<any[]> = new BehaviorSubject([])

    /**
     * @type Observable<>
     */
    public categories: Observable<DaletFolder[]> = this._categories.asObservable().pipe(filter(value => !!value))

    /**
     *
     * @param api
     */
    constructor(private apollo: Apollo) {}

    getRootSubCategories(): Observable<DaletFolder[]> {
        return cacheAndNetworkQuery(
            this.apollo,
            {
                query: rootSubCategoriesQuery,
            },
            ['data', 'rootSubCategories'],
        )
    }

    getSubCategories(categoryId): Observable<DaletFolder[]> {
        return cacheAndNetworkQuery(
            this.apollo,
            {
                query: subCategoriesQuery,
                variables: {
                    categoryId,
                },
            },
            ['data', 'subCategories'],
        )
    }

    getCategoryAssets(categoryId, assetType): Observable<DaletFile[]> {
        return cacheAndNetworkQuery(
            this.apollo,
            {
                query: categoryAssetsQuery,
                variables: {
                    categoryId,
                    assetType,
                },
            },
            ['data', 'categoryAssets'],
        )
    }
}
