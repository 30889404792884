import { gql } from '@apollo/client/core'
import { paginationFragment } from '../shared/pagination.fragment'
import { contributorFragment } from './contributor.fragment'

export const findAllContributorsQuery = gql`
    ${paginationFragment}
    ${contributorFragment}

    query contributorsList($search: SearchContributorInput, $options: QueryOptions) {
        contributors(search: $search, options: $options) {
            pagination {
                ...paginationInfo
            }
            results {
                ...contributorInfo
            }
        }
    }
`
