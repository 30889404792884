import { Directive, Input, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import {
    StoryContributorsReplicatedFields,
    StoryMainHeaderReplicatedFields,
    StoryPublicationReplicatedFields,
    StoryRelatedReplicatedFields,
    StoryReplicatedFields,
    StorySettingsReplicatedFields,
    StoryTagsAndMetadataReplicatedFields,
} from 'enums/replicated-fields'
import { Subject } from 'rxjs'
import { Story } from '../../entities/story'
import { MetaReplicationPosition } from '../../enums/meta-replication-position'
import { UserRight, UserRole } from '../../enums/user-role'
import { AuthService } from '../../services/auth/auth'

/**
 * Standard feature that ALL components should use
 *  - unsubscribe all Subscriptions
 *  -
 *
 *  @todo we need also a StoryWizardBaseAbstractComponent that extends this one and add extra feature for open/close and maybe redirect
 *  (but for this one it might be a StoryWizardBaseAbstractComponent (Youhou it's a Java naming style))
 */
@Directive()
export class BaseAbstractComponent implements OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject()

    /*
        Ugly hack for datePicker
        We don't know why but we can't change hours and minutes with tabIndex on modal so we remove it
     */
    removeTabIndexOnModal() {
        const myDiv: HTMLDivElement | null = document.querySelector('div.modal')
        if (myDiv) {
            myDiv.removeAttribute('tabindex')
        }
    }

    /**
     * Ugly hack for datePicker
     * We don't know why but we can't change hours and minutes with tabIndex on modal
     * so we put it again after
     */
    addTabIndexOnModal() {
        const myDiv: HTMLDivElement | null = document.querySelector('div.modal')
        if (myDiv) {
            myDiv.setAttribute('tabindex', '0')
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next()
        this.ngUnsubscribe.complete()
    }
}

@Directive()
export class FormBaseAbstractComponent extends BaseAbstractComponent implements OnDestroy {
    public MetaReplicationPosition = MetaReplicationPosition
    public StoryPublicationReplicatedFields = StoryPublicationReplicatedFields
    public StoryRelatedReplicatedFields = StoryRelatedReplicatedFields
    public StoryTagsAndMetadataReplicatedFields = StoryTagsAndMetadataReplicatedFields
    public StorySettingsReplicatedFields = StorySettingsReplicatedFields
    public StoryMainHeaderReplicatedFields = StoryMainHeaderReplicatedFields
    public StoryContributorsReplicatedFields = StoryContributorsReplicatedFields
    public formErrors: Set<string> = new Set([])
    public formSuccess: Set<string> = new Set([])
    public formWarning: Set<string> = new Set([])
    public formGroup: FormGroup

    @Input() public fieldsToReplicateForm: FormGroup = new FormGroup({})

    public getFieldsToReplicate() {
        return Object.entries(this.fieldsToReplicateForm.value)
            .filter(field => field[1])
            .map(field => field[0] as StoryReplicatedFields)
    }
    public isDisabled(e): boolean | null {
        return (e && e.target.getAttribute('disabled') === 'disabled') || null
    }
    /**
     * simple wrapper
     */
    protected resetErrors() {
        this.formErrors.clear()

        return this
    }

    protected resetSuccess() {
        this.formSuccess.clear()

        return this
    }

    protected resetWarning() {
        this.formWarning.clear()

        return this
    }
}

/**
 * Standard feature that ALL story wizard components should use
 *  - onCancel
 *  - redirectToStory
 */
@Directive()
export class StoryWizardOrModalBaseAbstractComponent extends FormBaseAbstractComponent {
    public open: boolean = false
    public story: Story
    public UserRight = UserRight
    /**
     * you have to inject it in your child class via constructor
     */
    protected router: Router
    protected authService: AuthService

    /**
     * route the user to the digital publishing page
     */
    protected redirectToStory(): void {
        this.open = false
        this.router.navigateByUrl(`/story/${this.story.id}`)
    }

    /**
     * @description Check if user has rights to
     * @param {Array<UserRight>} rights
     * @return {boolean}
     */
    protected userHasRights(rights: UserRight[]): boolean {
        const hasRights = rights.filter(right => this.authService.userHasRight(right))

        return Boolean(hasRights.length)
    }

    /**
     * Check if user can publish current story (Magazines)
     * @return {boolean}
     */
    protected userCanPublishStory(): boolean {
        return Boolean(
            this.authService.userHasRole(UserRole.DIGITAL_ADVANCED_USER) ||
                (this.story.isReadyToPublish() && this.userCanEditCommonMeta()),
        )
    }

    /**
     * Check if user can edit common metadata on current story (Magazines)
     * @return {boolean}
     */
    userCanEditCommonMeta(): boolean {
        if (!this.story) {
            return false
        }

        if (!this.story.isMagazine()) {
            return true
        }

        return this.story.isOnline() || this.userHasReplicationRights()
    }

    /**
     * Check if user has rights to replicate metadata (Magazines)
     * @return {boolean}
     */
    userHasReplicationRights(): boolean {
        return this.userHasRights([UserRight.EDIT_COMMON_METADATA])
    }
}

/**
 * Standard feature that ALL story wizard components should use
 *  - onCancel
 *  - redirectToStory
 */
@Directive()
export class StoryWizardBaseAbstractComponent extends StoryWizardOrModalBaseAbstractComponent {
    /**
     * action called when the user cancel the form or cancel the wizard (must be binded to clr-wizard event (clrWizardOnCancel))
     */
    public onCancel(): void {
        this.redirectToStory()
    }
}

/**
 * Standard feature that ALL story wizard components should use
 *  - onCancel
 *  - redirectToStory
 */
@Directive()
export class StoryModalBaseAbstractComponent extends StoryWizardOrModalBaseAbstractComponent {
    /**
     * route the user to the digital publishing page when the open state change
     * to be binded to this clr-modal event : (clrModalOpenChange)
     */
    onOpenChanged(openStatus) {
        if (openStatus === false) {
            this.redirectToStory()
        }
    }
}
