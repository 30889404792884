export const verticalFragment = `
fragment verticalInfo on Vertical {
    id
    slug
    priority
    cssTheme
    isDisplayedInCms
}
`

export const verticalWithTranslationsFragment = `
${verticalFragment}

fragment verticalWithTranslationsInfo on Vertical {
    ...verticalInfo
    translations {
        slug
        title
        language {
            id
            isoCode
        }
    }
}
`

export const verticalWithEnTranslationFragment = `
${verticalFragment}

fragment verticalWithEnTranslationInfo on Vertical {
    ...verticalInfo
    translations (language: en) {
        slug
        title
        language {
            id
            isoCode
        }
    }
}
`
