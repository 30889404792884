export enum PublicationStatus {
    OFFLINE = 1,
    ONLINE = 3,
    DELAYED = 4,
    SCHEDULED = 5,
    IN_PROGRESS = 6,
    NEW = 7,
    EDIT = 8,
    TO_REPUBLISH = 9,
    READY_TO_PUBLISH = 10,
    IN_TRANSLATION = 12,
    TRANSLATED = 13,
    SUBMITTED = 14,
    APPROVED = 15,
    AWAITING = 16,
}

export enum PublicationStatusLabels {
    OFFLINE = 'Offline',
    ONLINE = 'Online',
    DELAYED = 'Delayed',
    SCHEDULED = 'Scheduled',
    IN_PROGRESS = 'In Progress',
    NEW = 'New',
    EDIT = 'Edit',
    TO_REPUBLISH = 'To Republish',
    READY_TO_PUBLISH = 'Ready to publish',
    IN_TRANSLATION = 'In duplication',
    TRANSLATED = 'Duplicated',
    AWAITING = 'Awaiting',
    SUBMITTED = 'Submitted',
    APPROVED = 'Approved',
}

export const PublicationStatusLabelsById: Record<number, string> = {
    [PublicationStatus.OFFLINE]: PublicationStatusLabels.OFFLINE,
    [PublicationStatus.ONLINE]: PublicationStatusLabels.ONLINE,
    [PublicationStatus.DELAYED]: PublicationStatusLabels.DELAYED,
    [PublicationStatus.SCHEDULED]: PublicationStatusLabels.SCHEDULED,
    [PublicationStatus.IN_PROGRESS]: PublicationStatusLabels.IN_PROGRESS,
    [PublicationStatus.NEW]: PublicationStatusLabels.NEW,
    [PublicationStatus.EDIT]: PublicationStatusLabels.EDIT,
    [PublicationStatus.TO_REPUBLISH]: PublicationStatusLabels.TO_REPUBLISH,
    [PublicationStatus.READY_TO_PUBLISH]: PublicationStatusLabels.READY_TO_PUBLISH,
    [PublicationStatus.IN_TRANSLATION]: PublicationStatusLabels.IN_TRANSLATION,
    [PublicationStatus.TRANSLATED]: PublicationStatusLabels.TRANSLATED,
    [PublicationStatus.SUBMITTED]: PublicationStatusLabels.SUBMITTED,
    [PublicationStatus.APPROVED]: PublicationStatusLabels.APPROVED,
    [PublicationStatus.AWAITING]: PublicationStatusLabels.AWAITING,
    [PublicationStatus.APPROVED]: PublicationStatusLabels.APPROVED,
    [PublicationStatus.SUBMITTED]: PublicationStatusLabels.SUBMITTED,
}
