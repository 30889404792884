export enum LanguageIsoCode {
    CORE_LANGUAGES = 'core_languages',
    ENGLISH = 'en',
    FRENCH = 'fr',
    GERMAN = 'de',
    ITALIAN = 'it',
    SPANISH = 'es',
    PORTUGUESE = 'pt',
    POLISH = 'pl',
    UKRAINIAN = 'uk',
    RUSSIAN = 'ru',
    TURKISH = 'tr',
    ARABIC = 'ar',
    PERSIAN = 'fa',
    GREEK = 'el',
    HUNGARIAN = 'hu',
}

export const AfricanewsLanguages = [1, 2]
